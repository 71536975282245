import React, { Component, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import { validatorDouble } from 'helpers/validatorHelpers';
import * as wf from 'components/callmode/floatingControls/windowFields';

class CompoundRow extends Component {

    shouldComponentUpdate(_nextProps) {
        return this.props.errorFlag !== _nextProps.errorFlag ||
            this.props.textValue !== _nextProps.textValue ||
            this.props.radioValue !== _nextProps.radioValue;
    }

    render() {
        return <wf.GroupField
            label={this.props.label}
            className="form-group row">
            <div className="col-xs-5 flex flex-column justify-center">
                <wf.InputField hideLabel={true}
                    extraClasses={'relative no-margin' + (this.props.errorFlag ? ' input-error' : '')}
                    value={this.props.textValue}
                    onChange={this.props.handlers.textValueHandler}
                    onError={this.props.handlers.textValueErrorHandler}
                    validator={this.props.validator}
                    addon={<>m<span className="superscript">2</span></>}
                    errorMessage={this.props.errorMessage}>
                </wf.InputField>
            </div>
            <div className="col-xs-7">
                <wf.CheckboxGroupField extraClasses="no-margin" contentExtraClasses="flex flex-wrap" hideLabel={true} values={this.props.radioValue} onChange={this.props.handlers.radioHandler} radioName={this.props.radioName} />
            </div>
        </wf.GroupField>
    }
}

function Surface({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    const surfaceHandlers = useRef({
        textValueHandler: setData('floorSurface'),
        textValueErrorHandler: setData('floorSurface'),
        radioHandler: (_v) => setData('floorSurfaceType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))
    });
    const floorPlanHandlers = useRef({
        textValueHandler: setData('floorPlan'),
        textValueErrorHandler: setData('floorPlan'),
        radioHandler: (_v) => setData('floorPlanType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))
    });
    const validator = useRef((_v) => !_v || validatorDouble(_v, 0));
    const floorSurfaceType = ['unspecified', 'wallDepth', 'fieldCounter'].reduce(_checkboxGroupHOC.checkboxGroupReducer('floorSurfaceType'), {});
    const floorPlanType = ['unspecified', 'R', 'K', 'DK', 'LK', 'LDK', 'SK', 'SDK', 'SLK', 'SLDK'].reduce(_checkboxGroupHOC.checkboxGroupReducer('floorPlanType'), {});
    return <>
        <CompoundRow
            textValue={data.floorSurface.value || data.floorSurface.defaultValue}
            radioValue={floorSurfaceType}
            handlers={surfaceHandlers.current}
            validator={validator.current}
            errorFlag={data.floorSurface && data.floorSurface.hasError}
            errorMessage={t('incorrectNumberFormatError')}
            radioName={'floorSurfaceType'}
            label={t('floorSurfaceSize')}/>
        <CompoundRow
            textValue={data.floorPlan.value || data.floorPlan.defaultValue}
            radioValue={floorPlanType}
            handlers={floorPlanHandlers.current}
            validator={validator.current}
            errorFlag={data.floorPlan && data.floorPlan.hasError}
            errorMessage={t('incorrectNumberFormatError')}
            radioName={'floorPlanType'}
            label={t('floorPlan')} />
        <wf.InputField label={t('balcony')}
            extraClasses={'relative ' + ((data.balcony || {}).hasError ? ' input-error' : '')}
            value={data.balcony.value || data.balcony.defaultValue}
            onChange={setData('balcony')}
            onError={setData('balcony')}
            validator={validator.current}
            addon={<>m<span className="superscript">2</span></>}
            errorMessage={t('incorrectNumberFormatError')}/>
    </>
}
const SurfacePopup = ExportDefaultBehaviour(Surface, {
    editableFields: ['floorSurface', 'floorSurfaceType', 'floorPlan', 'floorPlanType', 'balcony']
});
SurfacePopup.whyDidYouRender = true;
export default SurfacePopup;