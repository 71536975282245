import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function LoanNotes({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <wf.InputField label={t('loanNotes')}
        value={data.remarks.value || data.remarks.defaultValue}
        className="form-group"
        onChange={setData('remarks')} />;
}
const LoanNotesPopup = ExportDefaultBehaviour(LoanNotes, {
    editableFields: ['remarks']
});
LoanNotesPopup.whyDidYouRender = true;
export default LoanNotesPopup;