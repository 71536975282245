import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { displayMessageAction, availableActions, clearAction } from 'reducers/priorityActionsReducers';
import { submitedFiles } from 'reducers/fileManagementReducers';
import { renewReminderProcedure, cancelReminderProcedure } from 'reducers/remindersReducers'
import { FileUpload } from './fileUpload';
import { FileImportCSV } from './fileImportCSV';
import { FileImportExcel } from './fileImportExcel';
import { ImageImport } from './imageImport/imageImport';
import { DisplayReminders } from './displayReminders';
import { SimpleMessage } from './simpleMessage'
import { useTranslation } from 'react-i18next';
import { formatDateTimeShort } from 'helpers/formatHelpers';
import { SimpleMessageWithTextBox } from './simpleMessageWithTextBox';
import { GroupEditPopup } from 'components/employeeManager/employeeManagerPopups/groupEditPopup';
import { TeamEditPopup } from 'components/employeeManager/employeeManagerPopups/teamEditPopup';
import { TelCallPopUp } from '../callmode/modulesContainers/telCallPopups/telCallPopUp';
import { PropertyRoomPopup } from '../callmode/modulesContainers/propertyRoomPopups/propertyRoomPopup';
import { AddpropertyRoomPopUp } from '../callmode/modulesContainers/propertyRoomPopups/AddpropertyRoomPopUp';
import { addReinsData } from 'reducers/importAllReducers';

import { ApiEntryPoint } from 'apiConnector';

export function ModalManager({ addReinsData: _addReinsData, displayMessageAction: _displayMessageAction, currentAction: _currentAction, clearAction: _clearAction, submitFilesAction: _submitFilesAction, renewReminder: _renewReminder, cancelReminder: _cancelReminder }) {

    const { t } = useTranslation();
    const [modalDisabled, setModalDisabled] = useState(false);
    const [currentAction, setCurrentAction] = useState(_currentAction);

    const importModes = {
        afterDM: 'afterDM',
        ec: 'ec',
        csvImport3: 'csvImport3',
        csvImport4: 'csvImport4',
        callImport: 'callImport',
        callImport2: 'callImport2',
        reinsCollation: 'reinsCollation',
        jonImportProperty: 'jonImportProperty',
        jonImportOwner: 'jonImportOwner',
        csvImport: 'csvImport'
    };

    useEffect(() => {
        if (_currentAction.type) {
            setCurrentAction(_currentAction)
        }
    }, [_currentAction])

    const uploadFilesToServer = (_files) => {
        if (_files == null) {
            _clearAction();
            return;
        }
        const fileKeys = Object.keys(_files);
        if (fileKeys.length < 1) {
            _clearAction();
            return;
        }
        const { id, promise } = ApiEntryPoint.UploadFiles(_files);
        setModalDisabled(true);
        _submitFilesAction({
            id: id,
            files: fileKeys
        });

        promise.then(_response => {
            setModalDisabled(false);
            if (_response.id === id) _clearAction();
        });
    };

    const importFile = (_formData) => {
        if (_formData.files == null) {
            _clearAction();
            return;
        }
        const fileKeys = Object.keys(_formData.files);
        if (fileKeys.length !== 1) {
            _clearAction();
            _displayMessageAction(t('No items or more than one is selected'), t('No items or more than one is selected'));
            return;
        }

        const request = {};
        request.file = _formData.files[fileKeys[0]];
        request.beforeCheck = _formData.data.beforeCheck;
        request.beforeCheck2 = _formData.data.beforeCheck2;
        request.kotei = _formData.data.fixed;
        request.keitai = _formData.data.cellphone;
        request.type = _formData.data.type;

        const setApiEntryPoint = () => {
            switch (_formData.data.type) {
                case 'FailedImport':
                    return ApiEntryPoint.EXRequestFailedImport(request);
                    break;
                case 'ECImport':
                    return ApiEntryPoint.EXRequestECImport(request);
                    break;
                case 'DmExportError':
                    return ApiEntryPoint.EXRequestDMMistake(request);
                    break;
                case 'DmOfferPriceImport':
                    return ApiEntryPoint.EXRequestDMImport(request);
                    break;
                case 'BukkenImport':
                    return ApiEntryPoint.EXRequestBukkenImport(request);
                    break;
                default:
                    return ApiEntryPoint.EXRequestImportAll(request);
                    break
            }
        };

        //need to change the last part
        const { promise } = setApiEntryPoint();

        setModalDisabled(true);

        promise.then(_response => {
            if (_formData.data.type === 'reinsCollation') {
                if (!_response.error) {
                    setModalDisabled(false);
                    _clearAction();
                    _displayMessageAction(t('SUCCESS'), t('SUCCESS'));
                    _addReinsData(_response, true);
                    if (_formData._onSuccessCallback) _formData._onSuccessCallback();
                }
                else {
                    setModalDisabled(false);
                    _clearAction();
                    _displayMessageAction(t('FAIL'), t('FAIL'));
                    if (_formData._onFailureCallBack) _formData._onFailureCallBack();
                }
            }
            else {
                if (_response.ok) {
                    setModalDisabled(false);
                    _clearAction();
                    _displayMessageAction(t('SUCCESS'), t('SUCCESS'));
                    if (_formData._onSuccessCallback) _formData._onSuccessCallback();
                }
                else {
                    setModalDisabled(false);
                    _clearAction();
                    _displayMessageAction(t('FAIL'), t('FAIL'));
                    if (_formData._onFailureCallBack) _formData._onFailureCallBack();
                }
            }
        });
    };

    const renewReminder = (_id, _updatedExpiryDate) => {
        _renewReminder(_id, _updatedExpiryDate);
    }

    const clearReminder = (_reminderId) => {
        _cancelReminder(_reminderId);
    }

    const defaultParams = {
        onCancelCallback: _clearAction
    };

    const modalForAcion = (_action) => {
        if (_action) {
            switch (_action.type) {
                case availableActions.displayNewReminderAction:
                    return <SimpleMessage message={formatDateTimeShort(_action.reminder.expiryDate, false) + t('callAgainAt')} okText={t('okEng')} title={t('bookingCompleted')} {...defaultParams} />
                case availableActions.displayRemindersAction:
                    return <DisplayReminders reminders={_action.reminders} onRenewReminderCallback={renewReminder} onRemoveReminderCallback={clearReminder} {...defaultParams} />;
                case availableActions.uploadFilesAction:
                    return <FileUpload onUploadCallback={uploadFilesToServer} disabled={modalDisabled} {...defaultParams} />;
                case availableActions.importFilesAction:
                    const prop1 = {
                        title: _action.title ? _action.title : t("fileUpload"),
                        type: _action.data,
                        onUploadCallback: importFile,
                        onSuccessCallback: _action.onSuccessCallback,
                        onFailureCallBack: _action.onFailureCallBack,
                        disabled: modalDisabled,
                        onCancelCallback: _clearAction
                    };
                    if (_action.data === importModes.callImport2)
                        return <FileImportExcel {...prop1} />;
                    else
                        return <FileImportCSV {...prop1} />;
                case availableActions.imagesImportAction:
                    return <ImageImport
                        data={_currentAction.data}
                        onUploadCallback={(_data) => {
                            _currentAction.onChangeCallback(_data);
                            _clearAction();
                        }}
                        {...defaultParams} />;
                case availableActions.displayMessageAction:
                    const prop2 = {
                        title: _action.title,
                        message: _action.message,
                        okText: _action.okProps ? _action.okProps.text : t('okEng'),
                        cancelText: _action.showCancelButton ? t('cancel') : undefined,
                        onOkCallback: _action.okProps ? () => { _action.okProps.callback(); defaultParams.onCancelCallback(); } : defaultParams.onCancelCallback,
                        onCancelCallback: _action.showCancelButton ? _action.okProps.cancelCallback ? () => { _action.okProps.cancelCallback(); defaultParams.onCancelCallback(); } : defaultParams.onCancelCallback : undefined
                    };
                    return <SimpleMessage {...prop2} />
                case availableActions.displayMessageWithTextBoxAction:
                    const prop3 = {
                        title: _action.title,
                        message: _action.message,
                        okText: _action.okProps ? _action.okProps.text : t('okEng'),
                        cancelText: _action.showCancelButton ? t('cancel') : undefined,
                        onCancelCallback: _action.showCancelButton ? defaultParams.onCancelCallback : undefined
                    };
                    return <SimpleMessageWithTextBox onOkCallback={(_data) => {
                        _currentAction.onChangeCallback(_data);
                        _clearAction();
                    }} {...prop3} />
                case availableActions.displayGroupEditPopup:
                    const prop4 = {
                        groupData: _action.data ? _action.data : undefined,
                        title: _action.title,
                        dropList: _action.list,
                        okText: _action.okProps ? _action.okProps.text : t('okEng'),
                        cancelText: _action.showCancelButton ? t('cancel') : undefined,
                        onCancelCallback: _action.showCancelButton ? defaultParams.onCancelCallback : undefined
                    };
                    return <GroupEditPopup onOkCallback={(_data) => {
                        _currentAction.onChangeCallback(_data);
                        _clearAction();
                    }} {...prop4} />
                case availableActions.displayTeamEditPopup:
                    const prop5 = {
                        teamData: _action.data ? _action.data : undefined,
                        title: _action.title,
                        dropList: _action.list,
                        okText: _action.okProps ? _action.okProps.text : t('okEng'),
                        cancelText: _action.showCancelButton ? t('cancel') : undefined,
                        onCancelCallback: _action.showCancelButton ? defaultParams.onCancelCallback : undefined
                    };
                    return <TeamEditPopup onOkCallback={(_data) => {
                        _currentAction.onChangeCallback(_data);
                        _clearAction();
                    }} {...prop5} />
                case availableActions.telCallPopUp:
                    const prop6 = {
                        onFunction: _action.phone,
                        phoneNumber: _action.phoneNumber,
                        CellPhone: _action.CellPhone,
                        onCancelCallback: defaultParams.onCancelCallback,
                        CallModeRequestCall: _action.CallModeRequestCall,
                        data: _action.data,
                        LoginId: _action.LoginId
                    };
                    return <TelCallPopUp {...prop6} />
                case availableActions.propertyRoomPopup:
                    const prop7 = {
                        onCancelCallback: defaultParams.onCancelCallback,
                        onRequestUnlockPropertyListData: _action.onRequestUnlockPropertyListData,
                        Id: _action.Id
                    }
                    return <PropertyRoomPopup {...prop7} />
                case availableActions.addpropertyRoomPopUp:
                    const prop8 = {
                        onCancelCallback: defaultParams.onCancelCallback
                    }
                    return <AddpropertyRoomPopUp {...prop8} />
            }
        }
    };

    return (
        <>
            {modalForAcion(currentAction)}
        </>
    );
}
const mapStateToProps = (_state) => {
    return {
        currentAction: _state.priorityActions.action
    }
}

const mapDispatchToProps = {
    clearAction: clearAction,
    submitFilesAction: submitedFiles,
    renewReminder: renewReminderProcedure,
    cancelReminder: cancelReminderProcedure,
    displayMessageAction: displayMessageAction,
    addReinsData: addReinsData
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);