import React, { useState } from "react";

export function inlineSegmentDecorator(_v, _k) { return <span key={_k} className="inline-segment">{_v}</span>; }

export function reduceBooleanObject(_o, _labelDecorator = (...args) => args) {
    return (Object.keys(_o || {})).reduce((_a, _b) => _a.concat(_o[_b] && _b !== 'unspecified' ? inlineSegmentDecorator(_labelDecorator(_b), _b) : []), []);
};

export function popupGenerator(Component, _onChange, _onIdle) {
    return (_client) => <Component data={_client || {}} onChange={(_data) => { _onChange(_data); _onIdle(); }} onIdle={_onIdle} />;
}

export default function ModuleRow({ label: _label, content: _content, onActive: _onActive, onIdle: _onIdle, noContentData: _noContentData }) {

    const [showPopup, setShowPopup] = useState(false);
    const contentFormatter = (_c) => {
        return _c.map((_topLevel, _topIndex) => {
            return <div className="fill-w single-line" key={_topIndex}>
                {_topLevel &&
                    (
                        (!Array.isArray(_topLevel) && _topLevel) || (_topLevel.map((_v, _i) => <span key={_i}>{_v}</span>))
                    )
                }
            </div>
        });
    };

    let labelClass = 'module-label col-xs-3 relative flex single-line';
    let handleClick = () => { };

    if (_onActive) {
        handleClick = (_e) => {
            _onActive();
            if (_e.target.className.includes('actionable')) setShowPopup(!showPopup);

        }
        labelClass += ' actionable'
    }

    return (<div className="row no-gutters">
        <div className={labelClass} onClick={handleClick}>
            <span className="flex-grow-1 ellipsize">{_label}</span>
            {_onActive && <i className="fa fa-pencil" aria-hidden="true"></i>}
        </div>
        <div className="module-content col-xs-9">
            <div className="flex flex-column">
                {_noContentData && _noContentData}
                {_content && contentFormatter(_content)}
            </div>
        </div>
    </div>);
}
//ModuleRow.whyDidYouRender = true;