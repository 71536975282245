import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { formatCurrency } from 'helpers/formatHelpers';
import { SimpleSelector } from 'components/inputs/selector';
import { SimpleTextInput, DateSelector } from 'components/inputs/textInput';
import { ApiEntryPoint } from 'apiConnector';
import AccountSettlementPopup from './propertyPopups/accountSettlementPopup';
import MediationAmountPopup from './propertyPopups/mediationAmountPopup';

export class CallModule extends Component {
    constructor(_props) {
        super(_props);
        this.state = {
            showPhoneSelector: false,
            memo: "",
            check: "absence",
            status: "normal"
        };

        const generateFloatingWindowProps = ((_props) => (_fieldName) => ({
            onChange: (_data) => { _props.onChange(_data); _props.onIdle(); },
            onIdle: _props.onIdle
        }))(this.props);

        this.checkControl = {
            values: ['absence', 'ownerAbsent', 'ownerCommunications', 'interrupted', 'receptionist', 'anotherPerson', '03' + 'rejected', 'lineBusy', 'separateResidence', 'gkg', 'sovereigntyG', 'inconvenienceMode', 'dm', 'visitAbsent', 'visitOwnerAbsent', 'visitSovereignty', 'visitReception', 'visitGkg', 'visitSovereigntyG', 'visitBespoke', 'moving', 'unknown', 'unknownRoom', 'ecOpening'].map(_v => ({ value: _v, text: this.props.t(_v) })),
            handler: (_v) => this.setState({ check: _v })
        }
        this.statusControl = {
            values: ['normal', 'declined', 'soldOut', 'currentlyUnavailable', 'expected', 'intermediary', 'ongoingContracts', 'settlement', 'remediation', 'visitCommon', 'visitDeclined', 'visitIpRefused', 'visitAllSoldOut', 'visitCurrentlyUnavailable', 'visitExpected', 'visitMediation', 'visitMediationReceived', 'visitInheritance', 'visitSettlement', 'ecNormal', 'ecInterrupted', 'ecWithheldRefusal', 'ecLineBusy', 'ecCellPhoneNormal', 'ecCellPhoneInterrupted', 'ecCellPhoneWithheldRefusal', 'ecCellPhoneBusy', 'ecSampledDispatchedSchedule', 'visitScheduled'].map(_v => ({ value: _v, text: this.props.t(_v) })),
            handler: (_v) => this.setState({ status: _v })
        }

        this.reminderControl = (_v) => {
            this.setState({ reminderDate: _v })
        }

        this.insertReCallData = () => {
            var date = new Date(this.state.reminderDate);
            var day = date.getDate();       // yields date
            var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
            var year = date.getFullYear();  // yields year
            var hour = date.getHours();     // yields hours 
            var minute = date.getMinutes(); // yields minutes
            var second = date.getSeconds();
            var time = year + ',' + month + ',' + day + ',' + hour + ',' + minute + ',' + second;

            this.props.requestInsertReCallData({ ownerId: this.props.data.ownerId, staffId: this.props.UserProfile.getId(), reCallTime: time });
            this.setState({ reminderDate: "" })
        }

        this.floatingWindows = {
            accountSettlementWindow: (_client) => <AccountSettlementPopup data={_client || {}} {...generateFloatingWindowProps('settlementData')} />,
            mediationDataWindow: (_client) => <MediationAmountPopup data={_client || {}} {...generateFloatingWindowProps('mediationData')} />
        }

        this.printMediationData = (_data) => {
            if (!_data || !_data.mediationAmount) return '';
            const mediationProxyType = (_data.mediationAmount.mediationIntermediaryTypes && this.props.t(Object.keys(_data.mediationAmount.mediationIntermediaryTypes).find(_k => _data.mediationAmount.mediationIntermediaryTypes[_k]))) || '';
            return ((_data.mediationAmount.mediationAmount &&
                !!_data.mediationAmount.mediationAmount.trim() &&
                `${formatCurrency(_data.mediationAmount.mediationAmount)} ${this.props.t('10kYen')} `) || '') + mediationProxyType;
        }
    }

    shouldComponentUpdate(_nextProps, _nextState) {
        return true;
    }

    render() {
        const Test = (State, ownerId) => {

            this.props.onRequestInsertHistoryData({ check: State.check, memo: State.memo, status: State.status, _timeStamp: this.props.lastCallReference, delay: 2500, staffId: this.props.UserProfile.getId(), ownerId: ownerId });

            this.setState({
                memo: ""
            })
        }

        let Obj = new Object();
        Obj.UserId = this.props.ownerId;

        const generateOnActiveHandler = (_window) => this.props.onActive ? () => this.props.onActive(_window) : () => { }
        const onCallHandler = this.props.onCall ? (_v) => {
            this.props.onCall(_v);
            this.setState({ showPhoneSelector: false });
        } : () => { };
        const callablePhones = [['phone', 'phoneNumber', 'phoneProhibited'], ['phone', 'secondaryPhone', 'secondaryPhoneProhibited'], ['cellphone', 'cellphone'], ['cellphone', 'cellphoneSecondary'], ['cellphone', 'tertiaryPhone']].reduce((_a, _b) => {
            const tmp = this.props.data[_b[0]];
            return _a.concat((this.props.data[_b[0]] || {})[_b[1]] ? {
                label: _b[1], content: this.props.data[_b[0]][_b[1]], disabled: (_b[2] && this.props.data[_b[0]][_b[2]]) || false
            } : [])
        }, []);
        const callButtonProps = {
            className: 'btn btn-secondary fill-w'
        }
        const accountSettlementValue = (() => {
            if (!this.props.data || !this.props.data.accountSettlement || this.props.data.accountSettlement.accountSettlement == null) return;
            const value = this.props.data.accountSettlement.accountSettlement;
            if ((typeof value === 'string' && (value.length < 1 || isNaN(value))) || (typeof value !== 'string' && typeof value !== 'number')) return;
            return `${formatCurrency(value)} ${this.props.t('10kYen')}`
        })();
        if (callablePhones.length > 0) {
            if (this.state.showPhoneSelector) {
                callButtonProps.className += ' active';
            }
            callButtonProps.onClick = () => this.setState((_s) => ({ showPhoneSelector: !_s.showPhoneSelector }));
        }
        const memoInput = (e) => {
            this.setState({ memo: e })
        }

        const phone = () => {
            return this.props.data.phone;
        }
        const phoneNumber = () => {
            return this.props.data.phoneNumber;
        }
        const cellphone = () => {
            return this.props.data.cellphone;
        }

        return (<div className={`call-module-container module-container ${this.props.extraClasses}`}>
            <Row className="no-gutters">
                <fieldset className="col-xs-4 form-group">
                    <label className="control-label ellipsize">{this.props.t('check')}</label>
                    <SimpleSelector options={this.checkControl.values} onChange={this.checkControl.handler} defaultValue={this.state.check || this.checkControl.values[0].value} />
                </fieldset>
                <fieldset className="col-xs-4 offset-sm-2 form-group">
                    <label className="control-label ellipsize">{this.props.t('status')}</label>
                    <SimpleSelector options={this.statusControl.values} onChange={this.statusControl.handler} defaultValue={this.state.status || this.statusControl.values[0].value} />
                </fieldset>
            </Row>
            <Row className="no-gutters">
                <fieldset className="col-xs-10 form-group">
                    <label className="control-label ellipsize">{this.props.t('memoEng')}</label>
                    <SimpleTextInput isMultiline={true} value={this.state.memo} onChange={(e) => memoInput(e)} />
                </fieldset>
            </Row>
            <Row className="no-gutters form-group">
                <Col className="col-xs-5 flex flex-column justify-center">
                    <div className="flex">
                        <div className="module-label relative actionable flex" onClick={generateOnActiveHandler(this.floatingWindows.accountSettlementWindow)}>
                            <span className="flex-grow-1">{this.props.t('accountSettlement')}</span>
                            <i className="fa fa-pencil" aria-hidden="true" />
                        </div>
                        <div className="flex-grow-1 ellipsize">
                            {accountSettlementValue}
                        </div>
                    </div>
                </Col>
                <Col className="col-xs-5 flex flex-column justify-center">
                    <div className="flex">
                        <div className="module-label relative actionable flex" onClick={generateOnActiveHandler(this.floatingWindows.mediationDataWindow)}>
                            <span className="flex-grow-1">{this.props.t('mediationAmount')}</span>
                            <i className="fa fa-pencil" aria-hidden="true" />
                        </div>
                        <div className="ellipsize flex-grow-1">
                            {this.printMediationData(this.props.data)}
                        </div>
                    </div>
                </Col>
                <Col className="col-xs-2">
                    <button type="button" className="btn btn-secondary fill-w" onClick={() => Test(this.state, this.props.data.ownerId)}>{this.props.t('save')}</button>
                </Col>
            </Row>
            <Row className="no-gutters">
                <fieldset className="col-xs-9 form-group">
                    <label className="control-label ellipsize flex single-line">{this.props.t('callAgain')}
                        <div className="flex flex-grow-1">
                            <div className="flex-grow-1" />
                            <DateSelector showIcon={true} value={this.state.reminderDate} showTimeSelect={true} onChange={this.reminderControl} />
                        </div>
                    </label>
                </fieldset>
                <fieldset className="col-xs-2 offset-sm-1 form-group">
                    <button type="button" className="btn btn-secondary fill-w" onClick={this.insertReCallData}>{this.props.t('save')}</button>
                </fieldset>
            </Row>
            <Row className="no-gutters">
                <fieldset className="phone-selector-container col-xs-2 form-group relative">
                    {this.state.showPhoneSelector && <div className="phone-selector absolute">
                        {callablePhones.map(_v =>
                            <button type="button"
                                className="btn btn-secondary fill-w"
                                key={_v.label}
                                disabled={_v.disabled}
                                onClick={(!_v.disabled && (() => onCallHandler(_v.content))) || (() => { })}>{_v.content}
                            </button>)}
                    </div>}
                    <button
                        type="button" {...callButtonProps}
                        onClick={() =>
                            this.props.onImagesImportAction(Obj,
                                phone,
                                phoneNumber,
                                cellphone,
                                this.props.onCall,
                                this.props.UserProfile.getId()
                            )} >
                        >
                        <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;{this.props.t('telephoneCall')}
                    </button>
                </fieldset>
            </Row>
        </div>);
    }
}
CallModule.defaultProps = {
    extraClasses: '',
    data: {}
}
CallModule.whyDidYouRender = true;

export default withTranslation()(CallModule);