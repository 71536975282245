import AssessmentRequestPageTable from 'components/tables/assessmentRequestPageTable';
import ResponsiveDatatable from 'components/tables/responsiveDatatable';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AssessmentRequestOwnerForm } from './assessmentRequestOwnerForm';
import { requestOwners, exportCsv, requestStaffs, requestOwnerListRooms, updateMemo, updateKidoku, updateShinsei, updateTorisage, updateStates } from 'reducers/assessmentRequestReducers';
import { displayMessageAction } from 'reducers/priorityActionsReducers';
import UserProfile from 'helpers/userProfile';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import * as helpers from 'helpers/formHelpers';
import ShinseiFloatingWindow from './shinseiFloatingWindow';
import StatusChangeFloatingWindow from './statusChangeFloatingWindow';
import Select from 'react-select';

export function AssessmentRequestList({ ownersPageDetails: _ownersPageDetails,
    owners: _owners,
    requestOwners: _requestOwners,
    exportCsv: _exportCsv,
    requestOwnerListRooms: _requestOwnerListRooms,
    displayMessageAction: _displayMessageAction,
    requestStaffs: _requestStaffs,
    updateMemo: _updateMemo,
    updateKidoku: _updateKidoku,
    updateShinsei: _updateShinsei,
    updateTorisage: _updateTorisage,
    updateStates: _updateStates}) {
    let holdUserId = UserProfile.getId();
    let post = UserProfile.getPost();
    const [postData, setpostDat] = useState((post == "6") ? true : false);
    const [userId, setUserId] = useState(holdUserId);
    const [sateiDetails, setSateiDetails] = useState();
    const { t } = useTranslation();
    const [ownersData, setOwnersData] = useState(!_owners ? [] : _owners);
    const [isActive, setIsActive] = useState(true);
    const tableAccessor = (_field) => (_d) => typeof _d === 'object' ? _d ? _d[_field] : "" : _d;
    const [ownersPageData, setOwnersPageData] = useState(!_ownersPageDetails ? {} : _ownersPageDetails);
    const [exportState, setExportState] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [formData, setFormData] = useState({});
    const [currentOwnerIndex, setCurrentOwnerIndex] = useState(0);
    const [currentPageMode, setCurrentPageMode] = useState(0);
    const [tablePageSize, setTablePageSize] = useState(100);

    const ownerTableOptions = {
        columnsOptions: {
            id: { header: t('　'), sortable: false, type: 'button', buttonClass: 'btn-primary detail-button', buttonText: '詳細', width: 120, callback: (_data) => { changePageMode(_data) } },
            registrationDate: { header: t('haishinDate'), sortable: false, type: 'registrationDate', accessor: tableAccessor('registrationDate') },
            bukkenName: { header: t('propertyName'), sortable: false, accessor: tableAccessor('bukkenName') },
            bukkenAddress: { header: t('propertyAddress'), sortable: false, accessor: tableAccessor('bukkenAddress') },
            ownerName: { header: t('fullName'), sortable: false, accessor: tableAccessor('ownerName') },
            ownerAddress: { header: t('streetAddress'), sortable: false, accessor: tableAccessor('ownerAddress') },
            kakinState: { header: t('kakinState'), sortable: false, type: 'kakinState', accessor: tableAccessor('kakinState') }
        },
        /*style: { maxHeight: 405 },*/
        pageSize: tablePageSize,
    };
    useEffect(() => {
        if (_owners) {
            setOwnersData(_owners);
            const cc = _ownersPageDetails.currentCount, cp = _ownersPageDetails.currentPage - 1;
            const num = cc - (cp * 100);
            if (num !== 100) {
                const data = _ownersPageDetails;
                data.pageSize = num;
                setTablePageSize(num);
            } else {
                const data = _ownersPageDetails;
                data.pageSize = 100;
                setTablePageSize(100);
            }
            setOwnersPageData(_ownersPageDetails);
        }
        setExportState(true);
    }, [_owners]);

    useEffect(() => {
        _requestOwners({
            holdStaffID: userId,
            visible: [{ value: 0, selected: true }],
            assessmentPageDetails: {
                currentPage: 1,
                pageSize: 100
            },
        }, postSuccess.bind(null, ''), postFailure.bind(null, '1'));
        _requestStaffs();
        setExportState(true);
        setFormData({});
        setCurrentPage(1);
    }, []);
    useEffect(() => {
        if (exportState != 0)
            setIsActive(exportState);
    }, [exportState]);

    const onOwnerPageChange = (_c, _i) => {
        if (currentPage != _c) {

            setExportState(true);

            if (formData == {}) {
                _requestOwners({
                    holdStaffID: userId,
                    visible: [{ value: 0, selected: true }],
                    assessmentPageDetails: {
                        isPrev: (currentPage > _c) ? true : false,
                        latestValue: _owners[_owners.length - 1].id,
                        currentPage: _c,
                        pageSize: 100
                    }
                }, postSuccess.bind(null, ''), postFailure.bind(null, '2'));
            }
            else {
                _requestOwners({
                    holdStaffID: userId,
                    visible: [{ value: 0, selected: true }],
                    id: formData.id,
                    ownerName: formData.fullName,
                    kana: formData.kana,
                    tel1: formData.tel1,
                    mail: formData.mail,
                    ownerAddress: formData.streetAddress,
                    bukkenName: formData.bukkenName,
                    bukkenAddress: formData.bukkenAddress,
                    bukkenType: formData.bukkenTypes,
                    kakinState: formData.kakinStates,
                    kidokuState: formData.kidokuStates,
                    registrationDate: formData.registrationDateRange,
                    toGetsu: formData.toGetsu,
                    zenGetsu: formData.zenGetsu,
                    sateiMemo: formData.sateiMemo,
                    sateiId: formData.sateiId,
                    assessmentPageDetails: {
                        isPrev: (currentPage > _c) ? true : false,
                        latestValue: _owners[_owners.length - 1].id,
                        currentPage: _c,
                        pageSize: 100
                    }
                }, postSuccess.bind(null, ''), postFailure.bind(null, '3'));
            }

            setCurrentPage(_c);
        }
    };
    const postFailure = (num) => {
        _displayMessageAction(t('FAIL'), t('FAIL') + num);
        setExportState(false);
        setIsActive(false);
    }

    const postSuccess = () => {
        setExportState(false);
        setIsActive(false);
    };
    const ownerFormCallback = (_action, _o) => {
        setExportState(true);

        setFormData(_o);
        setCurrentOwnerIndex(-1);
        setCurrentPage(1);
        _requestOwners({
            holdStaffID: userId,
            visible: [{ value: 0, selected: true }],
            id: _o.id,
            ownerName: _o.fullName,
            kana: _o.kana,
            tel1: _o.tel1,
            mail: _o.mail,
            ownerAddress: _o.streetAddress,
            bukkenName: _o.bukkenName,
            bukkenAddress: _o.bukkenAddress,
            bukkenType: _o.bukkenTypes,
            kakinState: _o.kakinStates,
            kidokuState: _o.kidokuStates,
            registrationDate: _o.registrationDateRange,
            toGetsu: _o.toGetsu,
            zenGetsu: _o.zenGetsu,
            sateiMemo: _o.sateiMemo,
            sateiId: _o.sateiId,
            assessmentPageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, '4'));

    };
    const exportCsvCallback = (_action, _o) => {
        setExportState(true);

        setFormData(_o);
        setCurrentOwnerIndex(-1);
        setCurrentPage(1);
        _exportCsv({
            holdStaffID: userId,
            visible: [{ value: 0, selected: true }],
            id: _o.id,
            ownerName: _o.fullName,
            kana: _o.kana,
            tel1: _o.tel1,
            mail: _o.mail,
            ownerAddress: _o.streetAddress,
            bukkenName: _o.bukkenName,
            bukkenAddress: _o.bukkenAddress,
            bukkenType: _o.bukkenTypes,
            kakinState: _o.kakinStates,
            kidokuState: _o.kidokuStates,
            registrationDate: _o.registrationDateRange,
            toGetsu: _o.toGetsu,
            zenGetsu: _o.zenGetsu,
            sateiMemo: _o.sateiMemo,
            sateiId: _o.sateiId,
            assessmentPageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, '5'));

    };

    const backToPageMode = () => {
        setCurrentPageMode(0);
    };
    const changePageMode = (data) => {
        setCurrentPageMode(1);
        if (userId != 1 && data.kidokuState != 1) {
            console.log("beofreupdateKidoku",data)
            data.kidokuState = 1;
            _updateKidoku(data, postFailure.bind(null, '6'));
        }
        setSateiDetails(data);
    };

    const flagFalseHandler = () => {
        setMemoFlag(false);
    };
    const flagTrueHandler = () => {
        setMemoFlag(true);
    };
    const [memoFlag, setMemoFlag] = useState(false);
    const [kakinFlag, setKakinFlag] = useState(false);
    const [shinseiFlg, setShinseiFlg] = useState(false);
    const [torisageFlg, setTorisageFlg] = useState(false);
    const [kakinStateChangeFlg, setKakinStateChangeFlg] = useState(false);
    const shinseiCategoryList = [
        { value: '0', label: '査定物件の情報、連絡先情報に虚偽の記載がある' },
        { value: '1', label: '電話番号、メールアドレスに不備があり連絡が取れない' },
        { value: '2', label: '過去3ヶ月以内にイエカカクから同一オーナーから同一の査定物件の依頼があった' },
        { value: '3', label: '電話、メールでの連絡が1週間以上取れない（他社および弊社で連絡が取れる場合は課金となります）' },
        { value: '4', label: '二親等以上離れた親族および法的に代理人ではない方からの査定依頼' },
        { value: '5', label: '専任媒介契約、又は専属専任媒介契約を他社と結んでいる' },
        { value: '6', label: 'ユーザーからイエカカクに対し、査定依頼から1週間以内に依頼のキャンセルが完了している' },
        { value: '7', label: '市街化調整区域の「土地」及び「旧耐震の上物」であり、且つ、原則第三者による建築（再建築）ができない' },
        { value: '8', label: '査定依頼者が価格調査目的の不動産会社である場合' },

    ];
    const kakinStateChangeList = [{ value: '0', label: t('kakinTaisyo') }, { value: '1', label: t('shinseichu') }, { value: '2', label: t('kakinTaisyoKyakka') },  { value: '3', label: t('kakinTaisyoGai') },   { value: '4', label: t('kakinTaisyoTorisage') }];

    const [categoryMsg, setCategoryMsg] = useState();
    const [detailMsg, setDetailMsg] = useState();
    const [tempCategory, setTempCategory] = useState();
    const [tempStates, setTempStates] = useState();
    const updateMemoHandler = () => {
        const value = document.getElementById("memoInput");
        const data = sateiDetails;
        data.sateiMemo = value.value;
        _updateMemo(sateiDetails, postFailure.bind(null, '7'));
        setMemoFlag(false);
    };
    const popupKakinShinsei = () => {
        if (!shinseiFlg) {
            setShinseiFlg(true);
            setTempCategory();
            setCategoryMsg();
            setDetailMsg();
        }
        else {
            setShinseiFlg(false);
        }
    };
    const popupShinseiTorisage = () => {
        if (!torisageFlg) {
            setTorisageFlg(true);
            setTempCategory();
            setDetailMsg();
        }
        else {
            setTorisageFlg(false);
        }
    };
    const popupKakinStateChange = () => {
        if (!kakinStateChangeFlg) {
            setKakinStateChangeFlg(true);
            setTempCategory();
            setDetailMsg();
        }
        else {
            setKakinStateChangeFlg(false);
        }
    };
    const shinseiHandler = () => {
        const time = new Date().toLocaleString();
        const detail = document.getElementById("shinseiDetail");
        if ((tempCategory == null || tempCategory == '') && tempCategory !== 0) {
            const msg = <p className='error-message'>申請カテゴリーを選択してください</p>;
            setCategoryMsg(msg);
            return false;
        } else if (detail.value == null || detail.value == '') {
            if (tempCategory !== null && tempCategory !== '') {
                setCategoryMsg();
            }
            const msg = <p className='error-message'>申請詳細を入力してください</p>;
            setDetailMsg(msg);
            return false;
        } else {
            if (tempCategory !== null && tempCategory !== '') {
                setCategoryMsg();
            }
            if (sateiDetails.shinseiDetail != null && sateiDetails.shinseiDetail != '') {
                setDetailMsg();
            }
            const data = sateiDetails;
            data.shinseiCategory = tempCategory;
            data.shinseiDetail = detail.value;
            data.shinseiDate = time;
            data.kakinState = 1;
            _updateShinsei(sateiDetails, postFailure.bind(null, '8'));
            return true;
        }
    };
    const torisageHandler = () => {
        const time = new Date().toLocaleString();
        const detail = document.getElementById("torisageDetail");
        if (detail.value == null || detail.value == '') {
            const msg = <p className='error-message'>取下げ申請詳細を入力してください</p>;
            setDetailMsg(msg);
            return false;
        } else {
            if (sateiDetails.torisage != null && sateiDetails.torisage != '') {
                setDetailMsg();
            }
            const data = sateiDetails;
            data.torisage = detail.value;
            data.torisageDate = time;
            data.kakinState = 4;
            _updateTorisage(sateiDetails, postFailure.bind(null, '9'));
            return true;
        }
    };
    const stateChangeHandler = () => {
        const time = new Date().toLocaleString();
        const detail = document.getElementById("hentoDetail");
        if ((tempStates == null || tempStates == '') && tempStates !== 0) {
            const msg = <p className='error-message'>ステータスを選択してください</p>;
            setCategoryMsg(msg);
            return false;
        } else if (detail.value == null || detail.value == '') {
            if (tempStates !== null && tempStates !== '') {
                setCategoryMsg();
            }
            const msg = <p className='error-message'>返答詳細を入力してください</p>;
            setDetailMsg(msg);
            return false;
        } else {
            if (tempStates !== null && tempStates !== '') {
                setCategoryMsg();
            }
            if (sateiDetails.shinseiDetail != null && sateiDetails.shinseiDetail != '') {
                setDetailMsg();
            }
            const data = sateiDetails;
            data.kakinState = tempStates;
            data.hentouDetail = detail.value;
            data.hentouDate = time;
            console.log("data", data);
            _updateStates(data, postFailure.bind(null, '8'));
            return true;
        }
    };
    const selectChanged = (e) => {
        setTempCategory(Number(e.value));
    };
    const statesSelectChanged = (e) => {
        setTempStates(Number(e.value));
    };
    const textBr = (brText) => {
        const texts = (brText != null) ? brText.split(/(\n)/).map((item, index) => {
            return <>{item.match(/\n/) ? <br /> : item}</>;
        }) : '';
        return <>{texts}</>;
    };

    const generateDetailPage = () => {
        const detailRow1 = [
            {
                key: 'sateiId', label: t('sateiId')
            },
            {
                key: 'registrationDate', label: t('registrationDate')
            },
            {
                key: 'ownerName', label: t('fullName')
            },
            {
                key: 'kana', label: t('kana')
            },
            {
                key: 'tel1', label: t('tel1')
            },
            {
                key: 'mail', label: t('mail')
            },
            {
                key: 'age', label: t('age')
            }
            //,
            //{
            //    key: 'kyogoNum', label: t('kyogoNum')
            //}
        ];

      
        const detailRow2 = [
            {
                key: 'rerationSonota', label: t('rerationSonota')
            },
            {
                key: 'reason', label: t('reason')
            },
            //{
            //    key: 'kibogaku', label: t('kibogaku')
            //},
        ];

        const detailRow3 = [
            {
                key: 'yobo', label: t('yobo')
            },
        ];
        const detailRow4 = [
            {
                key: 'roomNumber', label: t('roomNumber')
            },
            {
                key: 'heibei', label: t('heibei') + '(㎡)'
            },
            {
                key: 'tochiMenseki', label: t('tochiMenseki') + '(㎡)'
            },
            {
                key: 'chiku', label: t('chiku')
            },
        ];

        const kakinStateName = [t('kakinTaisyo'), t('shinseichu'), t('kakinTaisyoKyakka'), t('kakinTaisyoGai'), t('kakinTaisyoTorisage')];
        const bukkenTypeName = [t('apartment'), t('detachedHouse'), t('land'), t('aApartment'), t('building'), t('buildingRoom'), t('storFactoryAndGarage'), t('farmland')];

        const kidokuStatesName = [t('midoku'), t('kidoku')];
        const rerationName = [t('honnin'), t('kyoyu'), t('kazoku'), t('dairinin')];
        const jikiName = [t('asap'), t('threeMonth'), t('sixMonth'), t('oneYear'), t('moreThenYear')];
        const kaikaeName = [t('kaikaeAri'), t('kaikaeNashi')];
        const dateOfWeekName = [t('anytime'), t('weekDay'), t('nonWorkingDay'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];
        const timeOfDayName = [t('anytime'), t('tenToTwelve'), t('twelveTothieteen'), t('thirteenToFifteen'), t('fifteenToEighteen'), t('eighteenTotwentyOne')];

        const genkyoName = [t('kyojyuchu'), t('chinattichu'), t('kushitsu'), t('other')];
        const madoriTypeName = ["R", "K", "DK", "LDK", "SLDK"];
        const ageList = ["20歳未満", "20代", "30代", "40代", "50代", "60代", "70歳以上"];
        const detailRow1Checker = (key) => {
            switch (key) {
                case 'age':
                    return (
                        <>{ageList[sateiDetails[key]]}</>
                    );
                    break;
                case 'registrationDate':
                    return (
                        <>{sateiDetails[key].replace("T", " ")}</>
                    );
                    break;
                default:
                    return (
                        <>{sateiDetails[key]}</>
                    );
                    break;
            }
        }
        return <>
            <Row className="no-gutters form-group">
                {detailRow1.map(_d => {
                    return <>
                        <Col className="col-xs-6 flex flex-column ">
                            <div className="module-label {_d.key} label-fillcontent">
                                <span className="flex-grow-1 ellipsize label-fillcontent-span">{_d.label}</span>
                            </div>
                            <div className="module-content">
                                <div className="flex flex-column">
                                    {//(_d.key === 'registrationDate') ? sateiDetails[_d.key].replace("T", " ") : sateiDetails[_d.key];
                                        detailRow1Checker(_d.key)
                                    }
                                </div>

                            </div>
                        </Col>
                    </>;
                })}
            </Row>
            <Row className="no-gutters form-group">
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('dayOfWeek')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {dateOfWeekName[sateiDetails.dayOfWeek]}
                        </div>
                    </div>
                </Col>
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('timeOfDay')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {timeOfDayName[sateiDetails.timeOfDay]}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="no-gutters form-group">
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent　{_d.key}">
                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('ownerAddress')}&nbsp;&nbsp; <a className="g-map-link" target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + sateiDetails.ownerAddress}>地図で見る</a></span>
                    </div>
                    <div className="module-content">
                        <div className="flex-column">
                            {sateiDetails.ownerAddress}
                        </div>
                    </div>
                </Col>
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('reration')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {rerationName[sateiDetails.reration]}
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="no-gutters form-group">
                {detailRow2.map(_d => {
                    return <>
                        <Col className="col-xs-6 flex flex-column">
                            <div className="module-label {_d.key} label-fillcontent">
                                <span className="flex-grow-1 label-fillcontent-span ellipsize">{_d.label}</span>
                            </div>
                            <div className="module-content">
                                <div className="flex flex-column">
                                    {sateiDetails[_d.key]}
                                </div>
                            </div>
                        </Col>
                    </>;
                })}
            </Row>
            
            <Row className="no-gutters form-group">
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('jiki')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {jikiName[sateiDetails.jiki]}
                        </div>
                    </div>
                </Col>
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('kaikae')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {kaikaeName[sateiDetails.kaikae]}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="no-gutters form-group">
                {detailRow3.map(_d => {
                    return <>

                        <Col className="col-xs-6 flex flex-column">
                            <div className="module-label label-fillcontent {_d.key}">
                                <span className="flex-grow-1 label-fillcontent-span ellipsize">{_d.label}</span>
                            </div>
                            <div className="module-content">
                                <div className="flex flex-column">
                                    {sateiDetails[_d.key]}
                                </div>
                            </div>
                        </Col>
                    </>;
                })}
            </Row>
            <Row className="no-gutters form-group">
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('bukkenType')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {bukkenTypeName[sateiDetails.bukkenType]}
                        </div>
                    </div>
                </Col>
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('propertyName')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {sateiDetails.bukkenName}
                        </div>
                    </div>
                </Col>

                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('propertyAddress')}&nbsp;&nbsp;<a className="g-map-link" target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + sateiDetails.bukkenAddress}>地図で見る</a></span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {sateiDetails.bukkenAddress}
                        </div>

                    </div>
                </Col>
            </Row>
            <Row className="no-gutters form-group">
                {detailRow4.map(_d => {
                    return <>
                        <Col className="col-xs-6 flex flex-column">
                            <div className="module-label label-fillcontent {_d.key}">
                                <span className="flex-grow-1 label-fillcontent-span ellipsize">{_d.label}</span>
                            </div>
                            <div className="module-content">
                                <div className="flex flex-column">
                                    {(sateiDetails[_d.key] == "9") ? "~10" : sateiDetails[_d.key]}
                                </div>
                            </div>
                        </Col>
                    </>;
                })}
            </Row>
            <Row className="no-gutters form-group">
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('madoriSize')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {sateiDetails.madoriSize}{madoriTypeName[sateiDetails.madoriType]}
                        </div>
                    </div>
                </Col>
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('genkyo')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {genkyoName[sateiDetails.genkyo]}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="no-gutters form-group">
                <Col className="col-xs-6 flex flex-column">
                    <div className="module-label label-fillcontent {_d.key}">
                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('bulk')}</span>
                    </div>
                    <div className="module-content">
                        <div className="flex flex-column">
                            {(() => {
                                console.log(sateiDetails);
                                switch (sateiDetails.bulk) {
                                    case 1:
                                        console.log("あり");
                                        return <><div>あり</div>
                                            {/* <button className="btn btn-secondary" onClick={() => popupKakinShinsei()}>{t('kakingaiShinsei')}</button>*/}
                                        </>;
                                        break;
                                    default:
                                        console.log("無し");
                                        return <><div>無し</div></>;
                                        break;
                                }
                            })()}
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="memo-wrapper row no-gutters">
                {
                    memoFlag ?
                        <>
                            <Col className="col-xs-12 flex flex-column">
                                <div className="module-label label-fillcontent" >
                                    <span className="ellipsize">{t('memoJp')}</span>
                                    <i className="fa fa-check ellipsize"
                                        aria-hidden="true"
                                        onClick={() => updateMemoHandler()}
                                    />
                                    <i className="fa fa-times ellipsize label-fillcontent-span"
                                        aria-hidden="true"
                                        onClick={() => flagFalseHandler()}
                                    />
                                </div>
                            </Col>

                            <Col className="col-xs-12 flex flex-column">
                                <div className="module-content">
                                    <textarea
                                        row="3"
                                        isMultiline="true"
                                        id="memoInput"
                                        defaultValue={sateiDetails.sateiMemo}
                                    />
                                </div>
                            </Col>
                        </> :
                        <>
                            <Col className="col-xs-12 flex flex-column">
                                <div className="module-label label-fillcontent">
                                    <span className="label-fillcontent-span ellipsize">{t('memoJp')}</span>
                                    <i className="fa fa-pencil ellipsize"
                                        aria-hidden="true"
                                        onClick={() => flagTrueHandler()}
                                    />

                                </div>
                            </Col>
                            <Col className="col-xs-12 flex flex-column">
                                <div className="module-content">
                                    {textBr(sateiDetails.sateiMemo)}
                                </div>
                            </Col>

                        </>
                }

            </div>
            {(() => {
                switch (sateiDetails.kakinState) {
                    case 0:
                        return <>
                            <Row className="no-gutters form-group">
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('kakinState')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{kakinStateName[sateiDetails.kakinState]}</div>
                                        <button className="btn btn-secondary" onClick={() => popupKakinShinsei()}>{t('kakingaiShinsei')}</button>
                                    </div>
                                </Col>
                                <div>
                                    {shinseiFlg && (<ShinseiFloatingWindow title={t('kakingaiShinsei')} onChange={() => shinseiHandler()} onIdle={() => popupKakinShinsei()} >
                                        <div className="module-label label-fillcontent" >
                                            <span className="flex-grow-1 label-fillcontent-span ellipsize">
                                                {t('shinseiCategory')}</span>
                                        </div>
                                        <Select onChange={(e) => { selectChanged(e) }} options={shinseiCategoryList} placeholder='申請カテゴリーを選択' />
                                        {categoryMsg}

                                        <div className="module-label label-fillcontent" >
                                            <span className="flex-grow-1 label-fillcontent-span ellipsize">
                                                {t('shinseiDetail')}</span>
                                        </div>
                                        <textarea
                                            row="3"
                                            isMultiline="true"
                                            id="shinseiDetail"
                                            defaultValue={sateiDetails.shinseiDetail}
                                        />
                                        {detailMsg}
                                    </ShinseiFloatingWindow>)}
                                </div>

                            </Row>
                        </>;
                        break;
                    case 1:
                        return <>
                            <Row className="no-gutters form-group">
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('kakinState')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{kakinStateName[sateiDetails.kakinState]}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('shinseiDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiCategory')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{shinseiCategoryList[sateiDetails.shinseiCategory] && shinseiCategoryList[sateiDetails.shinseiCategory].label}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDetail}</div>
                                        <button className="btn btn-secondary" onClick={() => popupShinseiTorisage()}>{t('shinseiTorisage')}</button>
                                    </div>
                                </Col>
                                <div>
                                    {torisageFlg && (<ShinseiFloatingWindow title={t('shinseiTorisage')} onChange={() => torisageHandler()} onIdle={() => popupShinseiTorisage()} >

                                        <div className="module-label" >
                                            <span className="flex-grow-1 label-fillcontent ellipsize">
                                                {t('shinseiDetail')}</span>
                                        </div>
                                        <textarea
                                            row="3"
                                            isMultiline="true"
                                            id="torisageDetail"
                                            defaultValue={sateiDetails.torisage}
                                        />
                                        {detailMsg}
                                    </ShinseiFloatingWindow>)}
                                </div>
                            </Row>
                        </>;
                        break;
                    case 2:
                        return <>
                            <Row className="no-gutters form-group">
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('kakinState')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{kakinStateName[sateiDetails.kakinState]}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('shinseiCategory')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{shinseiCategoryList[sateiDetails.shinseiCategory] && shinseiCategoryList[sateiDetails.shinseiCategory].label}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDetail}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('hentouDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.hentouDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('hentouDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.hentouDetail}</div>
                                    </div>
                                </Col>
                            </Row>
                        </>;
                        break;
                    case 3:
                        return <>
                            <Row className="no-gutters form-group">
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('kakinState')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{kakinStateName[sateiDetails.kakinState]}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('shinseiCategory')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{shinseiCategoryList[sateiDetails.shinseiCategory] && shinseiCategoryList[sateiDetails.shinseiCategory].label}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDetail}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('hentouDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.hentouDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('hentouDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.hentouDetail}</div>
                                    </div>
                                </Col>
                            </Row>
                        </>;
                        break;
                    case 4:
                        return <>
                            <Row className="no-gutters form-group">
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('kakinState')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{kakinStateName[sateiDetails.kakinState]}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('shinseiCategory')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{shinseiCategoryList[sateiDetails.shinseiCategory] && shinseiCategoryList[sateiDetails.shinseiCategory].label}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label label-fillcontent" >
                                        <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('shinseiDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.shinseiDetail}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('kakinState')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{kakinStateName[sateiDetails.kakinState]}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('torisageDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.torisageDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('shinseiDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.torisage}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('hentouDate')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.hentouDate}</div>
                                    </div>
                                </Col>
                                <Col className="col-xs-6 flex flex-column">
                                    <div className="module-label" >
                                        <span className="flex-grow-1 label-fillcontent ellipsize">{t('hentouDetail')}</span>
                                    </div>
                                    <div className="module-content">
                                        <div>{sateiDetails.hentouDetail}</div>
                                    </div>
                                </Col>
                            </Row>
                        </>;
                        break;
                    default:
                        break;
                }
                
            })()}
            { postData && (<Row><Col className="col-xs-6 flex flex-column">
                <div className="module-label label-fillcontent" >
                    <span className="flex-grow-1 label-fillcontent-span ellipsize">{t('kakinStateChange')}</span>
                </div>
                <div className="module-content">
                    <div>{sateiDetails.shinseiDetail}</div>
                    <button className="btn btn-secondary" onClick={() => popupKakinStateChange()}>{t('kakinStateChangeButton')}</button>
                </div>
            </Col>
                <div>
                    {kakinStateChangeFlg && (<StatusChangeFloatingWindow title={t('kakinStateChange')} onChange={() => stateChangeHandler()} onIdle={() => popupKakinStateChange()} >
                        <div className="module-label label-fillcontent" >
                            <span className="flex-grow-1 label-fillcontent-span ellipsize">
                                {t('kakinState')}</span>
                        </div>
                        <Select onChange={(e) => { statesSelectChanged(e) }} options={kakinStateChangeList} placeholder='変更後のステータスを選択' />
                        {categoryMsg}

                        <div className="module-label label-fillcontent" >
                            <span className="flex-grow-1 label-fillcontent-span ellipsize">
                                {t('hentouDetail')}</span>
                        </div>
                        <textarea
                            row="3"
                            isMultiline="true"
                            id="hentoDetail"
                            defaultValue={sateiDetails.hentouDetail}
                        />
                        {detailMsg}
                    </StatusChangeFloatingWindow>)}
                </div>
            </Row>)}

        </>;
    };

    const switchPageMode = () => {
        let pageContents;
        switch (currentPageMode) {
            case 0:
                if (shinseiFlg) { setShinseiFlg(false) };
                if (kakinStateChangeFlg) { setKakinStateChangeFlg(false) };
                pageContents =
                    <div className="card card-block sameheight-item">
                        <h2>査定依頼一覧</h2>
                        <div className="title-separator"></div>
                        <div>
                            <AssessmentRequestOwnerForm callBack={ownerFormCallback.bind(null, '')} export={exportCsvCallback.bind(null, '')} />
                            <div className="tab-content">
                                <div className="callmode-data-container">
                                    <div className="data-top-wrapper">
                                        {_owners && < AssessmentRequestPageTable {...ownerTableOptions} rows={[...ownersData]} assessmentPageDetails={ownersPageData} pageChangeCallback={onOwnerPageChange} loading={isActive} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                break;
            case 1:
                pageContents =
                    <ReflexContainer orientation="horizontal">
                        <ReflexElement flex={0.235}>
                            <div className="card card-block sameheight-titem detail-card">
                                <h2>査定依頼詳細</h2>
                                <ul className="nav nav-pills relative">
                                    <button type="button" className="btn btn-primary" onClick={() => { backToPageMode(); }}>{t('goBack')}</button>
                                </ul>

                                <div className="title-separator"></div>
                                {generateDetailPage()}

                                <ul className="nav nav-pills relative">
                                    <button type="button" className="btn btn-primary" onClick={() => { backToPageMode(); }}>{t('goBack')}</button>
                                </ul>
                            </div>
                        </ReflexElement>
                    </ReflexContainer>
                break;
        }
        return <>
            { pageContents}
        </>


    };

    return (
        <article className="content assessmentRequestList-page">
            < section className="section" >
                {switchPageMode()}
            </section >
        </article>
    );

}
const mapStateToProps = (_state) => {
    return {
        owners: _state.assessmentRequest.owners.records,
        ownersPageDetails: _state.assessmentRequest.owners.assessmentPageDetails,
        propertyRooms: _state.maintenance.propertyRooms,
    }
}
const mapDispatchToProps = {
    requestOwners: requestOwners,
    exportCsv: exportCsv,
    requestOwnerListRooms: requestOwnerListRooms,
    displayMessageAction: displayMessageAction,
    requestStaffs: requestStaffs,
    updateMemo: updateMemo,
    updateKidoku: updateKidoku,
    updateShinsei: updateShinsei,
    updateTorisage: updateTorisage,
    updateStates: updateStates,
}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentRequestList);