import React from 'react';
import { useTranslation } from 'react-i18next';

export function SidebarButton(_props) {
    const { t } = useTranslation();

    return <li className={(_props.isActive ? "active" : "")}>
        <a href="javascript:void(0);" onClick={_props.clickHandler}>
            {t(_props.text)}
        </a>
        {_props.isActive && <div className="indicator" />}
    </li>
}

SidebarButton.defaultProps = {
    text: '',
    clickHandler: null,
    isActive: false
};