import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function PropertyName({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    return <>
        <wf.InputField
            label={t('propertyName')}
            value={data.propertyName.value || data.propertyName.defaultValue}
            className="form-group"
            onChange={setData('propertyName')} />
        <wf.InputField
            label={t('phoneticReading')}
            value={data.phoneticReading.value || data.phoneticReading.defaultValue}
            className="form-group"
            onChange={setData('phoneticReading')} />
    </>
}
const PropertyNamePopup = ExportDefaultBehaviour(PropertyName, {
    editableFields: ['propertyName', 'phoneticReading']
});
PropertyNamePopup.whyDidYouRender = true;
export default PropertyNamePopup;