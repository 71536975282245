import React, { Component, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import { SimpleToggle } from 'components/inputs/toggles';
import * as wf from 'components/callmode/floatingControls/windowFields';

export class CallRow extends Component {

    shouldComponentUpdate(_nextProps) {
        return Object.keys(_nextProps).some(_k => _k === 'rangeValue' ? (_nextProps[_k] || {}).start !== (this.props[_k] || {}).start || (_nextProps[_k] || {}).end !== (this.props[_k] || {}).end : _nextProps[_k] !== this.props[_k]);
    }

    render() {
        return <wf.GroupField hideLabel={true}>
            <Row>
                <Col className="col-xs-3">
                    <SimpleToggle label={this.props.label} value={this.props.toggleValue} onChange={this.props.toggleHandler} />
                </Col>
                <Col className="col-xs-9">
                    <wf.InputRange value={this.props.rangeValue}
                        crossValidator={this.props.rangeCrossValidator}
                        validator={this.props.rangeValidator}
                        onChange={this.props.rangeChangeHandler}
                        onError={this.props.rangeErrorHandler}
                        addon={this.props.rangeAddon}
                        errorMessage={this.props.rangeErrorMessage}
                        hideLabel={true} />
                </Col>
            </Row>
        </wf.GroupField>
    }
}

function CallAvailability({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC}) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;
    const staticRangeAddon = useRef({ start: t('hour'), end: t('approxTime'), separator: '～' });
    const staticRangeErrorMessage = useRef(t('incorrectNumberFormatError'));
    const staticCrossValidator = useRef((_a, _b) => Number(_b) - Number(_a) >= 0);

    const toggleRows = Object.keys(data);
    const staticRowsContent = useRef(toggleRows.slice(0, toggleRows.length - 1).reduce((_a, _b, _i) => {
        if (_i % 2 == 0) {
            return _a.concat({ key: _b, label: t(_b), toggleHandler: setData(_b) });
        } else {
            _a[_a.length - 1] = {
                ..._a[_a.length - 1],
                rangeCrossValidator: staticCrossValidator.current,
                rangeValidator: _validatorsHOC.optionalDayHours,
                rangeChangeHandler: setData(_b),
                rangeErrorHandler: setData(_b),
                rangeAddon: staticRangeAddon.current,
                rangeErrorMessage: staticRangeErrorMessage.current
            }
            return _a;
        }
    }, []));

    return <>
        {staticRowsContent.current.map((_v) => <CallRow {..._v} toggleValue={data[_v.key].value || data[_v.key].defaultValue || false} rangeValue={data[_v.key + 'Range'].value || data[_v.key + 'Range'].defaultValue}/>)}
        <wf.InputField hideLabel={true} value={data.callAvailabilityNotes.value || data.callAvailabilityNotes.defaultValue} onChange={setData('callAvailabilityNotes')} />
    </>
}
export const CallAvailabilityPopup = ExportDefaultBehaviour(CallAvailability, {
    editableFields: ['everyDay', 'everyDayRange',
        'weekday', 'weekdayRange',
        'nonWorkingDay', 'nonWorkingDayRange',
        'monday', 'mondayRange',
        'tuesday', 'tuesdayRange',
        'wednesday', 'wednesdayRange',
        'thursday', 'thursdayRange',
        'friday', 'fridayRange',
        'saturday', 'saturdayRange',
        'sunday', 'sundayRange',
        'callAvailabilityNotes'
    ],
    extraClasses: 'push-up'
});
CallAvailabilityPopup.whyDidYouRender = true;