import ApiConnector from 'apiConnector';
import AuthService from 'authService';
import Callmode from 'components/callmode/callmode';
import DM from 'components/dm/dm';
import AssessmentRequestList from 'components/assessmentRequestList/assessmentRequestList';
import Inquiry from 'components/inquiry/inquiry';
import EmployeeManager from 'components/employeeManager/employeeManager';
import Header from 'components/header/header';
import Home from 'components/home/home';
import ImportAll from 'components/importAll/importAll';
import Layout from 'components/layout';
import Maintenance from 'components/maintenance/maintenance';
import ModalManager from 'components/modals/modalManager';
import PromotionalActivities from 'components/promotionalActivities/promotionalActivities';
import Reminders from 'components/reminders/reminders';
import SessionManager from 'components/sessionManager';
import Sidebar from 'components/sidebar/sidebar';
import StatusBar from 'components/statusBar/statusBar';
import Config from 'config';
import UserProfile from 'helpers/userProfile';
import i18n from "i18next";
import Backend from 'i18next-xhr-backend';
import * as locale from 'locale/date-fns/ja';
import React, { Component } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import rootReducer from 'reducers/index';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas/sagas';
import iekakakuManual from 'data/iekakaku_manual.pdf';
//
//Development script, remove for production
const whyDidYouRender = require('@welldone-software/why-did-you-render');
whyDidYouRender(React);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        ns: 'default',
        defaultNS: 'default',
        lng: "jp",
        fallbackLng: "jp",

        interpolation: {
            escapeValue: false
        }
    });
AuthService.authConfig = Config.authInformation;
ApiConnector.apiEntryPoint = Config.apiServer;

//Datepicker lang setup
registerLocale(Config.defaultLocale, locale.default);
setDefaultLocale(Config.defaultLocale);

class App extends Component {
    displayName = App.name;
    constructor(_props) {
        super(_props);

        const pages = () => {
            let post = UserProfile.getPost();
            let editFlg = UserProfile.getEditterFlg();
            switch (post) {
                
                case "6":
                    return [
                        {
                            name: 'assessmentRequestList',
                            handler: () => this.props.history.push('./assessmentRequestList')
                        },
                        {
                            name: 'notise',
                            handler: () => this.props.history.push('./')
                        },
                        {
                            name: 'manual',
                            handler: () => window.open(iekakakuManual)
                        },
                        {
                            name: 'inquiry',
                            handler: () => this.props.history.push('./inquiry')
                        },
                        {
                            name: 'ownerList',
                            handler: () => this.props.history.push('./')
                        },
                        {
                            name: 'employeeManager',
                            handler: () => this.props.history.push('./employeeManager')
                        },
                        {
                            name: 'import',
                            handler: () => this.props.history.push('./import')
                        },
                        {
                            name: 'promotionalActivities',
                            handler: () => this.props.history.push('./promotionalActivities')
                        },
                        {
                            name: 'callmode',
                            handler: () => this.props.history.push('./callmode')
                        },
                        {
                            name: 'dm',
                            handler: () => this.props.history.push('./dm')
                        },
                        {
                            name: 'maintenanceList',
                            handler: () => this.props.history.push('./maintenance')
                        },
                    ];
                default:
                    return [
                        {
                            name: 'assessmentRequestList',
                            handler: () => this.props.history.push('./assessmentRequestList')
                        },
                        {
                            name: 'notise',
                            handler: () => this.props.history.push('./')
                        },
                        {
                            name: 'manual',
                            handler: () => window.open(iekakakuManual)
                        },

                    ];
            }
        
        }

        let location = this.props.history.location.pathname.substring(1);
        if (!pages().some(_p => _p.name === location)) {
            location = '';
        }

        this.state = {
            location: location,
            pages: pages()
        };

        this.props.history.listen((location, action) => {
            this.setState((_prev) => {
                return {
                    location: location.pathname.substring(1)
                }
            });
        });
    }

    render() {
        return (
            <Provider store={store}>
                <SessionManager history={this.props.history}>
                    <React.Suspense fallback={<div>loading</div>}>
                        <Layout>
                            {/*<StatusBar />*/}{/*ステータスバーいらないので一旦コメントアウト*/}
                            <Sidebar pages={this.state.pages} location={this.state.location} colSize={2} logout={AuthService.Logout} />
                            {/*<Header />*/}{/*ヘッダーいらないので一旦コメントアウト*/}
                            {/*<Reminders />*/}{/*コール通知うざいのでコメントアウト*/}
                            <Route exact path='/' component={AssessmentRequestList} colSize={10} />
                            <Route exact path='/assessmentRequestList' component={AssessmentRequestList} colSize={10} />
                            <Route exact path='/import' component={ImportAll} colSize={10} />
                            {!(UserProfile.getPost() === "3") && <Route exact path='/promotionalActivities' component={PromotionalActivities} colSize={10} />}
                            {!(UserProfile.getPost() === "3") && <Route exact path='/callmode' component={Callmode} colSize={10} />}
                            {((UserProfile.getPost() === "2" || UserProfile.getPost() === "4" || UserProfile.getPost() === "6") && (UserProfile.getEditterFlg() === "1")) && <Route exact path='/employeeManager' component={EmployeeManager} colSize={10} />}
                            <Route exact path='/dm' component={DM} colSize={10} />
                            <Route exact path='/manual' colSize={10} />
                            <Route exact path='/inquiry' component={Inquiry} colSize={10} />
                            {!(UserProfile.getPost() === "0" || UserProfile.getPost() === "1") && <Route exact path='/maintenance' component={Maintenance} colSize={10} />}
                            <ModalManager />
                        </Layout>
                    </React.Suspense>
                </SessionManager>
            </Provider>
        );
    }
}

export default withRouter(App);