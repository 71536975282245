import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelector } from 'components/inputs/textInput';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Cellphone({ useStateHOC: _useStateHOC, errorHandlingHOC: _errorHandlingHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField label={t('cellphone')} value={data.cellphone.value || data.cellphone.defaultValue} errorMessage={t('incorrectPhoneFormatError')}
            onChange={setData('cellphone')}
            validator={_validatorsHOC.phoneValidator} />
        <wf.InputField value={data.cellphoneSecondary.value || data.cellphoneSecondary.defaultValue} errorMessage={t('incorrectPhoneFormatError')}
            onChange={setData('cellphoneSecondary')}
            validator={_validatorsHOC.phoneValidator} />
        <wf.GroupField label={t('externalData')}>
            <wf.InputField extraClasses="flex-grow-2 no-margin" hideLabel={true}
                value={data.externalDataNumberMain.value || data.externalDataNumberMain.defaultValue}
                validator={_validatorsHOC.phoneValidator}
                errorMessage={t('incorrectPhoneFormatError')}
                onChange={setData('externalDataNumberMain')} />
            <div className="flex-grow-1" />
            <DateSelector extraClasses="col-xs-4" showIcon={true} value={data.externalDataNumberMainDate.value || data.externalDataNumberMainDate.defaultValue} onChange={setData('externalDataNumberMainDate')} extraClasses="flex flex-nowrap" />
        </wf.GroupField>
        <wf.GroupField>
            <wf.InputField extraClasses="flex-grow-2 no-margin" hideLabel={true}
                value={data.externalDataNumberSecondary.value || data.externalDataNumberSecondary.defaultValue}
                validator={_validatorsHOC.phoneValidator}
                errorMessage={t('incorrectPhoneFormatError')}
                onChange={setData('externalDataNumberSecondary')} />
            <div className="flex-grow-1" />
            <DateSelector extraClasses="col-xs-4" showIcon={true} value={data.externalDataNumberSecondaryDate.value || data.externalDataNumberSecondaryDate.defaultValue} onChange={setData('externalDataNumberSecondaryDate')} extraClasses="flex flex-nowrap" />
        </wf.GroupField>
    </>;
}
export const CellphonePopup = ExportDefaultBehaviour(Cellphone, {
    editableFields: ['cellphone', 'cellphoneSecondary', 'externalDataNumberMain', 'externalDataNumberMainDate', 'externalDataNumberSecondary', 'externalDataNumberSecondaryDate']
});
CellphonePopup.whyDidYouRender = true;