import React from 'react';
import { connect } from 'react-redux';
import { dismissStatusBar } from 'reducers/statusBarReducer';

export function StatusBar({ message: _message, hideDismissButton: _hideDismissButton, onDismiss: _onDismiss, dismissStatusBar: _dismissStatusBar, extraClasses: _extraClasses}) {

    if (!_message) return <></>;

    const dismissHandler = _onDismiss ? _onDismiss : _dismissStatusBar;
    return <div className={`status-bar fill-w flex fixed ${_extraClasses}`}>
        <div className="message flex-grow-1">{_message}</div>
        {!_hideDismissButton && <div className="dismiss-button status-bar-button relative circle-button noselect" onClick={dismissHandler}><i className="fa fa-times absolute button-content" aria-hidden="true"></i></div>}
    </div>;
}
StatusBar.whyDidYouRender = true;


const mapStateToProps = (_state) => {
    return {
        ..._state.statusBar
    }
}

const mapDispatchToProps = {
    dismissStatusBar: dismissStatusBar
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);