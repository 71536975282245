import { SortedArray } from 'helpers/functionalHelpers';

const types = {
    addHansokuRecords: 'PR_ADD_HANSOKU_RECORDS',
    addKainushiTantous: 'PR_ADD_KAINUSHI_TANTOUS',
    addStaffs: 'PR_ADD_STAFFS',
    addTemplates: 'PR_ADD_TEMPLATES',
    addHansokuRireki: 'PR_ADD_HANSOKU_RIREKI',
    addHansokuGyousha: 'PR_ADD_HANSOKU_GYOUSHA',
    addStaffAndTemplate: 'PR_ADD_STAFF_AND_TEMPLATE',
    addRoomData: 'PR_ADD_ROOM_DATA',
    updateBoxType: 'PR_UPDATE_BOX_TYPE',
    updateTemplateList: 'PR_UPDATE_TEMPLATE_LIST',
    updateHansokuRecords: 'PR_UPDATE_HANSOKU_RECORDS',
    updateRoomData: 'PR_UPDATE_ROOM_DATA',
    requestHansokuRecords: 'PR_REQUEST_HANSOKU_RECORDS',
    requestKainushiTantou: 'PR_REQUEST_KAINUSHI_TANTOU',
    requestTemplateList: 'PR_REQUEST_TEMPLATE_LIST',
    requestStaffList: 'PR_REQUEST_STAFF_LIST',
    requestHansokuRireki: 'PR_REQUEST_HANSOKU_RIREKI',
    requestHansokuGyousha: 'PR_REQUEST_HANSOKU_GYOUSHA',
    requestStaffAndTemplate: 'PR_REQUEST_STAFF_AND_TEMPLATE',
    requestRoomData: 'PR_REQUEST_ROOM_DATA',
    deleteHansokuRecords: 'PR_DELETE_HANSOKU_RECORDS',
    postDeleteHansokuRecords: 'PR_POST_DELETE_HANSOKU_RECORDS',
    setAssets: 'PR_SET_ASSETS',
    requestUpdateImage: 'PR_REQUEST_UPDATE_IMAGE'
}

/** Actions **/
export const addHansokuRecords = hansokuRecords => ({
    type: types.addHansokuRecords,
    hansokuRecords
})

export const addKainushiTantous = kainushiTantous => ({
    type: types.addKainushiTantous,
    kainushiTantous
})

export const addStaffs = staffs => ({
    type: types.addStaffs,
    staffs
})

export const addTemplates = templates => ({
    type: types.addTemplates,
    templates
})


export const addHansokuGyousha = hansokuGyoushas => ({
    type: types.addHansokuGyousha,
    hansokuGyoushas
})

export const addHansokuRireki = hansokuRirekis => ({
    type: types.addHansokuRireki,
    hansokuRirekis
})

export const addStaffAndTemplate = data => ({
    type: types.addStaffAndTemplate,
    data
})

export const addRoomData = data => ({
    type: types.addRoomData,
    data
})

export const postDeleteHansokuRecords = ids => ({
    type: types.postDeleteHansokuRecords,
    ids
});

export const setAssets = data => ({
    type: types.setAssets,
    data
});

/** Async Actions **/
export const requestHansokuRecords = (boxType, staffId) => ({
    type: types.requestHansokuRecords,
    boxType,
    staffId
})

export const requestKainushiTantou = () => ({
    type: types.requestKainushiTantou
})

export const updateBoxType = (data) => ({
    type: types.updateBoxType,
    data
})

export const updateTemplateList = (data) => ({
    type: types.updateTemplateList,
    data
})

export const updateHansokuRecords = (id, boxType, staffId, updateType, updateValue) => ({
    type: types.updateHansokuRecords,
    id,
    boxType,
    staffId,
    updateType,
    updateValue
})

export const updateRoomData = (data) => ({
    type: types.updateRoomData,
    data
})

export const requestTemplateList = id => ({
    type: types.requestTemplateList,
    id
})

export const requestStaffList = id => ({
    type: types.requestStaffList,
    id
})

export const requestHansokuGyousha = (data) => ({
    type: types.requestHansokuGyousha,
    data
})

export const requestHansokuRireki = (id, reqType) => ({
    type: types.requestHansokuRireki,
    id,
    reqType
})

export const requestStaffAndTemplate = () => ({
    type: types.requestStaffAndTemplate
})

export const requestRoomData = id => ({
    type: types.requestRoomData,
    id
})

export const deleteHansokuRecords = (data, successCallback, failureCallback) => ({
    type: types.deleteHansokuRecords,
    data,
    successCallback,
    failureCallback
});

export const requestUpdateImage = (data, fileName, bukkenId) => ({
    type: types.requestUpdateImage,
    data,
    fileName,
    bukkenId
});

/** Reducer **/
const kainushiTantous = new SortedArray();
kainushiTantous.comparator = (_a, _b) => {
    return _a.id - _b.id;
};

const templates = new SortedArray();
templates.comparator = (_a, _b) => {
    return _a.id - _b.id;
};

const staffs = new SortedArray();
staffs.comparator = (_a, _b) => {
    return _a.id - _b.id;
};

const hansokuRecords = new SortedArray();
hansokuRecords.comparator = (_a, _b) => {
    return _a.id - _b.id;
};

const reducer = (_state = { kainushiTantous: [], hansokuRecords: [], templates: [], staffs: [], hansokuGyoushas: [], hansokuRirekis: [], staffTemplate: [], pdfTemplate: [], roomData: [] }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addKainushiTantous:
            _action.kainushiTantous.forEach(_a => kainushiTantous.add(_a));
            newState.kainushiTantous = kainushiTantous.toArray();
            break;
        case types.addHansokuRecords:
            hansokuRecords.addWhole(_action.hansokuRecords);
            newState.hansokuRecords = hansokuRecords.toArray();
            break;
        case types.addTemplates:
            _action.templates.forEach(_a => templates.add(_a));
            newState.templates = templates.toArray();
            break;
        case types.addStaffs:
            _action.staffs.forEach(_a => staffs.add(_a));
            newState.staffs = staffs.toArray();
            break;
        case types.addHansokuGyousha:
            newState.hansokuGyoushas = _action.hansokuGyoushas;
            break;
        case types.addHansokuRireki:
            newState.hansokuRirekis = _action.hansokuRirekis;
            break;
        case types.addStaffAndTemplate:
            newState.staffTemplate = _action.data.staffs;
            newState.pdfTemplate = _action.data.templates;
            break;
        case types.addRoomData:
            newState.roomData = _action.data;
            break;
        case types.postDeleteHansokuRecords:
            _action.ids.forEach(_v => {
                hansokuRecords.delById(_v);
                newState.hansokuRecords = hansokuRecords.toArray();
            });
            break;
        case types.setAssets:
            let temp = newState.roomData;
            if (_action.data.fileName === "registrationInformation") {
                temp.imageRecords.registrationInformation = {
                    ..._action.data
                };
            }
            if (_action.data.fileName === "rentEvi") {
                temp.imageRecords.rentEvi = {
                    ..._action.data
                };
            } if (_action.data.fileName === "administrativeAndReserves") {
                temp.imageRecords.administrativeAndReserves = {
                    ..._action.data
                };
            } if (_action.data.fileName === "pamphlet") {
                temp.imageRecords.pamphlet = {
                    ..._action.data
                };
            } if (_action.data.fileName === "cityTaxEvi") {
                temp.imageRecords.cityTaxEvi = {
                    ..._action.data
                };
            } if (_action.data.fileName === "returnIntermediary") {
                temp.imageRecords.returnIntermediary = {
                    ..._action.data
                };
            } if (_action.data.fileName === "image") {
                temp.imageRecords.image = {
                    ..._action.data
                };
            } if (_action.data.fileName === "imageImport") {
                temp.imageRecords.imageImport = {
                    ..._action.data
                };
            } if (_action.data.fileName === "loanEvi") {
                temp.imageRecords.loanEvi = {
                    ..._action.data
                };
            }
            newState.roomData = [];
            newState.roomData = temp;
            break;
        default:
            break;
    }

    return newState;
}


export default reducer 