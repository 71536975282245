import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone'
import * as helpers from 'helpers/formHelpers';

export function FileImportCSV({ onUploadCallback: _onUploadCallback, onCancelCallback: _onCancelCallback, onSuccessCallback: _onSuccessCallback, onFailureCallBack: _onFailureCallBack, disabled: _disabled, type: _type, title: _title }) {


    const [files, setFiles] = useState({});
    const [data, setData] = useState({});
    const onDrop = useCallback(acceptedFiles => {
        const newFiles = { ...files };
        acceptedFiles.forEach(_f => {
            if (!files.hasOwnProperty(_f.path)) {
                newFiles[_f.path + ((Math.random() * 100) | 0)] = Object.assign(_f, {
                    preview: URL.createObjectURL(_f)
                });
            }
        });

        setFiles(newFiles);
    }, [files]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv' });
    const { t } = useTranslation();

    const importModes = {
        afterDM: 'afterDM',
        ec: 'ec',
        csvImport3: 'csvImport3',
        csvImport4: 'csvImport4',
        callImport: 'callImport',
        callImport2: 'callImport2',
        reinsCollation: 'reinsCollation',
        jonImportProperty: 'jonImportProperty',
        jonImportOwner: 'jonImportOwner',
        csvImport: 'csvImport'
    };

    const handler = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const cancelFile = (_id) => {
        const remainingFiles = { ...files };
        delete remainingFiles[_id];
        setFiles(remainingFiles);
    };

    const replaceState = (_attr, _val) => {
        const update = data;
        update[_attr] = _val;
        setData(update);
    }

    const setSelectedValue = (_attr) => {
        if (data[_attr]) {
            (Array.isArray(data[_attr])) && data[_attr].forEach(_v => {
                replaceState(_v.value, _v.selected ? _v.selected : false);
            });
        }
        else {
            replaceState('fixed', true);
            replaceState('cellphone', false);
        }
    };

    const uploadHandler = () => {
        if (!data['beforeCheck'])
            data['beforeCheck'] = false;
        if (!data['beforeCheck2'])
            data['beforeCheck2'] = false;
        data['type'] = _type;
        setSelectedValue('radioType');
        _onUploadCallback({ files, data, _onSuccessCallback, _onFailureCallBack });
    };

    const thumbs = Object.keys(files).map((_k, _i) => (
        <div className="thumb" key={_k}>
            <div className="thumb-inner">
                {files[_k].path.endsWith(".csv") &&
                    <i className="fa fa-file-text-o file-icon" />}
                {!files[_k].path.endsWith(".csv") &&
                    <img src={files[_k].preview} />}
                <div className="name-container" onClick={cancelFile.bind(null, _k)}>
                    <span>{files[_k].name}</span>
                    <i className="fa fa-times-circle" />
                </div>
            </div>
        </div>
    ));


    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        const keys = Object.keys(files);
        keys.forEach(_k => {
            URL.revokeObjectURL(files[_k].preview)
        });
    }, [files]);

    const groupValuesNormalizer = (_attributeName) => (_v, _i) => ({ ..._v, ...{ selected: _v.selected || (data[_attributeName] && data[_attributeName][_i] && data[_attributeName][_i].selected) || false } });
    const radioTypeValues = [{ value: 'fixed', text: t('fixed'), selected: true }, { value: 'cellphone', text: t('cellphone') }];
    const radioType = radioTypeValues.map((_v, _i) => groupValuesNormalizer('radioType')({ ..._v, ...{ selected: data.radioType ? data.radioType[_i].selected : _v.selected === true } }, _i));

    return <div className="file-upload-modal modal fade show">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{_title}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={_onCancelCallback}>&times;</span>
                        <span className="sr-only">{t("fileUpload")}</span>
                    </button>
                </div>
                <div className="modal-body modal-tab-container upload-container">
                    <div className="dropzone"  {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-message-block">
                            <div className="dz-message needsclick"> {t("dragAndUploadMessage")} </div>
                            <div className="dz-message needsclick"> {"CSVファイル(*.csv)"} </div>
                        </div>
                    </div>
                </div>
                <aside className="thumbs-container">
                    {thumbs}
                </aside>
                <div className="modal-footer">
                    {_type === 'ECImport' || _type === importModes.ec && <helpers.GenerateRadioGroup id="radioType" values={radioType} onChangeHandler={(_v) => handler('radioType', _v)} extraClasses="radioType-container" />}
                    {_type === 'FailedImport' && <helpers.GenerateCheckbox value={data.beforeCheck} text={t('beforeCheck')} onChangeHandler={(_v) => handler('beforeCheck', _v)} extraClasses="beforeCheck-container" />}
                    {_type === importModes.afterDM && <helpers.GenerateCheckbox value={data.beforeCheck} text={t('JON')} onChangeHandler={(_v) => handler('beforeCheck', _v)} extraClasses="beforeCheck-container" />}
                    {_type === importModes.csvImport3 && <helpers.GenerateCheckbox value={data.beforeCheck} text={t('cellphone')} onChangeHandler={(_v) => handler('beforeCheck', _v)} extraClasses="beforeCheck-container" />}

                    {_type === importModes.csvImport4 && <helpers.GenerateCheckbox value={data.beforeCheck} text={t('noDuplicateCheck')} onChangeHandler={(_v) => handler('beforeCheck', _v)} extraClasses="beforeCheck-container" />}
                    {_type === importModes.csvImport4 && <helpers.GenerateCheckbox value={data.beforeCheck2} text={t('feedback')} onChangeHandler={(_v) => handler('beforeCheck2', _v)} extraClasses="beforeCheck2-container" />}

                    {_type === importModes.csvImport && <helpers.GenerateCheckbox value={data.beforeCheck} text={t('JON')} onChangeHandler={(_v) => handler('beforeCheck', _v)} extraClasses="beforeCheck-container" />}
                    {_type === importModes.csvImport && <helpers.GenerateCheckbox value={data.beforeCheck2} text={t('feedback')} onChangeHandler={(_v) => handler('beforeCheck2', _v)} extraClasses="beforeCheck2-container" />}

                    <button type="button" className="btn btn-secondary" onClick={_onCancelCallback} data-dismiss="modal">{t("cancel")}</button>
                    {_disabled &&
                        <button type="button" className="btn btn-primary disabled" onClick={uploadHandler}>{t("loading")}&nbsp;<i className="fas fa-circle-notch fa-spin" /></button>}
                    {!_disabled &&
                        <button type="button" className="btn btn-primary" onClick={uploadHandler}>{t("upload")}</button>}
                </div>
            </div>
        </div>
    </div>;
}

FileImportCSV.defaultProps = {
    onUploadCallback: () => { },
    onCancelCallback: () => { }
}