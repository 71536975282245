import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function GroupEditPopup({ groupData: _groupData, title: _title, titleIcon: _titleIcon, okText: _okText, cancelText: _cancelText, onOkCallback: _onOkCallback, onCancelCallback: _onCancelCallback, dropList: _dropList }) {

    const { t } = useTranslation();
    const [data, setData] = useState(_groupData ? _groupData : {});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const uploadHandler = _onOkCallback ? () => _onOkCallback(data) : () => { };

    return <div className="simple-message-modal modal fade show">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{_titleIcon && <i class={"fa " + _titleIcon} />}{_title}</h4>
                    <button type="button" className="close" onClick={_onCancelCallback}>
                        <span>&times;</span>
                        <span className="sr-only">{_title}</span>
                    </button>
                </div>
                <div className="card">
                    <div className="card-block">
                        <Row>
                            <Col md={12} className="super-wide-input col-xxl-12 col-xl-12">
                                <helpers.GenerateInput value={data.groupName} text={t('groupName')} onChangeHandler={(_v) => updateState('groupName', _v)} labelSize={3} inputSize={9} extraClasses="groupName-container" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="super-wide-input col-xxl-12 col-xl-12">
                                <helpers.GenerateSelector value={data.groupLeaderId} text={t('groupLeader')} options={[{ value: null, text: null }].concat((_dropList || []).map(_v => ({ value: _v.id, text: _v.name })))} onChangeHandler={(_v) => updateState('groupLeaderId', _v)} labelSize={3} inputSize={9} extraClasses="status2SelectedEmployee-container" />
                            </Col>
                        </Row>
                        {/**<Row>
                            <Col md={12} className="super-wide-input col-xxl-12 col-xl-12">
                                <helpers.GenerateCheckbox value={data.hankyoFlg === 1 ? true : false} text={t('lsHoldFlagGrant')} onChangeHandler={(_v) => updateState('hankyoFlg', _v === true ? 1 : 0)} extraClasses="reverberation-container" />
                            </Col>
                        </Row>**/}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={uploadHandler}>{_okText}</button>
                    <button type="button" className="btn btn-secondary" onClick={_onCancelCallback} >{_cancelText}</button>
                </div>
            </div>
        </div>
    </div>;
}