import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleToggle } from 'components/inputs/toggles';
import { DateSelector } from 'components/inputs/textInput';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Name({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;
    const proxyTypes = ["unspecified", "spouse", "progeny", "siblings", "relatives"].reduce(_checkboxGroupHOC.checkboxGroupReducer('proxyTypes'), {});

    return <>
        <wf.TextField label={t('basicName')} text={data.name.defaultValue} />
        <wf.InputField label={t('phoneticSpelling')} value={data.phoneticSpelling.value || data.phoneticSpelling.defaultValue} className="form-group"
            onChange={setData('phoneticSpelling')} />
        <wf.CheckboxGroupField label={t('proxy')} values={proxyTypes} onChange={(_v) => setData('proxyTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="phoneticSpelling" />
        <wf.InputField value={data.proxyName.value || data.proxyName.defaultValue} className="form-group"
            onChange={setData('proxyName')} />
        <wf.GroupField contentExtraClasses="no-gutters">
            <SimpleToggle extraClasses="col-xs-4" label={t('identityConfirmed')} value={data.identityConfirmed.value || data.identityConfirmed.defaultValue || false} onChange={setData('identityConfirmed')} />
            <SimpleToggle extraClasses="col-xs-3" label={t('feedback')} value={data.feedbackSale.value || data.feedbackSale.defaultValue || false} onChange={setData('feedbackSale')} />
            <DateSelector extraClasses="col-xs-4" showIcon={true} value={data.feedbackDate.value || data.feedbackDate.defaultValue} onChange={setData('feedbackDate')} extraClasses="flex flex-nowrap" />
        </wf.GroupField>
    </>;
}

export const NamePopup = ExportDefaultBehaviour(Name, { editableFields: ['name', 'phoneticSpelling', 'proxyTypes', 'proxyName', 'identityConfirmed', 'feedbackSale', 'feedbackDate'] });
NamePopup.whyDidYouRender = true;