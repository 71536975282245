import 'babel-polyfill';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiEntryPoint } from 'apiConnector';
import { requestPromotionalActivityPdf, requestPromotionalActivityExcel, requestPdf, requestCSV, requestBukkenCSV, requestPropertyRoomsCSV, requestDMCSV, sendMailHansoku } from 'reducers/exportReducers';

function* requestPdfSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.EXRequestExportPdf, _action.data);
        if (!result.error) {
            exportData(result);
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestPdfSaga');
        console.log(_e);
    }
}

function* requestPromotionalActivityPdfSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.ExRequestPromotionalActivityPdf, _action.exportData);
        exportData(result);
    } catch (_e) {
        console.log('Error from requestPromotionalActivityPdfSaga');
        console.log(_e);
    }
}

function* requestPromotionalActivityExcelSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.ExRequestPromotionalActivityExcel, _action.exportData);
        if (!result.error) {
            exportData(result);
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestPromotionalActivityExcelSaga');
    }
}

function* requestCSVSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.EXRequestExportCSV, _action.data);
        if (!result.error) {
            exportData(result);
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestCSVSaga');
        console.log(_e);
    }
}

function* requestBukkenCSVSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.EXRequestBukkenExportCSV, _action.data);
        if (!result.error) {
            exportData(result);
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestBukkenCSVSaga');
        console.log(_e);
    }
}

function* requestDMCSVSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.EXRequestExportDMCSV, _action.data);
        if (!result.error) {
            exportData(result);
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestDMCSVSaga');
        console.log(_e);
    }
}

function* requestPropertyRoomsCSVSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.EXRequestPropertyRoomsCSV, _action.propertyRooms);
        exportData(result);
    } catch (_e) {
        console.log('Error from requestPropertyRoomsCSV');
        console.log(_e);
    }
}

function* sendMailHansokuSaga(_action) {
    try {
        var result = yield call(ApiEntryPoint.EXSendMailHansoku, _action.mailHansoku);
        if (result.success) {
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from sendMailHansokuSaga');
        console.log(_e);
    }
}


export function* watchrequestPdfSaga() {
    yield takeEvery(requestPdf().type, requestPdfSaga);
}

export function* watchrequestPromotionalActivityPdfSaga() {
    yield takeEvery(requestPromotionalActivityPdf().type, requestPromotionalActivityPdfSaga);
}

export function* watchrequestPromotionalActivityExcelSaga() {
    yield takeEvery(requestPromotionalActivityExcel().type, requestPromotionalActivityExcelSaga);
}

export function* watchrequestCSVSaga() {
    yield takeEvery(requestCSV().type, requestCSVSaga);
}

export function* watchRequestBukkenCSVSaaga() {
    yield takeEvery(requestBukkenCSV().type, requestBukkenCSVSaga);
}

export function* watchrequestDMCSVSaga() {
    yield takeEvery(requestDMCSV().type, requestDMCSVSaga);
}

export function* watchrequestPropertyRoomsCSVSaga() {
    yield takeEvery(requestPropertyRoomsCSV().type, requestPropertyRoomsCSVSaga);
}

export function* watchSendMailHansoku() {
    yield takeEvery(sendMailHansoku().type, sendMailHansokuSaga);
}




//Methods to save fIle from response
const saveAs = (blob, filename) => {
    const download = document.createElement("a");
    download.download = filename;
    download.href = window.URL.createObjectURL(blob);
    download.click();
}

const exportData = (_presetResponse) => {

    var base64str = _presetResponse.fileContents;
    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    var blob = new Blob();

    if (_presetResponse.fileDownloadName.includes('.csv'))
        blob = new Blob([view], { type: "text/csv" });
    else if (_presetResponse.fileDownloadName.includes('.pdf'))
        blob = new Blob([view], { type: "application/pdf" });
    else if (_presetResponse.fileDownloadName.includes('.xlsx'))
        blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(blob, _presetResponse.fileDownloadName);
}