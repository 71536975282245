import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import PropertyListModuleTable from './propertyListModuleTable';
import PropertyListModuleButtons from './propertyListModuleButtons';

export class PropertyListModule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            number: 0
        }
    }

    onClickCell(id) {
        this.setState({ number: id })
    }


    render() {


        const tablePropsCount = this.props.propertyListData ? this.props.propertyListData.length : 0;

        return (
            <div className={`propertylist-module-container ${this.props.extraClasses}`}>
                <div>
                    <PropertyListModuleButtons
                        tablePropsCount={tablePropsCount}
                        propsData={this.props}
                        onClickCell={this.onClickCell}
                        idNumber={this.state.number}
                        ownerId={this.props.selectedOwner}
                        onUnlockPropertyRoomPopup={this.props.onUnlockPropertyRoomPopup}
                        onAddPropertyRoomPopUp={this.props.onAddPropertyRoomPopUp}
                    />
                    <div>
                        <PropertyListModuleTable
                            tableprops={this.props.propertyListData ? this.props.propertyListData : [{}]}
                            propsData={this.props}
                            onClickCell={this.onClickCell.bind(this)}
                            idNumber={this.state.number}
                        />
                    </div>
                </div>
            </div>);
    }
}
PropertyListModule.defaultProps = {
    extraClasses: ''
}

export default withTranslation()(PropertyListModule);