import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { requestStats } from 'reducers/userSalesReducer';

function Header({stats: _statsssss,  requestStats: _requestStats}) {
    const { t } = useTranslation();
    const stats = _statsssss || {}
     useEffect(() => {
        _requestStats();
     }, []);

    return(
        <div className="header-container container">
                <Row className="justify-center">
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('targetSales')}　{stats.goalSales}</Col>
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('currentSales')}　{stats.currentSales}</Col>
                
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('requiredSales')}　{stats.requiredSales}</Col>
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('ongoingContracts')}　{stats.ongoingContracts}</Col>
                
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('agreementsReceived')}　<span className="blue">{stats.agreementsReceived}</span></Col>
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('agreementsAccepted')}　<span className="blue">{stats.agreementsAccepted}</span></Col>
              
                
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('ownerCommunications')}　<span className="red">{stats.ownersContactedToday}</span> / {stats.ownersContactedThisMonth}</Col>
                    <Col className="header-category col-xl-1 col-lg-3 col-xs-6">{t('calls')}　<span className="blue">{stats.callsToday}</span> / {stats.callsThisMoth}</Col>
                </Row>
        </div>
        )
};
Header.whyDidYouRender = true;

const mapStateToProps = (_state) => {
    return {
        stats: _state.userSales,
    }
}
const mapDispatchToProps = {
    requestStats: requestStats,
}


export default connect(mapStateToProps,mapDispatchToProps)(Header);