import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { formatDateTimeShort } from 'helpers/formatHelpers';
import { validatorPhone, validatorEmail, validatorNumbersOnly, validatorDouble } from 'helpers/validatorHelpers';

export function ShinseiFloatingWindow({title: _title, size: _size, extraClasses: _extraClasses, onChange: _onChange, onIdle: _onIdle, children: _children}) {
    const { t } = useTranslation();
    const buttonSize = _size === 'sm' ? '6' : '3';
    const [shinseiForm, setShinseiForm] = useState(true);
    const shinseiClick = () => {
        if (_onChange()) {
            console.log("どない？");
            setShinseiForm(false);
        }
    }

    return <div className="floating-container absolute fill-w relative" onClick={_onIdle ? _onIdle : () => { }}>
        <div className={`floating-window${_size ? '-' + _size : ''} card absolute ${_extraClasses}`} onClick={(_e) => _e.stopPropagation()}>
            <div className="window-title title-block flex">
                <h3 className="title single-line">{_title}</h3>
                <span className="subtitle ellipsize"></span>
            </div>
            {shinseiForm ?
                <>
                    {_children || <button type="button" className="btn btn-secondary" /*onClick={_onCancelCallback}*/>閉じる</button>
                    }
                    <div className="actions-container form-group row justify-content-end no-gutters">
                        <div className={`col-xs-${buttonSize}`}>
                            <button type="button" className="btn btn-primary fill-w" onClick={() => shinseiClick()}>{t('shinseisuru')}</button>
                        </div>
                        <div className={`col-xs-${buttonSize}`}>
                            <button type="button" className="btn btn-secondary fill-w" onClick={_onIdle ? _onIdle : () => { }}>{t('cancel')}</button>
                        </div>
                    </div>
                </> :
                <>
                    <div>申請を受付けました</div>
                    <div className="actions-container form-group row justify-content-end no-gutters">
                        <div className={`col-xs-${buttonSize}`}>
                            <button type="button" className="btn btn-secondary fill-w" onClick={_onIdle ? _onIdle : () => { }}>{t('goBack')}</button>
                        </div>
                    </div>
                </>
            }
        </div>
    </div>;
}
ShinseiFloatingWindow.defaultProps = {
    extraClasses: '',
    metadata: { author: '' }
}

//export default ExportDefaultBehaviour;
export default ShinseiFloatingWindow;