import { isFunction } from 'helpers/functionalHelpers';

const types = {
    displayMessageAction: 'PA_DISPLAY_MESSAGE_ACTION',
    displayNewReminderAction: 'PA_DISPLAY_NEW_REMINDER_ACTION',
    displayRemindersAction: 'PA_DISPLAY_REMINDERS_ACTION',
    uploadFilesAction: 'PA_UPLOAD_FILES_ACTION',
    importFilesAction: 'PA_IMPORT_FILES_ACTION',
    imagesImportAction: 'PA_IMAGES_IMPORT_ACTION',
    clearAction: 'PA_CLEAR_ACTION',
    displayMessageWithTextBoxAction: 'PA_DISPLAY_MESSAGE_WITH_TEXTBOX_ACTION',
    displayGroupEditPopup: 'PA_DISPLAY_GROUP_EDIT_POPUP',
    displayTeamEditPopup: 'PA_DISPLAY_TEAM_EDIT_POPUP',

    telCallPopUp: 'PA_TEL_CALL_POPUP',
    propertyRoomPopup: 'PA_PROPERTY_ROOM_POPUP',
    addpropertyRoomPopUp: 'PA_ADD_PROPERTY_ROOM_POPUP'
};

/** Actions **/
export const displayMessageAction = (title, message, okProps, showCancelButton) => ({
    type: types.displayMessageAction,
    title,
    message,
    okProps,
    showCancelButton
});

export const displayMessageWithTextBoxAction = (title, message, okProps, showCancelButton, onChangeCallback) => ({
    type: types.displayMessageWithTextBoxAction,
    title,
    message,
    okProps,
    showCancelButton,
    onChangeCallback
});

export const displayGroupEditPopup = (data, title, list, okProps, showCancelButton, onChangeCallback) => ({
    type: types.displayGroupEditPopup,
    data,
    title,
    list,
    okProps,
    showCancelButton,
    onChangeCallback
});

export const displayTeamEditPopup = (data, title, list, okProps, showCancelButton, onChangeCallback) => ({
    type: types.displayTeamEditPopup,
    data,
    title,
    list,
    okProps,
    showCancelButton,
    onChangeCallback
});

export const displayNewReminderAction = (reminder) => ({
    type: types.displayNewReminderAction,
    reminder
});

export const displayRemindersAction = (reminders) => ({
    type: types.displayRemindersAction,
    reminders
});

export const uploadFilesAction = () => ({
    type: types.uploadFilesAction,
});

export const importFilesAction = (data, title, onSuccessCallback, onFailureCallBack) => ({
    type: types.importFilesAction,
    data,
    title,
    onSuccessCallback,
    onFailureCallBack
});

export const imagesImportAction = (data, onChangeCallback) => ({
    type: types.imagesImportAction,
    data,
    onChangeCallback
});

export const clearAction = () => ({
    type: types.clearAction,
});

export const telCallPopUp = (data, phone, phoneNumber, CellPhone, CallModeRequestCall, LoginId) => ({
    type: types.telCallPopUp,
    data,
    phone,
    phoneNumber,
    CellPhone,
    CallModeRequestCall,
    LoginId
});

export const propertyRoomPopup = (onRequestUnlockPropertyListData, Id) => ({
    type: types.propertyRoomPopup,
    onRequestUnlockPropertyListData,
    Id
});

export const addpropertyRoomPopUp = () => ({
    type: types.addpropertyRoomPopUp
})

/** Reducer **/
const reducer = (_state = { action: { type: null } }, _action) => {
    const actionKeys = Object.keys(types);
    if (actionKeys.some(_k => types[_k] === _action.type)) {
        const newAction = JSON.parse(JSON.stringify(_action));//If we use a shallow clone, some of the attributes from previous states might be lost causing issues to components listening to changes in this state
        Object.keys(_action).forEach(_k => {//TODO: we probably want to add a function to the functionalHelpers lib that deep clones an object
            if (isFunction(_action[_k])) {
                newAction[_k] = _action[_k];
            }
        });
        if (_action.type === types.displayMessageAction || _action.type === types.displayMessageWithTextBoxAction) {
            newAction.okProps = _action.okProps;
        }
        return {
            action: newAction
        };
    }
    return _state;
}

export const availableActions = { ...types };

export default reducer;