import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function Gender({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    const genders = ["man", "woman", "unknown"].reduce(_checkboxGroupHOC.checkboxGroupReducer('gender'), {});
    return <>
        <wf.CheckboxGroupField hideLabel={true} values={genders} onChange={(_v) => setData('gender')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="genders" />
    </>
}
export const GenderPopup = ExportDefaultBehaviour(Gender, {
    editableFields: ['gender'],
    size: 'sm'
});
GenderPopup.whyDidYouRender = true;