import { themeHelper } from 'helpers/themeHelpers/teams';
import { insertBinary } from 'helpers/functionalHelpers';

const types = {
    addTeam: 'ADD_TEAM',
    updateTeam: 'UPDATE_TEAM',
    serverUpdateTeam: 'SERVER_UPDATE_TEAM',
    requestTeams: 'REQUEST_TEAMS'
};

const encapsulateTeams = (_teams) => {
    const formatStat = (_stat, _statName) => {
        if (_stat == null) return _stat;
        const output = Object.assign({}, _stat);

        output.theme = themeHelper.themeFor(_statName);
        return output;
    };

    const formatStatGroup = (_group) => {
        if (_group == null) return _group;
        const output = Object.assign({}, _group);
        output.stats = {};

        _group.stats.forEach(_s => {
            output.stats[_s.name] = Object.assign({}, _s);
            delete output.stats[_s.name].name;
        });

        output.stats.requiredSales = {};
        Object.keys(output.stats).forEach(_k => {
            const formatted = formatStat(output.stats[_k], _k);
            output.stats[_k] = formatted;
        });

        Object.defineProperty(output.stats.requiredSales, 'value', {
            get: (function () {
                return this.targetSales.value == null || isNaN(this.targetSales.value) ||
                    this.currentSales.value == null || isNaN(this.currentSales.value) ? NaN : this.targetSales.value - this.currentSales.value;
            }).bind(output.stats)
        });

        return output;
    };

    const output = formatStatGroup(JSON.parse(JSON.stringify(_teams)));
    output.members.sort((_a, _b) => {
        return _a.ranking - _b.ranking;
    });
    output.members.forEach((_m, _i) => {
        const formatted = formatStatGroup(_m);
        output.members[_i] = formatted;

    });
    
    return output;
};

/** Actions **/
export const addTeam = team => ({
    type: types.addTeam,
    team
})

export const updateTeam = data => ({
    type: types.updateTeam,
    data
})

/** Async Actions **/
export const requestTeams = team => ({
    type: types.requestTeams
})

export const serverUpdateTeam = data => ({
    type: types.serverUpdateTeam,
    data
})

/** Reducer **/
const reducer = (_state = null, _action) => {
    if (!_action || !_action.type) return _state;
    const prevState = _state == null ? [] : _state;
    switch (_action.type) {
        case types.addTeam:
            const newTeams = [...prevState];
            const toAdd = encapsulateTeams(JSON.parse(JSON.stringify(_action.team)));
            return insertBinary.call(newTeams, toAdd, (_a) => _a.ranking - toAdd.ranking);
        case types.updateTeam:
            const updatedTeams = prevState.slice(0);
            if (_action.data.memberIndex !== undefined) {
                updatedTeams[_action.data.teamIndex].members[_action.data.memberIndex].stats[_action.data.valueIndex].value = _action.data.value;
            } else {
                updatedTeams[_action.data.teamIndex].stats[_action.data.valueIndex].value = _action.data.value;
            }
            return updatedTeams;
        case types.serverUpdateTeam:
            const affectStat = (_id, _value, _target) => {
                let output = false;
                const keys = Object.keys(_target);
                const statIndex = keys.findIndex(_k => _target[_k].id === _action.data.valueId);
                if (statIndex >= 0) {
                    _target[keys[statIndex]].value = Number(_value);
                    output = true;
                }
                return output;
            };
            const toUpdate = prevState.slice(0);
            const teamIndex = toUpdate.findIndex(_t => _t.id === _action.data.teamId);
            if (teamIndex >= 0) {
                if (_action.data.memberId >= 0) {
                    const memberIndex = toUpdate[teamIndex].members.findIndex(_m => _m.id === _action.data.memberId);
                    if (memberIndex >= 0) {
                        affectStat(_action.data.valueId, _action.data.value, toUpdate[teamIndex].members[memberIndex].stats);
                    }
                } else {
                    affectStat(_action.data.valueId, _action.data.value, toUpdate[teamIndex].stats);
                }
            }
            return toUpdate;
        default:
            return _state;
    }
}

export default reducer 