import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function FinancialInstitution({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    const financialInstitutionType = ['unspecified', 'jaccs', 'orix', 'suruga', 'mizuho', 'orico', 'kansaiUrbanBank', 'other'].reduce(_checkboxGroupHOC.checkboxGroupReducer('financialInstitutionType'), {});
    const loanType = ['jointCollateral', 'mortgage'].reduce(_checkboxGroupHOC.checkboxGroupReducer('loanType'), {});

    const isOtherInstitution = data.financialInstitutionType && (data.financialInstitutionType.value || data.financialInstitutionType.defaultValue || {}).other;
    return <>
        <wf.CheckboxGroupField
            hideLabel={true}
            values={financialInstitutionType}
            onChange={(_v) => setData('financialInstitutionType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))}
            radioName="financialInstitutionType"
            contentExtraClasses="flex-wrap" />
        <wf.InputField hideLabel={true}
            value={isOtherInstitution ? data.financialInstitutionOther.value || data.financialInstitutionOther.defaultValue : ""}
            onChange={setData('financialInstitutionOther')}
            disabled={!isOtherInstitution} />
        <wf.CheckboxGroupField
            hideLabel={true}
            values={loanType}
            onChange={(_v) => setData('loanType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} />
    </>;
}

const FinancialInstitutionPopup = ExportDefaultBehaviour(FinancialInstitution, {
    editableFields: ['financialInstitutionType', 'financialInstitutionOther', 'loanType'],
    size: 'sm'
});
FinancialInstitutionPopup.whyDidYouRender = true;
export default FinancialInstitutionPopup;