import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import { SimpleToggle } from 'components/inputs/toggles';
import * as wf from 'components/callmode/floatingControls/windowFields';
import { validatorNumbersOnly } from 'helpers/validatorHelpers';

function Room({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    return <>
        <wf.TextField label={t('basicRoomNumber')} text={data.roomNumber.defaultValue} />
        <wf.InputField
            value={data.apartmentFloor.value || data.apartmentFloor.defaultValue}
            className="form-group"
            validator={(_v) => validatorNumbersOnly(_v, 0)}
            onChange={setData('apartmentFloor')}
            onError={setData('apartmentFloor')}
            addon={t('apartmentFloor')}
            errorMessage={t('incorrectNumberFormatError')} />
        <wf.GroupField>
            <wf.InputField hideLabel={true}
                extraClasses="no-margin"
                value={data.roomNumber.value || data.roomNumber.defaultValue}
                validator={(_v) => validatorNumbersOnly(_v, 0)}
                onChange={setData('roomNumber')}
                onError={setData('roomNumber')}
                addon={t('roomNumber')}
                errorMessage={t('incorrectNumberFormatError')} />
            <SimpleToggle extraClasses="col-xs-4" label={t('realResidence')} value={data.realResidence.value || data.realResidence.defaultValue || false} onChange={setData('realResidence')} />
        </wf.GroupField>
    </>
}
const RoomPopup = ExportDefaultBehaviour(Room, {
    editableFields: ['apartmentFloor', 'roomNumber', 'realResidence']
});
RoomPopup.whyDidYouRender = true;
export default RoomPopup;