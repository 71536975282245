import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function PropertyForm({ callBack: _callBack, extraClasses: _extraClasses }) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const updateName = (_v) => updateState('propertyName', _v);
    const updatePartialMatch = (_v) => updateState('partialMatch', _v);
    const updateRoomNumber = (_v) => updateState('roomNumber', _v);
    const updateRealResidence = (_v) => updateState('realResidence', _v);
    const updateNotRealResidence = (_v) => updateState('notRealResidence', _v);
    const updateStreetAddress = (_v) => updateState('propertyStreetAddress', _v);
    const updateBuildingAge = (_v) => updateState('buildingAge', _v);
    const updateBuildingAgeNone = (_v) => updateState('buildingAgeNone', _v);
    const updatePurposeValues = (_v) => updateState('purposeValues', _v);
    const updateNearestStation = (_v) => updateState('nearestStation', _v);
    const updateWalkingDistanceToStation = (_v) => updateState('walkingDistanceToStation', _v);
    const updateFloorSurfaceSize = (_v) => updateState('floorSurfaceSize', _v);
    const updateRentalManagement = (_v) => updateState('rentalManagement', _v);
    const updateWithCancellationPenalty = (_v) => updateState('withCancellationPenalty', _v);
    const updateBuildingManagement = (_v) => updateState('buildingManagement', _v);
    const updateRent = (_v) => updateState('rent', _v);
    const updateRentFlagValues = (_v) => updateState('rentFlagValues', _v);
    const updateManagementCost = (_v) => updateState('managementCost', _v);
    const updatePurchasePrice = (_v) => updateState('purchasePrice', _v);
    const updatePropertyStates = (_v) => updateState('propertyStates', _v);
    const updateCompetitionStates = (_v) => updateState('competitionStates', _v);
    const updateRegistrationAcquisition = (_v) => updateState('registrationAcquisition', _v);
    const updatePropertyRemarks = (_v) => updateState('propertyRemarks', _v);

    const clearHandler = () => setData({});

    const groupValuesNormalizer = (_attributeName) => (_v, _i) => ({ ..._v, ...{ selected: (data[_attributeName] && data[_attributeName][_i] && data[_attributeName][_i].selected) || false } });
    const purposeValues = [
        { value: 'nearCommercialArea', text: t('nearCommercialArea-short') },
        { value: 'c1highRiseResidentialArea', text: t('c1highRiseResidentialArea-short') },
        { value: 'c1ResidentialArea', text: t('c1ResidentialArea-short') },
        { value: 'firstTypeResidentialArea', text: t('firstTypeResidentialArea-short') },
        { value: 'semiIndustrialArea', text: t('semiIndustrialArea-short') },
        { value: 'commercialArea', text: t('commercialArea-short') },
        { value: 'secondTypeResidentialArea', text: t('secondTypeResidentialArea-short') },
        { value: 'semiResidentialArea', text: t('semiResidentialArea-short') },
        { value: 'industrialArea', text: t('industrialArea-short') }
    ].map(groupValuesNormalizer('purposeValues'));
    const rentFlagValues = [
        { value: 'agency', text: t('agency') },
        { value: 'warranty', text: t('warranty') },
        { value: 'voluntaryManagement', text: t('voluntaryManagement') },
        { value: 'inUse', text: t('inUse') },
        { value: 'vacant', text: t('vacant') }
    ].map(groupValuesNormalizer('rentFlagValues'));
    const propertyStates = [
        { value: 'newConstruction', text: t('newConstruction') },
        { value: 'secondHand', text: t('secondHand') }
    ].map(groupValuesNormalizer('propertyStates'));
    const competitionStates = [
        { value: 'inCompetition', text: t('inCompetition') }
        //{ value: 'hasCompetitionHistory', text: t('hasCompetitionHistory') }
    ].map(groupValuesNormalizer('competitionStates'));

    return <div className={'callmode-form-container ' + _extraClasses}>
        <form>
            <Row className="form-group">
                <Col lg={6} md={6} sm={12} className="col-xxl-4 col-xl-6">
                    <Row>
                        <Col sm={7} className="col-7">
                            <div className="single-line">
                                <helpers.GenerateInput text={t('fullName')} value={data.propertyName} onChangeHandler={updateName} labelSize={3} extraClasses="name-container" />
                            </div>
                        </Col>
                        <Col sm={5} className="col-5">
                            <div className="single-line">
                                <helpers.GenerateCheckbox text={t('partialMatch')} value={data.partialMatch} onChangeHandler={updatePartialMatch} extraClasses="partialMatch-container" />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} md={6} sm={12} className="col-xxl-4 col-xl-5">
                    <Row>
                        <Col sm={4} className="col-xl-4 col-4">
                            <div className="single-line">
                                <helpers.GenerateInput text={t('roomNumber')} value={data.roomNumber} onChangeHandler={updateRoomNumber} labelSize={6} extraClasses="roomNumber-container" />
                            </div>
                        </Col>
                        <Col sm={8} className="col-xl-8 col-8">
                            <Row>
                                <Col sm={4} className="col-4">
                                    <div className="single-line">
                                        <helpers.GenerateCheckbox text={t('realResidence')} value={data.realResidence} onChangeHandler={updateRealResidence} extraClasses="realResidence-container" />
                                    </div>
                                </Col>
                                <Col sm={8} className="col-8">
                                    <div className="single-line">
                                        <helpers.GenerateCheckbox text={t('notRealResidence')} value={data.notRealResidence} onChangeHandler={updateNotRealResidence} extraClasses="notRealResidence-container" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} md={6} sm={12} className="col-xxl-4 col-xl-6">
                    <Row>
                        <Col sm={12} className="col-10">
                            <div className="single-line">
                                <helpers.GenerateInput text={t('streetAddress')} value={data.propertyStreetAddress} onChangeHandler={updateStreetAddress} labelSize={2} extraClasses="streetAddress-container" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={5} sm={12} className="col-xl-4">
                    <helpers.GenerateInputRange text={t('buildingAge')} type={'number'} range={data.buildingAge} onChangeHandler={updateBuildingAge} labelSize={2} addon={t('year')} extraClasses="buildingAge-container" />
                </Col>
                <Col >
                    <helpers.GenerateCheckbox text={t('buildingAgeNone')} value={data.buildingAgeNone} onChangeHandler={updateBuildingAgeNone} extraClasses="withCancellationPenalty-container" />
                </Col>
                <Col lg={7} sm={12} className="col-xl-8">
                    <helpers.GenerateCheckboxGroup text={t('purpose')} values={purposeValues} onChangeHandler={updatePurposeValues} labelSize={1} extraClasses="purposeValues-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col md={6} sm={12} className="col-xxl-5 col-xl-4">
                    <helpers.GenerateInput text={t('nearestStation')} value={data.nearestStation} onChangeHandler={updateNearestStation} labelSize={2} extraClasses="nearestStation-container" />
                </Col>
                <Col md={6} sm={12} className="col-xxl-3 col-xl-4">
                    <helpers.GenerateInputRange text={t('walkingDistanceToStation')} type={'number'} range={data.walkingDistanceToStation} onChangeHandler={updateWalkingDistanceToStation} labelSize={2} addon={t('minutes')} extraClasses="walkingDistanceToStation-container" />
                </Col>
                <Col md={6} sm={12} className="col-xxl-3 col-xl-4">
                    <helpers.GenerateInputRange text={t('floorSurfaceSize')} type={'number'} range={data.floorSurfaceSize} onChangeHandler={updateFloorSurfaceSize} labelSize={2} addon={<>m<span className="superscript">2</span></>} extraClasses="floorSurfaceSize-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col md={4} className="col-xl-3 col-7">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('rentalManagement')} value={data.rentalManagement} onChangeHandler={updateRentalManagement} labelSize={4} inputSize={7} extraClasses="rentalManagement-container" />
                    </div>
                </Col>
                <Col md={4} className="col-xl-5 col-5">
                    <div className="single-line">
                        <helpers.GenerateCheckbox text={t('withCancellationPenalty')} value={data.withCancellationPenalty} onChangeHandler={updateWithCancellationPenalty} extraClasses="withCancellationPenalty-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col md={4} className="col-xl-3 col-7">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('buildingManagement')} value={data.buildingManagement} onChangeHandler={updateBuildingManagement} labelSize={4} inputSize={7} extraClasses="buildingManagement-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col md={4} sm={12} className="col-xl-4 col-12">
                    <div className="single-line">
                        <helpers.GenerateInputRange text={t('rent')} type={'number'} range={data.rent} onChangeHandler={updateRent} labelSize={2} addon={t('en')} extraClasses="rent-container" />
                    </div>
                </Col>
                <Col md={8} sm={9} className="col-xl-8 col-11 offset-md-0 offset-sm-3 offset-1">
                    <div className="single-line">
                        <helpers.GenerateCheckboxGroup values={rentFlagValues} onChangeHandler={updateRentFlagValues} labelSize={1} extraClasses="rentFlagValues-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={6} md={7} className="col-xxl-4 col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInputRange text={t('managementCost-repairReserves')} range={data.managementCost} onChangeHandler={updateManagementCost} labelSize={4} inputSize={6} addon={t('en')} extraClasses="managementCost-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={6} md={7} className="col-xl-4 col-7">
                    <div className="single-line">
                        <helpers.GenerateInputRange text={t('purchase')} range={data.purchasePrice} onChangeHandler={updatePurchasePrice} labelSize={2} addon={t('10kYen')} extraClasses="purchasePrice-container" />
                    </div>
                </Col>
                <Col lg={6} md={5} className="col-xl-8 col-5">
                    <div className="single-line">
                        <helpers.GenerateCheckboxGroup values={propertyStates} onChangeHandler={updatePropertyStates} labelSize={4} extraClasses="propertyStates-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={6} md={6} sm={8} className="col-xxl-4 col-xl-5 col-9">
                    <div className="single-line">
                        <helpers.GenerateCheckboxGroup text={t('competition')} values={competitionStates} onChangeHandler={updateCompetitionStates} labelSize={3} inputSize={8} extraClasses="competitionStates-container" />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={11} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateDateRange text={t('registrationAcquisition')} dateRange={data.registrationAcquisition} onChangeHandler={updateRegistrationAcquisition} labelSize={2} showIcon={true} extraClasses="registrationAcquisition-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={9} md={9} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('propertyRemarks')} value={data.propertyRemarks} onChangeHandler={updatePropertyRemarks} labelSize={2} inputSize={9} extraClasses="propertyRemarks-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="form-submit-container">
                    <button type="button" className="btn btn-primary" onClick={_callBack && (() => { _callBack(data); })}>{t('search')}</button>
                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('clear')}</button>
                </Col>
            </Row>
        </form>
    </div>
}

PropertyForm.defaultProps = {
    extraClasses: ''
}
PropertyForm.whyDidYouRender = true;