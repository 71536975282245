import React from 'react'
import { Button } from 'semantic-ui-react'

const Paginator = ({ currentPage, onPageChange, range = 3, pageCount, noOfRecords }) => {
    const renderedPages = [...Array(range * 2 + 1).keys()]
        .map(i => currentPage - range + i)
        .filter(i => i > 0 && i <= pageCount)

    const showStart = currentPage > 1 && pageCount !== 1
    const showEnd = currentPage < pageCount
    return (
        <Button.Group compact className={'pagination'}>

            {showStart && (
                [
                    <Button key={'prev'} content={'<<'} onClick={() => onPageChange(currentPage - 1)} />,
                ]
            )}
            {renderedPages.map(page => (
                <Button
                    key={page}
                    onClick={() => onPageChange(page)}
                    content={page}
                    primary={currentPage === page}
                />
            ))}
            {showEnd && (
                [
                    <Button key={'next'} content={'>>'} onClick={() => onPageChange(currentPage + 1)} />
                ]
            )}
            {
                noOfRecords
            }
        </Button.Group>
    )
}

export default Paginator