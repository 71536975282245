import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function PropertyListForm({ callBack: _callBack, sidePopup: _sidePopup, delProperty: _delProperty, delRoom: _delRoom, addRoom: _addRoom, addDetailsUnknownProperty: _addDetailsUnknownProperty }) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const updatePropertyName = (_v) => updateState('propertyName', _v);
    const updateNearestStation = (_v) => updateState('nearestStation', _v);
    const updateStreetAddress = (_v) => updateState('streetAddress', _v);
    const updateSold = (_v) => updateState('sold', _v);

    const clearHandler = () => setData({});

    const buttonRender = () => {
        let buttons = <div>
            <div className="btn-group">
                <button type="button" className="btn btn-secondary" onClick={_sidePopup && (() => { _sidePopup('registerProperty'); })} >{t('registerProperty')}</button>
                <button type="button" className="btn btn-secondary" onClick={_sidePopup && (() => { _sidePopup('editProperty'); })}>{t('editProperty')}</button>
                <button type="button" className="btn btn-secondary" onClick={_delProperty && (() => { _delProperty(); })}>{t('deleteProperty')}</button>
                <button type="button" className="btn btn-secondary" onClick={_addDetailsUnknownProperty && (() => { _addDetailsUnknownProperty(); })}>{t('registerDetailsUnknownProperty')}</button>
            </div>
            <div className="btn-group">
                <button type="button" className="btn btn-secondary" onClick={_sidePopup && (() => { _sidePopup('registerRoom'); })}>{t('registerRoom')}</button>
                <button type="button" className="btn btn-secondary" onClick={_sidePopup && (() => { _sidePopup('editRoom'); })}>{t('editRoom')}</button>
                <button type="button" className="btn btn-secondary" onClick={_delRoom && (() => { _delRoom(); })}>{t('deleteRoom')}</button>
                <button type="button" className="btn btn-secondary" onClick={_addRoom && (() => { _addRoom(); })}>{t('roomSelection')}</button>
            </div>
        </div>;

        return <>
            <div className="button-container">
                {buttons}
            </div>
        </>;
    }

    return <div className={'maintenance-form-container '}>
        <form>
            <Row className="form-group">
                <Col lg={5} md={5} sm={5} className="col-xl-4 col-5">
                    <div className="single-line">
                        <helpers.GenerateInput value={data.propertyName} onChangeHandler={updatePropertyName} text={t('propertyName')} extraClasses="propertyName-container" />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={4} className="col-xl-3 col-4">
                    <helpers.GenerateInput value={data.nearestStation} onChangeHandler={updateNearestStation} text={t('nearestStation')} extraClasses="nearestStation-container" />
                </Col>
                <Col lg={3} md={3} sm={3} className="col-xl-2 col-3">
                    <helpers.GenerateCheckbox text={t('sold')} value={data.sold} onChangeHandler={updateSold} extraClasses="sold-container" />
                </Col>
            </Row>
            <Row className="form-group">
                <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('streetAddress')} value={data.streetAddress} onChangeHandler={updateStreetAddress} labelSize={4} extraClasses="streetAddress-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="form-submit-container">
                    <button type="button" className="btn btn-primary" onClick={_callBack && (() => { _callBack(data); })}>{t('search')}</button>
                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('clear')}</button>
                </Col>
            </Row>
        </form>
        <Row className="form-group">
            <Col className='maintenance-page' md={12}>
                <section className="section title-section">
                    <div className="button-row flex">
                        {buttonRender()}
                    </div>
                </section>
            </Col>
        </Row>
    </div>
}

PropertyListForm.defaultProps = {
    extraClasses: ''
}
PropertyListForm.whyDidYouRender = true;