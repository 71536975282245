import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function InterestRate({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <wf.InputField hideLabel={true}
        value={data.interestRate.value || data.interestRate.defaultValue}
        className="form-group"
        onChange={setData('interestRate')}
        validator={_validatorsHOC.doubleValidator}
        errorMessage={t('incorrectNumberFormatError')}
        addon="%" />
}
const InterestRatePopup = ExportDefaultBehaviour(InterestRate, {
    editableFields: ['interestRate'],
    size: 'sm'
});
InterestRatePopup.whyDidYouRender = true;
export default InterestRatePopup;