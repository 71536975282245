import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function Liquidation({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <wf.GroupField hideLabel={true}>
        <wf.InputField hideLabel={true}
            value={data.year.value || data.year.defaultValue}
            className="form-group"
            onChange={setData('year')}
            validator={_validatorsHOC.yearValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('year')} />
        <wf.InputField hideLabel={true}
            value={data.month.value || data.month.defaultValue}
            className="form-group"
            onChange={setData('month')}
            validator={_validatorsHOC.monthValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('month')} />
    </wf.GroupField>
}
const LiquidationPopup = ExportDefaultBehaviour(Liquidation, {
    editableFields: ['year', 'month'],
    size: 'sm'
});
LiquidationPopup.whyDidYouRender = true;
export default LiquidationPopup;