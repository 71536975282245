import PageTable from 'components/tables/pageTable';
import * as helpers from 'helpers/formHelpers';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import UserProfile from 'helpers/userProfile';
import { init, send } from 'emailjs-com';
//import { useForm } from 'react-hook-form'; //フォーム検証　後で使ッタバージョンに書き直す



export function Inquiry() {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [mail, setMail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const sendMail = () => {
        {/*const userID = process.env.REACT_APP_USER_ID;
        const serviceID = process.env.REACT_APP_SERVICE_ID;
        const templateID = process.env.REACT_APP_TEMPLATE_ID;
        */}
        const userID = "user_WbeKBFu7Tiy3qH6smLcA7";
        const serviceID = "service_931w4s7";
        const templateID = "template_ov7a9bk";
        if (
            userID !== undefined &&
            serviceID !== undefined &&
            templateID !== undefined
        ) {
            init(userID);

            const template_param = {
                name: name,
                company: company,
                email: mail,
                tel: tel,
                message: message,
            };
            send(serviceID, templateID, template_param).then(() => {
                window.alert('お問い合わせを送信致しました。');
                setName('');
                setCompany('');
                setMail('');
                setTel('');
                setMessage('');
            });
        }
        else {
            console.log("送信失敗。");
        }
    };
    const handleClick = (e) => {
        e.preventDefault();
        sendMail();
    };
    const handleCanceled = () => {
        setName('');
        setCompany('');
        setMail('');
        setMessage('');
    };
    const disableSend =
        name === '' || mail === '' || message === '';
    return (
        <article className="content inquiry-page">
            <div className="mainContent inquiry-form">
                <h2 className="pageTitle">お問い合わせ</h2>
                <div className="contentsBox">
               
                    <form>
                        {/*<Row>
                            <Col lg={5} md={5} sm={6} className="col-xxl-2 col-xl-5">
                            <div className="single-line">
                                    <helpers.GenerateInput text={t('contactName')} value={name} oonChange={(e) => setName(e.target.value)} extraClasses="name-container" />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={5} sm={6} className="col-xxl-2 col-xl-5">
                                <div className="single-line">
                                    <helpers.GenerateInput text={t('companyName')} value={company} oonChange={(e) => setName(e.target.value)} extraClasses="name-container" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={5} sm={6} className="col-xxl-2 col-xl-5">
                                <div className="single-line">
                                    <helpers.GenerateInput text={t('emailJp')} value={mail} oonChange={(e) => setMail(e.target.value)} extraClasses="mail-container" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={5} md={5} sm={6} className="col-xxl-2 col-xl-5">
                                <div className="single-line">
                                    <label htmlFor="mailContentForm" className="title-label flex flex-wrap">お問い合わせ内容：</label>
                                    <textarea type="text" id="mailContentForm" className="formInput flex flex-sh-6" rows="5" value={message} onChange={(e) => setMessage(e.target.value)} />
                                </div>
                            </Col>
                        </Row>
                        */}
                        <div>
                            <label htmlFor="nameForm">ご氏名：</label>
                          </div>
                          <input type="text" id="nameForm" className="formInput" value={name} onChange={(e) => setName(e.target.value)}
                          />
                          <div>
                            <label htmlFor="companyNameForm">会社名：</label>
                          </div>
                          <input type="text" id="companyNameForm" className="formInput" required value={company} onChange={(e) => setCompany(e.target.value)}
                          />
                          <div>
                            <label htmlFor="mailForm">メールアドレス：</label>
                        </div>
                        <input type="email" id="mailForm" className="formInput" value={mail} onChange={(e) => setMail(e.target.value)} />
                        <div>
                            <label htmlFor="mailForm">電話番号：</label>
                        </div>
                        <input type="tel" id="telForm" className="formInput" value={tel} onChange={(e) => setTel(e.target.value)} />
                        <div>
                            <label htmlFor="mailContentForm">お問い合わせ内容：</label>
                        </div>
                        <textarea type="text" id="mailContentForm" className="formInput" rows="5" value={message} onChange={(e) => setMessage(e.target.value)} />
                    
                        <div className="btns">
                            <div>
                                <button variant="contained" color="default" onClick={handleClick} disabled={disableSend}>
                                    <strong>お問い合わせを送信する</strong>
                                </button>
                            </div>
                            <div>
                                <button variant="contained" color="default" onClick={handleCanceled}>
                                    <strong>キャンセル</strong>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </article>
    );
}
export default Inquiry;
