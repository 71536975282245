import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import { SimpleToggle } from 'components/inputs/toggles';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Rent({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    const rentTypes = ["unspecified", "agency", "warranty", "voluntaryManagement", "inUse", "vacant"].reduce(_checkboxGroupHOC.checkboxGroupReducer('rentTypes'), {});
    const cancellationTypes = ["unspecified", "cancellation", "succession"].reduce(_checkboxGroupHOC.checkboxGroupReducer('cancellationTypes'), {});
    const tenantGenders = ["unspecified", "male", "female"].reduce(_checkboxGroupHOC.checkboxGroupReducer('tenantGenders'), {});
    const tenantCategory = ["unspecified", "workingAdult", "student", "corporation"].reduce(_checkboxGroupHOC.checkboxGroupReducer('tenantCategory'), {});
    const renovationCategory = ["unspecified", "inProcess", "notInProcess"].reduce(_checkboxGroupHOC.checkboxGroupReducer('renovationCategory'), {});
    const keyCategory = ["unspecified", "owner", "managementCompany"].reduce(_checkboxGroupHOC.checkboxGroupReducer('keyCategory'), {});
    const relinquishCategory = ["unspecified", "consultation", "immediate", "dueDateDesignation", "plan"].reduce(_checkboxGroupHOC.checkboxGroupReducer('relinquishCategory'), {});

    const setVisible = (_field, _test, _hideClass = 'dissapear') => {
        return _test(data[_field].value || data[_field].defaultValue) ? '' : _hideClass;
    }

    return <>
        <wf.CheckboxGroupField values={rentTypes} onChange={(_v) => setData('rentTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="rentTypes" />
        <Row>
            <Col className={`col-xs-3 ${setVisible('rentTypes', (_v) => !_v || Object.keys(_v).every(_k => (_k !== 'inUse' || !_v[_k]) && (_k !== 'vacant' || !_v[_k])), 'hidden')}`}>
                <wf.InputField label={t('rent')} extraClasses="single-line"
                    value={data.rent.value || data.rent.defaultValue}
                    onChange={setData('rent')}
                    labelSizeOverride={4}
                    validator={_validatorsHOC.optionalDayHours}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('en')} />
            </Col>
            <Col className={`col-xs-4 ${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'vacant' && _v[_k]), 'hidden')}`}>
                <wf.InputField label={t('advertisedRent')} extraClasses="single-line"
                    value={data.advertisedRent.value || data.advertisedRent.defaultValue}
                    onChange={setData('advertisedRent')}
                    labelSizeOverride={4}
                    validator={_validatorsHOC.optionalDayHours}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('en')} />
            </Col>
            <Col className={`col-xs-4 ${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'vacant' && _v[_k]), 'hidden')}`}>
                <wf.InputField label={t('lastRent')} extraClasses="single-line"
                    value={data.lastRent.value || data.lastRent.defaultValue}
                    onChange={setData('lastRent')}
                    labelSizeOverride={4}
                    validator={_validatorsHOC.optionalDayHours}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('en')} />
            </Col>
            <Col className={`col-xs-3 ${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'warranty' && _v[_k]))}`}>
                <wf.InputField label={t('priorRent')} extraClasses="single-line"
                    value={data.priorRent.value || data.priorRent.defaultValue}
                    onChange={setData('priorRent')}
                    labelSizeOverride={4}
                    validator={_validatorsHOC.optionalDayHours}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('en')} />
            </Col>
            <Col className={`col-xs-4 ${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'warranty' && _v[_k]), 'hidden')}`}>
                <wf.InputField label={t('estimatedRent')} extraClasses="single-line"
                    value={data.estimatedRent.value || data.estimatedRent.defaultValue}
                    onChange={setData('estimatedRent')}
                    labelSizeOverride={4}
                    validator={_validatorsHOC.optionalDayHours}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('en')} />
            </Col>
            <SimpleToggle extraClasses={`col-xs-2 floating-row-container ${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'warranty' && _v[_k]), 'hidden')}`} label={t('firstVacantRoom')} value={data.firstVacantRoom.value || data.firstVacantRoom.defaultValue || false} onChange={setData('firstVacantRoom')} />
        </Row>
        <wf.CheckboxGroupField extraClasses={`${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'warranty' && _v[_k]))}`} label={t('cancellation')} values={cancellationTypes} onChange={(_v) => setData('cancellationTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="cancellationTypes" />
        <Row className={`${setVisible('rentTypes', (_v) => _v && Object.keys(_v).every(_k => (_k !== 'unspecified' || !_v[_k]) && (_k !== 'inUse' || !_v[_k])))}`}>
            <Col className="col-xs-7">
                <wf.DatePicker label={t('period')} labelSizeOverride={5} value={data.period.value || data.period.defaultValue} onChange={setData('period')} />
            </Col>
            <Col className={`col-xs-1 flex flex-column justify-center floating-row-container no-padding ${setVisible('rentTypes', (_v) => _v && Object.keys(_v).every(_k => _k !== 'vacant' || !_v[_k]), 'hidden')}`}>{t('until')}</Col>
            <Col className={`col-xs-1 flex flex-column justify-center floating-row-container no-padding ${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'vacant' && _v[_k]), 'hidden')}`}>{t('startingFrom')}</Col>
            <SimpleToggle extraClasses="col-xs-2 floating-row-container" label={t('withRentalContract')} value={data.withRentalContract.value || data.withRentalContract.defaultValue || false} onChange={setData('withRentalContract')} />
            <SimpleToggle extraClasses="col-xs-2 floating-row-container" label={t('withNonpayment')} value={data.withNonpayment.value || data.withNonpayment.defaultValue || false} onChange={setData('withNonpayment')} />
        </Row>
        <wf.InputField extraClasses={`${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'inUse' && _v[_k]))}`}
            label={t('statusOfUtilization')}
            value={data.statusOfUtilization.value || data.statusOfUtilization.defaultValue}
            onChange={setData('statusOfUtilization')} />
        <Row className={`${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => (_k === 'agency' && _v[_k]) || (_k === 'warranty' && _v[_k]) || (_k === 'voluntaryManagement' && _v[_k])))}`}>
            <Col md={12}>
                <wf.CheckboxGroupField label={t('tenant')} values={tenantGenders} onChange={(_v) => setData('tenantGenders')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="tenantGenders" />
                <wf.CheckboxGroupField values={tenantCategory} onChange={(_v) => setData('tenantCategory')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="tenantCategory" />
                <wf.InputField label={t('age')}
                    value={data.age.value || data.age.defaultValue}
                    validator={(_v) => _validatorsHOC.optionalDayHours(_v)}
                    errorMessage={t('incorrectNumberFormatError')}
                    onChange={setData('age')}
                    addon={t('yearsOld')} />
            </Col>
        </Row>
        <Row className={`${setVisible('rentTypes', (_v) => _v && Object.keys(_v).some(_k => _k === 'vacant' && _v[_k]))}`}>
            <Col md={12}>
                <wf.CheckboxGroupField label={t('renovation')} values={renovationCategory} onChange={(_v) => setData('renovationCategory')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="renovationCategory" />
                <wf.CheckboxGroupField label={t('key')} values={keyCategory} onChange={(_v) => setData('keyCategory')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="keyCategory" />
            </Col>
        </Row>
        <wf.CheckboxGroupField label={t('relinquish')} values={relinquishCategory} onChange={(_v) => setData('relinquishCategory')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="relinquishCategory" />
    </>;
}

const RentPopup = ExportDefaultBehaviour(Rent, {
    editableFields: ['rentTypes', 'rent', 'advertisedRent', 'lastRent', 'priorRent',
        'estimatedRent', 'firstVacantRoom', 'cancellationTypes',
        'period', 'withRentalContract', 'withNonpayment',
        'statusOfUtilization', 'tenantGenders', 'tenantCategory',
        'age', 'renovationCategory', 'keyCategory', 'relinquishCategory'],
    extraClasses: 'push-up',
    size: 'md'
});
RentPopup.whyDidYouRender = true;
export default RentPopup;