const types = {
    requestPdf: 'EX_REQUEST_PDF',
    requestCSV: 'EX_REQUEST_CSV',
    requestBukkenCSV: 'EX_REQUEST_BUKKEN_CSV',
    requestDMCSV: 'EX_REQUEST_DMCSV',
    requestPropertyRoomsCSV: 'EX_REQUEST_PROPERTY_ROOMS_CSV',
    requestPromotionalActivityPdf: 'EX_REQUEST_PROMOTIONAL_ACTIVITY_PDF',
    requestPromotionalActivityExcel: 'EX_REQUEST_PROMOTIONAL_ACTIVITY_EXCEL',
    sendMailHansoku: 'EX_SEND_MAIL_HANSOKU',
    addPDF: 'EX_ADD_PDF',
    addCSV: 'EX_ADD_CSV'
};


/** Actions **/
export const addPDF = bytes => ({
    type: types.addPDF,
    bytes
});

export const addCSV = bytes => ({
    type: types.addCSV,
    bytes
});

/** Async Actions **/
export const requestPdf = (data, successCallback, failureCallback) => ({
    type: types.requestPdf,
    data,
    successCallback,
    failureCallback
});

export const requestCSV = (data, successCallback, failureCallback) => ({
    type: types.requestCSV,
    data,
    successCallback,
    failureCallback
});

export const requestBukkenCSV = (data, successCallback, failureCallback) => ({
    type: types.requestBukkenCSV,
    data,
    successCallback,
    failureCallback
});

export const requestDMCSV = (data, successCallback, failureCallback) => ({
    type: types.requestDMCSV,
    data,
    successCallback,
    failureCallback
});

export const requestPropertyRoomsCSV = (propertyRooms) => ({
    type: types.requestPropertyRoomsCSV,
    propertyRooms
});

export const requestPromotionalActivityPdf = (exportData) => ({
    type: types.requestPromotionalActivityPdf,
    exportData
});

export const requestPromotionalActivityExcel = (exportData, successCallback, failureCallback) => ({
    type: types.requestPromotionalActivityExcel,
    exportData,
    successCallback,
    failureCallback
});

export const sendMailHansoku = (mailHansoku, successCallback, failureCallback) => ({
    type: types.sendMailHansoku,
    mailHansoku,
    successCallback,
    failureCallback
});

/** Reducer **/
const reducer = (_state = { bytes: [] }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addPDF:
            newState.pdf = _action.bytes;
            break;
        case types.addCSV:
            newState.csv = _action.bytes;
            break;
    }
    return newState;
}

export default reducer;