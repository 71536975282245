import React from 'react';

export function PropertyRoomPopup({ onCancelCallback: _onCancelCallback, onRequestUnlockPropertyListData: _onRequestUnlockPropertyListData, Id:_Id}) {

    return <div className="simple-message-modal modal fade show">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">解除の確認</h4>
                </div>
                <div className="simple-message-body modal-body modal-tab-container flex justify-center">
                    <>選択中の所有物件を解除しますか？</>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => _onRequestUnlockPropertyListData(_Id)}>はい</button>
                    <button type="button" className="btn btn-secondary" onClick={_onCancelCallback}>いいえ</button>
                </div>
            </div>
        </div>
    </div>
}