import React from 'react';

export function SimpleMessage({ title: _title, titleIcon: _titleIcon, message: _message, okText: _okText, cancelText: _cancelText, onOkCallback: _onOkCallback, onCancelCallback: _onCancelCallback}) {

    return <div className="simple-message-modal modal fade show">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{_titleIcon && <i class={"fa " + _titleIcon } />}{_title}</h4>
                    <button type="button" className="close" onClick={_onCancelCallback}>
                        <span>&times;</span>
                        <span className="sr-only">{_title}</span>
                    </button>
                </div>
                <div className="simple-message-body modal-body modal-tab-container flex justify-center">
                    {_message}
                </div>
                <div className="modal-footer">
                    {_okText && <button type="button" className="btn btn-primary" onClick={_onOkCallback || _onCancelCallback}>{_okText}</button>}
                    {_cancelText && <button type="button" className="btn btn-secondary" onClick={_onCancelCallback} >{_cancelText}</button>}
                </div>
            </div>
        </div>
    </div>;
}