import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';

export function TeamsEdit({ title: _title, teams: _teams, onTeamEditCallback: _onTeamEditCallback, onTeamDeleteCallback: _onTeamDeleteCallback, onCancelCallback: _onCancelCallback, editPopup: _editPopup }) {

    const [teams, setTeams] = useState(_teams);
    useEffect(() => setTeams(_teams), [_teams]);

    const onEditCallBack = (_id) => {
        _onTeamEditCallback(_id);
    };

    const onDeleteCallBack = (_id) => {
        _onTeamDeleteCallback(_id);
    };

    const generateTeamCell = (_team) => {
        const affectEditingTeam = () => onEditCallBack(_team.id);
        const affectDeleteTeam = () => onDeleteCallBack(_team.id);
        return <tr key={_team.id}>
            <td className="value-container flex flex-nowrap">
                <div className="value-name flex-grow-1">{_team.name}</div>
                <div className="actions-container flex flex-nowrap">
                    <div className="action-button flex justify-center flex-column" onClick={affectEditingTeam}><i className="fa fa-pencil" aria-hidden="true"></i></div>
                    <div className="action-button flex justify-center flex-column" onClick={affectDeleteTeam}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                </div>
            </td>
        </tr>;
    }

    function editPopupCallback() {
        _editPopup();
    }

    return <div className="card">
        <div className="card-block">
            <div className="title-block">
                <Row md={12} className="align-title">
                    <div className="button-container button-filler flex-grow-1 pd-left">
                        <div className="btn-group">
                            <h3 className="title">{_title}</h3>
                        </div>
                    </div>
                    <div className="button-container">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={editPopupCallback}><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </Row>
            </div>
            <section className="section title-section">
                <div className="editable-panel table-responsive">
                    <table className="editable-table table table-striped table-bordered table-hover">
                        <tbody>
                            {teams.map(_t => generateTeamCell(_t))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>;
}