import React from 'react';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function SellingCompany({ useStateHOC: _useStateHOC }) {
    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField hideLabel={true} value={data.sellingCompany.value || data.sellingCompany.defaultValue}
            onChange={setData('sellingCompany')}/>
    </>;
}

const SellingCompanyPopup = ExportDefaultBehaviour(SellingCompany, {
    editableFields: ['sellingCompany'],
    size: 'sm'
});
SellingCompanyPopup.whyDidYouRender = true;
export default SellingCompanyPopup;