import LoanModule from 'components/callmode/modulesContainers/loanModule';
import PropertyModule from 'components/callmode/modulesContainers/propertyModule';
import UserModule from 'components/callmode/modulesContainers/userModule';
import pdfViewer1 from 'data/org0.jpg';
import pdfViewer2 from 'data/org1.jpg';
import pdfViewer3 from 'data/org2.jpg';
import pdfViewer4 from 'data/org3.jpg';
import UserProfile from 'helpers/userProfile';
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import 'react-reflex/styles.css';
import { BottomRightHikitsugiZumiMainForm } from './bottomRightHikitsugiZumiMainForm';
import { HistoryPannel } from './historyPannel';

class PromotionalActivitiesData extends Component {
    constructor(_props) {
        super(_props);

        this.state = {
            activeContainer: undefined,
            focusedClient: 0
        };

        this.stateUpdater = (_data) => {
            const updatedClient = { ...this.props.data, ..._data };
            this.props.onRecordUpdate(updatedClient);
        };

        const images = (imgIndex) => {
            switch (imgIndex - 1) {
                case 0: return pdfViewer1; break;
                case 1: return pdfViewer2; break;
                case 2: return pdfViewer3; break;
                case 3: return pdfViewer4; break;
                default: return pdfViewer1; break;
            }
        }

        const generateModuleProps = ((_fieldName) => ({
            onActive: (_component) => this.setState({ activeContainer: _component }),
            onIdle: () => this.setState({ activeContainer: undefined }),
            onChange: this.stateUpdater.bind(this),
            stateFilter: (_state) => _state[_fieldName] || {},
            ...this.state[_fieldName]
        })).bind(this);

        this.moduleProps = ['userModuleData', 'propertyModuleData', 'loanModuleData'].reduce((_a, _b) => ({ ..._a, [_b]: generateModuleProps(_b) }), {});

        this.focusClient = (_c, _i) => this.setState({ focusedClient: _i });

        this.generateForm = (clientData) => {
            let form;
            if (this.props.processState === 0 || this.props.processState === 1 || this.props.processState === 2) {
                form = <>
                    {this.props.renderState === 'diagram' &&
                        <>
                            <ReflexElement flex={0.6}>
                                <ReflexContainer orientation="horizontal">
                                    <ReflexElement>
                                        <img src={images(this.props.imgIndex)} className="pdf-viewer"></img>
                                        <div className="pdf-viewer-buttons">
                                            <button type="button" className="btn btn-secondary" onClick={this.props.exportExcel.bind(null, "")}>{this.props.t('excelExport')}</button>
                                            <button type="button" className="btn btn-secondary" onClick={this.props.sendMailCallback.bind(null, "noMail")}>{this.props.t('pdfExport')}</button>
                                        </div>
                                    </ReflexElement>
                                </ReflexContainer>
                            </ReflexElement>
                        </>
                    }
                    {
                        this.props.renderState === 'information' &&
                        <>
                            <ReflexElement flex={0.3}>
                                <ReflexContainer orientation="horizontal">
                                    <ReflexElement>
                                        <UserModule {...this.moduleProps.userModuleData} imageImportType={'partnersPage'} data={(clientData || {})} imageRecords={(clientData || {}).imageRecords} />
                                    </ReflexElement>
                                </ReflexContainer>
                            </ReflexElement>
                            <ReflexSplitter />
                            <ReflexElement flex={0.3}>
                                <ReflexContainer orientation="horizontal">
                                    <ReflexElement flex={0.6}>
                                        <PropertyModule {...this.moduleProps.propertyModuleData} imageImportType={'partnersPage'} imageRecords={(clientData || {}).imageRecords} data={(clientData || {})} />
                                    </ReflexElement>
                                    <ReflexSplitter />
                                    <ReflexElement flex={0.4}>
                                        <LoanModule {...this.moduleProps.loanModuleData} imageImportType={'partnersPage'} imageRecords={(clientData || {}).imageRecords} data={(clientData || {})} />
                                    </ReflexElement>
                                </ReflexContainer>
                            </ReflexElement>
                        </>
                    }
                    {
                        this.props.processState === 2 &&
                        <>
                            <ReflexSplitter />
                            <BottomRightHikitsugiZumiMainForm processState={this.props.processState} />
                        </>
                    }
                    {
                        UserProfile.getPost() !== '0' && UserProfile.getPost() !== '1' && this.props.processState !== 2 &&
                        <>
                            <ReflexSplitter />
                            <ReflexElement flex={0.4}>
                                <HistoryPannel mailTemplate={this.props.templateData} gyoushaData={this.props.hansokuGyoushaData} rirekiData={this.props.hansokuRirekiDataData} onTemplateChange={this.props.templateUpdateCallback} onGyoushaButtonChange={this.props.hansokuGyoushaButtonChangeCallback} onMailButtonClick={this.props.sendMailCallback} onRirekiButtonChange={this.props.hansokuRirekiButtonChangeCallback} />
                            </ReflexElement>
                        </>
                    }
                </>
            }
            else {
                form = <>
                    {this.props.renderState === 'diagram' &&
                        <>
                            <ReflexElement flex={0.4}>
                                <ReflexContainer orientation="horizontal">
                                    <ReflexElement>
                                        <img src={images(this.props.imgIndex)} className="pdf-viewer"></img>
                                        <div className="pdf-viewer-buttons">
                                            <button type="button" className="btn btn-secondary" onClick={this.props.exportExcel.bind(null, "")}>{this.props.t('excelExport')}</button>
                                            <button type="button" className="btn btn-secondary" onClick={this.props.sendMailCallback.bind(null, "noMail")}>{this.props.t('pdfExport')}</button>
                                        </div>
                                    </ReflexElement>
                                </ReflexContainer>
                            </ReflexElement>
                        </>
                    }
                    {this.props.renderState === 'information' &&
                        <>
                            <ReflexElement flex={0.2}>
                                <ReflexContainer orientation="horizontal">
                                    <ReflexElement>
                                    <UserModule {...this.moduleProps.userModuleData} data={(clientData || {})} imageImportType={'partnersPage'} imageRecords={(clientData || {}).imageRecords} />
                                    </ReflexElement>
                                </ReflexContainer>
                            </ReflexElement>
                            <ReflexSplitter />
                            <ReflexElement flex={0.2}>
                                <ReflexContainer orientation="horizontal">
                                    <ReflexElement flex={0.6}>
                                    <PropertyModule {...this.moduleProps.propertyModuleData} imageImportType={'partnersPage'} imageRecords={(clientData || {}).imageRecords} data={(clientData || {})} />
                                    </ReflexElement>
                                    <ReflexSplitter />
                                    <ReflexElement flex={0.4}>
                                    <LoanModule {...this.moduleProps.loanModuleData} imageImportType={'partnersPage'} imageRecords={(clientData || {}).imageRecords} data={(clientData || {})} />
                                    </ReflexElement>
                                </ReflexContainer>
                            </ReflexElement>
                        </>
                    }
                    <ReflexSplitter />
                    <BottomRightHikitsugiZumiMainForm processState={this.props.processState} />
                </>
            };
            return form;
        };
    }

    render() {
        const clientData = this.props.data ? this.props.data : undefined;
        return (<div className="callmode-data-container">
            <div className="data-bottom-wrapper relative" style={{ height: 645 + 'px' }}>
                <ReflexContainer orientation="vertical">
                    {this.generateForm((clientData || {}))}
                </ReflexContainer>
                {this.state.activeContainer && this.state.activeContainer((clientData || {}))}
            </div>
        </div>);
    }
}

PromotionalActivitiesData.defaultProps = {
    extraClasses: ''
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(withTranslation()(PromotionalActivitiesData));