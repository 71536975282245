import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function DebtConsolidation({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const debtConsolidationTypes = ['offmarketSale', 'voluntaryBankruptcy', 'civilRehabilitationLaw'].reduce(_checkboxGroupHOC.checkboxGroupReducer('debtConsolidationTypes'), {});

    return <>
        <wf.CheckboxGroupField hideLabel={true} values={debtConsolidationTypes} onChange={(_v) => setData('debtConsolidationTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))}/>
        <wf.InputField
            value={data.debtConsolidationNote.value || data.debtConsolidationNote.defaultValue}
            hideLabel={true}
            className="form-group"
            onChange={setData('debtConsolidationNote')} />
    </>
}
export const DebtConsolidationPopup = ExportDefaultBehaviour(DebtConsolidation, {
    editableFields: ['debtConsolidationTypes', 'debtConsolidationNote']
});
DebtConsolidationPopup.whyDidYouRender = true;