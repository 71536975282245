import auth0 from 'auth0-js';

function AuthService() {
    const listeners = {
        logout: [],
        accessTokenChanged: []
    };
    let authObj = null, authConfig = null, accessToken = null;

    Object.defineProperty(this, 'authConfig', {
        get() {
            return authConfig;
        },
        set(_v) {
            if (_v) {
                authConfig = { ..._v };
                const auth0Config = { ..._v.authServer, ...{ redirectUri: window.location.origin + _v.authServer.redirectUri } };
                authObj = new auth0.WebAuth(auth0Config);
            }
        },
        enumerable: true,
        configurable: true
    });

    Object.defineProperty(this, 'accessToken', {
        get() {
            return accessToken;
        },
        enumerable: true,
        configurable: true
    });

    Object.defineProperty(this, 'isAuthenticated', {
        get() {
            const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
            return new Date().getTime() < expiresAt;
        },
        enumerable: true,
        configurable: true
    });

    this.Login = () => {
        authObj.authorize({ language: authConfig.defaultLanguage });
    }

    this.Logout = (_redirectUrl) => {

        triggerListeners('logout');

        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('previous_path');
        localStorage.removeItem('saved_at');
        localStorage.removeItem('user_details');

        authObj.logout({
            returnTo: _redirectUrl || authConfig.logoutUrl
        });
    }

    this.HandleAuthentication = (_history, _sucessListener) => {
        authObj.parseHash((_err, _authResult) => {
            if (_authResult && _authResult.accessToken && _authResult.idToken) {
                setSession(_authResult);
                if (_sucessListener) _sucessListener(_authResult.accessToken);
                window.location.href = localStorage.getItem('previous_path') ? localStorage.getItem('previous_path') : "/";
            } else if (_err) {
                console.log(_err);
            } else {
                localStorage.setItem('previous_path', _history.location.pathname + _history.location.search);
                this.Login();
            }
        });
    }

    this.RenewSession = (_sucessListener, _errorListener, _force) => {
        const lastSaveTime = localStorage.getItem('saved_at');
        if (!_force && lastSaveTime && Date.now() - JSON.parse(lastSaveTime) < authConfig.renewDelay) return;

        authObj.checkSession({
            scope: authConfig.authServer.scope
        }, (_err, _authResult) => {
            if (_authResult && _authResult.accessToken && _authResult.idToken) {
                accessToken = _authResult.accessToken;
                triggerListeners('accessTokenChanged', _authResult.accessToken);
                setSession(_authResult);
                if (_sucessListener) _sucessListener(_authResult.accessToken);
            } else if (_err && _errorListener) {
                _errorListener(_err);
            }
        });
    }

    this.AddLogoutListener = (_listener) => {
        listeners.logout.push(_listener);
    };

    this.RemoveLogoutListener = (_listener) => {
        const foundIndex = listeners.logout.findIndex(_f => _f === _listener);
        if (foundIndex >= 0) listeners.logout.splice(foundIndex, 1);
    };

    this.AddAccessTokenChangedListener = (_listener) => {
        listeners.accessTokenChanged.push(_listener);
    };

    this.RemoveAccessTokenChangedListener = (_listener) => {
        const foundIndex = listeners.accessTokenChanged.findIndex(_f => _f === _listener);
        if (foundIndex >= 0) listeners.accessTokenChanged.splice(foundIndex, 1);
    };

    const triggerListeners = function (_listener) {
        const args = Array.prototype.slice.call(arguments, 1);
        listeners[_listener].forEach(_l => _l.apply(null, args));
    }

    const setSession = (_authResult) => {
        let expiresAt = JSON.stringify((_authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', _authResult.accessToken);
        localStorage.setItem('id_token', _authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('saved_at', JSON.stringify(new Date().getTime()));
        localStorage.setItem('user_details', JSON.stringify(_authResult.idTokenPayload));
    }

    const sessionRelayHandler = (_e) => {
        if (_e.key === 'access_token') {
            if (_e.newValue == null) {
                window.removeEventListener('storage', sessionRelayHandler);
                this.Logout();
            } else {
                accessToken = _e.newValue;
                triggerListeners('accessTokenChanged', _e.newValue);
            }
        }
    };

    if (this.isAuthenticated) {
        accessToken = localStorage.getItem('access_token');
        triggerListeners('accessTokenChanged', accessToken);

        window.addEventListener('storage', sessionRelayHandler);
    }
}

export default new AuthService();