import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function AccountSettlement({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField hideLabel={true} value={data.accountSettlement.value || data.accountSettlement.defaultValue}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('accountSettlement')}
            addon={t('10kYen')} />
    </>;
}

const AccountSettlementPopup = ExportDefaultBehaviour(AccountSettlement, {
    editableFields: ['accountSettlement'],
    size: 'sm'
});
AccountSettlementPopup.whyDidYouRender = true;
export default AccountSettlementPopup;