import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function MediationAmount({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC, checkboxGroupHOC: _checkboxGroupHOC}) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    const mediationIntermediaryTypes = ["unspecified", "general", "fullTimeService", "exclusive"].reduce(_checkboxGroupHOC.checkboxGroupReducer('mediationIntermediaryTypes'), {});
    return <>
        <wf.CheckboxGroupField hideLabel={true} values={mediationIntermediaryTypes} onChange={(_v) => setData('mediationIntermediaryTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName={'mediationIntermediaryTypes'}/>
        <wf.InputField hideLabel={true} value={data.mediationAmount.value || data.mediationAmount.defaultValue}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('mediationAmount')}
            addon={t('10kYen')} />
    </>;
}

const MediationAmountPopup = ExportDefaultBehaviour(MediationAmount, {
    editableFields: ['mediationIntermediaryTypes', 'mediationAmount']
});
MediationAmountPopup.whyDidYouRender = true;
export default MediationAmountPopup;