import RowComponent from "./RowComponent";
import { formatDateTimeShort } from 'helpers/formatHelpers';
import React, { useState, useEffect } from "react";
import { SimpleTextInput, InputRange as BaseInputRange, DateSelector } from 'components/inputs/textInput';
import { SimpleSelector } from 'components/inputs/selector.js';
import { log } from "util";
import CallPageInput from "./callPageInput";

export default function TableComponent(props) {
    //propsのコピーを作る
    let propsCopy = Object.assign([], props.propsData.data);
    //現在選択している項目の番号を示す
    const [FlagNumber, setFlagNumber] = useState(0);
    //全件表示と過去三ヶ月表示を分けるためのSTATE
    const [ThreeMonthsFlag, SetThreeMonthsFlag] = useState(false);

    const [ChangeInputValues, SetChangeInputValues] = useState();

    //表示を行うかどうかを示す
    const [Visible, SetVisible] = useState();
    //コメントのみを表示するかを決める
    const [CommentOnly, SetCommentOnly] = useState(false);
    //チェック、ステータスの情報を保存する
    //const [inputValues, setInputValues] = useState(RoopSet());

    const [deleteNumber, setDeleteNumber] = useState(-1);

    const [newInputText, SetNewInputText] = useState("");

    const [newInputflag, SetNewInputTextFlag] = useState("");

    //チェックコンボボックスのItem管理
    const checkControl = {
        values: ['absence', 'ownerAbsent', 'ownerCommunications', 'interrupted', 'receptionist', 'anotherPerson', '03' + 'rejected', 'lineBusy', 'separateResidence', 'gkg', 'sovereigntyG', 'inconvenienceMode', 'dm', 'visitAbsent', 'visitOwnerAbsent', 'visitSovereignty', 'visitReception', 'visitGkg', 'visitSovereigntyG', 'visitBespoke', 'moving', 'unknown', 'unknownRoom', 'ecOpening'].map(_v => ({ value: _v, text: props.propsData.t(_v) })),
        handler: (_v) => onSetCheckValue(_v)
    }
    //ステータスコンボボックスのItem管理
    const statusControl = {
        values: ['normal', 'declined', 'soldOut', 'currentlyUnavailable', 'expected', 'intermediary', 'ongoingContracts', 'settlement', 'remediation', 'visitCommon', 'visitDeclined', 'visitIpRefused', 'visitAllSoldOut', 'visitCurrentlyUnavailable', 'visitExpected', 'visitMediation', 'visitMediationReceived', 'visitInheritance', 'visitSettlement', 'ecNormal', 'ecInterrupted', 'ecWithheldRefusal', 'ecLineBusy', 'ecCellPhoneNormal', 'ecCellPhoneInterrupted', 'ecCellPhoneWithheldRefusal', 'ecCellPhoneBusy', 'ecSampledDispatchedSchedule', 'visitScheduled'].map(_v => ({ value: _v, text: props.propsData.t(_v) })),
        handler: (_v) => onSetStatusValue(_v)
    }


    //ボタンをクリックしたら入力状態に切替・戻すを行うための関数
    function onClickHandler(id, _itemMemo) {

        const onSetNewInputText = () => SetNewInputText(_itemMemo);
        onSetNewInputText();

        const onSetNewInputTextFlag = () => SetNewInputTextFlag(false);
        onSetNewInputTextFlag();

        const onSetFlagNumber = () => setFlagNumber(id);
        onSetFlagNumber();


        const onSetChangeInputValue = () => SetChangeInputValues({ id: id, value: true });
        onSetChangeInputValue();

    }

    //ボタンをクリックしたら入力状態に切替・戻すを行うための関数
    function onClickCancelHandler(id, _itemMemo) {

        propsCopy[Number(id)].memo = newInputText;

        const onSetNewInputTextFlag = () => SetNewInputTextFlag(true);
        onSetNewInputTextFlag();

        const onSetFlagNumber = () => setFlagNumber("-0");
        onSetFlagNumber();


        const onSetChangeInputValue = () => SetChangeInputValues({ id: id, value: true });
        onSetChangeInputValue();

    }

    //チェックの値の変更を行うための関数
    const onSetCheckValue = (_v) => {
        propsCopy[Number(FlagNumber)].check = props.propsData.t(_v);
    }
    //ステータスの値を変更するための関数
    const onSetStatusValue = (_v) => {
        propsCopy[Number(FlagNumber)].status = props.propsData.t(_v);
    }

    //もともとのデータに値を反映させるための関数
    const onClickCheckMark = (e) => {
        setFlagNumber("");

        props.propsData.onRequestUpdateHistoryData(propsCopy[Number(e.target.id)]);
    }
    //データを削除するための関数（元データから削除）
    const onClickDelButton = (e, actionId) => {

        props.propsData.onRequestDeleteHistoryData(actionId);
    }
    //全画面処理を行うための関数
    const onClickDisplayAllButton = () => {
        propsCopy = Object.assign([], props.propsData.data);
        SetThreeMonthsFlag(false);
    }
    //三ヶ月表示を行うための関数
    const onClickpastThreeMonthsButton = () => {


        function VisibleSet() {
            const Obj = [];
            for (let i = 0; i < props.propsData.data.length; i++) {
                const x = {
                    id: i,
                    isVisible: false
                }
                Obj.push(x);
            }
            return Obj;
        }

        let Vi = VisibleSet();

        const dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth() + 1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;



        const CopyObj = Object.assign([], props.propsData.data);
        //const visiblecopy = Object.assign([], Visible);

        function ThreeMonthsFunction(element) {

            let CreationDate = formatDateTimeShort(element.creationDate);
            CreationDate = CreationDate.substring(0, 10);

            const CrDate = new Date(CreationDate)
            const nowDate = new Date(result)
            let msDiff = nowDate.getTime() - CrDate.getTime();
            let daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));

            const IdCount = element.recordId.indexOf(",");
            const IdTest = element.recordId.substring(IdCount + 1);

            //テスト用に10日を基準にしています　９０日に変えてください
            if (daysDiff >= 90) {
                Vi[Number(IdTest)].isVisible = true;
                SetVisible(Vi)
            } else {
                Vi[Number(IdTest)].isVisible = false;
                SetVisible(Vi)
            }

            //すべて表示する
            return true;
        }


        CopyObj.filter(function (element) {
            return ThreeMonthsFunction(element);
        });
        SetThreeMonthsFlag(true);

    }
    //コメントのみを表示するための関数
    const onCheckBox = () => {
        if (CommentOnly) {
            SetCommentOnly(false);
        } else if (!CommentOnly) {
            SetCommentOnly(true);
        }
    }
    //SimpleTextInputからの値を受け取るための関数
    const onChangeHistoryInputTextValues = (textMemo) => {
        propsCopy[Number(FlagNumber)].memo = textMemo;
        //const onsetNewInputText = () => SetNewInputText(textMemo);
        //onsetNewInputText();
    }
    //件数を表示するための関数
    function visibleCounter() {
        let count = 0;

        if (!ThreeMonthsFlag) {
            if (!CommentOnly) {
                count = propsCopy.length;
            } else {
                propsCopy.map(_item => {
                    const IdCount = _item.recordId.indexOf(",");
                    const ID = _item.recordId.substring(IdCount + 1);
                    if (_item.memo) {
                        count++;
                    }
                })
            }
        } else {
            if (!CommentOnly) {
                propsCopy.map(_item => {
                    const IdCount = _item.recordId.indexOf(",");
                    const ID = _item.recordId.substring(IdCount + 1);
                    if (ThreeMonthsFlag) {
                        if (!Visible[Number(ID)].isVisible) {
                            count++;
                        }
                    }

                })
            } else {
                propsCopy.map(_item => {
                    const IdCount = _item.recordId.indexOf(",");
                    const ID = _item.recordId.substring(IdCount + 1);
                    if (ThreeMonthsFlag) {
                        if (!Visible[Number(ID)].isVisible) {
                            if (_item.memo) {
                                count++;
                            }
                        }
                    }

                })
            }
        }
        return count.toString() + props.propsData.t("number");
    }
    //propsが更新されたときに、propsのコピーを取り直す
    useEffect(() => {
        propsCopy = Object.assign([], props.propsData.data);
    }, [props])



    let count = -1;

    return (
        <div className={`contact-history-module-container module-container ${props.propsData.extraClasses}`}>
            <div className="row">
                <div className="col-xs-3 ellipsize">
                    <input type="button" value={props.propsData.t("displayAll")} onClick={onClickDisplayAllButton} />
                </div>
                <div className="col-xs-3 ellipsize">
                    <input type="button" value={props.propsData.t("pastThreeMonths")} onClick={onClickpastThreeMonthsButton} />
                </div>
                <div className="col-xs-3 ellipsize">
                    <label>
                        <input type="checkbox" checked={CommentOnly} onChange={() => onCheckBox()} />
                        {props.propsData.t("commentsOnly")}
                    </label>
                </div>
                <div className="col-xs-3 ellipsize">
                    {visibleCounter()}
                </div>
            </div>
            <div className="contact-history-rows">
                {

                    (propsCopy || []).map(_item => {
                        const IdCount = _item.recordId.indexOf(",");
                        const ID = _item.recordId.substring(IdCount + 1);
                        const UserID = _item.recordId.substring(0, _item.recordId.indexOf(","));
                        count++;
                        if (!CommentOnly) {
                            return <>
                                <RowComponent
                                    rowName="contact-history row"
                                    key={"contact-history" + count}
                                    UserID={UserID}
                                    isVisible={(ThreeMonthsFlag) ? Visible[Number(ID)].isVisible : false}
                                    content={[
                                        { content: formatDateTimeShort(_item.creationDate), key: "creationDate" + count, className: "ellipsize", widthValue: "3" },
                                        {
                                            content: (FlagNumber === count.toString()) ?
                                                <SimpleSelector
                                                    options={checkControl.values}
                                                    onChange={checkControl.handler}
                                                    defaultValue={_item.check != undefined ? _item.check : ""}
                                                /> :
                                                props.propsData.t(_item.check != undefined ? _item.check : ""),
                                            className: "ellipsize",
                                            key: "checkValue" + count, widthValue: "3"
                                        },
                                        {
                                            content: (FlagNumber === count.toString()) ?
                                                <SimpleSelector
                                                    options={statusControl.values}
                                                    onChange={statusControl.handler}
                                                    defaultValue={_item.status != undefined ? _item.status : ""}
                                                /> :
                                                props.propsData.t(_item.status != undefined ? _item.status : ""),
                                            className: "ellipsize",
                                            key: "statusValue" + count, widthValue: "2"
                                        },
                                        {
                                            content: _item.userName,
                                            className: "ellipsize",
                                            key: "userName" + count, widthValue: "2"
                                        },
                                        {
                                            content: (FlagNumber === count.toString()) ?
                                                <i className="fa fa-check ellipsize"
                                                    aria-hidden="true"
                                                    id={count}
                                                    onClick={(e) => onClickCheckMark(e)}
                                                /> :
                                                <i className="fa fa-pencil ellipsize"
                                                    aria-hidden="true"
                                                    id={count}
                                                    onClick={(e) => onClickHandler(e.target.id, _item.memo)}
                                                />,
                                            className: "action-button",
                                            key: "CheckMark" + count, widthValue: "1"
                                        },
                                        {
                                            content: (FlagNumber === count.toString()) ?
                                                <i className="fa fa-times ellipsize"
                                                    aria-hidden="true"
                                                    id={count}
                                                    onClick={(e) => onClickCancelHandler(e.target.id)}
                                                /> :
                                                <i className="fa fa-trash-o ellipsize"
                                                    aria-hidden="true"
                                                    id={count}
                                                    onClick={(e) => onClickDelButton(e, _item.actionId)}
                                                />,
                                            className: "action-button",
                                            key: "TrushMark" + count, widthValue: "1"
                                        },
                                    ]}
                                />
                                <CallPageInput
                                    Flag={FlagNumber === count.toString()}
                                    Memo={_item.memo}
                                    isVisible={(ThreeMonthsFlag) ? Visible[Number(ID)].isVisible : false}
                                    onChangeHistoryInputTextValues={onChangeHistoryInputTextValues}
                                    rowName="row"
                                    key={"row" + count}
                                    widthValue="12"
                                    FlagNumber={FlagNumber}
                                    SelectUser={props.focusClient}
                                    deleteNumber={deleteNumber}
                                    newInputflag={newInputflag}
                                />
                            </>
                        } else {
                            return <>
                                <RowComponent
                                    rowName="contact-history row"
                                    key={"contact-history" + count}
                                    UserID={UserID}
                                    isVisible={(ThreeMonthsFlag) ? Visible[Number(ID)].isVisible : false}
                                    content={[
                                        {
                                            content: _item.memo,
                                            widthValue: "12",
                                            id: count,
                                            key: ("Memo" + count)
                                        }
                                    ]}
                                />
                            </>
                        }
                    }
                    )
                }
            </div>
        </div>

    )
}


