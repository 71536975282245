import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function Email({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;
    return <>
        <wf.InputField
            value={data.email.value || data.email.defaultValue}
            validator={_validatorsHOC.emailValidator}
            hideLabel={true}
            errorMessage={t('invalidEmailFormatErrorMessage')} className="form-group"
            onChange={setData('email')}
            onError={setData('email')} />
    </>
}
export const EmailPopup = ExportDefaultBehaviour(Email, {
    editableFields: ['email'],
    size: 'sm'
});
EmailPopup.whyDidYouRender = true;
