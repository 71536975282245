import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import PageTable from 'components/tables/pageTable';
import UserModule from './modulesContainers/userModule';
import PropertyModule from './modulesContainers/propertyModule';
import ContactHistoryModule from './modulesContainers/contactHistoryModule';
import LoanModule from './modulesContainers/loanModule';
import CallModule from './modulesContainers/callModule';
import PropertyListModule from './modulesContainers/propertyListModule';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import 'react-reflex/styles.css';
import LoadingOverlay from 'react-loading-overlay';

class CallmodeData extends Component {
    constructor(_props) {
        super(_props);

        this.state = {
            activeContainer: undefined,
            loading: false,
            focusedClient: 0
        };

        this.stateUpdater = (_data) => {
            const updatedClient = { ...this.props.propertListData, ..._data };
            this.props.onRecordUpdate(updatedClient);
        }

        this.onCallHandler = (_phoneNumber) => this.props.onCall({
            id: this.props.data[this.state.focusedClient].id,
            callNumber: _phoneNumber,
            fullName: this.props.data[this.state.focusedClient].fullName
        });

        const generateModuleProps = ((_fieldName) => ({
            onActive: (_component) => this.setState({ activeContainer: _component }),
            onIdle: () => this.setState({ activeContainer: undefined }),
            onChange: this.stateUpdater.bind(this),
            stateFilter: (_state) => _state[_fieldName] || {},
            ...this.state[_fieldName]
        })).bind(this);

        this.moduleProps = ['userModuleData', 'propertyModuleData', 'loanModuleData', 'callModuleData'].reduce((_a, _b) => ({ ..._a, [_b]: generateModuleProps(_b) }), {});

        this.setLoading = () => {
            this.setState({ loading: true });
        }

        this.setFocusedClient = (value) => {
            this.setState({ focusedClient: value })
        }

        this.setLoadingFalse = () => {
            this.setState({ loading: false });
            //setTimeout(() => { this.setState({ loading: false }) }, 1000)
        }

        this.focusClient = (_c, _i) => {
            this.setLoading();
            let requestData = { ownerId: this.props.data[_i].ownerId, roomId: this.props.data[_i].roomId, bukkenId: this.props.data[_i].bukkenId, loanId: this.props.data[_i].loanId };
            this.props.onRequestPropertyListData(requestData, this.setLoadingFalse(), this.setLoadingFalse())
            this.setFocusedClient(_i);
        };

    }

    render() {
        const tableAccessor = (_field) => (_d) => typeof _d === 'object' ? _d ? _d[_field] : "" : _d;

        this.tableOptions = {
            columnsOptions: {
                phoneNumber: { header: this.props.t('phoneNumber'), accessor: tableAccessor('phoneNumber'), sortable: false },
                fullName: { header: this.props.t('fullName'), accessor: tableAccessor('fullName'), sortable: false },
                lastCheck: { header: this.props.t('check'), accessor: tableAccessor('lastCheck'), sortable: false },
                streetAddress: { header: this.props.t('streetAddress'), accessor: tableAccessor('streetAddress'), sortable: false },
                bukkenName: { header: this.props.t('propertyName'), accessor: tableAccessor('bukkenName'), sortable: false },
                roomNumber: { header: this.props.t('roomNumber'), accessor: tableAccessor('roomNumber'), width: 70, sortable: false },
                bukkenAddress: { header: this.props.t('propertyAddress'), accessor: tableAccessor('bukkenAddress'), sortable: false },
                lotNumber: { header: this.props.t('lotNumber'), accessor: tableAccessor('lotNumber'), sortable: false }
            },
            style: { maxHeight: 450 },
            pageSize: 100
        };

        if (this.props.data && this.props.data.length > 0 && !this.state.focusedClient) {
            this.tableOptions.selectedRow = 0;
        }

        const clientData = this.props.propertListData;
        return (<div className="callmode-data-container">
            <div className="data-top-wrapper">
                < PageTable {...this.tableOptions} rows={[...this.props.data]} selectedCallback={this.focusClient} pageChangeCallback={this.props.pageChangeCallback} pageDetails={this.props.serverRecords.pageDetails} loading={this.props.isActive} />
            </div>
            {!this.props.isActive && clientData.id && <LoadingOverlay
                active={this.state.loading}
                spinner
                text={this.props.t('inProcessLoading')}
            >
                <div className="data-bottom-wrapper relative">
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.33}>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement flex={0.5}>
                                    <UserModule {...this.moduleProps.userModuleData} data={clientData} imageImportType={'callmodePage'} imageRecords={clientData.imageRecords} focusClient={this.state.focusedClient} />
                                </ReflexElement>
                                <ReflexSplitter />
                                <ReflexElement>
                                    <PropertyListModule selectedOwner={this.props.data[this.state.focusedClient].ownerId} propertyListData={(clientData || {}).propertyDatas} onUnlockPropertyRoomPopup={this.props.onUnlockPropertyRoomPopup} onAddPropertyRoomPopUp={this.props.onAddPropertyRoomPopUp} />
                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>
                        <ReflexSplitter />
                        <ReflexElement flex={0.34}>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement flex={0.5}>
                                    <PropertyModule {...this.moduleProps.propertyModuleData} data={clientData} imageImportType={'callmodePage'} imageRecords={clientData.imageRecords} focusClient={this.state.focusedClient} />
                                </ReflexElement>
                                <ReflexSplitter />
                                <ReflexElement flex={0.5}>
                                    <LoanModule {...this.moduleProps.loanModuleData} data={clientData} imageImportType={'callmodePage'} imageRecords={clientData.imageRecords} focusClient={this.state.focusedClient} />
                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>
                        <ReflexSplitter />
                        <ReflexElement flex={0.33}>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement flex={0.5}>
                                    <ContactHistoryModule data={(clientData || {}).history} focusClient={this.state.focusedClient} onRequestDeleteHistoryData={this.props.onRequestDeleteHistoryData} onRequestUpdateHistoryData={this.props.onRequestUpdateHistoryData} />
                                </ReflexElement>
                                <ReflexSplitter />
                                <ReflexElement flex={0.5}>
                                    <CallModule {...this.moduleProps.callModuleData} data={clientData} lastCallReference={this.props.lastCallReference} onCall={this.onCallHandler} onRequestInsertHistoryData={this.props.onRequestInsertHistoryData} focusedClient={this.state.focusedClient} propertyListData={(clientData || {}).propertyDatas} onImagesImportAction={this.props.onImagesImportAction} UserProfile={this.props.UserProfile} requestInsertReCallData={this.props.requestInsertReCallData} />
                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>
                    </ReflexContainer>
                    {this.state.activeContainer && this.state.activeContainer(clientData)}
                </div>
            </LoadingOverlay>}
        </div>)
    }
}
CallmodeData.defaultProps = {
    extraClasses: ''
}

export default withTranslation()(CallmodeData);