import { ApiEntryPoint } from 'apiConnector';
import 'babel-polyfill';
import { addBuildingAssets, addOwners, addPropertyRooms, addStaffs, deleteBuildingAsset, deleteOwner, deletePropertyRoom, insertBuildingAsset, insertOwner, insertRoom, insertUnknownProperty, postDeleteBuildingAsset, postDeleteOwner, postDeletePropertyRoom, requestBuildingAssets, requestOwnerRooms, requestOwners, requestPropertyRooms, requestStaffs, updateBuildingAsset, updateOwner, updateOwnerRoom, updateRoom, requestZaitakuDetails, addZaitakuDetails, checkUnknownProperty } from 'reducers/maintenanceReducers';
import { call, put, takeEvery } from 'redux-saga/effects';

/**Request Data**/
function* requestBuildingAssetsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceRequestBuildingAssets, { bukkenName: _action.data.bukkenName, bukkenAddress: _action.data.bukkenAddress, bukkenMoyoriEki: _action.data.bukkenMoyoriEki, roomUrizumi: _action.data.roomUrizumi, pageDetails: _action.data.pageDetails });
        if (result && Array.isArray(result.items)) {
            yield put(addBuildingAssets(result, true));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestBuildingAssetsSaga');
        console.log(_e);
    }
}

function* requestOwnersSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceRequestOwners, { ownerName: _action.data.ownerName, ownerAddress: _action.data.ownerAddress, pageDetails: _action.data.pageDetails });
        if (result && Array.isArray(result.items)) {
            yield put(addOwners(result, true));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestOwnersSaga');
        console.log(_e);
    }
}

function* requestPropertyRoomsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceRequestPropertyRooms, _action.data.id);
        if (result && Array.isArray(result)) {
            yield put(addPropertyRooms(result, true));
        }
    } catch (_e) {
        console.log('Error from requestPropertyRoomsSaga');
        console.log(_e);
    }
}

function* requestOwnerRoomsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceRequestOwnerRooms, _action.data.id);
        if (result && Array.isArray(result)) {
            yield put(addPropertyRooms(result, true));
        }
    } catch (_e) {
        console.log('Error from requestOwnerRoomsSaga');
        console.log(_e);
    }
}

function* requestStaffsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CommonDataGetStaffs);
        if (result && Array.isArray(result)) {
            yield put(addStaffs(result, true));
        }
    } catch (_e) {
        console.log('Error from requestStaffsSaga');
        console.log(_e);
    }
}

function* requestZaitakuDetailsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CommonDataGetZaitakuDetails, _action.data.id);
        if (result && Array.isArray(result)) {
            yield put(addZaitakuDetails(result, true));
        }
    } catch (_e) {
        console.log('Error from requestZaitakuDetailsSaga');
        console.log(_e);
    }
}

/**Delete Data**/
function* deleteBuildingAssetSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceDeleteBuildingAsset, _action.id);
        if (!result.error) {
            yield put(postDeleteBuildingAsset(_action.id));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from deleteBuildingAssetSaga');
        console.log(_e);
    }
}

function* deleteOwnerSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceDeleteOwner, _action.id);
        if (!result.error) {
            yield put(postDeleteOwner(_action.id));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from deleteOwnerSaga');
        console.log(_e);
    }
}

function* deletePropertyRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceDeleteRoom, _action.data);
        if (!result.error) {
            yield put(postDeletePropertyRoom(_action.data.ids));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from deletePropertyRoomSaga');
        console.log(_e);
    }
}

/**Update Data**/
function* updateBuildingAssetSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceUpdateBuildingAsset, _action.data);
        if (!result.error) {
            yield put(addBuildingAssets(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateBuildingAssetSaga');
        console.log(_e);
    }
}

function* updateRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceUpdateRoom, _action.data);
        if (!result.error) {
            yield put(addPropertyRooms(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateRoomSaga');
        console.log(_e);
    }
}

function* updateOwnerSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceUpdateOwner, _action.data);
        if (!result.error) {
            yield put(addOwners(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateOwnerSaga');
        console.log(_e);
    }
}

function* updateOwnerRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceUpdateOwnerRoom, _action.data.roomId, _action.data.ownerId);
        if (!result.error) {
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateOwnerRoomSaga');
        console.log(_e);
    }
}

/**Insert Data**/
function* insertBuildingAssetSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceInsertBuildingAssset, _action.data);
        if (!result.error) {
            yield put(addBuildingAssets(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertBuildingAssetSaga');
        console.log(_e);
    }
}

function* insertRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceInsertRoom, _action.data);
        if (!result.error) {
            yield put(addPropertyRooms(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertRoomSaga');
        console.log(_e);
    }
}

function* insertOwnerSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceInsertOwner, _action.data);
        if (!result.error) {
            yield put(addOwners(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertOwnerSaga');
        console.log(_e);
    }
}

function* insertUnknownPropertySaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceInsertUnknownProperty, _action.data.ownerId, _action.data.propertyName);
        if (!result.error) {
            yield put(addBuildingAssets(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertUnknownPropertySaga');
        console.log(_e);
    }
}

/**Check Data**/
function* checkUnknownPropertySaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.MaintenanceCheckUnknownProperty, _action.data.propertyName);
        if (!result.error) {
            if (result == false) {
                if (_action.successCallback) _action.successCallback();
            }
            else {
                if (_action.failureCallback) _action.failureCallback();
            }
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from checkUnknownPropertySaga');
        console.log(_e);
    }
}

/**Request Data**/
export function* watchRequestBuildingAssetsSaga() {
    yield takeEvery(requestBuildingAssets().type, requestBuildingAssetsSaga);
}

export function* watchRequestOwnersSaga() {
    yield takeEvery(requestOwners().type, requestOwnersSaga);
}

export function* watchRequestPropertyRoomsSaga() {
    yield takeEvery(requestPropertyRooms().type, requestPropertyRoomsSaga);
}

export function* watchRequestOwnerRoomsSaga() {
    yield takeEvery(requestOwnerRooms().type, requestOwnerRoomsSaga);
}

export function* watchRequestStaffsSaga() {
    yield takeEvery(requestStaffs().type, requestStaffsSaga);
}

export function* watchRequestZaitakuDetailsSaga() {
    yield takeEvery(requestZaitakuDetails().type, requestZaitakuDetailsSaga);
}

/**Delete Data**/
export function* watchDeleteBuildingAssetSaga() {
    yield takeEvery(deleteBuildingAsset().type, deleteBuildingAssetSaga);
}

export function* watchDeleteOwnerSaga() {
    yield takeEvery(deleteOwner().type, deleteOwnerSaga);
}

export function* watchDeletePropertyRoomSaga() {
    yield takeEvery(deletePropertyRoom().type, deletePropertyRoomSaga);
}

/**Update Data**/
export function* watchUpdateBuildingAssetSaga() {
    yield takeEvery(updateBuildingAsset().type, updateBuildingAssetSaga);
}

export function* watchUpdateRoomSaga() {
    yield takeEvery(updateRoom().type, updateRoomSaga);
}

export function* watchUpdateOwnerSaga() {
    yield takeEvery(updateOwner().type, updateOwnerSaga);
}

export function* watchUpdateOwnerRoomSaga() {
    yield takeEvery(updateOwnerRoom().type, updateOwnerRoomSaga);
}

/**Insert Data**/
export function* watchInsertBuildingAssetSaga() {
    yield takeEvery(insertBuildingAsset().type, insertBuildingAssetSaga);
}

export function* watchInsertRoomSaga() {
    yield takeEvery(insertRoom().type, insertRoomSaga);
}

export function* watchInsertOwnerSaga() {
    yield takeEvery(insertOwner().type, insertOwnerSaga);
}

export function* watchInsertUnknownPropertySaga() {
    yield takeEvery(insertUnknownProperty().type, insertUnknownPropertySaga);
}

/**Check Data**/
export function* watchCheckUnknownPropertySaga() {
    yield takeEvery(checkUnknownProperty().type, checkUnknownPropertySaga);
}