import React, { Component } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export class SimpleTextInput extends Component {
    constructor(_props) {
        super(_props);
        this.state = {
            isError: false
        }
        this.validator = (_d) => this.props.validator ? this.props.validator(_d) : true;
        this.errorHandler = this.props.onError ? this.props.onError : () => { };
        this.handleChange = (_e) => {
            if (this.validator(_e.target.value.trim())) {
                if (this.props.onChange) this.props.onChange(_e.target.value);
                if (this.state.isError) this.setState({ isError: false });
            } else {
                this.errorHandler(_e.target.value);
                if (!this.state.isError) this.setState({ isError: true });
            }
        }
        this.keyDownHandler = (_e) => _e.key === 'Enter' && this.props.onEnterPressed && this.props.onEnterPressed();
    }

    render() {
        const props = {
            defaultValue: this.props.defaultValue,
            value: this.props.value,
            onChange: this.handleChange,
            className: "form-control boxed multiLine",
            addon: this.props.addon
        }
        return (<div className={`input-group ${this.props.extraClasses}`}>
            {!this.props.isMultiline && <input {...props} onKeyDown={this.keyDownHandler} disabled={this.props.disabled} />}
            {this.props.isMultiline && <textarea {...props} rows={4} />}
            {this.props.children}
            {this.props.addon &&
                <span className="input-group-append"><span className="input-group-text">{this.props.addon}</span></span>
            }
        </div>);
    }
}
SimpleTextInput.whyDidYouRender = true;
SimpleTextInput.defaultProps = {
    extraClasses: '',
    value: ''
}

export function InputRange({ value: _value, defaultValue: _defaultValue, addons: _addons, onChange: _onChange, onError: _onError, validator: _validator, subComponents: _subComponents, extraClasses: _extraClasses }) {
    const validator = _validator ? _validator : () => true;
    const errorHandler = _onError ? _onError : () => { };
    const handleChange = (_values) => {
        let errorCode = 0;

        if (!validator((_values.start || "").trim())) errorCode |= 1;
        if (!validator((_values.end || "").trim())) errorCode |= 2;
        if (errorCode === 0) {
            if (_onChange) _onChange(_values);
        } else {
            errorHandler(_values, errorCode);
        }
    }
    const inputDecorator = ({ defaultContent: _defaultContent, content: _content, handler: _handler, addon: _addon, children: _children }) => {
        const props = {
            defaultValue: _defaultContent,
            value: (_content || ''),
            onChange: _handler,
            className: "form-control boxed"
        }
        return <div className={`input-group`}>
            <input {...props} />
            {_addon &&
                <span className="input-group-append"><span className="input-group-text">{_addon}</span></span>
            }
            {_children}
        </div>
    }
    return (<div className={`inputs-container flex flex-nowrap single-line ${_extraClasses}`}>
        {inputDecorator({
            handler: _e => handleChange({ ..._value, start: _e.target.value }),
            content: (_value || {}).start,
            defaultContent: (_defaultValue || {}).start,
            addon: (_addons || {}).start,
            children: (_subComponents || {}).start
        })}
        <div className="separator flex flex-column justify-center">{(_addons || {}).separator}</div>
        {inputDecorator({
            handler: _e => handleChange({ ..._value, end: _e.target.value }),
            content: (_value || {}).end,
            defaultContent: (_defaultValue || {}).end,
            addon: (_addons || {}).end,
            children: (_subComponents || {}).end
        })}
    </div>);
}
InputRange.whyDidYouRender = true;

export function DateSelector({ value: _value, showIcon: _showIcon, showTimeSelect: _showTimeSelect, extraClasses: _extraClasses, extraPickerOptions: _extraPickerOptions, onChange: _onChange }) {
    let selectorRef;
    const pickerOptions = {
        selected: _value ? new Date(_value) : null,
        onChange: (_v) => _onChange(_v),
        showTimeSelect: _showTimeSelect,
        dateFormat: 'yyyy/MM/dd' + (_showTimeSelect ? ' HH:mm' : ''),
        className: _showIcon ? 'datepicker-input' : `datepicker-input ${_extraClasses}`
    }

    const clickHandler = () => {
        selectorRef.setOpen(true);
    }

    //TODO: ideally, the input should be disabled and the user should be forced to enter
    //the date trough the popup. But if the input is marked as disabled/readonly the
    //popup does not appear
    const picker = <DatePicker
        className='datepicker-input'
        timeFormat='HH:mm'
        timeIntervals={5}
        {...pickerOptions}
        ref={(c) => selectorRef = c}
    />;

    const decorator = (_picker) => <div className={`datepicker-container input-group ${_extraClasses || ''}`}>
        {_picker}
        <div className="input-group-addon relative" onClick={clickHandler}>
            <span className="absolute"><i className="fa fa-calendar" aria-hidden="true"></i></span>
        </div>
    </div>

    return _showIcon ? decorator(picker) : picker;
}
DateSelector.whyDidYouRender = true;