import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { helpers } from 'reducers/callmodeSearchReducers';
import { formatDateTimeShort } from 'helpers/formatHelpers';
import TableComponent from "./TableComponent";

class ContactHistoryModule extends Component {

    render() {
        return (
            <div>
                <TableComponent
                    propsData={this.props}
                />
            </div>
        );
}
}
ContactHistoryModule.defaultProps = {
    extraClasses: ''
}
ContactHistoryModule.whyDidYouRender = true;

export default withTranslation()(ContactHistoryModule);