import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const compareCheckboxGroups = (_old, _new) => _old.every((_e, _i) => _new[_i].value === _e.value && _new[_i].selected === _e.selected)

export class GenerateInput extends Component {
    constructor(_props) {
        super(_props);
        const handler = this.props.onChangeHandler ? (_e) => this.props.onChangeHandler(_e.target.value) : () => { };
        if (!this.props.text) {
            this.labelSize = 0;
        } else {
            this.labelSize = this.props.labelSize ? this.props.labelSize : 4
        }
        this.inputSize = this.props.inputSize ? this.props.inputSize : 10 - this.labelSize

        this.required = this.props.required ? this.props.required ? true : false : false;

        this.type = this.props.type ? this.props.type : "text";

        this.decorator = () => {
            const input = <>
                <input value={this.props.value || ''} type={this.type} name={this.props.text} className={'form-control flex-sh-' + this.inputSize} onChange={handler} required={this.required} />
            </>;

            if (this.props.addon) {
                return <div className={'input-range-container input-group flex-sh-' + this.inputSize}>
                    {input}
                    <div className="input-group-addon relative"><span className="absolute">{this.props.addon}</span></div>
                </div>
            }

            return input;
        }
    }

    shouldComponentUpdate(_nextProps) {
        return this.props.value !== _nextProps.value || JSON.stringify(this.props.text) !== JSON.stringify(_nextProps.text);
    }

    render() {
        let labelContent = '';
        if (this.props.text) {
            labelContent = !Array.isArray(this.props.text) ? this.props.text :
                this.props.text.map(_v => <div key={_v}>{_v}</div>)
        }

        return (<div className={'form-group flex ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + this.labelSize}><label className="title-label flex flex-wrap">{labelContent}</label></div>}
            {this.decorator()}
        </div>);
    }
};
GenerateInput.defaultProps = {
    extraClasses: ''
}
GenerateInput.whyDidYouRender = true;

export class GenerateNumberWithMaxLength extends Component {
    constructor(_props) {
        super(_props);
        const handler = this.props.onChangeHandler ? (_e) => this.props.onChangeHandler(_e.target.value) : () => { };
        if (!this.props.text) {
            this.labelSize = 0;
        } else {
            this.labelSize = this.props.labelSize ? this.props.labelSize : 4
        }
        this.inputSize = this.props.inputSize ? this.props.inputSize : 10 - this.labelSize

        this.required = this.props.required ? this.props.required ? true : false : false;

        this.type = this.props.type ? this.props.type : "text";

        this.Max = this.props.max;

        this.decorator = () => {
            const input = <>
                <input value={this.props.value || ''} type={this.type} max={this.Max} name={this.props.text} className={'form-control flex-sh-' + this.inputSize} onChange={handler} required={this.required} />
            </>;

            if (this.props.addon) {
                return <div className={'input-range-container input-group flex-sh-' + this.inputSize}>
                    {input}
                    <div className="input-group-addon relative"><span className="absolute">{this.props.addon}</span></div>
                </div>
            }

            return input;
        }
    }

    shouldComponentUpdate(_nextProps) {
        return this.props.value !== _nextProps.value || JSON.stringify(this.props.text) !== JSON.stringify(_nextProps.text);
    }

    render() {
        let labelContent = '';
        if (this.props.text) {
            labelContent = !Array.isArray(this.props.text) ? this.props.text :
                this.props.text.map(_v => <div key={_v}>{_v}</div>)
        }

        return (<div className={'form-group flex ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + this.labelSize}><label className="title-label flex flex-wrap">{labelContent}</label></div>}
            {this.decorator()}
        </div>);
    }
};
GenerateNumberWithMaxLength.defaultProps = {
    extraClasses: ''
}
GenerateNumberWithMaxLength.whyDidYouRender = true;


export class GenerateCheckbox extends Component {
    shouldComponentUpdate(_nextProps) {
        return this.props.value !== _nextProps.value || this.props.text !== _nextProps.text;
    }

    render() {
        const handler = this.props.onChangeHandler ? (_e) => this.props.onChangeHandler(_e.target.checked) : () => { };
        return (<div className={'flex ' + this.props.extraClasses}>
            <label className={'single-line flex-sh-' + (this.props.containerSize ? this.props.containerSize : 10)}>
                <input className="checkbox" type="checkbox" checked={this.props.value === undefined ? false : this.props.value} disabled={this.props.disabled} onChange={handler} /><span>{this.props.text}</span>
            </label>
        </div>);
    }
};
GenerateCheckbox.defaultProps = {
    extraClasses: ''
}
GenerateCheckbox.whyDidYouRender = true;

export class GenerateSelector extends Component {
    constructor(_props) {
        super(_props);
        this.required = this.props.required ? this.props.required ? true : false : false;
    }
    shouldComponentUpdate(_nextProps) {
        return this.props.value !== _nextProps.value || this.props.text !== _nextProps.text || JSON.stringify(this.props.options) !== JSON.stringify(_nextProps.options);
    }

    render() {
        const handler = this.props.onChangeHandler ? (_e) => this.props.onChangeHandler(_e.target.value) : () => { };

        let labelSize = 0;
        if (this.props.text) labelSize = this.props.labelSize ? this.props.labelSize : 2;
        const selectorSize = 10 - labelSize;

        return (<div className={'form-group flex ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + (labelSize)}><label className="title-label">{this.props.text}</label></div>}
            <div className={'flex flex-' + (this.props.nowrap ? 'no' : '') + 'wrap flex-sh-' + selectorSize}>
                <select className="form-control" value={this.props.value} onChange={handler} name={this.props.text} required={this.required}>
                    {Array.isArray(this.props.options) && this.props.options.map(_o => <option key={_o.value} value={_o.value}>{_o.text}</option>)}
                </select>
            </div>
        </div>);
    }
}
GenerateSelector.defaultProps = {
    extraClasses: ''
}
GenerateSelector.whyDidYouRender = true;

export class GenerateCheckboxGroup extends Component {
    shouldComponentUpdate(_nextProps) {
        return JSON.stringify(this.props.text) !== JSON.stringify(_nextProps.text) || (Array.isArray(_nextProps.values) && (_nextProps.values.length !== this.props.values.length || !compareCheckboxGroups(this.props.values, _nextProps.values)));
    }

    render() {
        const handler = this.props.onChangeHandler ? (_index, _e) => {
            const newValues = this.props.values.map(_v => {
                const output = Object.assign({}, _v);;
                delete output.text;
                return output;
            });
            newValues[_index].selected = _e.target.checked;
            this.props.onChangeHandler(newValues);
        } : () => { };
        const values = Array.isArray(this.props.values) ? this.props.values.slice(0).map((_v, _i) => {
            const value = { ..._v }
            value.handler = handler.bind(null, _i);
            return value;
        }) : undefined;

        let labelSize = 0;
        let labelContent = '';
        if (this.props.text) {
            labelSize = this.props.labelSize ? this.props.labelSize : 2;
            labelContent = !Array.isArray(this.props.text) ? this.props.text :
                this.props.text.map(_v => <div key={_v}>{_v}</div>)
        }
        const groupSize = 10 - labelSize;

        return (<div className={'flex ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + (labelSize)}>
                <label className="title-label flex flex-wrap">{labelContent}</label>
            </div>}
            <div className={'flex flex-' + (this.props.nowrap ? 'no' : '') + 'wrap flex-sh-' + (groupSize) + (this.props.extraCheckboxGroupClasses ? ' ' + this.props.extraCheckboxGroupClasses : '')}>
                {values && values.map((_v, _i) =>
                    <label className='single-line' key={_v.value}>
                        <input className="checkbox" type="checkbox" onChange={_v.handler} checked={_v.selected} /><span>{_v.text}</span>
                    </label>
                )}
            </div>
        </div>);
    }
};
GenerateCheckboxGroup.defaultProps = {
    extraClasses: ''
}
GenerateCheckboxGroup.whyDidYouRender = true;

export class GenerateRadioGroup extends Component {
    shouldComponentUpdate(_nextProps) {
        return this.props.id !== _nextProps.id || this.props.text !== _nextProps.text || (Array.isArray(_nextProps.values) && (this.props.values.length !== _nextProps.values.length || !compareCheckboxGroups(this.props.values, _nextProps.values)));
    }

    render() {
        const handler = this.props.onChangeHandler ? (_index, _e) => {
            this.props.onChangeHandler(this.props.values.map((_v, _i) => {
                const output = { ..._v }
                output.selected = _e.target.checked && _i === _index;
                return output;
            }));
        } : () => { };
        const values = Array.isArray(this.props.values) ? this.props.values.slice(0).map((_v, _i) => {
            const value = { ..._v }
            value.handler = handler.bind(null, _i);
            return value;
        }) : undefined;

        let labelSize = 0;
        if (this.props.text) labelSize = this.props.labelSize ? this.props.labelSize : 2;
        const groupSize = 10 - labelSize;

        return (<div className={'flex ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + (labelSize)}>
                <label className="title-label flex flex-wrap">{this.props.text}</label>
            </div>}
            <div className={'flex flex-' + (this.props.nowrap ? 'no' : '') + 'wrap flex-sh-' + (groupSize) + (this.props.extraRadioGroupClasses ? ' ' + this.props.extraRadioGroupClasses : '')}>
                {values && values.map((_v, _i) =>
                    <label className='single-line' key={_v.value}>
                        <input className="radio" type="radio" name={this.props.id} onChange={_v.handler} checked={_v.selected} /><span>{_v.text}</span>
                    </label>
                )}
            </div>
        </div>);
    }
};
GenerateRadioGroup.defaultProps = {
    extraClasses: ''
}
GenerateRadioGroup.whyDidYouRender = true;

export class GenerateDatePicker extends Component {
    constructor(_props) {
        super(_props);
        this.required = this.props.required ? this.props.required ? true : false : false;
    }
    shouldComponentUpdate(_nextProps) {
        return this.props.text !== _nextProps.text || this.props.value !== _nextProps.value;
    }

    render() {
        const handler = this.props.onChangeHandler ? (_value) => {
            this.props.onChangeHandler(_value);
        } : () => { };

        let labelSize = 0;
        if (this.props.text) labelSize = this.props.labelSize ? this.props.labelSize : 2;
        const inputSize = 10 - labelSize;

        const iconDecorator = () => {
            const picker = <DatePicker
                selected={this.props.value}
                name={this.props.text}
                required={this.required}
                onChange={handler}
                className='datepicker-input'
                showTimeSelect={this.props.showTimeSelect}
                timeFormat='HH:mm'
                timeIntervals={5}
                dateFormat={'yyyy/MM/dd' + (this.props.showTimeSelect ? ' HH:mm' : '')}
            />;

            if (this.props.showIcon) {
                return <div className={'datepicker-container input-group flex flex-nowrap flex-sh-' + inputSize}>
                    {picker}
                    <div className="input-group-addon relative">
                        <span className="absolute"><i className="fa fa-calendar" aria-hidden="true"></i></span>
                    </div>
                </div>
            }
            return <div className={'datepicker-container flex-sh-' + inputSize}>{picker}</div>;
        };

        return (<div className={'form-group flex ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + labelSize}><label className="title-label">{this.props.text}</label></div>}
            {iconDecorator()}
        </div>);
    }
};
GenerateDatePicker.defaultProps = {
    extraClasses: ''
}
GenerateDatePicker.whyDidYouRender = true;

export class GenerateDisableInput extends Component {
    shouldComponentUpdate(_nextProps) {
        return this.props.text !== _nextProps.text || this.props.value !== _nextProps.value || this.props.disabled !== _nextProps.disabled;
    }

    render() {
        const labelSize = this.props.labelSize ? this.props.labelSize : 2;
        const inputSize = this.props.inputSize ? this.props.inputSize : 10 - labelSize;
        const handler = this.props.onChangeHandler ?
            (_e) => {
                if (!this.props.disabled) this.props.onChangeHandler(_e.target.value);
            } :
            () => { };
        const checkHandler = this.props.onDisableHandler ? (_e) => this.props.onDisableHandler(_e.target.checked) : () => { };
        return (<div className={'form-group flex ' + this.props.extraClasses}>
            <div className={'form-control-label table flex-center single-line flex-sh-' + labelSize}><label className="title-label table-cell">{this.props.text}</label></div>
            <div className={'disable-input-control input-group flex-sh-' + inputSize}>
                <input type="text" className="form-control" onChange={handler} disabled={this.props.disabled && 'disabled'} value={this.props.value || ''} />
                <label className="disable-container input-group-addon relative">
                    <input type="checkbox" className="checkbox" onChange={checkHandler} checked={this.props.disabled === undefined ? false : this.props.disabled} />
                    <span className="absolute">{this.props.disableText}</span>
                </label>
            </div>
        </div>);
    }
};
GenerateDisableInput.defaultProps = {
    extraClasses: ''
}
GenerateDisableInput.whyDidYouRender = true;

export class GenerateInputRange extends Component {
    constructor(_props) {
        super(_props);
        if (this.props.text) {
            this.textSize = this.props.labelSize ? this.props.labelSize : 4;
        }
        this.inputSize = 10 - this.textSize;
    }

    shouldComponentUpdate(_nextProps) {
        return this.props.text !== _nextProps.text || JSON.stringify(this.props.range) !== JSON.stringify(_nextProps.range);
    }

    render() {
        const output = { ...{ start: '', end: '' }, ...this.props.range };

        const handler = (_attribute, _e) => {
            output[_attribute] = _e.target.value;
            this.props.onChangeHandler(output);
        };

        const startHandler = handler.bind(null, 'start');
        const endHandler = handler.bind(null, 'end');

        const decorator = (_value, _handler, _rhsAddon, _type) => {
            const inputValue = <input type="text" type={_type} className="form-control" onChange={_handler} value={_value} />;

            if (_rhsAddon) {
                return <div className="input-range-container input-group">
                    {inputValue}
                    <div className="input-group-addon relative"><span className="absolute">{_rhsAddon}</span></div>
                </div>
            }

            return inputValue;
        }

        return (<div className={'form-group single-line flex flex-nowrap ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + this.textSize}><label className="title-label">{this.props.text}</label></div>}
            <div className={'inputs-container flex flex-nowrap single-line flex-sh-' + this.inputSize}>
                {decorator(output.start, startHandler, this.props.addon, this.props.type ? this.props.type : "text")}
                <div className="input-group-separator flex"><span className="flex-center">〜</span></div>
                {decorator(output.end, endHandler, this.props.addonRhs ? this.props.addonRhs : this.props.addon, this.props.type ? this.props.type : "text")}
            </div >
        </div>);
    };
}
GenerateInputRange.defaultProps = {
    extraClasses: ''
}
GenerateInputRange.whyDidYouRender = true;

export class GenerateDateRange extends Component {
    constructor(_props) {
        super(_props);
        this.textSize = 0;
        if (this.props.text) {
            this.textSize = this.props.labelSize ? this.props.labelSize : 4;
        }
        this.inputSize = 10 - this.textSize;
    }

    shouldComponentUpdate(_nextProps) {
        return this.props.text !== _nextProps.text || JSON.stringify(this.props.dateRange) !== JSON.stringify(_nextProps.dateRange);
    }
    render() {
        const output = { ...this.props.dateRange };
        const handler = (_attribute, _value, _format) => {
            output[_attribute] = _value;
            this.props.onChangeHandler(output);
        };
        const startHandler = handler.bind(null, 'startDate');
        const endHandler = handler.bind(null, 'endDate');
        const Today = new Date();
        const format = 'yyyy/MM/dd' + (this.props.showTimeSelect ? ' HH:mm' : '');
        const iconDecorator = (_selectedDate, _handler) => {
            console.log("op");
            console.log(output);
            const picker = <DatePicker
                selected={_selectedDate}
                startDate={output.startDate}
                endDate={output.endDate}
                onChange={_handler}
                className='datepicker-input flex-grow-1'
                showTimeSelect={this.props.showTimeSelect}
                timeFormat='HH:mm'
                timeIntervals={5}
                dateFormat={format}
                maxDate={Today}
            />;

            if (this.props.showIcon) {
                return <div className="datepicker-container input-group flex flex-nowrap flex-grow-1">
                    {picker}
                    <div className="input-group-addon relative">
                        <span className="absolute"><i className="fa fa-calendar" aria-hidden="true"></i></span>
                    </div>
                </div>
            }
            return <div className="datepicker-container flex-grow-1">{picker}</div>;
        };

        return (<div className={'form-group single-line flex flex-nowrap ' + this.props.extraClasses}>
            {this.props.text && <div className={'form-control-label table flex-center single-line flex-sh-' + this.textSize}><label className="title-label table-cell">{this.props.text}</label></div>}
            <div className={'flex flex-nowrap single-line flex-sh-' + this.inputSize}>
                {iconDecorator(output.startDate, startHandler)}
                <div className="input-group-separator flex"><span className="flex-center">〜</span></div>
                {iconDecorator(output.endDate, endHandler)}
            </div>
        </div>);
    };
}
GenerateDateRange.defaultProps = {
    extraClasses: ''
}
GenerateDateRange.whyDidYouRender = true;