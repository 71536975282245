import React from 'react';

export function TelCallPopUp({ onCancelCallback: _onCancelCallback, onFunction: _onFunction, phoneNumber: _phoneNumber, CellPhone: _CellPhone, CallModeRequestCall: _CallModeRequestCall, data: _data, LoginId: _LoginId }) {
    var xxx = _onFunction();
    var zzz = _CellPhone();

    const test = (phoneNumber) => {
        let request = { callNumber: phoneNumber, loginId: _LoginId };
        _CallModeRequestCall(request);
        _onCancelCallback();
    }

    return <div className="simple-message-modal modal fade show">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">電話番号選択</h4>
                </div>
                <div className="simple-message-body modal-body modal-tab-container flex justify-center flex-direction flex-wrap">
                    {
                        xxx.phoneNumber ? <button type="button" className="btn btn-secondary" onClick={() => test(xxx.phoneNumber)}>{xxx.phoneProhibited ? (xxx.phoneNumber + " TEL禁止") : xxx.phoneNumber}</button> : ""
                    }
                    {
                        xxx.secondaryPhone ? <button type="button" className="btn btn-secondary" onClick={() => test(xxx.secondaryPhone)}>{xxx.secondaryPhoneProhibited ? (xxx.secondaryPhone + " TEL禁止") : xxx.secondaryPhone}</button> : ""
                    }
                    {
                        zzz.cellphone ? <button type="button" className="btn btn-secondary" onClick={() => test(zzz.cellphone)}>{zzz.cellphone}</button> : ""
                    }
                    {
                        xxx.tertiaryPhone ? <button type="button" className="btn btn-secondary" onClick={() => test(xxx.tertiaryPhone)}>{xxx.tertiaryPhone}</button> : ""
                    }
                    {
                        zzz.cellphoneSecondary ? <button type="button" className="btn btn-secondary" onClick={() => test(zzz.cellphoneSecondary)}>{zzz.cellphoneSecondary}</button> : ""
                    }
                    <button type="button" className="btn btn-secondary" onClick={_onCancelCallback}>切断</button>
                </div>
            </div>
        </div>
    </div>;
}