import React, { Component } from "react";
import { withTranslation, } from 'react-i18next';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { isFunction } from 'helpers/functionalHelpers';
import { AutohideInput } from "components/inputs/autohideInput";
import Paginator from 'helpers/paginator';
const equal = require('fast-deep-equal');

class AssessmentRequestPageTable extends Component {
    constructor(_props) {
        super(_props);
        this.state = {
            page: 1,
            loading: false,
            selectedRow: this.props.selectedRow,
            expanded: {}
        }
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleLoading = this.handleLoading.bind(this)
        this.handleSorting = this.handleSorting.bind(this)

        this.rowOnclickHandler = ((_state, _rowInfo) => {
            if (_rowInfo && _rowInfo.row) {
                const handler = ((_statusCb, _parentCb, _data, _e) => {
                    if (_e.target.tagName === 'DIV') {
                        _statusCb(_rowInfo.index);
                        _parentCb(_data, _rowInfo.index);
                    }
                }).bind(null, (_i) => this.setState({ selectedRow: _i }), this.props.selectedCallback, _rowInfo.original);
                return {
                    onClick: handler,
                    className: _rowInfo.index === this.state.selectedRow ? ["selected-row"] : []
                }
            } else {
                return {}
            }
        }).bind(this);

        this.tableProps = {
            showPageSizeOptions: false,
            className: "-striped -highlight responsive-data-table",
            noDataText: this.props.t('noDataFound'),
            loadingText: this.props.t('loading'),
            previousText: this.props.t('previousPage'),
            nextText: this.props.t('nextPage'),
            pageText: this.props.t('page'),
            ofText: '～',
            onExpandedChange: ((_newExpanded, _i, _e) => {
                const expandedState = {};
                Object.keys(_newExpanded).forEach(_k => {
                    expandedState[_k] = !(_newExpanded[_k] === false);
                });
                this.setState({ expanded: expandedState });
            }).bind(this),
            ...this.generateTableProps({ ...this.props, ...this.state }),
            data: this.props.rows,
            initialState: {
                hiddenColumns: ['kidokuState'],
            }
        }
        this.kakinStateName = [this.props.t('kakinTaisyo'), this.props.t('shinseichu'), this.props.t('kakinTaisyoKyakka'), this.props.t('kakinTaisyoGai'), this.props.t('kakinTaisyoTorisage')];
    }

    shouldComponentUpdate(_nextProps, _nextState) {
        const didOptionsChanged = !equal(this.state, _nextState) || !equal(this.props.columnsOptions, _nextProps.columnsOptions) || !equal(this.props.style, _nextProps.style) || !equal(this.props.pageSize, _nextProps.pageSize) || !equal(this.props.subDataColumnsOptions, _nextProps.subDataColumnsOptions) || this.props.loading !== _nextProps.loading;
        const didDataChanged = this.props.rows.length !== _nextProps.rows.length || this.props.rows.some((_d, _i) => !equal(_d, _nextProps.rows[_i]));
        if (didOptionsChanged) {
            this.tableProps = {
                ...this.tableProps,
                ...this.generateTableProps({ ..._nextProps, ..._nextState })
            }
        }
        if (didDataChanged) {
            this.tableProps = {
                ...this.tableProps,
                data: _nextProps.rows
            }
        }
        return didOptionsChanged || didDataChanged;
    }

    generateTableProps(_opts) {
        const tableProps = {
            style: (_opts.style ? _opts.style : undefined),
            loading: _opts.loading === true,
            //className: ("midoku-line"),
            columns: this.formatColumns(_opts.columnsOptions),
            getTrProps: (_opts.selectedCallback ? this.rowOnclickHandler : undefined),
            expanded: _opts.expanded,
            pageSize: _opts.pageSize,
            
        }
        
        if (_opts.subDataColumnsOptions) {
            const subComponentProps = { ...tableProps };
            delete subComponentProps.SubComponent;
            tableProps.SubComponent = _row => {
                return <div className="data-table-subcomponent"><ReactTable
                    {...{
                        ...subComponentProps,
                        data: _opts.subDataRowFetcher(_row.original, _opts.subDataRows).map(_v => ({ ..._v, parent: _row.original })),
                        columns: this.formatColumns(_opts.subDataColumnsOptions),
                        pageSize: _opts.subDataRowFetcher(_row.original, _opts.subDataRows).length,
                        getTrProps: (_opts.selectedCallback ? this.rowOnclickHandler : undefined),
                        ...this.generatePageOptions({ ...subComponentProps, rows: _opts.subDataRowFetcher(_row.original, _opts.subDataRows) })

                    }}
                /></div>
            };;

        }

        return tableProps;
    }

    generatePageOptions(_opts) {
        return {
            showPagination: false,
            showPaginationBottom: false,
            showPageJump: false,
            defaultPageSize: _opts.pageSize
        };
    }

    formatColumns(_columns) {
        const onChangeWrapper = (_attributeGetter, _valueAttribute, _originalData, _e) => {
            const update = {};
            update[_valueAttribute] = _attributeGetter(_e);
            this.props.updateCallback({ ..._originalData, ...update })
        }

        const comboBoxRenderer = (_attribute, _accessor, _options, _data) => {
            return (
                <select
                    className="form-control"
                    defaultValue={_accessor(_data.original)}
                    onChange={this.props.updateCallback ? (onChangeWrapper).bind(null, (_e) => _e.target.value, _attribute, _data.original) : () => { }}>
                    {_options.map((_o, _i) => <option value={_o} key={_i}>{_o}</option>)}
                </select>
            )
        }

        const checkboxRenderer = (_attribute, _accessor, _data) => {
            return (
                <label>
                    <input
                        className="checkbox"
                        type="checkbox"
                        checked={_accessor(_data.original) ? 'checked' : ''}
                        onChange={this.props.updateCallback ? (onChangeWrapper).bind(null, (_e) => _e.target.checked, _attribute, _data.original) : () => { }} /> <span />
                </label>
            )
        };

        const editableRenderer = (_attribute, _accessor, _data) => {
            return (<div className="flex justify-center flex-column fill-h">
                <AutohideInput
                    value={_accessor(_data.original)}
                    extraClasses='responsive-datatable-text'
                    handleChangedCallback={this.props.updateCallback && (onChangeWrapper).bind(null, (_e) => _e.value, _attribute, _data.original)} />
            </div>);
        };

        const buttonRenderer = (_icon, _btnClass, _buttonText, _callback, _data,) => {
            return (<div className="responsive-data-table-button-cell flex justify-center">
                <div className={'btn btn-sm ' + _btnClass} onClick={() => _callback(_data.original)}><i className={'fa-' + _icon} >{_buttonText}</i></div>
            </div>
            );
        };
        const kakinStateRenderer = (_attribute, _accessor, _data) => {
            var styleSet = "";
            switch (_accessor(_data.original)){
                case 0:
                    console.log("_accessor(_data.original)", _accessor(_data.original));
                    styleSet = 'color: red';
                    break;
                default:
                    console.log("_accessor(_data.original)", _accessor(_data.original));
                    styleSet = "color: #4f5f6f";
                    break;
            }
            return (<div className="flex justify-center flex-column fill-h">
                <div className="responsive-datatable-text center">{this.kakinStateName[_accessor(_data.original)]}</div>
            </div>);
        };
        const registrationDateRenderer = (_attribute, _accessor, _data) => {
            return (<div className="flex justify-center flex-column fill-h">
                <div className="responsive-datatable-text center">{_accessor(_data.original).replace("T", " ")}</div>
            </div>);
        };

        return !_columns ? [] : Object.keys(_columns).map(_key => {
            
            const output = {
                id: _key,
                Header: this.props.t(_columns[_key].header || _key),
                sortable: _columns[_key].sortable !== false,
                accessor: _columns[_key].accessor ?
                    isFunction(_columns[_key].accessor) ? _d => _columns[_key].accessor(_d[_key]) :
                        _d => _d[_columns[_key].accessor] : _d => _d[_key],
                width: _columns[_key].width
            }
            let cellRenderer = ((_accessor, _data) => <div className="flex justify-center flex-column fill-h"><div className="responsive-datatable-text center">{_accessor(_data.original)}</div></div>).bind(null, output.accessor);

            if (_columns[_key].type === 'dropdown') {
                cellRenderer = comboBoxRenderer.bind(null, _key, output.accessor, _columns[_key].options);
            }

            if (_columns[_key].type === 'checkbox') {
                cellRenderer = checkboxRenderer.bind(null, _key, output.accessor);
            }

            if (_columns[_key].type === 'editable') {
                cellRenderer = editableRenderer.bind(null, _key, output.accessor);
            }

            if (_columns[_key].type === 'button') {
                cellRenderer = buttonRenderer.bind(null, _columns[_key].icon, _columns[_key].buttonClass, _columns[_key].buttonText, _columns[_key].callback);
            }
            if (_columns[_key].type === 'kakinState') {
                
                cellRenderer = kakinStateRenderer.bind(null, _key, output.accessor);
            }
            if (_columns[_key].type === 'registrationDate') {
               
                cellRenderer = registrationDateRenderer.bind(null, _key, output.accessor);
            }
            output.Cell = cellRenderer;

            return output;
        });
    }

    handlePageChange(page) {
        this.setState({ loading: true })
        this.props.pageChangeCallback(page)
        this.setState({ page: page })
    }

    handleSorting(data) {
        this.setState({ loading: true })
        this.setState({ page: 1 })
        this.props.sortCallback(data[0])
    }

    handleLoading() {
        this.setState({ loading: false })
    }

    getTRPropsType(state, rowInfo, column) {
        if (rowInfo) {
            console.log("rowInfo", rowInfo);
            return {
                style: {
                    background: rowInfo.original.kidokuState == 0 ? '#ffe4e1' : '#fff'
                }
            }
        }
        return {};
    }
    render() {

        const { page, loading } = this.state
        return <>
            {this.props.rows.length > 0 && <Paginator
                pageCount={this.props.assessmentPageDetails.totalPages}
                currentPage={this.props.assessmentPageDetails.currentPage/*page*/}
                onPageChange={this.handlePageChange}
                noOfRecords={this.props.rows && this.props.rows.length > 0 && !this.props.loading && <div className={'noOfRecords'}>{(this.props.assessmentPageDetails.currentCount) + this.props.t('-itemCounter')} / {(this.props.assessmentPageDetails.totalCount) + this.props.t('-itemCounter')}</div>}
            />}
            <ReactTable {...this.tableProps} loading={this.props.loading} {...this.generatePageOptions(this.props)}
                getTrProps={this.getTRPropsType}/>
            {this.props.rows.length > 0 && <Paginator
                pageCount={this.props.assessmentPageDetails.totalPages}
                currentPage={this.props.assessmentPageDetails.currentPage/*page*/}
                onPageChange={this.handlePageChange}
                noOfRecords={this.props.rows && this.props.rows.length > 0 && !this.props.loading && <div className={'noOfRecords'}>{(this.props.assessmentPageDetails.currentCount) + this.props.t('-itemCounter')} / {(this.props.assessmentPageDetails.totalCount) + this.props.t('-itemCounter')}</div>}
            />}
        </>;
    }
}

AssessmentRequestPageTable.defaultProps = {
    rows: []
};

export default withTranslation()(AssessmentRequestPageTable);