import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

const uniqueRowsIDs = ['1', '2'];
const fields = ['companyName', 'companyType', 'personInCharge', 'agentCharge', 'period'];
function ConflictsWithOthers({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const handoffCategory = uniqueRowsIDs.map(_id => ["unspecified", "newConstruction", "secondHand"].reduce(_checkboxGroupHOC.checkboxGroupReducer('companyType' + _id), {}))

    return <>
        {uniqueRowsIDs.map((_id, _index) => {
            return <Row key={_id}>
                <Col className="col-xs-4">
                    <wf.InputField label={t(fields[0])} extraClasses="single-line"
                        value={data[fields[0] + _id].value || data[fields[0] + _id].defaultValue}
                        onChange={setData(fields[0] + _id)}
                        labelSizeOverride={4}/>
                </Col>
                <Col className="col-xs-8">
                    <wf.CheckboxGroupField hideLabel={true} values={handoffCategory[_index]} onChange={(_v) => setData(fields[1] + _id)(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName={fields[1] + _id} />
                </Col>
                <Col className="col-xs-4">
                    <wf.InputField label={t(fields[2])} extraClasses="single-line"
                        value={data[fields[2] + _id].value || data[fields[2] + _id].defaultValue}
                        onChange={setData(fields[2] + _id)}
                        labelSizeOverride={4}/>
                </Col>
                <Col className="col-xs-4">
                    <wf.InputField label={t(fields[3])}
                        value={data[fields[3] + _id].value || data[fields[3] + _id].defaultValue}
                        onChange={setData(fields[3] + _id)}
                        validator={_validatorsHOC.doubleValidator}
                        errorMessage={t('incorrectNumberFormatError')}
                        addon={t('10kYen')}
                        labelSizeOverride={5}/>
                </Col>
                <Col className="col-xs-4">
                    <wf.DatePicker label={t(fields[4])}
                        value={data[fields[4] + _id].value || data[fields[4] + _id].defaultValue}
                        onChange={setData(fields[4] + _id)} />
                </Col>
            </Row>;
        })}
    </>;
}
const ConflictsWithOthersPopup = ExportDefaultBehaviour(ConflictsWithOthers, {
    editableFields: uniqueRowsIDs.reduce((_a, _b) => _a.concat(fields.map(_v => _v + _b)), []),
    size: 'md'
});
ConflictsWithOthersPopup.whyDidYouRender = true;

export default ConflictsWithOthersPopup;