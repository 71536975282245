import { ApiEntryPoint } from 'apiConnector';
import 'babel-polyfill';
import { addBuildingAssets, addOwners, addPropertyRooms, addStaffs, deleteBuildingAsset, deleteOwner, deletePropertyRoom, insertBuildingAsset, insertOwner, insertRoom, insertUnknownProperty, postDeleteBuildingAsset, postDeleteOwner, postDeletePropertyRoom, requestBuildingAssets, requestOwnerRooms, requestOwnerListRooms, requestOwners, exportCsv, requestPropertyRooms, requestStaffs, updateBuildingAsset, updateOwner, updateMemo, updateKidoku, updateShinsei, updateTorisage, updateStates, updateOwnerRoom, updateRoom, requestZaitakuDetails, addZaitakuDetails, checkUnknownProperty } from 'reducers/assessmentRequestReducers';
import { call, put, takeEvery } from 'redux-saga/effects';

/**Request Data**/
function* requestBuildingAssetsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestRequestBuildingAssets, { bukkenName: _action.data.bukkenName, bukkenAddress: _action.data.bukkenAddress, bukkenMoyoriEki: _action.data.bukkenMoyoriEki, roomUrizumi: _action.data.roomUrizumi, assessmentPageDetails: _action.data.assessmenttPageDetails });
        if (result && Array.isArray(result.items)) {
            yield put(addBuildingAssets(result, true));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestBuildingAssetsSaga');
        console.log(_e);
    }
}

function* requestOwnersSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestRequestOwners, {
            holdStaffId: _action.data.holdStaffID,
            visible: _action.data.visible,
            assessmentPageDetails: _action.data.assessmentPageDetails,
            id: _action.data.id,
            registrationDate: _action.data.registrationDate,
            kakinState: _action.data.kakinState,
            kidokuState: _action.data.kidokuState,
            sateiMemo: _action.data.sateiMemo,
            sateiId: _action.data.sateiId,
            bukkenMemo: _action.data.bukkenMemo,
            bukkenType: _action.data.bukkenType,
            ownerName: _action.data.ownerName,
            kana: _action.data.kana,
            mail: _action.data.mail,
            tel1: _action.data.tel1,
            ownerAddress: _action.data.ownerAddress,
            bukkenName: _action.data.bukkenName,
            bukkenAddress: _action.data.bukkenAddress,
            toGetsu: _action.data.toGetsu,
            zenGetsu: _action.data.zenGetsu
        });
        if (result && Array.isArray(result.items)) {
            yield put(addOwners(result, true));
            if (_action.successCallback) {
                _action.successCallback();
            }
        }
        else {
            if (_action.failureCallback) {
                _action.failureCallback();
            }
        }
    } catch (_e) {
        console.log('Error from requestOwnersSaga');
        console.log(_e);
    }
}
function* exportCsvSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestExportCsv, {
            holdStaffId: _action.data.holdStaffID,
            visible: _action.data.visible,
            assessmentPageDetails: _action.data.assessmentPageDetails,
            id: _action.data.id,
            registrationDate: _action.data.registrationDate,
            kakinState: _action.data.kakinState,
            kidokuState: _action.data.kidokuState,
            sateiMemo: _action.data.sateiMemo,
            bukkenMemo: _action.data.bukkenMemo,
            bukkenType: _action.data.bukkenType,
            ownerName: _action.data.ownerName,
            kana: _action.data.kana,
            mail: _action.data.mail,
            tel1: _action.data.tel1,
            ownerAddress: _action.data.ownerAddress,
            bukkenName: _action.data.bukkenName,
            bukkenAddress: _action.data.bukkenAddress,
            toGetsu: _action.data.toGetsu,
            zenGetsu: _action.data.zenGetsu
        });
        if (!result.error) {
            exportData(result);
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from exportOwnerSaga');
        console.log(_e);
    }
}
const exportData = (_presetResponse) => {

    var base64str = _presetResponse.fileContents;
    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    var blob = new Blob([view], { type: "text/csv" });
    saveAs(blob, _presetResponse.fileDownloadName);
}
const saveAs = (blob, filename) => {
    const download = document.createElement("a");
    download.download = filename;
    download.href = window.URL.createObjectURL(blob);
    download.click();
}
function* requestPropertyRoomsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestRequestPropertyRooms, _action.data.id);
        if (result && Array.isArray(result)) {
            yield put(addPropertyRooms(result, true));
        }
    } catch (_e) {
        console.log('Error from requestPropertyRoomsSaga');
        console.log(_e);
    }
}

function* requestOwnerRoomsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestRequestOwnerRooms, _action.data.id);
        if (result && Array.isArray(result)) {
            yield put(addPropertyRooms(result, true));
        }
    } catch (_e) {
        console.log('Error from requestOwnerRoomsSaga');
        console.log(_e);
    }
}
function* requestOwnerListRoomsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestRequestOwnerListRooms, _action.data);
        if (result && Array.isArray(result)) {
            yield put(addPropertyRooms(result, true));
        }
    } catch (_e) {
        console.log('Error from requestOwnerRoomsSaga');
        console.log(_e);
    }
}

function* requestStaffsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CommonDataGetStaffs);
        if (result && Array.isArray(result)) {
            yield put(addStaffs(result, true));
        }
    } catch (_e) {
        console.log('Error from requestStaffsSaga');
        console.log(_e);
    }
}

function* requestZaitakuDetailsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CommonDataGetZaitakuDetails, _action.data.id);
        if (result && Array.isArray(result)) {
            yield put(addZaitakuDetails(result, true));
        }
    } catch (_e) {
        console.log('Error from requestZaitakuDetailsSaga');
        console.log(_e);
    }
}

/**Delete Data**/
function* deleteBuildingAssetSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestDeleteBuildingAsset, _action.id);
        if (!result.error) {
            yield put(postDeleteBuildingAsset(_action.id));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from deleteBuildingAssetSaga');
        console.log(_e);
    }
}

function* deleteOwnerSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestDeleteOwner, _action.id);
        if (!result.error) {
            yield put(postDeleteOwner(_action.id));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from deleteOwnerSaga');
        console.log(_e);
    }
}

function* deletePropertyRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestDeleteRoom, _action.data);
        if (!result.error) {
            yield put(postDeletePropertyRoom(_action.data.ids));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from deletePropertyRoomSaga');
        console.log(_e);
    }
}

/**Update Data**/
function* updateBuildingAssetSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateBuildingAsset, _action.data);
        if (!result.error) {
            yield put(addBuildingAssets(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateBuildingAssetSaga');
        console.log(_e);
    }
}

function* updateRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateRoom, _action.data);
        if (!result.error) {
            yield put(addPropertyRooms(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateRoomSaga');
        console.log(_e);
    }
}

function* updateOwnerSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateOwner, _action.data);
        if (!result.error) {
            yield put(addOwners(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateOwnerSaga');
        console.log(_e);
    }
}
function* updateMemoSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateMemo, _action.data);
        if (!result.error) {
            /*yield put(addMemo(result, false));
            if (_action.successCallback) _action.successCallback();*/
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateMemoSaga');
        console.log(_e);
    }
}
function* updateKidokuSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateKidoku, _action.data);
        if (!result.error) {
            /*yield put(addMemo(result, false));
            if (_action.successCallback) _action.successCallback();*/
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateKidokuSaga');
        console.log(_e);
    }
}
function* updateShinseiSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateShinsei, _action.data);
        if (!result.error) {
            /*yield put(addMemo(result, false));
            if (_action.successCallback) _action.successCallback();*/
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateShinseiSaga');
        console.log(_e);
    }
}
function* updateTorisageSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateTorisage, _action.data);
        if (!result.error) {
            /*yield put(addMemo(result, false));
            if (_action.successCallback) _action.successCallback();*/
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateTorisageSaga');
        console.log(_e);
    }
}
function* updateStatesSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateStates, _action.data);
        if (!result.error) {
            /*yield put(addMemo(result, false));
            if (_action.successCallback) _action.successCallback();*/
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateStatesSaga');
        console.log(_e);
    }
}

function* updateOwnerRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestUpdateOwnerRoom, _action.data.roomId, _action.data.ownerId);
        if (!result.error) {
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from updateOwnerRoomSaga');
        console.log(_e);
    }
}

/**Insert Data**/
function* insertBuildingAssetSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestInsertBuildingAssset, _action.data);
        if (!result.error) {
            yield put(addBuildingAssets(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertBuildingAssetSaga');
        console.log(_e);
    }
}

function* insertRoomSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestInsertRoom, _action.data);
        if (!result.error) {
            yield put(addPropertyRooms(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertRoomSaga');
        console.log(_e);
    }
}

function* insertOwnerSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestInsertOwner, _action.data);
        if (!result.error) {
            yield put(addOwners(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertOwnerSaga');
        console.log(_e);
    }
}

function* insertUnknownPropertySaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestInsertUnknownProperty, _action.data.ownerId, _action.data.propertyName);
        if (!result.error) {
            yield put(addBuildingAssets(result, false));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from insertUnknownPropertySaga');
        console.log(_e);
    }
}

/**Check Data**/
function* checkUnknownPropertySaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.AssessmentRequestCheckUnknownProperty, _action.data.propertyName);
        if (!result.error) {
            if (result == false) {
                if (_action.successCallback) _action.successCallback();
            }
            else {
                if (_action.failureCallback) _action.failureCallback();
            }
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from checkUnknownPropertySaga');
        console.log(_e);
    }
}

/**Request Data**/
export function* watchRequestBuildingAssetsSaga() {
    yield takeEvery(requestBuildingAssets().type, requestBuildingAssetsSaga);
}

export function* watchRequestOwnersSaga() {
    yield takeEvery(requestOwners().type, requestOwnersSaga);
}
export function* watchExportCsvSaga() {
    yield takeEvery(exportCsv().type, exportCsvSaga);
}
export function* watchRequestPropertyRoomsSaga() {
    yield takeEvery(requestPropertyRooms().type, requestPropertyRoomsSaga);
}

export function* watchRequestOwnerRoomsSaga() {
    yield takeEvery(requestOwnerRooms().type, requestOwnerRoomsSaga);
}
export function* watchRequestOwnerListRoomsSaga() {
    yield takeEvery(requestOwnerListRooms().type, requestOwnerListRoomsSaga);
}

export function* watchRequestStaffsSaga() {
    yield takeEvery(requestStaffs().type, requestStaffsSaga);
}

export function* watchRequestZaitakuDetailsSaga() {
    yield takeEvery(requestZaitakuDetails().type, requestZaitakuDetailsSaga);
}

/**Delete Data**/
export function* watchDeleteBuildingAssetSaga() {
    yield takeEvery(deleteBuildingAsset().type, deleteBuildingAssetSaga);
}

export function* watchDeleteOwnerSaga() {
    yield takeEvery(deleteOwner().type, deleteOwnerSaga);
}

export function* watchDeletePropertyRoomSaga() {
    yield takeEvery(deletePropertyRoom().type, deletePropertyRoomSaga);
}

/**Update Data**/
export function* watchUpdateBuildingAssetSaga() {
    yield takeEvery(updateBuildingAsset().type, updateBuildingAssetSaga);
}

export function* watchUpdateRoomSaga() {
    yield takeEvery(updateRoom().type, updateRoomSaga);
}

export function* watchUpdateOwnerSaga() {
    yield takeEvery(updateOwner().type, updateOwnerSaga);
}
export function* watchUpdateMemoSaga() {
    yield takeEvery(updateMemo().type, updateMemoSaga);
}
export function* watchUpdateKidokuSaga() {
    yield takeEvery(updateKidoku().type, updateKidokuSaga);
}
export function* watchUpdateShinseiSaga() {
    yield takeEvery(updateShinsei().type, updateShinseiSaga);
}
export function* watchUpdateTorisageSaga() {
    yield takeEvery(updateTorisage().type, updateTorisageSaga);
}
export function* watchUpdateStatesSaga() {
    yield takeEvery(updateStates().type, updateStatesSaga);
}

export function* watchUpdateOwnerRoomSaga() {
    yield takeEvery(updateOwnerRoom().type, updateOwnerRoomSaga);
}

/**Insert Data**/
export function* watchInsertBuildingAssetSaga() {
    yield takeEvery(insertBuildingAsset().type, insertBuildingAssetSaga);
}

export function* watchInsertRoomSaga() {
    yield takeEvery(insertRoom().type, insertRoomSaga);
}

export function* watchInsertOwnerSaga() {
    yield takeEvery(insertOwner().type, insertOwnerSaga);
}

export function* watchInsertUnknownPropertySaga() {
    yield takeEvery(insertUnknownProperty().type, insertUnknownPropertySaga);
}

/**Check Data**/
export function* watchCheckUnknownPropertySaga() {
    yield takeEvery(checkUnknownProperty().type, checkUnknownPropertySaga);
}