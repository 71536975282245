import { SortedArray } from 'helpers/functionalHelpers';

const types = {
    addEmployees: 'EM_ADD_EMPLOYEES',
    replaceEmployees: 'EM_REPLACE_EMPLOYEES',
    deleteEmployee: 'EM_DELETE_EMPLOYEE',
    deleteEmployees: 'EM_DELETE_EMPLOYEES',
    addRoles: 'EM_ADD_ROLES',
    addTeams: 'EM_ADD_TEAMS',
    addMultiTeams: 'EM_ADD_MULTI_TEAMS',
    replaceMultiTeams: 'EM_REPLACE_MULTI_TEAMS',
    deleteTeam: 'EM_DELETE_TEAM',
    addGroups: 'EM_ADD_GROUPS',
    deleteGroup: 'EM_DELETE_GROUP',
    requestEmployees: 'EM_REQUEST_EMPLOYEES',
    requestRoles: 'EM_REQUEST_ROLES',
    requestTeams: 'EM_REQUEST_TEAMS',
    requestMultiTeams: 'EM_REQUEST_MULTI_TEAMS',
    requestGroups: 'EM_REQUEST_GROUPS',
    pushEmployee: 'EM_PUSH_EMPLOYEE',
    pushModifyEmployee: 'EM_PUSH_MODIFY_EMPLOYEE',
    pushModifyEmployeeTeams: 'EM_PUSH_MODIFY_EMPLOYEE_TEAMS',
    pushDeleteEmployee: 'EM_PUSH_DELETE_EMPLOYEE',
    pushModifyTeam: 'EM_PUSH_MODIFY_TEAM',
    pushAddTeam: 'EM_PUSH_ADD_TEAM',
    pushDeleteTeam: 'EM_PUSH_DELETE_TEAM',
    pushModifyGroup: 'EM_PUSH_MODIFY_GROUP',
    pushAddGroup: 'EM_PUSH_ADD_GROUP',
    pushDeleteGroup: 'EM_PUSH_DELETE_GROUP'
};

/** Actions **/
export const addEmployees = employees => ({
    type: types.addEmployees,
    employees
});

export const replaceEmployees = employees => ({
    type: types.replaceEmployees,
    employees
});

export const deleteEmployee = employee => ({
    type: types.deleteEmployee,
    employee
});

export const deleteEmployees = employees => ({
    type: types.deleteEmployees,
    employees
});

export const addRoles = roles => ({
    type: types.addRoles,
    roles
});

export const addTeams = teams => ({
    type: types.addTeams,
    teams
});

export const addMultiTeams = multiTeams => ({
    type: types.addMultiTeams,
    multiTeams
});

export const replaceMultiTeams = multiTeams => ({
    type: types.replaceMultiTeams,
    multiTeams
});

export const deleteTeam = team => ({
    type: types.deleteTeam,
    team
});

export const addGroups = groups => ({
    type: types.addGroups,
    groups
});

export const deleteGroup = group => ({
    type: types.deleteGroup,
    group
});

/** Async Actions **/
export const requestEmployees = (id) => ({
    type: types.requestEmployees,
    id
});

export const requestRoles = () => ({
    type: types.requestRoles
});

export const requestTeams = () => ({
    type: types.requestTeams
});

export const requestMultiTeams = () => ({
    type: types.requestMultiTeams
});

export const requestGroups = () => ({
    type: types.requestGroups
});

export const pushEmployee = (employee, successCallback, failureCallback) => ({
    type: types.pushEmployee,
    employee,
    successCallback,
    failureCallback
});

export const pushModifyEmployee = (employee, successCallback, failureCallback) => ({
    type: types.pushModifyEmployee,
    employee,
    successCallback,
    failureCallback
});

export const pushModifyEmployeeTeams = (employee) => ({
    type: types.pushModifyEmployeeTeams,
    employee
});

export const pushDeleteEmployee = (employee, successCallback) => ({
    type: types.pushDeleteEmployee,
    employee,
    successCallback
});

export const pushModifyTeam = (team) => ({
    type: types.pushModifyTeam,
    team
});

export const pushAddTeam = (team) => ({
    type: types.pushAddTeam,
    team
});

export const pushDeleteTeam = (team, successCallback) => ({
    type: types.pushDeleteTeam,
    team,
    successCallback
});

export const pushModifyGroup = (group) => ({
    type: types.pushModifyGroup,
    group
});

export const pushAddGroup = (group) => ({
    type: types.pushAddGroup,
    group
});

export const pushDeleteGroup = (group, successCallback) => ({
    type: types.pushDeleteGroup,
    group,
    successCallback
});

/** Reducer **/
const employees = new SortedArray();
employees.comparator = (_a, _b) => {
    return _a.id - _b.id;
};
const roles = new SortedArray();
roles.comparator = (_a, _b) => {
    return _a.id - _b.id;
};
const teams = new SortedArray();
teams.comparator = (_a, _b) => {
    return _a.id - _b.id;
};
const multiTeams = new SortedArray();
multiTeams.comparator = (_a, _b) => {
    return _a.id - _b.id;
};
const groups = new SortedArray();
groups.comparator = (_a, _b) => {
    return _a.id - _b.id;
};
const reducer = (_state = { employees: [], roles: [], teams: [], groups: [], multiTeams: [] }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addEmployees:
            _action.employees.forEach(_a => employees.add(_a));
            newState.employees = employees.toArray();
            break;
        case types.replaceEmployees:
            newState.employees = _action.employees;
            break;
        case types.deleteEmployee:
            employees.delById(_action.employee);
            newState.employees = employees.toArray();
            break;
        case types.deleteEmployees:
            _action.employees.forEach(_a => employees.delById(_a));
            newState.employees = employees.toArray();
            break;
        case types.addRoles:
            _action.roles.forEach(_a => roles.add(_a));
            newState.roles = roles.toArray();
            break;
        case types.addTeams:
            _action.teams.forEach(_a => teams.add(_a));
            newState.teams = teams.toArray();
            break;
        case types.addMultiTeams:
            _action.multiTeams.forEach(_a => multiTeams.add(_a));
            newState.multiTeams = multiTeams.toArray();
            break;
        case types.replaceMultiTeams:
            newState.multiTeams = _action.multiTeams;
            break;
        case types.deleteTeam:
            teams.delById(_action.team);
            newState.teams = teams.toArray();
            break;
        case types.addGroups:
            _action.groups.forEach(_a => groups.add(_a));
            newState.groups = groups.toArray();
            break;
        case types.deleteGroup:
            groups.delById(_action.group);
            newState.groups = groups.toArray();
            break;
    }

    return newState;
}

export default reducer;