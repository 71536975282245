import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthService from 'authService';
import ApiConnector from 'apiConnector';

class SessionManager extends Component {
    displayName = SessionManager.name;

    constructor(_props) {
        super(_props);
        AuthService.AddAccessTokenChangedListener(_t => {
            if (_t !== ApiConnector.accessToken) {
                ApiConnector.accessToken = _t;
            }
        });
    }

    updateAccessToken() {
        //TODO: We should validate the token with the API server and force a login redirect if the server says the token is invalid
        if (AuthService.accessToken && (!ApiConnector.accessToken || AuthService.accessToken !== ApiConnector.accessToken)) {
            ApiConnector.accessToken = AuthService.accessToken;
        } else if (AuthService.isAuthenticated) {
            AuthService.RenewSession((_token) => ApiConnector.accessToken = _token);
        }
    }

    componentWillMount() {
        if (!AuthService.isAuthenticated) {
            AuthService.HandleAuthentication(this.props.history, this.updateAccessToken);
        }
        this.updateAccessToken();
        ApiConnector.connexionErrorListener = (_error, _request) => {
            if (AuthService.accessToken && AuthService.accessToken !== _request.token) {
                //If multiple requests fail, the token of the connecter might be fixed in-between the fail-chain
                //so there might not be any need to rewrite the token on every fail
                if (AuthService.accessToken !== ApiConnector.accessToken) {
                    ApiConnector.accessToken = AuthService.accessToken;
                }
                ApiConnector.RetryRequest(_request);
            } else {
                AuthService.Logout(window.location.origin)
            }
        };
    }


    componentDidUpdate() {
        this.updateAccessToken();
    }

    render() {
        if (AuthService.isAuthenticated) {
            return (<>
                {this.props.children}
            </>);
        } else {
            AuthService.RenewSession(() => {
                this.forceUpdate();
            }, AuthService.Login, true);
            return (<div>
                handling session
            </div>);
        }
    }
}

export default connect()(SessionManager);
