export const themeHelper = {
    themes: {
        blue: 'blue',
        red: 'red',
        default: 'black'
    },
    themeFor(_key) {
        switch (_key) {
            case 'targetSales':
            case 'agreementsAccepted':
                return this.themes.blue;
            case 'requiredSales':
            case 'currentSales':
                return this.themes.red;
            default:
                return this.themes.default;
        }
    },
    canonicalOrder: {
        0: 'targetSales',
        1: 'currentSales',
        2: 'requiredSales',
        3: 'ongoingContracts',
        4: 'agreementsReceived',
        5: 'agreementsAccepted',
        6: 'ownerCommunications',
        7: 'calls'
    }
};