import UserProfile from 'helpers/userProfile';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { importFilesAction } from 'reducers/priorityActionsReducers';
import ImportAllData from './importAllData';

function ImportAll({ importFilesAction: _importFilesAction, reinsData: _reinsData }) {

    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const [reinsDatas, setReinsDatas] = useState(!_reinsData ? [] : _reinsData);
    const [currentMode, setCurrentMode] = useState(0);

    useEffect(() => {
        if (_reinsData) {
            setReinsDatas(_reinsData);
        }
    }, [_reinsData]);

    const importModes = {
        afterDM: 'afterDM',
        ec: 'ec',
        csvImport3: 'csvImport3',
        csvImport4: 'csvImport4',
        callImport: 'callImport',
        callImport2: 'callImport2',
        reinsCollation: 'reinsCollation',
        jonImportProperty: 'jonImportProperty',
        jonImportOwner: 'jonImportOwner',
        csvImport: 'csvImport'
    };

    const onSucessCallback = (importType) => {
        if (importModes.reinsCollation === importType)
            setCurrentMode(1);
    }

    const generateUploadPopup = (_presetRequest, _o) => {
        _importFilesAction(importModes[_presetRequest], t(importModes[_presetRequest]), onSucessCallback.bind(null, importModes[_presetRequest]), null);
    }

    const generateButtonBanner = () => {
        return <>
            <div className="button-container">
                <div className="btn-group">
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.afterDM)} className={"btn btn-secondary"}>{t('afterDM')}</button>
                    {UserProfile.getPost() === "6" && <button type="button" onClick={generateUploadPopup.bind(null, importModes.ec)} className={"btn btn-secondary"}>{t('ec')}</button>}
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.reinsCollation)} className={"btn btn-secondary"}>{t('reinsCollation')}</button>
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.jonImportProperty)} className={"btn btn-secondary"}>{t('jonImportProperty')}</button>
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.jonImportOwner)} className={"btn btn-secondary"}>{t('jonImportOwner')}</button>
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.csvImport)} className={"btn btn-secondary"}>{t('csvImport')}</button>
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.csvImport3)} className={"btn btn-secondary"}>{t('csvImport3')}</button>
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.csvImport4)} className={"btn btn-secondary"}>{t('csvImport4')}</button>
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.callImport)} className={"btn btn-secondary"}>{t('callImport')}</button>
                    <button type="button" onClick={generateUploadPopup.bind(null, importModes.callImport2)} className={"btn btn-secondary"}>{t('callImport2')}</button>
                </div>
            </div>
        </>;
    }

    const generateReinsPage = () => {
        return <>
            {console.log(reinsDatas)}
            <ul className="nav nav-pills relative">
                <button type="button" className="btn btn-primary" onClick={() => { setCurrentMode(0); }}>{t('goBack')}</button>
            </ul>
            <div className="tab-content">
                {<ImportAllData data={reinsDatas} />}
                />
            </div>
        </>;
    }

    return <LoadingOverlay
        active={isActive}
        spinner
        text={t('inProcessLoading')}
    >
        <article className="content callmode-page">
            <section className="section">
                <Row className="sameheight-container ">
                    <Col md={12}>
                        <div className="card card-block sameheight-item">
                            {currentMode === 0 && generateButtonBanner()}
                            {currentMode === 1 && generateReinsPage()}
                        </div>
                    </Col>
                </Row>
            </section>
        </article>
    </LoadingOverlay>
}

const mapStateToProps = (_state) => {
    return {
        reinsData: _state.importAll.reinsData
    }
}

const mapDispatchToProps = {
    importFilesAction: importFilesAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportAll);