import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { formatDateShort, formatCurrency } from 'helpers/formatHelpers'
import { imagesImportAction } from 'reducers/priorityActionsReducers';
import { requestBatchAssetContent } from 'reducers/imageImportReducer';
import { requestUpdate } from 'reducers/callmodeSearchReducers';
import { requestUpdateImage } from 'reducers/partnersReducers';
import ModuleRow, * as mr from './moduleRow';
import PropertyNamePopup from './propertyPopups/propertyNamePopup';
import RoomPopup from './propertyPopups/roomPopup';
import PropertyAddressPopup from './propertyPopups/propertyAddressPopup';
import BuildingAgePopup from './propertyPopups/buildingAgePopup';
import StationPopup from './propertyPopups/stationPopup';
import SurfacePopup from './propertyPopups/surfacePopup';
import RentManagementPopup from './propertyPopups/rentManagementPopup';
import BuildingManagementPopup from './propertyPopups/buildingManagementPopup';
import RentPopup from './propertyPopups/rentPopup';
import AdministrativeAndReservesPopup from './propertyPopups/administrativeAndReservesPopup';
import PurchasePopup from './propertyPopups/purchasePopup';
import ConflictsWithOthersPopup from './propertyPopups/conflictsWithOthersPopup';
import PropertyRemarksPopup from './propertyPopups/propertyRemarksPopup';
import RoomRemarksPopup from './propertyPopups/roomRemarksPopup';
import SoldPopup from './propertyPopups/soldPopup';
import LandRightsPopup from './propertyPopups/landRightsPopup';
import ConstructionPopup from './propertyPopups/constructionPopup';
import SellingCompanyPopup from './propertyPopups/sellingCompanyPopup';
import CityTaxPopup from './propertyPopups/cityTaxPopup';
import AccountSettlementPopup from './propertyPopups/accountSettlementPopup';
import MediationAmountPopup from './propertyPopups/mediationAmountPopup';

export class PropertyModule extends Component {

    constructor(_props) {
        super(_props);
        const reduceBooleanObject = (_v) => mr.reduceBooleanObject(_v, this.props.t);
        const generateOnActiveHandler = (_window) => this.props.onActive ? () => {
            this.props.onActive(_window);
        } : () => { }

        const popupGenerator = (Component) => mr.popupGenerator(Component, this.props.onChange, this.props.onIdle);
        const mapper = _v => ({ ..._v, label: this.props.t(_v.label || _v.key), onActive: this.popups[_v.key + 'Popup'] ? generateOnActiveHandler(this.popups[_v.key + 'Popup']) : undefined });

        this.moduleRowPropGenerator = (_rowData, _props) => ({
            ..._rowData,
            content: _rowData.content ? _rowData.content(_props[_rowData.key] || {}, _props) : [(_props[_rowData.key] || {})[_rowData.key]]
        });

        this.registrationInformationHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.registrationInformation ? this.props.imageRecords.registrationInformation.images : [{}, {}],
                (_received) => {
                    if (this.props.imageImportType === 'callmodePage')
                        this.props.requestUpdate(_received, "registrationInformation", this.props.data.bukkenId);
                    else if (this.props.imageImportType === 'partnersPage')
                        this.props.requestUpdateImage(_received, "registrationInformation", this.props.data.bukkenId);
                }
            );
        };

        this.rentEviHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.rentEvi ? this.props.imageRecords.rentEvi.images : [{}, {}, {}, {}],
                (_received) => {
                    this.props.requestUpdate(_received, "rentEvi", this.props.data.bukkenId);
                }
            );
        };

        this.administrativeAndReservesHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.administrativeAndReserves ? this.props.imageRecords.administrativeAndReserves.images : [{}, {}, {}, {}],
                (_received) => {
                    this.props.requestUpdate(_received, "administrativeAndReserves", this.props.data.bukkenId);
                }
            );
        };

        this.pamphletHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.pamphlet ? this.props.imageRecords.pamphlet.images : [{}, {}],
                (_received) => {
                    this.props.requestUpdate(_received, "pamphlet", this.props.data.bukkenId);
                }
            );
        };

        this.cityTaxEviHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.cityTaxEvi ? this.props.imageRecords.cityTaxEvi.images : [{}, {}],
                (_received) => {
                    this.props.requestUpdate(_received, "cityTaxEvi", this.props.data.bukkenId);
                }
            );
        };

        this.returnIntermediaryHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.returnIntermediary ? this.props.imageRecords.returnIntermediary.images : [{}, {}, {}, {}],
                (_received) => {
                    this.props.requestUpdate(_received, "returnIntermediary", this.props.data.bukkenId);
                }
            );
        };

        this.imageHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.image ? this.props.imageRecords.image.images : [{}, {}, {}, {}],
                (_received) => {
                    this.props.requestUpdate(_received, "image", this.props.data.bukkenId);
                }
            );
        };

        this.popups = {
            propertyNamePopup: popupGenerator(PropertyNamePopup),
            roomPopup: popupGenerator(RoomPopup),
            propertyAddressPopup: popupGenerator(PropertyAddressPopup),
            buildingAgePopup: popupGenerator(BuildingAgePopup),
            stationPopup: popupGenerator(StationPopup),
            surfacePopup: popupGenerator(SurfacePopup),
            rentManagementPopup: popupGenerator(RentManagementPopup),
            buildingManagementPopup: popupGenerator(BuildingManagementPopup),
            rentPopup: popupGenerator(RentPopup),
            administrativeAndReservesPopup: popupGenerator(AdministrativeAndReservesPopup),
            purchasePopup: popupGenerator(PurchasePopup),
            conflictsWithOthersPopup: popupGenerator(ConflictsWithOthersPopup),
            propertyRemarksPopup: popupGenerator(PropertyRemarksPopup),
            roomRemarksPopup: popupGenerator(RoomRemarksPopup),
            soldPopup: popupGenerator(SoldPopup),
            landRightsPopup: popupGenerator(LandRightsPopup),
            constructionPopup: popupGenerator(ConstructionPopup),
            sellingCompanyPopup: popupGenerator(SellingCompanyPopup),
            cityTaxPopup: popupGenerator(CityTaxPopup),
            accountSettlementPopup: popupGenerator(AccountSettlementPopup),
            mediationAmountPopup: popupGenerator(MediationAmountPopup)
        };

        this.section1Rows = [
            { key: 'propertyName', content: (_d) => [_d.propertyName] },
            { key: 'room', label: 'roomNumber', content: (_d) => [[_d.roomNumber != null ? mr.inlineSegmentDecorator(`${this.props.t('roomNumber')}: ${_d.roomNumber}`) : undefined, _d.realResidence ? mr.inlineSegmentDecorator(this.props.t('realResidence')) : undefined, _d.apartmentFloor != null ? mr.inlineSegmentDecorator(`${_d.apartmentFloor} ${this.props.t('apartmentFloor')}`) : undefined]] },
            { key: 'propertyAddress', content: (_d) => [_d.location ? _d.location : undefined, _d.locationNumber ? _d.locationNumber : undefined] }
        ].map(mapper);

        this.section2Rows = [
            //{ key: 'lotNumber' },
            { key: 'buildingAge', content: (_d) => [_d.year != null ? _d.year + (_d.month ? `/${_d.month}` : '') : undefined] },
            {
                key: 'station', label: 'nearestStation', content: (_d) => [1, 2, 3].map(_k => [{ field: `lineName`, decorator: _v => _v },
                { field: `stationName`, decorator: _v => _v },
                { field: `walkingTime`, decorator: _v => `${this.props.t('walkingTime')}${_v}${this.props.t('minutes')}` },
                { field: `busTime`, decorator: _v => `${this.props.t('bus')}${_v}${this.props.t('minutes')}` }]
                    .reduce((_a, _b) => _a.concat(_d[_b.field + _k] ? mr.inlineSegmentDecorator(_b.decorator(_d[_b.field + _k])) : undefined), []))
            },
            //{ key: 'walkingDistanceToStation' },
            {
                key: 'surface', label: 'floorSurfaceSize', content: (_d) => {
                    let selectedSurfaceType = reduceBooleanObject(_d.floorSurfaceType);
                    if (selectedSurfaceType && selectedSurfaceType.length > 0) selectedSurfaceType = mr.inlineSegmentDecorator(<>(&nbsp;{selectedSurfaceType})</>);
                    return [[
                        _d.floorSurface != null ? <>{_d.floorSurface}m<span className="superscript">2</span></> : undefined,
                        selectedSurfaceType,
                        _d.floorPlan,
                        reduceBooleanObject(_d.floorPlanType),
                        _d.balcony != null ? <>{_d.balcony}m<span className="superscript">2</span></> : undefined
                    ]];
                }
            },
            { key: 'rentManagement', content: (_d) => [_d.companyName] },
            { key: 'buildingManagement', content: (_d) => [_d.notes] },
            {
                key: 'rent', content: (_d) => {
                    const output = [];
                    const availableModes = ['unspecified', 'agency', 'warranty', 'voluntaryManagement', 'inUse', 'vacant'];
                    const mode = _d.rentTypes ? Object.keys(_d.rentTypes).findIndex(_k => _d.rentTypes[_k]) : -1;
                    switch (mode) {
                        case -1:
                        case 0:
                            output.push(_d.rent != null ? `${_d.rent}${this.props.t('en')}` : undefined);
                            break;
                        case 1:
                        case 2:
                        case 3:
                        case 5:
                            const line1 = [];
                            const line2 = [];
                            const period = _d.period != null ? new Date(_d.period) : undefined;
                            if (mode !== 2) {
                                line1.push(_d.rent != null ? `${_d.rent}${this.props.t('en')}` : undefined);
                                line1.push(this.props.t(availableModes[mode]));
                                if (mode === 5) {
                                    line1[0] = _d.advertisedRent != null ? `${_d.advertisedRent}${this.props.t('en')}` : undefined;
                                    line1.push(_d.lastRent != null ? `${this.props.t('priorRent')}: ${_d.lastRent}${this.props.t('en')}` : undefined);
                                }
                            } else {
                                line1.push(`${this.props.t(availableModes[mode])}:`);
                                line1.push(_d.rent != null ? `${_d.rent}${this.props.t('en')}` : undefined);
                                line1.push(_d.priorRent != null ? `${this.props.t('priorRent')}: ${_d.priorRent}${this.props.t('en')}` : undefined);
                                line1.push(_d.estimatedRent != null ? `${this.props.t('estimatedRent')}: ${_d.estimatedRent}${this.props.t('en')}` : undefined);
                                line1.push(_d.firstVacantRoom ? this.props.t('firstVacantRoom') : undefined);
                            }
                            line1.push(period ? `${period.getFullYear()}/${('0' + (period.getMonth() + 1)).slice(-2)}${this.props.t(mode !== 5 ? 'until' : 'startingFrom')}` : undefined);
                            line1.push(_d.withRentalContract ? this.props.t('withRentalContract') : undefined);

                            const toggleGroups = reduceBooleanObject(_d.tenantGenders).concat(reduceBooleanObject(_d.tenantCategory));
                            if (mode !== 5) {
                                line2.push(toggleGroups.length > 0 ? toggleGroups : undefined);
                                line2.push(_d.age ? mr.inlineSegmentDecorator(`${_d.age}${this.props.t('-yearsOld')}`) : undefined);
                            }
                            line2.push(_d.withNonpayment ? this.props.t('withNonpayment') : undefined);
                            output.push(line1.map(_v => _v != null ? mr.inlineSegmentDecorator(_v) : _v));
                            output.push(line2.some(_v => _v) ? [mr.inlineSegmentDecorator(`${this.props.t('tenant')}:`)].concat(line2) : undefined);
                            break;
                        case 4:
                            output.push([this.props.t(availableModes[mode])]);
                            break;
                        default:
                            break;

                    }

                    const relinquishValue = reduceBooleanObject(_d.relinquishCategory);

                    output.push(relinquishValue.length > 0 ? [mr.inlineSegmentDecorator(`${this.props.t('relinquish')}:`)].concat(relinquishValue) : undefined);
                    return output;
                }
            }
        ].map(mapper);

        this.administrativeAndReserves = mapper({
            key: 'administrativeAndReserves', content: (_d) => {
                const output = [];
                const line1 = [];
                if (_d.administrativeExpenses) {
                    line1.push(`${_d.administrativeExpenses}${this.props.t('en')}`);
                }
                if (_d.repairDeposit) {
                    line1.push(`${_d.repairDeposit}${this.props.t('en')}`);
                }
                if (line1.length > 1) {
                    line1.splice(1, 0, '/');
                }
                if (_d.isOver) {
                    line1.push(mr.inlineSegmentDecorator(`${this.props.t('over')}`));
                }
                output.push(line1);

                if (_d.otherFees) {
                    output.push(`${this.props.t('otherExpenses')}: ${_d.otherFees}`);
                }

                return output;
            }
        });

        this.section3Rows = [
            {
                key: 'purchase', content: (_d) => [[reduceBooleanObject(_d.handoffCategory), mr.inlineSegmentDecorator(formatDateShort(_d.phase)), _d.sum ? `${_d.sum}${this.props.t('10kYen')}` : undefined]]
            },
            {
                key: 'conflictsWithOthers', content: (_d) => {
                    const output = [];
                    for (let i = 0; i <= 1; i++) {
                        const line = [];
                        if (_d['companyName' + (i + 1)]) {
                            line.push(mr.inlineSegmentDecorator(_d['companyName' + (i + 1)]));
                        }
                        if (_d['personInCharge' + (i + 1)]) {
                            line.push(mr.inlineSegmentDecorator(_d['personInCharge' + (i + 1)]));
                        }
                        if (_d['agentCharge' + (i + 1)]) {
                            line.push(mr.inlineSegmentDecorator(_d['agentCharge' + (i + 1)] + this.props.t('10kYen')));
                        }
                        output.push(line);
                        const companyType = reduceBooleanObject(_d['companyType' + (i + 1)]);
                        if (companyType.length > 0) {
                            output.push(companyType);
                        }
                        const period = formatDateShort(_d['period' + (i + 1)], true);
                        if (period) {
                            output[output.length - 1].push(mr.inlineSegmentDecorator(`${period}${this.props.t('until')}`));
                        }
                    }
                    return output;
                }
            },
            { key: 'propertyRemarks', content: (_d) => [_d.remarks] }
        ].map(mapper);

        this.section4Rows = [
            { key: 'roomRemarks', content: (_d) => (_d.remarks || '').split('\n').reduce((_a, _b) => _a.concat([[_b]]), []) },
            { key: 'application' },
            {
                key: 'sold', content: (_d) => {
                    if (_d.sold) {
                        return [[mr.inlineSegmentDecorator(<i className="fa fa-circle-thin" aria-hidden="true"></i>), reduceBooleanObject(_d.soldByTypes), _d.soldAmount ? _d.soldAmount + this.props.t('10kYen') : undefined]]
                    }
                }
            },
            { key: 'landRights', content: (_d) => reduceBooleanObject(_d.landRightsTypes) },
            {
                key: 'construction', content: (_d) => {
                    const constructionTypes = { S: 'steelConstruction', RC: 'reinforcedConcreteConstruction', SRC: 'steelFramedReinforcedConcreteConstruction' };
                    const selectedType = Object.keys(_d.constructionTypes || {}).find(_k => _d.constructionTypes[_k]);
                    return [[selectedType ? mr.inlineSegmentDecorator(this.props.t(constructionTypes[selectedType])) : undefined, _d.floors != null ? mr.inlineSegmentDecorator(`${_d.floors}${this.props.t('floors')}`) : undefined, _d.units != null ? mr.inlineSegmentDecorator(`${_d.units}${this.props.t('-houses')}`) : undefined]];
                }
            },
            { key: 'sellingCompany' },
            {
                key: 'cityTax', content: (_d) => [_d.cityTax != null ? `${formatCurrency(_d.cityTax)}${this.props.t('en')}/${this.props.t('year')}` : undefined]
            }
        ].map(mapper);

        this.section5Rows = [
            { key: 'nominee' },
            { key: 'accountSettlement', content: (_d) => [_d.accountSettlement != null ? `${formatCurrency(_d.accountSettlement)}${this.props.t('10kYen')}` : undefined] },
            { key: 'mediationAmount', content: (_d) => [[_d.mediationAmount != null ? `${formatCurrency(_d.mediationAmount)}${this.props.t('10kYen')}` : undefined, reduceBooleanObject(_d.mediationIntermediaryTypes)]] }
        ].map(mapper);
    }

    render() {
        return (<div className={`property-module module-container ${this.props.extraClasses}`}>
            {this.section1Rows.map(_r => {
                return <ModuleRow {...this.moduleRowPropGenerator(_r, this.props.data)} />;
            })}
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.registrationInformationHandler}>{this.props.t('registrationInformation')}</button>
            </div>
            {this.section2Rows.map(_r => {
                return <ModuleRow {..._r} content={_r.content ? _r.content(this.props.data[_r.key] || {}, this.props.data) : [(this.props.data[_r.key] || {})[_r.key]]} />;
            })}
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.rentEviHandler}>{this.props.t('rentEvi')}</button>
            </div>
            <ModuleRow {...this.administrativeAndReserves} content={this.administrativeAndReserves.content(this.props.data[this.administrativeAndReserves.key] || {}, this.props.data)} />
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.rentEviHandler}>{`${this.props.t('administrativeAndReserves')}Evi`}</button>
            </div>
            {this.section3Rows.map(_r => {
                return <ModuleRow {..._r} content={_r.content ? _r.content(this.props.data[_r.key] || {}, this.props.data) : [(this.props.data[_r.key] || {})[_r.key]]} />;
            })}
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.pamphletHandler}>{`${this.props.t('pamphlet')}`}</button>
            </div>
            {this.section4Rows.map(_r => {
                return <ModuleRow {..._r} content={_r.content ? _r.content(this.props.data[_r.key] || {}, this.props.data) : [(this.props.data[_r.key] || {})[_r.key]]} />;
            })}
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.cityTaxEviHandler}>{`${this.props.t('cityTax')}Evi`}</button>
            </div>
            {this.section5Rows.map(_r => {
                return <ModuleRow {..._r} content={_r.content ? _r.content(this.props.data[_r.key] || {}, this.props.data) : [(this.props.data[_r.key] || {})[_r.key]]} />;
            })}
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.returnIntermediaryHandler}>{`${this.props.t('returnIntermediary')}`}</button>
            </div>
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.imageHandler}>{`${this.props.t('image')}`}</button>
            </div>
        </div>);
    }
}
PropertyModule.defaultProps = {
    extraClasses: '',
    data: {}
}

const mapDispatchToProps = {
    imagesImportAction: imagesImportAction,
    requestBatchAssetContent: requestBatchAssetContent,
    requestUpdate: requestUpdate,
    requestUpdateImage: requestUpdateImage
}

export default connect(null, mapDispatchToProps)(withTranslation()(PropertyModule));