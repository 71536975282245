import { SortedArray } from 'helpers/listMaintenanceHelper';

const types = {
    requestOwners: 'DM_REQUEST_OWNERS',
    requestStaffs: 'DM_REQUEST_STAFFS',
    addOwners: 'DM_ADD_OWNERS',
    addStaffs: 'DM_ADD_STAFFS',
    deleteOwner: 'DM_DELETE_OWNER',
    postDeleteOwner: 'DM_POST_DELETE_OWNER',
    clearActiveRequest: 'DM_CLEAR_ACTIVE_REQUEST'
};



/** Actions **/
export const addOwners = (data, addWhole) => ({
    type: types.addOwners,
    data,
    addWhole
});

export const addStaffs = (data, addWhole) => ({
    type: types.addStaffs,
    data,
    addWhole
});

export const postDeleteOwner = id => ({
    type: types.postDeleteOwner,
    id
});

export const clearActiveRequest = () => ({
    type: types.clearActiveRequest
});


/** Async Actions **/
export const requestOwners = (data, successCallback, failureCallback) => ({
    type: types.requestOwners,
    data,
    successCallback,
    failureCallback
});

export const requestStaffs = () => ({
    type: types.requestStaffs
});

export const deleteOwner = (id, successCallback, failureCallback) => ({
    type: types.deleteOwner,
    id,
    successCallback,
    failureCallback
});

const owners = new SortedArray();
owners.comparator = (_a, _b) => _a.id - _b.id;

const staffs = new SortedArray();
staffs.comparator = (_a, _b) => _a.id - _b.id;

/** Reducer **/
const reducer = (_state = { owners: { records: [], pageDetails: { currentPage: 0, pageSize: 0, totalCount: 0, currentCount: 0, totalPages: 0, totalIds: [] } }, staffs: [] }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addOwners:
            if (_action.addWhole === true) {
                if (Array.isArray(_action.data.items)) {

                    owners.addWhole(_action.data.items);
                    newState.owners.records = owners.toArray();

                    newState.owners.pageDetails.currentPage = _action.data.currentPage;
                    newState.owners.pageDetails.pageSize = _action.data.pageSize;
                    newState.owners.pageDetails.totalCount = _action.data.totalCount;
                    newState.owners.pageDetails.currentCount = _action.data.currentCount;
                    newState.owners.pageDetails.totalPages = _action.data.totalPages;
                    newState.owners.pageDetails.totalIds = _action.data.totalIds;
                }
            }
            else {
                _action.data.forEach(_v => owners.addById(_v));
                newState.owners = owners.toArray();
            }
            break;

        case types.addStaffs:
            if (_action.addWhole === true) {
                newState.staffs = _action.data;
            }
            else {
                _action.data.forEach(_v => staffs.addById(_v));
                newState.staffs = staffs.toArray();
            }
            break;

        case types.postDeleteOwner:
            owners.delById(_action.id);
            newState.owners = owners.toArray();
            break;

        case types.clearActiveRequest:
            newState.owners = [];
            newState.staffs = [];
            break;
        default:
            break;
    }

    return newState;
}
export default reducer;