import React from 'react';
import CellComponent from "./CellComponent";
import { formatDateTimeShort } from 'helpers/formatHelpers';

export default function RowComponent({ rowName: _rowName, content: _content, UserId: _UserId, isVisible: _isVisible  }) {
        
    return (
        <div className={_rowName} hidden={(_isVisible)} >
            {_content.map(_v => <CellComponent {..._v}  />)}
        </div>

       
    )

}