import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';
import { validatorDouble } from 'helpers/validatorHelpers';
import { SimpleToggle } from 'components/inputs/toggles';

export function Buyer({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;
    return <>
        <wf.GroupField>
            <SimpleToggle label={t('buyer')} value={data.buyer.value || data.buyer.defaultValue || false} onChange={setData('buyer')} />
        </wf.GroupField>
        <wf.InputField
            value={data.desiredArea.value || data.desiredArea.defaultValue}
            label={t('desiredArea')}
            className="form-group"
            onChange={setData('desiredArea')} />
        <wf.InputField
            value={data.desiredYield.value || data.desiredYield.defaultValue}
            label={t('desiredYield')}
            className="form-group"
            validator={(_v) => validatorDouble(_v, 0)}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('desiredYield')}
            onError={setData('desiredYield')}
            addon="%" />
        <wf.InputField
            value={data.selfFunding.value || data.selfFunding.defaultValue}
            label={t('selfFunding')}
            className="form-group"
            validator={(_v) => validatorDouble(_v, 0)}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('selfFunding')}
            onError={setData('selfFunding')}
            addon={t('10k')} />
        <wf.GroupField>
            <SimpleToggle label={t('usingLoan')} value={data.usingLoan.value || data.usingLoan.defaultValue || false} onChange={setData('usingLoan')} />
        </wf.GroupField>
    </>
}
export const BuyerPopup = ExportDefaultBehaviour(Buyer, {
    editableFields: ['buyer', 'desiredArea', 'desiredYield', 'selfFunding', 'usingLoan']
});
BuyerPopup.whyDidYouRender = true;