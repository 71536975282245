import { SortedArray } from 'helpers/functionalHelpers';

const types = {
    addRecords: 'CS_ADD_RECORDS',
    updateRecords: 'CS_UPDATE_RECORDS',
    clearActiveRequest: 'CS_CLEAR_ACTIVE_REQUEST',
    setLastCallReference: 'CS_SET_LAST_CALL_REFERENCE',
    addEmployees: 'CS_ADD_EMPLOYEES',
    addPropertList: 'CS_ADD_PROPERTY_LIST',
    addHistoryRecords: 'CS_ADD_HISTORY_RECORDS',
    requestRecords: 'CS_REQUEST_RECORDS',
    requestRecordUpdate: 'CS_REQUEST_RECORD_UPDATE',
    requestRecordsMetadata: 'CS_REQUEST_RECORDS_METADATA',
    requestCall: 'CS_REQUEST_CALL',
    requestHangUp: 'CS_REQUEST_HANG_UP',
    requestEmployees: 'CS_REQUEST_EMPLOYEES',
    requestDeleteHistoryData: 'CS_REQUEST_DELETE_HISTORY_DATA',
    requestUpdateHistoryData: 'CS_REQUEST_UPDATE_HISTORY_DATA',
    requestUnlockPropertyListData: 'CS_REQUEST_UNLOCK_PROPERTY_LIST_DATA',
    requestPropertyListData: 'CS_REQUEST_PROPERTY_LIST_DATA',
    requestInsertHistoryData: 'CS_REQUEST_INSERT_HISTORY_DATA',
    requestInsertReCallData: 'CS_REQUEST_INSERT_RE_CALL_DATA',
    requestUpdate: 'CS_REQUEST_UPDATE',
    setAssets: 'CS_SET_ASSETS'
};

/** Actions **/
export const addRecords = (data, addWhole, history, propertyModule) => ({
    type: types.addRecords,
    data,
    addWhole,
    history,
    propertyModule
});

export const clearActiveRequest = () => ({
    type: types.clearActiveRequest
});

export const setLastCallReference = data => ({
    type: types.setLastCallReference,
    data
});

export const addEmployees = data => ({
    type: types.addEmployees,
    data
});

export const addPropertList = (data) => ({
    type: types.addPropertList,
    data
});

export const setAssets = data => ({
    type: types.setAssets,
    data
});

export const updateRecords = data => ({
    type: types.updateRecords,
    data
});

/** Async Actions **/
export const requestRecords = (data, successCallback, failureCallback) => ({
    type: types.requestRecords,
    data,
    successCallback,
    failureCallback
});

export const requestRecordUpdate = (data, successCallback, failureCallback) => ({
    type: types.requestRecordUpdate,
    data,
    successCallback,
    failureCallback
});

export const requestEmployees = () => ({
    type: types.requestEmployees
});

export const requestCall = (data, o) => ({
    type: types.requestCall,
    data
});

export const requestHangUp = data => ({
    type: types.requestHangUp,
    data
});

export const requestDeleteHistoryData = (id, ids) => ({
    type: types.requestDeleteHistoryData,
    id,
    ids
});

export const requestUpdateHistoryData = data => ({
    type: types.requestUpdateHistoryData,
    data
});

export const requestUnlockPropertyListData = (id, successCallback, failureCallback) => ({
    type: types.requestUnlockPropertyListData,
    id,
    successCallback,
    failureCallback
});

export const requestPropertyListData = (data, successCallback, failureCallback) => ({
    type: types.requestPropertyListData,
    data,
    successCallback,
    failureCallback
});

export const requestInsertHistoryData = (data) => ({
    type: types.requestInsertHistoryData,
    data
})

export const requestInsertReCallData = (data) => ({
    type: types.requestInsertReCallData,
    data
})

export const requestUpdate = (data, fileName, bukkenId) => ({
    type: types.requestUpdate,
    data,
    fileName,
    bukkenId
});

const activeServerRecords = new SortedArray();
activeServerRecords.comparator = (_a, _b) => _a.id - _b.id;

const propertList = new SortedArray();
propertList.comparator = (_a, _b) => _a.id - _b.id;

/** Reducer **/
const reducer = (_state = { employees: [], propertList: {}, activeServerRecords: { records: [], pageDetails: { currentPage: 0, pageSize: 0, totalCount: 0, totalPages: 0 } } }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addRecords:
            if (_action.addWhole === true) {

                if (Array.isArray(_action.data.items)) {
                    activeServerRecords.addWhole(_action.data.items);
                    newState.activeServerRecords.records = activeServerRecords.toArray();

                    newState.activeServerRecords.pageDetails.currentPage = _action.data.currentPage;
                    newState.activeServerRecords.pageDetails.pageSize = _action.data.pageSize;
                    newState.activeServerRecords.pageDetails.totalCount = _action.data.totalCount;
                    newState.activeServerRecords.pageDetails.currentCount = _action.data.currentCount;
                    newState.activeServerRecords.pageDetails.totalPages = _action.data.totalPages;
                }
            }
            else {
                activeServerRecords.addById(_action.data);
                newState.activeServerRecords.records = activeServerRecords.toArray();
            }
            break;
        case types.clearActiveRequest:
            newState.activeServerRecords.records = [];
            newState.activeServerRecords.pageDetails = {};
            newState.propertList = {};
            break;
        case types.addEmployees:
            newState.employees = _action.data;
            break;
        case types.addPropertList:
            newState.propertList = _action.data;
            break;
        case types.setLastCallReference:
            newState.lastCallReference = _action.data;
            break;
        case types.setAssets:
            let temp = newState.propertList;
            if (_action.data.fileName === "registrationInformation") {
                temp.imageRecords.registrationInformation = {
                    ..._action.data
                };
            }
            if (_action.data.fileName === "rentEvi") {
                temp.imageRecords.rentEvi = {
                    ..._action.data
                };
            } if (_action.data.fileName === "administrativeAndReserves") {
                temp.imageRecords.administrativeAndReserves = {
                    ..._action.data
                };
            } if (_action.data.fileName === "pamphlet") {
                temp.imageRecords.pamphlet = {
                    ..._action.data
                };
            } if (_action.data.fileName === "cityTaxEvi") {
                temp.imageRecords.cityTaxEvi = {
                    ..._action.data
                };
            } if (_action.data.fileName === "returnIntermediary") {
                temp.imageRecords.returnIntermediary = {
                    ..._action.data
                };
            } if (_action.data.fileName === "image") {
                temp.imageRecords.image = {
                    ..._action.data
                };
            } if (_action.data.fileName === "imageImport") {
                temp.imageRecords.imageImport = {
                    ..._action.data
                };
            } if (_action.data.fileName === "loanEvi") {
                temp.imageRecords.loanEvi = {
                    ..._action.data
                };
            }
            newState.propertList = temp;
            break;
        case types.updateRecords:

            activeServerRecords.addById(_action.data.tableRecord);
            newState.activeServerRecords.records = activeServerRecords.toArray();

            let temp2 = newState.propertList;
            if (_action.data.bottomRecord.fileName === "registrationInformation") {
                temp2.imageRecords.registrationInformation = {
                    ..._action.data.bottomRecord
                };
            }
            if (_action.data.bottomRecord.fileName === "rentEvi") {
                temp2.imageRecords.rentEvi = {
                    ..._action.data.bottomRecord
                };
            } if (_action.data.bottomRecord.fileName === "administrativeAndReserves") {
                temp2.imageRecords.administrativeAndReserves = {
                    ..._action.data.bottomRecord
                };
            } if (_action.data.bottomRecord.fileName === "pamphlet") {
                temp2.imageRecords.pamphlet = {
                    ..._action.data.bottomRecord
                };
            } if (_action.data.bottomRecord.fileName === "cityTaxEvi") {
                temp2.imageRecords.cityTaxEvi = {
                    ..._action.data.bottomRecord
                };
            } if (_action.data.bottomRecord.fileName === "returnIntermediary") {
                temp2.imageRecords.returnIntermediary = {
                    ..._action.data.bottomRecord
                };
            } if (_action.data.bottomRecord.fileName === "image") {
                temp2.imageRecords.image = {
                    ..._action.data.bottomRecord
                };
            } if (_action.data.bottomRecord.fileName === "imageImport") {
                temp2.imageRecords.imageImport = {
                    ..._action.data.bottomRecord
                };
            } if (_action.data.bottomRecord.fileName === "loanEvi") {
                temp2.imageRecords.loanEvi = {
                    ..._action.data.bottomRecord
                };
            }
            newState.propertList = temp2;
            break;
        default:
            break;
    }

    return newState;
}
export default reducer;