import 'babel-polyfill';
import { eventChannel } from 'redux-saga';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { ApiEntryPoint } from 'apiConnector';
import { addReminder, addReminders, expireReminders, renewReminder, cancelReminder as cancelReminderAction, requestReminders, checkReminders, addReminderProcedure, renewReminderProcedure, cancelReminderProcedure, isExpired } from 'reducers/remindersReducers';
import { displayNewReminderAction } from 'reducers/priorityActionsReducers';

function interval(_duration) {
    return eventChannel(emitter => {
        const id = setInterval(() => {
            emitter(_duration);
            //emitter can be wrapped inside a condition and trigger emitter(END) to cancel interval if needed
        }, _duration);

        return () => {
            clearInterval(id)
        }
    }
    )
}

function* requestRemindersSaga() {
    try {
        const result = yield call(ApiEntryPoint.FetchReminders);
        if (result.reminders && Array.isArray(result.reminders)) {
            yield put(addReminders(result.reminders));
        }
    } catch (_e) {
        console.log('Error from fetchRemindersSaga');
        console.log(_e);
    }
}

function* checkRemindersSaga() {
    try {
        const channel = yield call(interval, 1000);
        yield takeEvery(channel, function* () {
            const currentWorkers = yield select((_state) => _state.reminders.workers);
            const expiredWorkers = [];
            currentWorkers.forEach(_w => {
                if (isExpired(_w)) {
                    expiredWorkers.push(_w);
                }
            });

            if (expiredWorkers.length > 0) {
                yield put(expireReminders(expiredWorkers));
            }
        });

    } catch (_e) {
        console.log('Error from checkRemindersSaga');
        console.log(_e);
    }
}

function* addReminderProcedureSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PushReminder, { recordId: 'recordId', ..._action.reminder });
        yield put(addReminder(result));
        yield put(displayNewReminderAction(result));
    } catch (_e) {
        console.log('Error from addReminderProcedureSaga');
        console.log(_e);
    }
}

function* renewReminderProcedureSaga(_action) {
    try {

        const result = yield call(ApiEntryPoint.UpdateReminder, _action.id, _action.expiryDate);
        yield put(renewReminder(result.id, result.expiryDate));
        yield put(displayNewReminderAction(result));
    } catch (_e) {
        console.log('Error from renewReminderProcedureSaga');
        console.log(_e);
    }
}

function* cancelReminderProcedureSaga(_action) {
    try {

        const result = yield call(ApiEntryPoint.CancelReminder, _action.id);
        yield put(cancelReminderAction(_action.id));
    } catch (_e) {
        console.log('Error from renewReminderProcedureSaga');
        console.log(_e);
    }
}

export function* watchAddReminderProcedureSaga() {
    yield takeEvery(addReminderProcedure().type, addReminderProcedureSaga);
}

export function* watchRenewReminderProcedureSaga() {
    yield takeEvery(renewReminderProcedure({}).type, renewReminderProcedureSaga);
}

export function* watchCancelReminderProcedureSaga() {
    yield takeEvery(cancelReminderProcedure().type, cancelReminderProcedureSaga);
}

export function* watchRequestRemindersSaga() {
    yield takeEvery(requestReminders().type, requestRemindersSaga);
}

export function* watchCheckRemindersSaga() {
    yield takeEvery(checkReminders().type, checkRemindersSaga);
}