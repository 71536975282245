import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { imagesImportAction } from 'reducers/priorityActionsReducers';
import ModuleRow, * as mr from './moduleRow';
import { NamePopup } from './userModulePopups/namePopup';
import { PhonePopup } from './userModulePopups/phonePopup';
import { CellphonePopup } from './userModulePopups/cellphonePopup';
import { FaxPopup } from './userModulePopups/faxPopup';
import { EmailPopup } from './userModulePopups/emailPopup';
import { AddressPopup } from './userModulePopups/addressPopup';
import { GenderPopup } from './userModulePopups/genderPopup';
import { ProfessionPopup } from './userModulePopups/professionPopup';
import { AgePopup } from './userModulePopups/agePopup';
import { BuyerPopup } from './userModulePopups/buyerPopup';
import { CallAvailabilityPopup } from './userModulePopups/callAvailabilityPopup';
import { SaleDatePopup } from './userModulePopups/saleDatePopup';
import { FamilyPrivacyPopup } from './userModulePopups/familyPrivacyPopup';
import { InheritancePopup } from './userModulePopups/inheritancePopup';
import { DebtConsolidationPopup } from './userModulePopups/debtConsolidationPopup';
import { OwnerRemarksPopup } from './userModulePopups/ownerRemarksPopup';
import { DmPopup } from './userModulePopups/dmPopup';
import { formatDateShort } from 'helpers/formatHelpers';
import { requestUpdate } from 'reducers/callmodeSearchReducers';
import { requestUpdateImage } from 'reducers/partnersReducers';

export class UserModule extends Component {

    constructor(_props) {
        super(_props);

        const reduceBooleanObject = (_v) => mr.reduceBooleanObject(_v, this.props.t);

        const generateOnActiveHandler = (_window) => this.props.onActive ? () => {
            this.props.onActive(_window);
        } : () => { }

        const popupGenerator = (Component) => mr.popupGenerator(Component, this.props.onChange, this.props.onIdle);

        this.imageImportHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.imageImport ? this.props.imageRecords.imageImport.images : [{}, {}, {}, {}],
                (_received) => {
                    if (this.props.imageImportType === 'callmodePage')
                        this.props.requestUpdate(_received, "imageImport", this.props.data.bukkenId);
                    else if (this.props.imageImportType === 'partnersPage')
                        this.props.requestUpdateImage(_received, "imageImport", this.props.data.bukkenId);
                }
            );
        };

        this.popups = {
            namePopup: popupGenerator(NamePopup),
            phonePopup: popupGenerator(PhonePopup),
            cellphonePopup: popupGenerator(CellphonePopup),
            faxPopup: popupGenerator(FaxPopup),
            emailPopup: popupGenerator(EmailPopup),
            addressPopup: popupGenerator(AddressPopup),
            genderPopup: popupGenerator(GenderPopup),
            professionPopup: popupGenerator(ProfessionPopup),
            agePopup: popupGenerator(AgePopup),
            buyerPopup: popupGenerator(BuyerPopup),
            callAvailabilityPopup: popupGenerator(CallAvailabilityPopup),
            saleDatePopup: popupGenerator(SaleDatePopup),
            familyPrivacyPopup: popupGenerator(FamilyPrivacyPopup),
            inheritancePopup: popupGenerator(InheritancePopup),
            debtConsolidationPopup: popupGenerator(DebtConsolidationPopup),
            ownerRemarksPopup: popupGenerator(OwnerRemarksPopup),
            dmPopup: popupGenerator(DmPopup)
        };

        this.rows = [
            {
                key: 'name', label: 'fullName', content: (_d) => [_d.name]

            },
            { key: 'phone', label: 'phoneNumber', content: (_d) => [_d.tertiaryPhone, [reduceBooleanObject(_d.phoneTypes), (_d.secondaryPhone ? <>{_d.secondaryPhone || ''}</> : undefined)], reduceBooleanObject(_d.mainPhoneModes), reduceBooleanObject(_d.secondaryPhoneModes)] },
            {
                key: 'workplaceSearchPhoneNumberMain', label: 'workplaceSearch', content: (_d, _a) => {
                    const phoneData = _a.phone || {};
                    return [
                        [<>{phoneData.workplaceSearchPhoneNumberMain && mr.inlineSegmentDecorator(phoneData.workplaceSearchPhoneNumberMain)}</>, <>{phoneData.workplaceSearchPhoneNumberMainDate && formatDateShort(phoneData.workplaceSearchPhoneNumberMainDate)}</>],
                        [<>{phoneData.workplaceSearchPhoneNumberSecondary && mr.inlineSegmentDecorator(phoneData.workplaceSearchPhoneNumberSecondary)}</>, <>{phoneData.workplaceSearchPhoneNumberSecondaryDate && formatDateShort(phoneData.workplaceSearchPhoneNumberSecondaryDate)}</>]
                    ];
                }
            },
            { key: 'externalImport', label: 'externalImport-short', content: (_d, _a) => [(_a.phone || {}).externalImportNumberMain, (_a.phone || {}).externalImportNumberSecondary] },
            { key: 'cellphone', content: (_d) => [_d.cellphoneSecondary, _d.cellphone] },
            { key: 'personalPhone', content: (_d, _a) => [(_a.cellphone || {}).externalDataNumberMain, (_a.cellphone || {}).externalDataNumberSecondary] },
            { key: 'fax', label: 'faxEng', content: (_d) => [[reduceBooleanObject(_d.faxTypes), _d.fax]] },
            { key: 'email', label: 'mailEng' },
            { key: 'address', label: 'streetAddress', content: (_d) => [[reduceBooleanObject(_d.addressTypes), _d.address]] },
            { key: 'gender', content: (_d) => [reduceBooleanObject(_d.gender)] },
            { key: 'profession', content: (_d) => [[reduceBooleanObject(_d.jobTypes), _d.jobNotes]] },
            {
                key: 'age', content: (_d) => {
                    const output = [];
                    if (_d.ageOptions) {
                        const selectedOption = Object.keys(_d.ageOptions).find(_k => _d.ageOptions[_k] && _k !== 'unspecified');
                        if (selectedOption != null && selectedOption !== '') {
                            output.push(`${this.props.t(selectedOption)}${this.props.t('-decade')}`)
                        }
                    }
                    if (_d.age) output.push(`${_d.age}${this.props.t('yearsOld')}`);
                    if (_d.yob) output.push(`${_d.yob}${this.props.t('year')}${_d.mob ? _d.mob + this.props.t('month') : ''}${this.props.t('pointInTime')}`);

                    return output.length > 0 ? [[output[0]].concat(output.slice(1).map(_v => `(${_v})`))] : [];
                }
            },
            {
                key: 'buyer', content: (_d) => [
                    [_d.buyer ? this.props.t('buyer') : undefined],
                    [_d.desiredArea != null ? `${this.props.t('desiredArea')}: ${_d.desiredArea}` : undefined],
                    [_d.desiredYield != null ? `${this.props.t('desiredYield')}: ${_d.desiredYield}%` : undefined],
                    [_d.selfFunding != null ? `${this.props.t('selfFunding')}: ${_d.selfFunding}${this.props.t('10kYen')}` : undefined],
                    [_d.usingLoan ? this.props.t('usingLoan') : undefined]
                ]
            },
            {
                key: 'callAvailability', label: 'TEL' + this.props.t('timeRange'), content: (_d) => {
                    const output = Object.keys(_d).filter(_k => _k.endsWith('ay') && _d[_k]).map(_day => {
                        let range = '';
                        const rangeKey = _day + 'Range';
                        if (_d[rangeKey] && (_d[rangeKey].start || _d[rangeKey].end)) {
                            range = ` ${_d[rangeKey].start ? _d[rangeKey].start + this.props.t('hour') : ''}~${_d[rangeKey].end ? _d[rangeKey].end + this.props.t('hour') : ''}`;
                        }
                        return [`${this.props.t(_day)}${range}`];
                    });
                    if (_d.callAvailabilityNotes && _d.callAvailabilityNotes.length > 0) {
                        output.push(_d.callAvailabilityNotes);
                    }
                    return output;
                }
            },
            {
                key: 'saleDate', content: (_d) => {
                    const output = [].concat(reduceBooleanObject(_d.saleReasonTypes));
                    if (_d.saleReasonNote) output.push(mr.inlineSegmentDecorator(_d.saleReasonNote, 'saleReasonNote'));
                    if (_d.saleDate) {
                        const saleDate = new Date(_d.saleDate);
                        output.push(mr.inlineSegmentDecorator(`${saleDate.getFullYear()}${this.props.t('year')}${saleDate.getMonth() + 1}${this.props.t('-approxTime')}`, 'saleDate'));
                    }
                    return [output];
                }
            },
            { key: 'familyPrivacy', content: (_d) => _d.familyPrivacy ? [this.props.t('private')] : undefined },
            {
                key: 'inheritance', content: (_d) => reduceBooleanObject(_d.inheritanceType).concat(_d.inheritanceNote ? _d.inheritanceNote.split('\n') : [])
            },
            { key: 'debtConsolidation', content: (_d) => [reduceBooleanObject(_d.debtConsolidationTypes).concat(_d.debtConsolidationNote ? _d.debtConsolidationNote : [])] },
            { key: 'lastCheck', noContent: true },
            { key: 'ownerRemarks' },
            { key: 'dm', content: (_d) => [[reduceBooleanObject(_d.dmType), _d.dmProhibited ? this.props.t('dmProhibited') : undefined]] }
        ].map(_v => ({ ..._v, label: this.props.t(_v.label || _v.key), onActive: this.popups[_v.key + 'Popup'] ? generateOnActiveHandler(this.popups[_v.key + 'Popup']) : undefined }));
    }

    render() {
        return (<div className={`user-module module-container ${this.props.extraClasses}`}>
            {this.rows.map(_r => {
                return <ModuleRow {..._r} noContentData={_r.noContent ? this.props.data[_r.key] : null} content={_r.content ? _r.content(this.props.data[_r.key] || {}, this.props.data) : [(this.props.data[_r.key] || {})[_r.key]]} />;
            })}
            <div className="row no-gutters justify-content-end">
                <button type="button" className="btn btn-primary" onClick={this.imageImportHandler}>{this.props.t('personalInformation')}</button>
            </div>
        </div>);
    }
}
UserModule.whyDidYouRender = true;
UserModule.defaultProps = {
    extraClasses: '',
    data: {}
}
const mapDispatchToProps = {
    imagesImportAction: imagesImportAction,
    requestUpdate: requestUpdate,
    requestUpdateImage: requestUpdateImage
}

export default connect(null, mapDispatchToProps)(withTranslation()(UserModule));