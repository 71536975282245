import { catchClause } from '@babel/types';

const uuidv4 = require('uuid/v4');

const ApiConnector = new (function () {
    let apiEntryPoint, accessToken, connexionErrorListener, localAddressPrefix;

    Object.defineProperty(this, 'apiEntryPoint', {
        get() { return apiEntryPoint; },
        set(_v) {
            apiEntryPoint = _v;
            localAddressPrefix = _v.address + '/';
        },
        enumerable: true,
        configurable: true
    });

    Object.defineProperty(this, 'accessToken', {
        get() { return accessToken; },
        set(_v) { accessToken = _v; },
        enumerable: true,
        configurable: true
    });

    Object.defineProperty(this, 'connexionErrorListener', {
        get() { return connexionErrorListener; },
        set(_v) { connexionErrorListener = _v; },
        enumerable: true,
        configurable: true
    });

    const generateRequest = (_data, _success, _error, _method) => {

        const options = {}

        if (_method === 'file') {

            const argsKeys = Object.keys(_data.body).filter(_k => _k !== 'file');

            let form = new FormData();

            form.append('data', _data.body.file);
            argsKeys.forEach(_k => form.append(_k, _data.body[_k]));

            options.method = "POST";
            options.body = form;

        }
        else if ((_method || '').toLowerCase() === 'put') {

            const argsKeys = Object.keys(_data.body).filter(_k => _k !== 'files');
            const fileKeys = Object.keys(_data.body.files);

            let form = new FormData()
            fileKeys.forEach(_k => form.append('data', _data.body.files[_k]));
            argsKeys.forEach(_k => form.append(_k, _data.body[_k]));

            options.method = "POST";
            options.body = form;

        } else {
            options.method = _data.body ? "POST" : "GET";
            options.body = JSON.stringify(_data.body);
            options.headers = new Headers({
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            });
        }

        fetch(_data.url, options)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json()
            })
            .then(_response => {
                if (_success) _success(_response);
            })
            .catch(((_token, _e) => {
                if (_e.message.includes('Unauthorized') && connexionErrorListener) {
                    connexionErrorListener(_e, {
                        data: _data,
                        success: _success,
                        error: _error,
                        method: _method,
                        token: _token
                    });
                } else if (_error) _error(_e);
            }).bind(null, accessToken));
    };

    this.RetryRequest = (_options) => {
        generateRequest(_options.data, _options.success, _options.error, _options.method)
    };

    this.ApiEntryPoint = {
        FetchGlobalStatistics: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.gsGetGlobalStatistics }, _success, _error);
            });
        },
        FetchTeams: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.teamInformation }, _success, _error);
            });
        },
        PushTeamUpdate: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.teamUpdate,
                    body: _data
                }, _success, _error);
            });
        },
        UploadFiles: (_data) => {
            const id = uuidv4();
            const promise = new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.uploadCSV,
                    body: { id: id, files: _data }
                }, _success, _error, 'put');
            });

            return {
                id: id,
                promise: promise
            };
        },
        FetchPartnersEntries: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.partnersInformation }, _success, _error);
            });
        },
        FetchPartnerEntry(_entryId) {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.partnerEntry + "?entryId=" + _entryId }, _success, _error);
            });
        },
        PartnersRequestHansokuRecords: (_boxType, _staffId) => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pRequestHansokuRecords + `?boxType=${_boxType}&staffId=${_staffId}` }, _success, _error);
            });
        },
        PartnersRequestKainushiTantou() {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pRequestKainushiTantou }, _success, _error);
            });
        },
        PartnersUpdateBoxType: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.pUpdateBoxType,
                    body: _data
                }, _success, _error);
            });
        },
        PartnersDeleteHansokuRecords: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.pDeleteHansokuRecords,
                    body: _data
                }, _success, _error);
            });
        },
        PartnersRequestTemplateList(_id) {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pRequestTemplateList + "?id=" + _id }, _success, _error);
            });
        },
        PartnersUpdateTemplateList: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.pUpdateTemplateList,
                    body: _data
                }, _success, _error);
            });
        },
        PartnersRequestStaffList(_id) {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pRequestStaffList + "?id=" + _id }, _success, _error);
            });
        },
        PartnersUpdateHansokuRecords: (_id, _boxType, _staffId, _updateType, _updateValue) => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pUpdateHansokuRecords + `?id=${_id}&boxType=${_boxType}&staffId=${_staffId}&updateType=${_updateType}&updateValue=${_updateValue}` }, _success, _error);
            });
        },
        PartnersRequestHansokuRireki: (_id, _reqType) => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pRequestHansokuRireki + `?id=${_id}&type=${_reqType}` }, _success, _error);
            });
        },
        PartnersRequestHansokuGyousha: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.pRequestHansokuGyousha,
                    body: _data
                }, _success, _error);
            });
        },
        PartnersRequestStaffAndTemplate() {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pRequestStaffAndTemplate }, _success, _error);
            });
        },
        PartnersRequestRoomData(_id) {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.pRequestRoomData + "?id=" + _id }, _success, _error);
            });
        },
        PartnersUpdateRoomData: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.pUpdateRoomData,
                    body: _data
                }, _success, _error);
            });
        },
        FetchReminders: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.getReminders }, _success, _error);
            });
        },
        PushReminder: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.addReminder,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        UpdateReminder: (_id, _expireDate) => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.renewReminder + `?id=${_id}&expiryDate=${_expireDate}` }, _success, _error);
            });
        },
        CancelReminder: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.cancelReminder + `?id=${_id}` }, _success, _error);
            });
        },
        CallModeRequestRecords: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmRequestRecords,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        CallModeRequestRecordsMetadata: (_request) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmRequestRecordsMetadata,
                    body: _request._o
                }, _success, _error);
            });
        },
        CallModeRequestCall: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.cmRequestCall, body: { ..._data } }, _success, _error);
            });
        },
        CallModeRequestHangUp: (_data, _callReference) => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.cmRequestHangUp + (_callReference != null ? `?timeStamp=${_callReference}` : ''), body: { ..._data } }, _success, _error);
            });
        },
        CallModeRequestEmployees: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.cmRequestEmployees }, _success, _error);
            });
        },
        CallModeUpdateRoomData: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmUpdateRoomData,
                    body: _data,
                }, _success, _error);
            });
        },
        CallModeRequestHistoryRecords: () => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmRequestHistoryRecords,
                }, _success, _error);
            });
        },
        CallModeRequestPropertyListDatas: (_ownerId, _roomId, _bukkenId, _loanId) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmRequestPropertyListDatas + `?ownerId=${_ownerId}&roomId=${_roomId}&bukkenId=${_bukkenId}&loanId=${_loanId}`,
                }, _success, _error);
            });
        },
        CallModeDeleteHistoryRecords: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmDeleteHistoryRecords + `?id=${_id}`,
                }, _success, _error);
            });
        },
        CallModeUpdateHistoryRecords: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmUpdateHistoryRecords,
                    body: _data
                }, _success, _error);
            });
        },
        CallModeUnlockPropertyListDatas: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmUnlockPropertyListDatas + `?id=${_id}`,
                }, _success, _error);
            });
        },
        CallModeTelCall: (phoneNumber) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmTelCall + `?callNumberOwner=${phoneNumber}`
                }, _success, _error);
            });
        },
        CallModeUpdateAllResult: (_ownerId) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmUpdateAllSold + `?ownerId=${_ownerId}`
                }, _success, _error);
            });
        },
        CallModeUpdateStaffData: (_ownerId, _data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmCallModeUpdateStaffData,
                    body: { ..._data, ..._ownerId }
                }, _success, _error);
            });
        },
        InsertHistoryDatas: (_data, _ownerId) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmInsertHistoryDatas,
                    body: { ..._data, ..._ownerId }
                }, _success, _error);
            });
        },
        CallModeInsertReCallData: (_ownerId, _staffId, _reCallTime) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cmInsertReCallData + `?ownerId=${_ownerId}&staffId=${_staffId}&reCallTime=${_reCallTime}`,
                }, _success, _error);
            });
        },
        EmployeesManagerRequestEmployees: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emRequestEmployees + `?id=${_id}`,
                }, _success, _error);
            });
        },
        EmployeesManagerRequestRoles: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.emRequestRoles }, _success, _error);
            });
        },
        EmployeesManagerRequestTeams: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.emRequestTeams }, _success, _error);
            });
        },
        EmployeesManagerRequestMultiTeams: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.emRequestMultiTeams }, _success, _error);
            });
        },
        EmployeesManagerRequestGroups: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.emRequestGroups }, _success, _error);
            });
        },
        EmployeesManagerPushEmployee: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emPushEmployee,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerModifyEmployee: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emEditEmployee,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerModifyEmployeeTeams: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emEditEmployeeTeams,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerDeleteEmployee: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emDeleteEmployee,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerModifyTeam: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emEditTeam,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerAddTeam: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emAddTeam,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerDeleteTeam: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emDeleteTeam,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerModifyGroup: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emEditGroup,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerAddGroup: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emAddGroup,
                    body: _data
                }, _success, _error);
            });
        },
        EmployeesManagerDeleteGroup: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.emDeleteGroup,
                    body: _data
                }, _success, _error);
            });
        },
        ImageImportRequestAssetsDescription: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.iiGetServerAssetsDescription }, _success, _error);
            });
        },
        ImageImportRequestDefaultAsset: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.iiGetServerDefaultAsset }, _success, _error);
            });
        },
        ImageImportRequestAssets: (_BukkenId, _fileName) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.iiGetServerAssets + `?BukkenId=${_BukkenId}&fileName=${_fileName}`
                }, _success, _error);
            });
        },
        ImageImportRequestAsset: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.iiGetServerAsset,
                    body: _data
                }, _success, _error);
            });
        },
        ImageImportUpdateAssets: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.iiUpdateServerAssets,
                    body: _data
                }, _success, _error);
            });
        },

        DMRequestOwners: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.dmRequestOwners,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        DMRequestOwnerDetail: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.dmRequestOwnerDetail + `?id=${_id}`
                }, _success, _error);
            });
        },
        DMDeleteOwner: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.dmDeleteOwner + `?id=${_id}`
                }, _success, _error);
            });
        },

        EXRequestExportPdf: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestExportPdf,
                    body: _data
                }, _success, _error);
            });
        },
        EXRequestExportCSV: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestExportCSV,
                    body: _data
                }, _success, _error);
            });
        },
        EXRequestBukkenExportCSV: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestBukkenExportCSV,
                    body: _data
                }, _success, _error);
            });
        },
        EXRequestExportDMCSV: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestExportDMCSV,
                    body: _data
                }, _success, _error);
            });
        },
        EXRequestPropertyRoomsCSV: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestPropertyRoomsCSV,
                    body: _data
                }, _success, _error);
            });
        },
        EXRequestFailedImport: (_data) => {
            const promise = new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestFailedImport,
                    body: { file: _data.file, beforeCheck: _data.beforeCheck }
                }, _success, _error, 'file');
            });

            return {
                promise: promise
            };
        },
        EXRequestECImport: (_data) => {
            const promise = new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestECImport,
                    body: { file: _data.file, keitai: _data.keitai, kotei: _data.kotei }
                }, _success, _error, 'file');
            });

            return {
                promise: promise
            };
        },
        EXRequestImportAll: (_data) => {
            const promise = new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestImportAll,
                    body: { file: _data.file, beforeCheck: _data.beforeCheck, beforeCheck2: _data.beforeCheck2, keitai: _data.keitai, kotei: _data.kotei, type: _data.type }
                }, _success, _error, 'file');
            });

            return {
                promise: promise
            };
        },
        EXRequestDMMistake: (_data) => {
            const promise = new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestDMMistake,
                    body: { file: _data.file }
                }, _success, _error, 'file');
            });

            return {
                promise: promise
            };
        },
        EXRequestDMImport: (_data) => {
            const promise = new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestDMImport,
                    body: { file: _data.file }
                }, _success, _error, 'file');
            });

            return {
                promise: promise
            };
        },
        EXRequestBukkenImport: (_data) => {
            const promise = new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestBukkenImport,
                    body: { file: _data.file }
                }, _success, _error, 'file');
            });

            return {
                promise: promise
            };
        },
        EXSendMailHansoku: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exSendMailHansoku,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceRequestBuildingAssets: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mRequestBuildingAssets,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceRequestOwners: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mRequestOwners,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceRequestRooms: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mRequestRooms,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceRequestPropertyRooms: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mRequestPropertyRooms + `?id=${_id}`
                }, _success, _error);
            });
        },
        MaintenanceRequestOwnerRooms: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mRequestOwnerRooms + `?id=${_id}`
                }, _success, _error);
            });
        },
        MaintenanceDeleteBuildingAsset: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mDeleteBuildingAsset + `?id=${_id}`
                }, _success, _error);
            });
        },
        MaintenanceDeleteRoom: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mDeleteRoom,
                    body: { ..._id }
                }, _success, _error);
            });
        },
        MaintenanceDeleteOwner: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mDeleteOwner + `?id=${_id}`
                }, _success, _error);
            });
        },
        MaintenanceUpdateBuildingAsset: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mUpdateBuildingAsset,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceUpdateRoom: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mUpdateRoom,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceUpdateOwner: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mUpdateOwner,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceUpdateOwnerRoom: (_roomId, _ownerId) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mUpdateOwnerRoom + `?roomId=${_roomId}&ownerId=${_ownerId}`
                }, _success, _error);
            });
        },
        MaintenanceInsertBuildingAssset: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mInsertBuildingAssset,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceInsertRoom: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mInsertRoom,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceInsertOwner: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mInsertOwner,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        MaintenanceInsertUnknownProperty: (_ownerId, _propertyName) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mInsertUnknownProperty + `?ownerId=${_ownerId}&propertyName=${_propertyName}`
                }, _success, _error);
            });
        },
        MaintenanceCheckUnknownProperty: (_propertyName) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.mCheckUnknownProperty + `?propertyName=${_propertyName}`
                }, _success, _error);
            });
        },
        AssessmentRequestRequestBuildingAssets: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asRequestBuildingAssets,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestRequestOwners: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asRequestOwners,
                    body: { ..._data }
                }, _success, _error);
            });
        },

        AssessmentRequestExportCsv: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asExportCsv,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestRequestRooms: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asRequestRooms,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestRequestPropertyRooms: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asRequestPropertyRooms + `?id=${_id}`
                }, _success, _error);
            });
        },
        AssessmentRequestRequestOwnerRooms: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asRequestOwnerRooms + `?id=${_id}`
                }, _success, _error);
            });
        },
        AssessmentRequestRequestOwnerListRooms: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asRequestOwnerListRooms,
                    body: _data
                }, _success, _error);
            });
        },
        AssessmentRequestDeleteBuildingAsset: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asDeleteBuildingAsset + `?id=${_id}`
                }, _success, _error);
            });
        },
        AssessmentRequestDeleteRoom: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asDeleteRoom,
                    body: { ..._id }
                }, _success, _error);
            });
        },
        AssessmentRequestDeleteOwner: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asDeleteOwner + `?id=${_id}`
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateBuildingAsset: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateBuildingAsset,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateRoom: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateRoom,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateOwner: (_data) => {
            return new Promise(( _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateOwner,
                    body: { ..._data }
                }, _error);
            });
        },
        AssessmentRequestUpdateMemo: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateMemo,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateKidoku: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateKidoku,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateShinsei: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateShinsei,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateTorisage: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateTorisage,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateStates: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateStates,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestUpdateOwnerRoom: (_roomId, _ownerId) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asUpdateOwnerRoom + `?roomId=${_roomId}&ownerId=${_ownerId}`
                }, _success, _error);
            });
        },
        AssessmentRequestInsertBuildingAssset: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asInsertBuildingAssset,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestInsertRoom: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asInsertRoom,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestInsertOwner: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asInsertOwner,
                    body: { ..._data }
                }, _success, _error);
            });
        },
        AssessmentRequestInsertUnknownProperty: (_ownerId, _propertyName) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asInsertUnknownProperty + `?ownerId=${_ownerId}&propertyName=${_propertyName}`
                }, _success, _error);
            });
        },
        AssessmentRequestCheckUnknownProperty: (_propertyName) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.asCheckUnknownProperty + `?propertyName=${_propertyName}`
                }, _success, _error);
            });
        },

        CommonDataGetStaffs: () => {
            return new Promise((_success, _error) => {
                generateRequest({ url: localAddressPrefix + apiEntryPoint.cGetStaffs }, _success, _error);
            });
        },
        CommonDataGetZaitakuDetails: (_id) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cGetZaitakuDetails + `?id=${_id}`
                }, _success, _error);
            });
        },
        CommonDataGetLastEditer: (_tableId, _dataId, _dataType) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cGetLastEditer + `?tableId=${_tableId}&dataId=${_dataId}&dataType=${_dataType}`
                }, _success, _error);
            });
        },
        CommonDataSetLastEditer: (_tableId, _dataId, _dataType, _staffId) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cSetLastEditer + `?tableId=${_tableId}&dataId=${_dataId}&dataType=${_dataType}&staffId=${_staffId}`
                }, _success, _error);
            });
        },
        CommonImportImages: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.cImportImages,
                    body: { ..._data.received }
                }, _success, _error);
            });
        },
        ExRequestPromotionalActivityExcel: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestPromotionalActivityExcel,
                    body: _data
                }, _success, _error);
            });
        },
        ExRequestPromotionalActivityPdf: (_data) => {
            return new Promise((_success, _error) => {
                generateRequest({
                    url: localAddressPrefix + apiEntryPoint.exRequestPromotionalActivityPdf,
                    body: _data
                }, _success, _error);
            });
        },
    };
})();
const ApiEntryPoint = ApiConnector.ApiEntryPoint;

export { ApiEntryPoint };
export default ApiConnector;