import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function OwnerForm({ callBack: _callBack, sidePopup: _sidePopup, delOwner: _delOwner, addOwnedRoom: _addOwnedRoom }) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const updateFullName = (_v) => updateState('fullName', _v);
    const updateStreetAddress = (_v) => updateState('streetAddress', _v);

    const clearHandler = () => setData({});

    return <div className={'maintenance-form-container '}>
        <form>
            <Row className="form-group">
                <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('fullName')} onChangeHandler={updateFullName} value={data.fullName} labelSize={4} extraClasses="fullName-container" />
                    </div>
                </Col>
            </Row>
            <Row className="form-group">
                <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('streetAddress')} onChangeHandler={updateStreetAddress} value={data.streetAddress} labelSize={4} extraClasses="streetAddress-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="form-submit-container">
                    <button type="button" className="btn btn-primary" onClick={_callBack && (() => { _callBack(data); })}>{t('search')}</button>
                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('clear')}</button>
                </Col>
            </Row>
        </form>
        <Row className="form-group">
            <Col className='maintenance-page' md={12}>
                <section className="section title-section">
                    <div className="button-row flex">
                        <div className="button-container">
                            <div className="btn-group">
                                <button type="button" className="btn btn-secondary" onClick={_sidePopup && (() => { _sidePopup('registerOwner'); })}>{t('registerOwner')}</button>
                                <button type="button" className="btn btn-secondary" onClick={_sidePopup && (() => { _sidePopup('editOwner'); })}>{t('editOwner')}</button>
                                <button type="button" className="btn btn-secondary" onClick={_delOwner && (() => { _delOwner(); })}>{t('deleteOwner')}</button>
                            </div>
                            <div className="btn-group">
                                <button type="button" className="btn btn-secondary" onClick={_sidePopup && (() => { _addOwnedRoom('addOwnedRoom'); })}>{t('addOwnedRoom')}</button>
                            </div>
                        </div>
                    </div>
                </section>
            </Col>
        </Row>
    </div>
}

OwnerForm.defaultProps = {
    extraClasses: ''
}
OwnerForm.whyDidYouRender = true;