import * as helpers from 'helpers/formHelpers';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReflexContainer, ReflexElement } from 'react-reflex';

export function RoomAddForm({ title: _title, onCancelCallback: _onCancelCallback, onSubmitCallback: _onSubmitCallback, room: _room, staffList: _staffList }) {

    const { t } = useTranslation();

    const [room, setRoom] = useState(!_room ? {} : _room);

    const clearHandler = () => {
        setRoom({});
        _onCancelCallback();
    };

    useEffect(() => {
        setRoom(_room);
    }, [_room]);

    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setRoom({ ...room, ...update });
    }

    const replaceState = (_attr, _val) => {
        const update = room;
        update[_attr] = _val;
        setRoom(update);
    }

    const setSelectedValue = (_attr) => {
        var selectedValue = 0;
        (room[_attr] && Array.isArray(room[_attr])) && room[_attr].forEach(_v => {
            if (_v.selected === true)
                selectedValue = _v.value;
        });
        replaceState(_attr, selectedValue);
    };

    const setSelectedValueDropDown = (_attr) => {
        var selectedValue = 0;
        if (!(room[_attr] === null || room[_attr] === undefined)) {
            selectedValue = room[_attr];
        }
        replaceState(_attr, selectedValue);
    };

    const handleSubmit = (event) => {
        //setting values for radio buttons
        setSelectedValue('mensekiKeisokuType');
        setSelectedValue('madoriType');
        setSelectedValue('hoshoType');
        setSelectedValue('gender');
        setSelectedValue('job');
        setSelectedValue('reform');
        setSelectedValue('keyType');
        setSelectedValue('hikiwatashi');
        setSelectedValue('shinchiku');
        setSelectedValue('tashaKyougoType1');
        setSelectedValue('tashaKyougoType2');
        setSelectedValue('urizumiType');
        setSelectedValue('baikaiGakuType');

        //setting values for drop downs
        setSelectedValueDropDown('shinseishaId');

        _onSubmitCallback(room);
    };

    const updateRoomNumber = (_v) => updateState('roomNum', _v);
    const updateApartmentFloor = (_v) => updateState('shozaikai', _v);
    const updateRoomNumberSub = (_v) => updateState('roomNum2', _v);
    const updateFloorSurfaceSize = (_v) => updateState('heibei', _v);
    const updateBalcony = (_v) => updateState('balconyMenseki', _v);
    const updateMensekiKeisokuType = (_v) => updateState('mensekiKeisokuType', _v);
    const updateFloorPlan = (_v) => updateState('madoriSize', _v);
    const updateMadoriType = (_v) => updateState('madoriType', _v);
    const updateRentManagementCompany = (_v) => updateState('chintaiKanri', _v);
    const updateAgencyFee = (_v) => updateState('daikoTesuryo', _v);
    const updateWithCancellationPenalty = (_v) => updateState('kaijoIyakuAri', _v === true ? 1 : 0);
    const updateHoshoType = (_v) => updateState('hoshoType', _v);
    const updateRent = (_v) => updateState('yachin', _v);
    const updateSakiYachin = (_v) => updateState('sakiYachin', _v);
    const updateSouteiYachin = (_v) => updateState('souteiYachin', _v);
    const updateSakiKuushitsu = (_v) => updateState('sakiKuushitsu', _v === true ? 1 : 0);
    const updateKigen = (_v) => updateState('kigen', _v);
    const updateChintaiKeiyakuAri = (_v) => updateState('chintaiKeiyakuAri', _v === true ? 1 : 0);
    const updateTainouAri = (_v) => updateState('tainouAri', _v === true ? 1 : 0);
    const updateShiyoJokyo = (_v) => updateState('shiyoJokyo', _v);
    const updateGender = (_v) => updateState('gender', _v);
    const updateJob = (_v) => updateState('job', _v);
    const updateAge = (_v) => updateState('age', _v);
    const updateReform = (_v) => updateState('reform', _v);
    const updateKeyType = (_v) => updateState('keyType', _v);
    const updateHikiwatashi = (_v) => updateState('hikiwatashi', _v);
    const updateKanrihi = (_v) => updateState('kanrihi', _v);
    const updateShuzenTsumitatekin = (_v) => updateState('shuzenTsumitatekin', _v);
    const updateJokiKomi = (_v) => updateState('jokiKomi', _v === true ? 1 : 0);
    const updateKansekiEtc = (_v) => updateState('kansekiEtc', _v);
    const updateShinchiku = (_v) => updateState('shinchiku', _v);
    const updateKounyuGakuDate = (_v) => updateState('kounyuGakuDate', _v);
    const updateKounyuGaku = (_v) => updateState('kounyuGaku', _v);
    const updateTashaKyougoName1 = (_v) => updateState('tashaKyougoName1', _v);
    const updateTashaKyougoName2 = (_v) => updateState('tashaKyougoName2', _v);
    const updateTashaKyougoType1 = (_v) => updateState('tashaKyougoType1', _v);
    const updateTashaKyougoType2 = (_v) => updateState('tashaKyougoType2', _v);
    const updateTashaKyougoTantosha1 = (_v) => updateState('tashaKyougoTantosha1', _v);
    const updateTashaKyougoTantosha2 = (_v) => updateState('tashaKyougoTantosha2', _v);
    const updateTashaKyougoBaikaiGaku1 = (_v) => updateState('tashaKyougoBaikaiGaku1', _v);
    const updateTashaKyougoBaikaiGaku2 = (_v) => updateState('tashaKyougoBaikaiGaku2', _v);
    const updateTashaKyougoKigen1 = (_v) => updateState('tashaKyougoKigen1', _v);
    const updateTashaKyougoKigen2 = (_v) => updateState('tashaKyougoKigen2', _v);
    const updateUrizumi = (_v) => updateState('urizumi', _v === true ? 1 : 0);
    const updateUrizumiDate = (_v) => updateState('urizumiDate', _v);
    const updateUrizumiType = (_v) => updateState('urizumiType', _v);
    const updateUrizumiGaku = (_v) => updateState('urizumiGaku', _v);
    const updateMeiginin = (_v) => updateState('meiginin', _v);
    const updateBiko = (_v) => updateState('biko', _v);
    const updateShinseiDate = (_v) => updateState('shinseiDate', _v);
    const updateShinseishaId = (_v) => updateState('shinseishaId', _v);
    const updateShikiri = (_v) => updateState('shikiri', _v);
    const updateBaikaiGaku = (_v) => updateState('baikaiGaku', _v);
    const updateBaikaiGakuType = (_v) => updateState('baikaiGakuType', _v);

    const mensekiKeisokuTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('wallDepth') }, { value: 2, text: t('fieldCounter') }];
    const madoriTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: 'R' }, { value: 2, text: 'K' }, { value: 3, text: 'DK' }, { value: 4, text: 'LK' }, { value: 5, text: 'LDK' }, { value: 6, text: 'SK' }, { value: 7, text: 'SDK' }, { value: 8, text: 'SLK' }, { value: 9, text: 'SLDK' }];
    const hoshoTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('agency') }, { value: 2, text: t('warranty') }, { value: 3, text: t('voluntaryManagement') }, { value: 4, text: t('inUse') }, { value: 5, text: t('vacant') }];
    const genderValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('man') }, { value: 2, text: t('woman') }];
    const jobValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('workingAdult') }, { value: 2, text: t('student') }, { value: 3, text: t('corporation') }];
    const reformValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('inProcess') }, { value: 2, text: t('notInProcess') }];
    const keyTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('owner') }, { value: 2, text: t('managementCompany') }];
    const hikiwatashiValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('consultation') }, { value: 2, text: t('immediate') }, { value: 3, text: t('dueDateDesignation') }, { value: 4, text: t('plan') }];
    const shinchikuValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('newConstruction') }, { value: 2, text: t('secondHand') }];
    const tashaKyougoType1Values = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('general') }, { value: 2, text: t('fullTimeService') }, { value: 3, text: 'NO' + t('intermediary') }];
    const tashaKyougoType2Values = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('general') }, { value: 2, text: t('fullTimeService') }, { value: 3, text: 'NO' + t('intermediary') }];
    const urizumiTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('anotherCompany') }, { value: 2, text: t('ourCompany') }];
    const baikaiGakuTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('general') }, { value: 2, text: t('fullTimeService') }, { value: 3, text: t('exclusive') }];

    const setRadioValues = (_attr, _attrValues) => {
        return _attrValues.map(function (_v, _i) {
            return ({ value: _v.value, text: _v.text, selected: room[_attr] ? (Array.isArray(room[_attr]) ? room[_attr][_i].selected === true ? room[_attr][_i].selected === true : false : _v.value === room[_attr]) : _v.selected === true });
        });
    }

    const mensekiKeisokuType = setRadioValues('mensekiKeisokuType', mensekiKeisokuTypeValues);
    const madoriType = setRadioValues('madoriType', madoriTypeValues);
    const hoshoType = setRadioValues('hoshoType', hoshoTypeValues);
    const gender = setRadioValues('gender', genderValues);
    const job = setRadioValues('job', jobValues);
    const reform = setRadioValues('reform', reformValues);
    const keyType = setRadioValues('keyType', keyTypeValues);
    const hikiwatashi = setRadioValues('hikiwatashi', hikiwatashiValues);
    const shinchiku = setRadioValues('shinchiku', shinchikuValues);
    const tashaKyougoType1 = setRadioValues('tashaKyougoType1', tashaKyougoType1Values);
    const tashaKyougoType2 = setRadioValues('tashaKyougoType2', tashaKyougoType2Values);
    const urizumiType = setRadioValues('urizumiType', urizumiTypeValues);
    const baikaiGakuType = setRadioValues('baikaiGakuType', baikaiGakuTypeValues);

    return <ReflexContainer orientation="horizontal">
        <ReflexElement flex={0.465}>
            <div className="card">
                <div className="card-block">
                    <div className="title-block">
                        <h3 className="title">{_title}</h3>
                    </div>
                    <div className='maintenance-form-container'>
                        <div className="title-separator"></div>
                        <form onSubmit={handleSubmit}>
                            <Row className="form-group">
                                <Col lg={4} md={4} sm={12} className="col-xxl-4 col-xl-4">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('roomNumber')} value={room.roomNum} type={'number'} onChangeHandler={updateRoomNumber} labelSize={3} extraClasses="roomNumber-container" required={false} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={4} md={4} sm={12} className="col-xxl-4 col-xl-4">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput addon={t('apartmentFloor')} value={room.shozaikai} type={'number'} onChangeHandler={updateApartmentFloor} labelSize={3} extraClasses="apartmentFloor-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={4} md={4} sm={12} className="col-xxl-4 col-xl-4">
                                    <Row>
                                        <Col sm={12} className="col-12">


                                            <div className="single-line">
                                                <helpers.GenerateInput addon={t('roomNumber')} value={room.roomNum2} type={'number'} onChangeHandler={updateRoomNumberSub} labelSize={3} extraClasses="roomNumberSub-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('floorSurfaceSize')} type={'number'} addon={<>m<span className="superscript">2</span></>} value={room.heibei} onChangeHandler={updateFloorSurfaceSize} labelSize={3} extraClasses="floorSurfaceSize-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('balcony')} type={'number'} addon={<>m<span className="superscript">2</span></>} value={room.balconyMenseki} onChangeHandler={updateBalcony} labelSize={3} extraClasses="balcony-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup id="mensekiKeisokuType" values={mensekiKeisokuType} onChangeHandler={updateMensekiKeisokuType} extraClasses="mensekiKeisokuType-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={5} md={5} sm={12} className="col-xxl-5 col-xl-5">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('floorPlan')} type={'number'} addon={<>m<span className="superscript">2</span></>} value={room.madoriSize} onChangeHandler={updateFloorPlan} labelSize={3} extraClasses="floorPlan-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={7} md={7} sm={12} className="col-xxl-7 col-xl-7">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup id="madoriType" values={madoriType} onChangeHandler={updateMadoriType} extraClasses="floorPlanType-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('rentManagement') + t('company')} value={room.chintaiKanri} onChangeHandler={updateRentManagementCompany} labelSize={3} extraClasses="rentManagementCompany-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={8} md={8} sm={12} className="col-xxl-8 col-xl-8">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('agencyFee')} type={'number'} addon={t('en')} value={room.daikoTesuryo} onChangeHandler={updateAgencyFee} labelSize={3} extraClasses="agencyFee-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={4} md={4} sm={12} className="col-xxl-4 col-xl-4">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateCheckbox value={room.kaijoIyakuAri === 1 ? true : false} text={t('withCancellationPenalty')} onChangeHandler={updateWithCancellationPenalty} extraClasses="withCancellationPenalty-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup id="hoshoType" values={hoshoType} onChangeHandler={updateHoshoType} extraClasses="rentManagementCompanyType-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {!room['hoshoType'] ? <Row className="form-group">
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('rent')} type={'number'} addon={t('en')} value={room.yachin} onChangeHandler={updateRent} labelSize={3} extraClasses="rent-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][0].selected === true || room['hoshoType'][2].selected === true || room['hoshoType'][3].selected === true || room['hoshoType'][1].selected === true || room['hoshoType'][5].selected === true)) || (room['hoshoType'] === 0 || room['hoshoType'] === 1 || room['hoshoType'] === 2 || room['hoshoType'] === 3 || room['hoshoType'] === 5)) ? <Row className="form-group">
                                {((Array.isArray(room['hoshoType']) && (room['hoshoType'][0].selected === true || room['hoshoType'][2].selected === true || room['hoshoType'][3].selected === true || room['hoshoType'][1].selected === true)) || (room['hoshoType'] === 0 || room['hoshoType'] === 1 || room['hoshoType'] === 2 || room['hoshoType'] === 3)) ? <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('rent')} type={'number'} addon={t('en')} value={room.yachin} onChangeHandler={updateRent} labelSize={3} extraClasses="rent-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col> : null}
                                {((Array.isArray(room['hoshoType']) && (room['hoshoType'][2].selected === true || room['hoshoType'][5].selected === true)) || (room['hoshoType'] === 2 || room['hoshoType'] === 5)) ? <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={room['hoshoType'][5].selected === true ? t('lastRent') : t('priorRent')} type={'number'} addon={t('en')} value={room.sakiYachin} onChangeHandler={updateSakiYachin} labelSize={3} extraClasses="priorRent-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col> : null}
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][2].selected === true || room['hoshoType'][5].selected === true)) || (room['hoshoType'] === 2 || room['hoshoType'] === 5)) ? <Row className="form-group">
                                {((Array.isArray(room['hoshoType']) && (room['hoshoType'][2].selected === true || room['hoshoType'][5].selected === true)) || (room['hoshoType'] === 2 || room['hoshoType'] === 5)) ? <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('estimatedRent')} type={'number'} addon={t('en')} value={room.souteiYachin} onChangeHandler={updateSouteiYachin} labelSize={3} extraClasses="estimatedRent-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col> : null}
                                {((Array.isArray(room['hoshoType']) && (room['hoshoType'][2].selected === true)) || (room['hoshoType'] === 2)) ? <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateCheckbox value={room.sakiKuushitsu === 1 ? true : false} text={t('firstVacantRoom')} onChangeHandler={updateSakiKuushitsu} extraClasses="firstVacantRoom-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col> : null}
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][2].selected === true || room['hoshoType'][3].selected === true || room['hoshoType'][1].selected === true || room['hoshoType'][5].selected === true)) || (room['hoshoType'] === 1 || room['hoshoType'] === 2 || room['hoshoType'] === 3 || room['hoshoType'] === 5)) ? <Row className="form-group">
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateDatePicker value={room.kigen && new Date(room.kigen)} text={t('period')} onChangeHandler={updateKigen} showIcon={true} labelSize={3} extraClasses="kigen-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} md={3} sm={12} className="col-xxl-3 col-xl-3">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateCheckbox value={room.chintaiKeiyakuAri === 1 ? true : false} text={t('withRentalContract')} onChangeHandler={updateChintaiKeiyakuAri} extraClasses="withRentalContract-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} md={3} sm={12} className="col-xxl-3 col-xl-3">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateCheckbox value={room.tainouAri === 1 ? true : false} text={t('withNonpayment')} onChangeHandler={updateTainouAri} extraClasses="withNonpayment-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][4].selected === true)) || (room['hoshoType'] === 4)) ? <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('statusOfUtilization')} value={room.shiyoJokyo} onChangeHandler={updateShiyoJokyo} labelSize={3} extraClasses="statusOfUtilization-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][2].selected === true || room['hoshoType'][3].selected === true || room['hoshoType'][1].selected === true)) || (room['hoshoType'] === 1 || room['hoshoType'] === 2 || room['hoshoType'] === 3)) ? <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup text={t('tenant')} id="gender" values={gender} onChangeHandler={updateGender} extraClasses="gender-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][2].selected === true || room['hoshoType'][3].selected === true || room['hoshoType'][1].selected === true)) || (room['hoshoType'] === 1 || room['hoshoType'] === 2 || room['hoshoType'] === 3)) ? <Row className="form-group">
                                <Col lg={4} md={4} sm={4} className="col-xxl-4 col-xl-4">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput type={'number'} text={t('age')} addon={t('yearsOld')} value={room.age} onChangeHandler={updateAge} labelSize={3} extraClasses="age-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={8} md={8} sm={8} className="col-xxl-8 col-xl-8">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup id="job" values={job} onChangeHandler={updateJob} extraClasses="job-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][5].selected === true)) || (room['hoshoType'] === 5)) ? <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup text={t('renovation')} id="reform" values={reform} onChangeHandler={updateReform} extraClasses="reform-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}

                            {((Array.isArray(room['hoshoType']) && (room['hoshoType'][5].selected === true)) || (room['hoshoType'] === 5)) ? <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup text={t('key')} id="keyType" values={keyType} onChangeHandler={updateKeyType} extraClasses="keyType-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup text={t('relinquish')} id="hikiwatashi" values={hikiwatashi} onChangeHandler={updateHikiwatashi} extraClasses="relinquish-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={8} md={8} sm={12} className="col-xxl-8 col-xl-8">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('managementFee')} type={'number'} addon={t('en')} value={room.kanrihi} onChangeHandler={updateKanrihi} labelSize={3} extraClasses="managementFee-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={8} md={8} sm={12} className="col-xxl-8 col-xl-8">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('repairDeposit')} type={'number'} addon={t('en')} value={room.shuzenTsumitatekin} onChangeHandler={updateShuzenTsumitatekin} labelSize={3} extraClasses="repairDeposit-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={4} md={4} sm={12} className="col-xxl-4 col-xl-4">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateCheckbox value={room.jokiKomi === 1 ? true : false} text={t('over')} onChangeHandler={updateJokiKomi} extraClasses="over-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={5} md={5} sm={12} className="col-xxl-5 col-xl-5">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('other')} value={room.kansekiEtc} onChangeHandler={updateKansekiEtc} labelSize={3} extraClasses="other-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={7} md={7} sm={12} className="col-xxl-7 col-xl-7">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup id="buildingType" values={shinchiku} onChangeHandler={updateShinchiku} extraClasses="buildingType-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={6} md={6} sm={6} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateDatePicker value={room.kounyuGakuDate && new Date(room.kounyuGakuDate)} onChangeHandler={updateKounyuGakuDate} text={t('purchase') + t('phase')} showIcon={true} labelSize={3} extraClasses="kounyuGakuDate-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} sm={6} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('purchase') + t('sum')} type={'number'} addon={t('10kYen')} value={room.kounyuGaku} onChangeHandler={updateKounyuGaku} labelSize={3} extraClasses="purchaseSum-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('competition') + t('company') + t('sum')} value={room.tashaKyougoName1} onChangeHandler={updateTashaKyougoName1} labelSize={3} extraClasses="competitionCompany1-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup id="tashaKyougoType1" values={tashaKyougoType1} onChangeHandler={updateTashaKyougoType1} extraClasses="competitionCompanyType1-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('personInCharge')} value={room.tashaKyougoTantosha1} onChangeHandler={updateTashaKyougoTantosha1} labelSize={3} extraClasses="personInCharge1-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>



                            <Row className="form-group">
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('agentCharge')} addon={t('10kYen')} value={room.tashaKyougoBaikaiGaku1} onChangeHandler={updateTashaKyougoBaikaiGaku1} labelSize={3} extraClasses="agentCharge1-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateDatePicker value={room.tashaKyougoKigen1 && new Date(room.tashaKyougoKigen1)} onChangeHandler={updateTashaKyougoKigen1} text={t('period')} showIcon={true} labelSize={3} extraClasses="period1-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('competition') + t('company') + t('sum')} value={room.tashaKyougoName2} onChangeHandler={updateTashaKyougoName2} labelSize={3} extraClasses="competitionCompany2-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup id="tashaKyougoType2" values={tashaKyougoType2} onChangeHandler={updateTashaKyougoType2} extraClasses="competitionCompanyType2-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={8} md={8} sm={12} className="col-xxl-8 col-xl-8">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('personInCharge')} value={room.tashaKyougoTantosha2} onChangeHandler={updateTashaKyougoTantosha2} labelSize={3} extraClasses="personInCharge2-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('agentCharge')} type={'number'} addon={t('10kYen')} value={room.tashaKyougoBaikaiGaku2} onChangeHandler={updateTashaKyougoBaikaiGaku2} labelSize={3} extraClasses="agentCharge2-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateDatePicker value={room.tashaKyougoKigen2 && new Date(room.tashaKyougoKigen2)} onChangeHandler={updateTashaKyougoKigen2} text={t('period')} showIcon={true} labelSize={3} extraClasses="period2-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('accountSettlement')} type={'number'} addon={t('10kYen')} value={room.shikiri} onChangeHandler={updateShikiri} labelSize={3} extraClasses="accountSettlement-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateRadioGroup text={t('intermediary')} id="baikaiGakuType" values={baikaiGakuType} onChangeHandler={updateBaikaiGakuType} extraClasses="intermediaryType-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput type={'number'} addon={t('10kYen')} value={room.baikaiGaku} onChangeHandler={updateBaikaiGaku} labelSize={3} extraClasses="baikai-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-4 col-xl-4">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateCheckbox value={room.urizumi === 1 ? true : false} text={t('sold')} onChangeHandler={updateUrizumi} extraClasses="sold-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {(room.urizumi === true || room.urizumi === 1) ?
                                <>
                                    <Row className="form-group">
                                        <Col lg={5} md={5} sm={12} className="col-xxl-5 col-xl-5">
                                            <Row>
                                                <Col sm={12} className="col-12">
                                                    <div className="single-line">
                                                        <helpers.GenerateDatePicker value={room.urizumiDate && new Date(room.tashaKyougoKigen2)} onChangeHandler={updateUrizumiDate} showIcon={true} labelSize={3} extraClasses="urizumiCalender-container" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={7} md={7} sm={12} className="col-xxl-7 col-xl-7">
                                            <Row>
                                                <Col sm={12} className="col-12">
                                                    <div className="single-line">
                                                        <helpers.GenerateRadioGroup id="urizumiType" values={urizumiType} onChangeHandler={updateUrizumiType} extraClasses="urizumiType-container" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                            <Row>
                                                <Col sm={12} className="col-12">
                                                    <div className="single-line">
                                                        <helpers.GenerateInput type={'number'} addon={t('10kYen')} value={room.urizumiGaku} onChangeHandler={updateUrizumiGaku} labelSize={3} extraClasses="urizumiGaku-container" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </> : null}

                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('nominee')} value={room.meiginin} onChangeHandler={updateMeiginin} labelSize={3} extraClasses="nominee-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} className="col-xxl-12 col-xl-12">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateInput text={t('roomRemarks')} value={room.biko} onChangeHandler={updateBiko} labelSize={3} extraClasses="roomRemarks-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={5} md={5} sm={12} className="col-xxl-5 col-xl-5">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateDatePicker text={t('applicationDate')} value={room.shinseiDate && new Date(room.shinseiDate)} onChangeHandler={updateShinseiDate} showIcon={true} labelSize={3} extraClasses="applicationDate-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={7} md={7} sm={12} className="col-xxl-7 col-xl-7">
                                    <Row>
                                        <Col sm={12} className="col-12">
                                            <div className="single-line">
                                                <helpers.GenerateSelector value={room.shinseishaId} text={t('applicant')} options={[{ value: null, text: null }].concat((_staffList || []).map(_v => ({ value: _v.id, text: _v.name })))} onChangeHandler={updateShinseishaId} labelSize={3} inputSize={9} extraClasses="shinseishaId-container" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="separator"></div>
                            <Row>
                                <Col md={12} className="form-submit-container">
                                    <button type="submit" className="btn btn-primary">{t('okEng')}</button>
                                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('cancel')}</button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
        </ReflexElement>
    </ReflexContainer>;
}

RoomAddForm.defaultProps = {
    room: {
    }
}