import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';
import { SimpleToggle } from 'components/inputs/toggles';

function ResidualDebt({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    const existenceType = ['unspecified', 'without', 'with'].reduce(_checkboxGroupHOC.checkboxGroupReducer('existenceType'), {});
    existenceType.without.label = t('without-kana');

    const isDebtUnspecified = (() => {
        const valueContainer = data.existenceType.value || data.existenceType.defaultValue || {};
        return valueContainer.unspecified == null || valueContainer.unspecified;
    })();
    const hasNoDebt = !(data.existenceType && (data.existenceType.value || data.existenceType.defaultValue || {}).with);
    return <>
        <wf.CheckboxGroupField
            hideLabel={true}
            values={existenceType}
            onChange={(_v) => setData('existenceType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))}
            radioName="existenceType" />
        <wf.InputField
            hideLabel={true}
            value={!hasNoDebt ? data.amount.value || data.amount.defaultValue : ''}
            className="form-group"
            onChange={setData('amount')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('10kYen')}
            disabled={hasNoDebt} />
        <Row>
            <Col className="col-xs-4">
                <wf.InputField
                    hideLabel={true}
                    value={!hasNoDebt ? data.year.value || data.year.defaultValue : ''}
                    className="form-group"
                    onChange={setData('year')}
                    validator={_validatorsHOC.yearValidator}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('year')}
                    disabled={hasNoDebt} />
            </Col>
            <Col className="col-xs-4 offset-1">
                <wf.InputField
                    hideLabel={true}
                    value={!hasNoDebt ? data.month.value || data.month.defaultValue : ''}
                    className="form-group"
                    onChange={setData('month')}
                    validator={_validatorsHOC.monthValidator}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('asOfMonth')}
                    disabled={hasNoDebt} />
            </Col>
        </Row>
        <Row>
            <Col className="col-xs-4">
                <SimpleToggle
                    label={t('withPrepayment')}
                    value={!isDebtUnspecified ? data.withPrepayment.value || data.withPrepayment.defaultValue || false : false}
                    onChange={setData('withPrepayment')}
                    disabled={isDebtUnspecified} />
            </Col>
            <Col className="col-xs-4 offset-1">
                <wf.InputField
                    hideLabel={true}
                    value={!isDebtUnspecified ? data.prepaymentAmount.value || data.prepaymentAmount.defaultValue : ""}
                    className="form-group"
                    onChange={setData('prepaymentAmount')}
                    validator={_validatorsHOC.doubleValidator}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('10kYen')}
                    disabled={isDebtUnspecified || !(data.withPrepayment.value || data.withPrepayment.defaultValue)} />
            </Col>
        </Row>
    </>;
}

const ResidualDebtPopup = ExportDefaultBehaviour(ResidualDebt, {
    editableFields: ['existenceType', 'amount', 'year', 'month', 'withPrepayment', 'prepaymentAmount']
});
ResidualDebtPopup.whyDidYouRender = true;
export default ResidualDebtPopup;