import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function Address({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const addressTypes = ["unspecified", "newAddress", "company", "familyHome", "anotherHouse"].reduce(_checkboxGroupHOC.checkboxGroupReducer('addressTypes'), {});
    return <>
        <wf.TextField label={t('basicAddress')} text={'address'} />
        <wf.CheckboxGroupField hideLabel={true} values={addressTypes} onChange={(_v) => setData('addressTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="addressTypes" />
        <wf.InputField
            value={data.address.value || data.address.defaultValue}
            hideLabel={true}
            className="form-group"
            onChange={setData('address')} />
    </>
}
export const AddressPopup = ExportDefaultBehaviour(Address, {
    editableFields: ['addressTypes', 'address']
});
AddressPopup.whyDidYouRender = true;