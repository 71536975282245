import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleToggle } from 'components/inputs/toggles';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Dm({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const dmType = ['notDone', 'done', 'disallowedReturned', 'disallowedBeforeReturCheck'].reduce(_checkboxGroupHOC.checkboxGroupReducer('dmType'), {});
    
    return <>
        <wf.CheckboxGroupField hideLabel={true} values={dmType} onChange={(_v) => setData('dmType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="dmType"/>
        <wf.GroupField className="form-group row" hideLabel={true}>
            <SimpleToggle label={t('dmProhibited')} value={data.dmProhibited.value || data.dmProhibited.defaultValue || false}
                onChange={setData('dmProhibited')} />
        </wf.GroupField>
    </>;
}

export const DmPopup = ExportDefaultBehaviour(Dm, {
    editableFields: ['dmType', 'dmProhibited']
});
DmPopup.whyDidYouRender = true;