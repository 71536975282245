import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function AssessmentRequestOwnerForm({ callBack: _callBack,
export: _export}) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    };
    const [searchFlg, setSearchFlg] = useState(false);
    const [detailText, setDetailText] = useState(t('detailedSearch'));
    const updateId = (_v) => updateState('sateiId', _v);
    const updateFullName = (_v) => updateState('fullName', _v);
    const updateKana = (_v) => updateState('kana', _v);
    const updateTel1 = (_v) => updateState('tel1', _v);
    const updateMail = (_v) => updateState('mail', _v);
    const updateSateiMemo = (_v) => updateState('sateiMemo', _v);
    const updateStreetAddress = (_v) => updateState('streetAddress', _v);
    const updateBukkenName = (_v) => updateState('bukkenName', _v);
    const updateBukkenAddress = (_v) => updateState('bukkenAddress', _v);
    const updateBukkenTypes = (_v) => updateState('bukkenTypes', _v);
    const updateKakinStates = (_v) => updateState('kakinStates', _v);
    const updateKidokuStates = (_v) => updateState('kidokuStates', _v);
    const updateRegistrationDateRange = (_v) => {
        updateState('registrationDateRange', _v);
    };
    const updateZenGetsuTogetsu = (_v) => {
        updateState('zenGetsuTogetsu', _v);
    };

    const groupValuesNormalizer = (_attributeName) => (_v, _i) => ({ ..._v, ...{ selected: _v.selected || (data[_attributeName] && data[_attributeName][_i] && data[_attributeName][_i].selected) || false } });

    const bukkenTypes = [{
        value: 'apartment', text: t('apartment')
    }, { value: 'detachedHouse', text: t('detachedHouse') }, { value: 'land', text: t('land') }].map(groupValuesNormalizer('bukkenTypes'));
    const kakinStates = [{ value: 'kakinTaisyo', text: t('kakinTaisyo') }, { value: 'shinseichu', text: t('shinseichu') }, { value: 'kakinTaisyoKyakka', text: t('kakinTaisyoKyakka') }, { value: 'kakinTaisyoGai', text: t('kakinTaisyoGai') }, { value: 'kakinTaisyoTorisage', text: t('kakinTaisyoTorisage') }].map(groupValuesNormalizer('kakinStates'));
    const zenGetsuTogetsu = [{ value: 'zenGetsu', text: t('zenGetsu') }, { value: 'toGetsu', text: t('toGetsu') }];
    const kidokuStatesValues = [{ value: 'showAll', text: t('showAll'), selected: true }, { value: 'onlyMidoku', text: t('onlyMidoku') }, { value: 'onlykidoku', text: t('onlykidoku') }];
    const kidokuStates = kidokuStatesValues.map((_v, _i) => groupValuesNormalizer('kidokuStates')({ ..._v, ...{ selected: data.kidokuStates ? data.kidokuStates[_i].selected : _v.selected === true } }, _i));
    const clearHandler = () => setData({});
    const setMoreSearch = () => {
        if (!searchFlg) {
            setSearchFlg(true);
            setDetailText(t('hideDetailedSearch'));
        }
        else {
            setSearchFlg(false);
            setDetailText(t('detailedSearch'));
        }
    };
    return <div className={'maintenance-form-container asr-owner-form'}>
        <form className="as-form">
            <Row className="form-group" >
                <Col lg={12} md={12} sm={12} className="col-xl-5 col-12" >
                    <div className="single-line">
                        <helpers.GenerateInput text={t('fullName')} onChangeHandler={updateFullName} value={data.fullName} labelSize={2} extraClasses="fullName-container" />
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('kana')} onChangeHandler={updateKana} value={data.kana} labelSize={2} extraClasses="kana-container" />
                    </div>
                </Col>
            </Row>

            <Row className="form-group">
                <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('sateiId')} onChangeHandler={updateId} value={data.sateiId} labelSize={2} extraClasses="id-container" />
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('streetAddress')} onChangeHandler={updateStreetAddress} value={data.streetAddress} labelSize={2} extraClasses="streetAddress-container" />
                    </div>
                </Col>
            </Row>

            {searchFlg && <>
                <Row className="form-group">
                    <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                        <div className="single-line">
                            <helpers.GenerateInput text={t('tel1')} onChangeHandler={updateTel1} value={data.tel1} labelSize={2} extraClasses="tel1-container" />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                        <div className="single-line">
                            <helpers.GenerateInput text={t('mail')} onChangeHandler={updateMail} value={data.mail} labelSize={2} extraClasses="mail-container" />
                        </div>
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                        <div className="single-line">
                            <helpers.GenerateInput text={t('propertyName')} onChangeHandler={updateBukkenName} value={data.bukkenName} labelSize={2} extraClasses="bukkenName-container" />
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                        <div className="single-line">
                            <helpers.GenerateInput text={t('propertyAddress')} onChangeHandler={updateBukkenAddress} value={data.bukkenAddress} labelSize={2} extraClasses="bukkenAddress-container" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} m={5} className="super-wide-input col-xxl-11 col-xl-12 mb-1">
                        <helpers.GenerateCheckboxGroup text={t('bukkenType')} values={bukkenTypes} onChangeHandler={updateBukkenTypes} labelSize={2} inputSize={10} extraClasses="bukkenTypes-container" />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} m={5} className="super-wide-input col-xxl-11 col-xl-12 mb-1">
                        <helpers.GenerateCheckboxGroup text={t('kakinState')} values={kakinStates} onChangeHandler={updateKakinStates} labelSize={2} inputSize={10} extraClasses="kakinStates-container" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} m={5} className="super-wide-input col-xxl-11 col-xl-12 mb-1">
                        <helpers.GenerateRadioGroup text={t('kidokuSate')} id="kidokuStates" values={kidokuStates} onChangeHandler={updateKidokuStates} labelSize={2} inputSize={10}  extraClasses="kidokuStates-container" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                        <helpers.GenerateDateRange dateRange={data.registrationDateRange} onChangeHandler={updateRegistrationDateRange} showTimeSelect={true} text={t('registrationDate')} showIcon={true} labelSize={2} extraClasses="registrationDateRamge-container" />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} m={5} className="super-wide-input col-xxl-11 col-xl-12 mb-1">
                        <helpers.GenerateCheckboxGroup text='　' values={zenGetsuTogetsu}  onChangeHandler={updateZenGetsuTogetsu} labelSize={2} inputSize={10} extraClasses="zenGetsuTogetsu-container" />
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col lg={12} md={12} sm={12} className="col-xl-5 col-12">
                        <div className="single-line">
                            <helpers.GenerateInput text={t('memoJp')} onChangeHandler={updateSateiMemo} value={data.sateiMemo} labelSize={2} extraClasses="sateiMemo-container" />
                        </div>
                    </Col>
                </Row>
            </>}
            <div className="btn-group">
                <button type="button" className="btn btn-secondary" onClick={() => setMoreSearch()}>{detailText}</button>
            </div>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="form-submit-container">
                    <div className="btn-group">
                        <button type="button" className="btn btn-primary" onClick={_export && (() => { _export(data); })}>{t('csvExport')}</button>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={_callBack && (() => { _callBack(data); })}>{t('search')}</button>
                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('clear')}</button>
                </Col>
            </Row>
        </form>
    </div>
}

AssessmentRequestOwnerForm.defaultProps = {
    extraClasses: ''
}
AssessmentRequestOwnerForm.whyDidYouRender = true;