import UserProfile from 'helpers/userProfile';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearActiveRequest, requestCall, requestPropertyListData, requestDeleteHistoryData, requestInsertReCallData, requestEmployees, requestHangUp, requestInsertHistoryData, requestRecords, requestRecordUpdate, requestUnlockPropertyListData, requestUpdateHistoryData } from 'reducers/callmodeSearchReducers';
import { dismissStatusBar, displayMessage } from 'reducers/statusBarReducer';
import { displayMessageAction } from 'reducers/priorityActionsReducers';
import { telCallPopUp } from '../../reducers/priorityActionsReducers';
import CallmodeData from './callmodeData';
import { LoanForm } from './loanForm';
import { OwnerForm } from './ownerForm';
import { PropertyForm } from './propertyForm';
import PropertyList from './propertyList';
import LoadingOverlay from 'react-loading-overlay';
import { requestAssets } from 'reducers/imageImportReducer';

function Callmode({ lastCallReference: _lastCallReference, requestInsertReCallData: _requestInsertReCallData, requestPropertyListData: _requestPropertyListData, propertyList: _propertyList, displayMessageAction: _displayMessageAction, employees: _employees, serverRecords: _serverRecords, clearActiveRequest: _clearActiveRequest, requestRecords: _requestRecords, requestAssets: _requestAssets, requestRecordUpdate: _requestRecordUpdate, requestEmployees: _requestEmployees, requestCall: _requestCall, requestHangUp: _requestHangUp, displayMessage: _displayMessage, dismissStatusBar: _dismissStatusBar, requestDeleteHistoryData: _requestDeleteHistoryData, requestUpdateHistoryData: _requestUpdateHistoryData, requestUnlockPropertyListData: _requestUnlockPropertyListData, requestInsertHistoryData: _requestInsertHistoryData, telCallPopUp: _telCallPopUp, imageRecords: _imageRecords }) {

    const { t } = useTranslation();
    const searchPages = ['ownerInformation', 'propertyInformation', 'loanInformation'];
    const [currentMode, setCurrentMode] = useState(0);
    const [currentSearchMode, setCurrentSearchMode] = useState(searchPages[0]);
    const [formData, setFormData] = useState({});
    const [isActive, setIsActive] = useState(false);
    const [exportState, setExportState] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [ownerId, setOwnerId] = useState(null);
    const [propertyList, setPropertyList] = useState({});

    useEffect(() => {
        if (exportState != 0)
            setIsActive(exportState);
    }, [exportState]);

    useEffect(() => {
        _requestEmployees();
    }, []);

    useEffect(() => {
        if (_propertyList) {
            setPropertyList(_propertyList);
        }
    }, [_propertyList]);

    const handleOnCall = _v => {
        const requestData = { loginId: _v.callNumber.loginId, callNumber: _v.callNumber.callNumber };
        _requestCall({
            ...requestData, callback: () => {
                _displayMessage({
                    extraClasses: 'success-message',
                    message: <div className="oncall-message flex">
                        <div className="animate-flicker-fast"><i className="fa fa-phone" aria-hidden="true" /> </div>{`${_v.fullName}(${_v.callNumber.callNumber}) ${t(`calling`)}`}
                        <div className="flex-grow-1" />
                        <div className="hang-up-button status-bar-button noselect" onClick={() => {
                            _dismissStatusBar();
                            _requestHangUp(requestData);
                        }}>{t('disconnect')}</div>
                    </div>
                });
            }
        });
    };

    const postFailure = () => {
        _displayMessageAction(t('FAIL'), t('FAIL'));
        setExportState(false);
        setIsActive(false);
    }

    const postSuccess = () => {
        setExportState(false);
        setIsActive(false);
    }

    const searchOptionsCallback = (_presetRequest, _o) => {
        let searchFormData = _o;
        searchFormData.loggedInStaffId = UserProfile.getId();
        setCurrentMode(1);
        setFormData(searchFormData);
        setExportState(true);
        _requestRecords({
            ...searchFormData,
            pageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        setCurrentPage(1);
    };

    const generateMenuItem = (_id, _text) => {
        const handler = () => setCurrentSearchMode(_id);
        return <li className="nav-item" onClick={handler}>
            <div className={'nav-link' + (_id === currentSearchMode ? ' active show' : '')}>{_text}</div>
        </li>
    }

    const generateSearchPage = () => {
        let searchPage;
        switch (currentSearchMode) {
            case searchPages[0]:
                searchPage = <OwnerForm callBack={searchOptionsCallback.bind(null, '')} employeeList={_employees} />;
                break;
            case searchPages[1]:
                searchPage = <PropertyForm callBack={searchOptionsCallback.bind(null, '')} />;
                break;
            case searchPages[2]:
                searchPage = <LoanForm callBack={searchOptionsCallback.bind(null, '')} />;
                break;
        }

        return <>
            <ul className="nav nav-pills bordered">
                {searchPages.map(_p => <div key={_p}>{generateMenuItem(_p, t(_p))}</div>)}
            </ul>
            <div className="tab-content">
                {searchPage}
            </div>
        </>;
    }

    const onPageChange = (_c, _i) => {
        if (currentPage != _c) {
            let records = [..._serverRecords.records];
            setExportState(true);
            _requestRecords({
                ...formData,
                pageDetails: {
                    isPrev: (currentPage > _c) ? true : false,
                    latestValue: records[records.length - 1].id,
                    currentPage: _c,
                    pageSize: 100
                }
            }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
            setCurrentPage(_c);
        }
    };

    const generateNoItemSelectedPopup = () => {
        _displayMessageAction((''), t('No Items Selected'),
            {
                text: t('okEng'),
                callback: () => {
                    console.log(t('No Items Selected'));
                }
            }, false);
    }

    const postSuccessUnlockPropertyListData = () => {
        setExportState(true);
        _requestRecords({
            ...formData,
            pageDetails: {
                currentPage: currentPage,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
    }

    const onUnlockRoomPopup = (id) => {
        if (id == 0) {
            generateNoItemSelectedPopup();
        }
        else {
            _displayMessageAction(t('confirmDelete'), t('emConfirmDeleteMessage'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _requestUnlockPropertyListData(id, postSuccessUnlockPropertyListData.bind(null, ''), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
    }

    const generateContentPage = () => {
        return <>
            <ul className="nav nav-pills relative">
                <button type="button" className="btn btn-primary" onClick={() => { setCurrentMode(0); setCurrentPage(1); setFormData({}); _clearActiveRequest(); }}>{t('goBack')}</button>
            </ul>
            <div className="tab-content">
                <CallmodeData data={_serverRecords.records}
                    onRecordUpdate={_requestRecordUpdate}
                    onCall={handleOnCall}
                    propertListData={propertyList}
                    onRequestDeleteHistoryData={_requestDeleteHistoryData}
                    onRequestUpdateHistoryData={_requestUpdateHistoryData}
                    onRequestPropertyListData={_requestPropertyListData}
                    onRequestInsertHistoryData={_requestInsertHistoryData}
                    serverRecords={_serverRecords ? _serverRecords : ""}
                    onImagesImportAction={_telCallPopUp}
                    onUnlockPropertyRoomPopup={onUnlockRoomPopup}
                    onAddPropertyRoomPopUp={onAddRoomPopup}
                    UserProfile={UserProfile}
                    imageRecords={_imageRecords}
                    pageChangeCallback={onPageChange}
                    isActive={isActive}
                    currentPage={currentPage}
                    requestImages={_requestAssets}
                    requestInsertReCallData={_requestInsertReCallData}
                    lastCallReference={_lastCallReference}
                />
            </div>
        </>;
    }

    const onAddRoomPopup = (id) => {
        setOwnerId(id);
        setCurrentMode(2);
    }

    const onBackCallback = () => {
        setOwnerId(null);
        setCurrentMode(1);
    }

    const onAddRoomCallback = () => {
        setOwnerId(null);
        setCurrentMode(1);
        setExportState(true);
        _requestRecords({
            ...formData,
            pageDetails: {
                currentPage: currentPage,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
    }

    const generatePropertyListPage = () => {
        return <>
            <ul className="nav nav-pills relative">
                <button type="button" className="btn btn-primary" onClick={onBackCallback}>{t('goBack')}</button>
            </ul>
            <div className="tab-content">
                <PropertyList ownerId={ownerId} onAddRoomCallback={onAddRoomCallback} />
            </div>
        </>;
    }

    return <LoadingOverlay
        active={false}
        spinner
        text={t('inProcessLoading')}
    ><article className="content callmode-page">
            <section className="section">
                <Row className="sameheight-container ">
                    <Col md={12}>
                        <div className="card card-block sameheight-item">
                            {currentMode === 0 && generateSearchPage()}
                            {currentMode === 1 && generateContentPage()}
                            {currentMode === 2 && generatePropertyListPage()}
                        </div>
                    </Col>
                </Row>
            </section>
        </article>
    </LoadingOverlay>
}
Callmode.whyDidYouRender = true;

const mapStateToProps = (_state) => {
    return {
        employees: _state.callmodeSearch.employees,
        serverRecords: { ..._state.callmodeSearch.activeServerRecords },
        imageRecords: { ..._state.imageImport.assets },
        propertyList: _state.callmodeSearch.propertList,
        lastCallReference: _state.callmodeSearch.lastCallReference
    }
}

const mapDispatchToProps = {
    requestRecords: requestRecords,
    requestEmployees: requestEmployees,
    requestCall: requestCall,
    requestHangUp: requestHangUp,
    requestRecordUpdate: requestRecordUpdate,
    clearActiveRequest: clearActiveRequest,
    displayMessage: displayMessage,
    displayMessageAction: displayMessageAction,
    dismissStatusBar: dismissStatusBar,
    requestDeleteHistoryData: requestDeleteHistoryData,
    requestUpdateHistoryData: requestUpdateHistoryData,
    requestUnlockPropertyListData: requestUnlockPropertyListData,
    requestInsertHistoryData: requestInsertHistoryData,
    telCallPopUp: telCallPopUp,
    requestPropertyListData: requestPropertyListData,
    requestAssets: requestAssets,
    requestInsertReCallData: requestInsertReCallData
}

export default connect(mapStateToProps, mapDispatchToProps)(Callmode);