import { binaryIndexOf } from 'helpers/functionalHelpers';

const types = {
    addReminder: 'RR_ADD_REMINDER',
    addReminders: 'RR_ADD_REMINDERS',
    renewReminder: 'RR_RENEW_REMINDER',
    expireReminders: 'RR_EXPIRE_REMINDERS',
    checkReminders: 'RR_CHECK_REMINDERS',
    cancelReminder: 'RR_CANCEL_REMINDER',
    requestReminders: 'RR_REQUEST_REMINDERS',
    addReminderProcedure: 'RR_ADD_REMINDER_PROCEDURE',
    renewReminderProcedure: 'RR_RENEW_REMINDER_PROCEDURE',
    cancelReminderProcedure: 'RR_CANCEL_REMINDER_PROCEDURE'
};

/** Actions **/
export const addReminder = ({ id, name, expiryDate }) => ({
    type: types.addReminder,
    record: { id, name, expiryDate }
});

export const addReminders = (records) => ({
    type: types.addReminders,
    records
});

export const expireReminders = (reminders) => ({
    type: types.expireReminders,
    reminders
});

export const renewReminder = (id, expiryDate) => ({
    type: types.renewReminder,
    id,
    expiryDate
});

export const cancelReminder = id => ({
    type: types.cancelReminder,
    id
});

/** Async Actions **/
export const requestReminders = () => ({
    type: types.requestReminders
});

export const checkReminders = () => ({
    type: types.checkReminders
});

export const addReminderProcedure = reminder => ({
    type: types.addReminderProcedure,
    reminder
});

export const renewReminderProcedure = (id, expiryDate) => ({
    type: types.renewReminderProcedure,
    id,
    expiryDate
});

export const cancelReminderProcedure = id => ({
    type: types.cancelReminderProcedure,
    id
});

const addBinary = function(_e) {
    const output = this.slice(0);
    const foundIndex = binaryIndexOf.call(output, _e, (_a, _b) => _a.id - _b.id, true);
    if (!this[foundIndex] || this[foundIndex].id !== _e.id) {
        output.splice(foundIndex, 0, _e);
    } else {
        output[foundIndex] = _e
    }

    return output;
};

export const isExpired = (_e) => _e.expiryDate < Date.now() || (_e.expiryDate - Date.now() < 60000 && (new Date(_e.expiryDate)).getMinutes() <= (new Date()).getMinutes());

/** Reducer **/
const reducer = (_state = { workers: [], expiredWorkers: [] }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addReminder:
            if (!newState.workersIdCount) newState.workersIdCount = 0;
            const record = { ..._action.record };
            record.id = record.id || newState.workersIdCount++;

            newState.workers.push(record);
            break;
        case types.addReminders:
            if (!newState.workersIdCount) newState.workersIdCount = 0;

            _action.records.forEach(_record => {
                if (isExpired(_record)) {
                    const foundIndex = binaryIndexOf.call(newState.workers, _record, (_a, _b) => _a.id - _b.id);
                    if (foundIndex !== -1) {
                        newState.workers.splice(foundIndex, 1);
                    }
                    newState.expiredWorkers = addBinary.call(newState.expiredWorkers, _record);
                } else {
                    newState.workers = addBinary.call(newState.workers, _record);
                }
            });

            break;
        case types.renewReminder:

            let toTransfer = undefined;
            if (newState.expiredWorkers && newState.expiredWorkers.length > 0) {
                const foundIndex = newState.expiredWorkers.findIndex(_v => _v.id === _action.id);
                if (foundIndex >= 0) {
                    toTransfer = newState.expiredWorkers.splice(foundIndex, 1)[0];
                }
            }

            const updated = toTransfer || { id: _action.id};
            updated.expiryDate = _action.expiryDate;
            let foundIndex = binaryIndexOf.call(newState.workers, updated, (_a, _b) => _a.id - _b.id, true);
            if (!newState.workers[foundIndex] || newState.workers[foundIndex].id !== updated.id) {
                newState.workers.splice(foundIndex, 0, updated);
            } else {
                newState.workers[foundIndex] = { ...newState.workers[foundIndex], ...updated };
            }

            break;
        case types.expireReminders:
            if (Array.isArray(_action.reminders)) {
                _action.reminders.forEach(_r => {
                    const foundIndex = newState.workers.findIndex(_v => _v.id === _r.id);
                    if (foundIndex >= 0) {
                        newState.expiredWorkers = addBinary.call(newState.expiredWorkers, newState.workers.splice(foundIndex, 1)[0]);
                    }
                });
            }
            break;
        case types.cancelReminder:
            const removeFromArray = (_array, _id) => {
                if (_array && _array.length > 0) {
                    const output = _array.splice(0);
                    const foundIndex = binaryIndexOf.call(output, { id: _id }, (_a, _b) => _a.id - _b.id);
                    if (foundIndex >= 0) {
                        output.splice(foundIndex, 1);
                    }
                    return output;
                }
                return _array;
            }
            newState.workers = removeFromArray(newState.workers, _action.id);
            newState.expiredWorkers = removeFromArray(newState.expiredWorkers, _action.id);
            break;
        default:
            break;
    }

    return newState;
}

export default reducer;