import { ApiEntryPoint } from 'apiConnector';
import 'babel-polyfill';
import { requestUpdate, addEmployees, addRecords, requestCall, requestDeleteHistoryData, setAssets, requestEmployees, requestPropertyListData, requestInsertReCallData, requestHangUp, requestInsertHistoryData, requestRecords, requestRecordUpdate, requestUnlockPropertyListData, requestUpdateHistoryData, setLastCallReference } from 'reducers/callmodeSearchReducers';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { addPropertList } from '../reducers/callmodeSearchReducers';

function* requestUpdateSaga(_action) {
    try {
        _action.data.map(_t => {
            _t.fileName = _action.fileName;
            _t.bukkenId = _action.bukkenId;
        })

        const result = yield call(ApiEntryPoint.ImageImportUpdateAssets, _action.data);
        if (result) {
            yield put(setAssets(result));
        }
    } catch (_e) {
        console.log('Error from requestUpdateSaga');
        console.log(_e);
    }
}

function* requestRecordsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CallModeRequestRecords, _action.data);
        if (result && Array.isArray(result.items)) {
            yield put(addRecords(result, true));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestRecordsSaga');
        console.log(_e);
    }
}

function* requestRecordUpdateSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CallModeUpdateRoomData, _action.data);
        if (!result.error) {
            yield put(addPropertList(result));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestRecordUpdateSaga');
        console.log(_e);
    }
}

function* requestPropertyListDatasSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CallModeRequestPropertyListDatas, _action.data.ownerId, _action.data.roomId, _action.data.bukkenId, _action.data.loanId)
        if (result && !result.error) {
            yield put(addPropertList(result));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestPropertyListDatasSaga');
        console.log(_e);
    }
}

function* requestInsertReCallDataSaga(_action) {
    try {
        yield call(ApiEntryPoint.CallModeInsertReCallData, _action.data.ownerId, _action.data.staffId, _action.data.reCallTime)
    } catch (_e) {
        console.log('Error from requestInsertReCallDataSaga');
        console.log(_e);
    }
}

function* requestDeleteHistoryDataSaga(_action) {
    try {

        yield call(ApiEntryPoint.CallModeDeleteHistoryRecords, _action.id);

    } catch (_e) {
        console.log('Error from requestRecordUpdateSaga');
        console.log(_e);
    }
}

function* requestUpdateHistoryDataSaga(_data) {
    try {
        yield call(ApiEntryPoint.CallModeUpdateHistoryRecords, _data.data);
    } catch (_e) {
        console.log('Error from requestRecordUpdateSaga');
        console.log(_e);
    }
}

function* requestCallSaga(_action) {
    try {

        const result = yield call(ApiEntryPoint.CallModeRequestCall, _action.data);
        if (result && !result.error) {
            if (result.callReference) {
                yield put(setLastCallReference(result.callReference));
            }
            if (_action.data.callback) {
                yield call(_action.data.callback);
            }
        }
    } catch (_e) {
        console.log('Error from requestCallSaga');
        console.log(_e);
    }
}

function* requestHangUpSaga(_action) {
    try {

        const lastCallReference = yield select((_state) => _state.callmodeSearch.lastCallReference);
        const result = yield call(ApiEntryPoint.CallModeRequestHangUp, _action.data, lastCallReference);
        if (result && !result.error) {
            console.log(result);
        }
    } catch (_e) {
        console.log('Error from requestCallSaga');
        console.log(_e);
    }
}

function* requestEmployeesSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CallModeRequestEmployees);
        if (result && result.employees && Array.isArray(result.employees)) {
            yield put(addEmployees(result.employees));
        }
    } catch (_e) {
        console.log('Error from requestEmployeesSaga');
        console.log(_e);
    }
}

function* requestUnlockPropertyListDataSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CallModeUnlockPropertyListDatas, _action.id)
        if (!result.error) {
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestUnlockPropertyListDataSaga');
        console.log(_e);
    }
}

function* requestInsertHistoryDataSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.InsertHistoryDatas, _action.data);
        if (result == "") {
        }
        else if (result == "全売り済に変更するにはすべての物件の売済みにチェックを入れる必要があります。　すべての物件を売り済にしますか？") {
            let resultText = window.confirm(result);
            if (resultText) {
                result = yield call(ApiEntryPoint.CallModeUpdateAllResult, _action.data.ownerId);
                alert(result);
            }
        }
        else if (result == "ほかのユーザーにすでにホールドされています。ステータスを編集しますか？") {
            let resultText = window.confirm(result);
            if (resultText) {
                result = yield call(ApiEntryPoint.CallModeUpdateStaffData._action.data, _action.data.ownerId)
                //"すべての物件を売り済にし、ステータスの変更を行いました"
                //ステータスの変更を中止しました

            }
        }
        else {
            alert(result);
        }

    } catch (_e) {
        console.log('Eroor from requestUnlockPropertyListDataSaga');
        console.log(_e);
    }
}

export function* watchRequestRecordsSaga() {
    yield takeEvery(requestRecords().type, requestRecordsSaga);
}

export function* watchRequestRecordUpdateSaga() {
    yield takeEvery(requestRecordUpdate().type, requestRecordUpdateSaga);
}

export function* watchRequestCallSaga() {
    yield takeEvery(requestCall().type, requestCallSaga);
}

export function* watchRequestHangUpSaga() {
    yield takeEvery(requestHangUp().type, requestHangUpSaga);
}

export function* watchRequestEmployeesSaga() {
    yield takeEvery(requestEmployees().type, requestEmployeesSaga);
}

export function* watchRequestDeleteHistoryDataSaga() {
    yield takeEvery(requestDeleteHistoryData().type, requestDeleteHistoryDataSaga);
}

export function* watchRequestUpdateHistoryData() {
    yield takeEvery(requestUpdateHistoryData().type, requestUpdateHistoryDataSaga);
}

export function* watchRequestUnlockPropertyListData() {
    yield takeEvery(requestUnlockPropertyListData().type, requestUnlockPropertyListDataSaga);
}

export function* watchRequestInsertHistoryData() {
    yield takeEvery(requestInsertHistoryData().type, requestInsertHistoryDataSaga);
}

export function* watchRequestPropertyListDatasSaga() {
    yield takeEvery(requestPropertyListData().type, requestPropertyListDatasSaga);
}

export function* watchRequestInsertReCallDataSaga() {
    yield takeEvery(requestInsertReCallData().type, requestInsertReCallDataSaga);
}

export function* watchRequestUpdateSaga() {
    yield takeEvery(requestUpdate().type, requestUpdateSaga);
}