import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function OwnerRemarks({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField
            value={data.ownerRemarks.value || data.ownerRemarks.defaultValue}
            label={t('ownerRemarks')}
            className="form-group"
            onChange={setData('ownerRemarks')} />
    </>
}
export const OwnerRemarksPopup = ExportDefaultBehaviour(OwnerRemarks, {
    editableFields: ['ownerRemarks']
});
OwnerRemarksPopup.whyDidYouRender = true;