const types = {
    startSessionCheck: 'SM_START_SESSION_CHECK',
    stopSessionCheck: 'SM_STOP_SESSION_CHECK',
    triggerSessionWatcher: 'SM_TRIGGER_SESSION_WATCHER',
};

/** Actions **/
export const startSessionCheck = () => ({
    type: types.startSessionCheck
});

export const stopSessionCheck = () => ({
    type: types.stopSessionCheck
});

/** Async Actions **/
export const triggerSessionWatcher = (checkCallback, errorCallback) => ({
    type: types.triggerSessionWatcher,
    checkCallback, errorCallback
});

/** Reducer **/
const reducer = (_state, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.startSessionCheck:
            newState.state = States.Started;
            break;
        case types.stopSessionCheck:
            newState.state = States.Stopped;
            break;
    }

    return newState;
}

export const States = {
    Started: 'started',
    Stopped: 'stopped'
};

export default reducer;