import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ResponsiveDatatable from 'components/tables/responsiveDatatable';
import { Row } from 'react-bootstrap';

export function GroupsEdit({ title: _title, groups: _groups, teams: _teams, employees: _employees, onGroupEditCallback: _onGroupEditCallback, onGroupDeleteCallback: _onGroupDeleteCallback, onCancelCallback: _onCancelCallback, editPopup: _editPopup }) {
    const [groups, setGroups] = useState(_groups);
    const [teams, setTeams] = useState(_teams);
    const deleteGroup = _onGroupDeleteCallback ? (_group) => _onGroupDeleteCallback(_group.id) : () => { };
    const editGroup = _onGroupEditCallback ? (_group) => _onGroupEditCallback(_group.id) : () => { };
    const { t } = useTranslation();

    const subValues = {};
    const columnsOptions = {
        id: {
            header: t('idEng'),
            width: 70
        },
        name: {
            header: t('groupName')
        },
        leaderName: null,
        edit: {
            header: ' ',
            type: 'button',
            icon: 'fa-pencil',
            buttonClass: 'btn-success',
            callback: (_toEdit) => {
                editGroup(_toEdit);
            },
            sortable: false,
            width: 40
        },
        delete: {
            header: ' ',
            type: 'button',
            icon: 'fa-trash-o',
            buttonClass: 'btn-danger',
            callback: (_toDelete) => {
                delete _toDelete.leaderName;
                deleteGroup(_toDelete);
            },
            sortable: false,
            width: 40
        }
    };

    const groupOptions = { ...columnsOptions, leaderName: { header: t('groupLeader') } };
    const teamOptions = {
        ...columnsOptions, leaderName: { header: t('teamLeader') }, name: { header: t('teamName') },
        edit: {
            header: ' ',
            sortable: false,
            width: 40
        },
        delete: {
            header: ' ',
            sortable: false,
            width: 40
        },
    };

    useEffect(() => { setGroups(_groups); setTeams(_teams); }, [_groups, _teams]);

    function isEmpty(_val) {
        if (_val === null || _val === undefined || _val === '')
            return true;
        else
            return false;
    }

    groups.forEach((_g, _i) => {

        const leaderNameIndex = _employees.findIndex(_e => _e.id === _g.groupLeaderId);

        _g.id = _g.id;
        _g.name = _g.groupName;
        _g.leaderName = _employees[leaderNameIndex] ? _employees[leaderNameIndex].name : '';

        const tempEmpData = _employees;

        const formattedData = tempEmpData.reduce((_a, _t) => {
            const toAdd = [];
            if (!isEmpty(_t.groupId) && _t.groupId === _g.id && (_t.visibleFlg === "1" || _t.visibleFlg === 1) && (_t.post === "1" || _t.post === "2" || _t.post === 1 || _t.post === 2)) {
                const teamId = _t.teamId;
                const teamLeader = _t.name;
                const index = _teams.findIndex(_e => _e.id === teamId);
                if (index >= 0)
                    toAdd.push({
                        ...{
                            id: teamId, name: _teams[index].name, leaderName: teamLeader
                        }
                    })
                else
                    toAdd.push({
                        ...{
                            id: teamId, name: '', leaderName: teamLeader
                        }
                    })
            }
            return _a.concat(toAdd);
        }, []);

        subValues[_g.id] = formattedData;
    });

    function editPopupCallback() {
        _editPopup();
    }

    return <div className="card">
        <div className="card-block">
            <div className="title-block">
                <Row md={12} className="align-title">
                    <div className="button-container button-filler flex-grow-1 pd-left">
                        <div className="btn-group">
                            <h3 className="title">{_title}</h3>
                        </div>
                    </div>
                    <div className="button-container">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary" onClick={editPopupCallback}><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </Row>
            </div>
            <section className="editable-panel section title-section">
                <ResponsiveDatatable rows={_groups} columnsOptions={groupOptions} subDataRows={subValues} subDataColumnsOptions={teamOptions} subDataRowFetcher={(_selected, _data) => _data[_selected.id]} />
            </section>
        </div>
    </div>;
}