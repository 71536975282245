import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function Profession({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const jobTypes = ['unspecified', 'working', 'employee', 'retired', 'selfEmployed', 'civilServant', 'doctor', 'teacher', 'other', 'jobChecked'].reduce(_checkboxGroupHOC.checkboxGroupReducer('jobTypes'), {});
    return <>
        <wf.CheckboxGroupField hideLabel={true} values={jobTypes} onChange={(_v) => setData('jobTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="jobTypes" contentExtraClasses="flex-wrap" />
        <wf.InputField
            value={data.jobNotes.value || data.jobNotes.defaultValue}
            hideLabel={true}
            className="form-group"
            onChange={setData('jobNotes')} />
    </>
}
export const ProfessionPopup = ExportDefaultBehaviour(Profession, {
    editableFields: ['jobTypes', 'jobNotes'],
    size: 'sm'
});
ProfessionPopup.whyDidYouRender = true;