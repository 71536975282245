import * as helpers from 'helpers/formHelpers';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReflexContainer, ReflexElement } from 'react-reflex';

export function PropertyAddForm({ title: _title, onCancelCallback: _onCancelCallback, property: _property, onSubmitCallback: _onSubmitCallback, staffList: _staffList }) {
    const { t } = useTranslation();

    const [property, setProperty] = useState(!_property ? {} : _property);

    const clearHandler = () => {
        setProperty({});
        _onCancelCallback();
    };

    useEffect(() => {
        setProperty(_property);
    }, [_property]);

    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setProperty({ ...property, ...update });
    }

    const replaceState = (_attr, _val) => {
        const update = property;
        update[_attr] = _val;
        setProperty(update);
    }

    const setSelectedValue = (_attr) => {
        var selectedValue = 0;
        (property[_attr] && Array.isArray(property[_attr])) && property[_attr].forEach(_v => {
            if (_v.selected === true)
                selectedValue = _v.value;
        });
        replaceState(_attr, selectedValue);
    };

    const setSelectedValueDropDown = (_attr) => {
        var selectedValue = 0;
        if (!(property[_attr] === null || property[_attr] === undefined)) {
            selectedValue = property[_attr];
        }
        replaceState(_attr, selectedValue);
    };

    const setBuildingAgeYear = (_attr) => {
        var value = buildingAgeYear;
        if ((property[_attr] === null || property[_attr] === undefined)) {
            if ((buildingAgeYear === null || buildingAgeYear === undefined)) {
                value = 0;
            }
            else {
                value = buildingAgeYear;
            }
        }
        else {
            value = property[_attr];
        }
        replaceState(_attr, value);
    };

    const setBuildingAgeMonth = (_attr) => {
        var value = buildingAgeMonth;
        if ((property[_attr] === null || property[_attr] === undefined)) {
            if ((buildingAgeMonth === null || buildingAgeMonth === undefined)) {
                value = 0;
            }
            else {
                value = buildingAgeMonth;
            }
        }
        else {
            value = property[_attr];
        }
        replaceState(_attr, value);
    };

    const setRadioValues = (_attr, _attrValues) => {
        return _attrValues.map(function (_v, _i) {
            return ({ value: _v.value, text: _v.text, selected: property[_attr] ? (Array.isArray(property[_attr]) ? property[_attr][_i].selected === true ? property[_attr][_i].selected === true : false : _v.value === property[_attr]) : _v.selected === true });
        });
    }

    const handleSubmit = (event) => {
        //setting values for radio buttons
        setSelectedValue('tochiKenri');
        setSelectedValue('bukkenType');
        setSelectedValue('kouzou');

        //setting values for drop downs
        setSelectedValueDropDown('shinseishaId');

        //setting values for chiku
        setBuildingAgeYear('buildingAgeYear');
        setBuildingAgeMonth('buildingAgeMonth');

        _onSubmitCallback(property);
    };

    const updatePropertyName = (_v) => updateState('name', _v);
    const updatePhoneticReading = (_v) => updateState('yomi', _v);
    const updateLocation = (_v) => updateState('address', _v);
    const updateLocationNumber = (_v) => updateState('kaokuNum', _v);
    const updateFloors = (_v) => updateState('kaidate', _v);
    const updateUnits = (_v) => updateState('kosu', _v);
    const updateBuildingAgeYear = (_v) => updateState('buildingAgeYear', _v);
    const updateBuildingAgeMonth = (_v) => updateState('buildingAgeMonth', _v);
    const updateBuildingManagement = (_v) => updateState('tatemonoKanri', _v);
    const updateSellingCompany = (_v) => updateState('bunjoGaisha', _v);
    const updateLineName1 = (_v) => updateState('line1', _v);
    const updateStationName1 = (_v) => updateState('moyoriEki', _v);
    const updateWalkingTime1 = (_v) => updateState('ekiToho', _v);
    const updateBus1 = (_v) => updateState('bus1', _v);
    const updateLineName2 = (_v) => updateState('line2', _v);
    const updateStationName2 = (_v) => updateState('station2', _v);
    const updateWalkingTime2 = (_v) => updateState('walk2', _v);
    const updateBus2 = (_v) => updateState('bus2', _v);
    const updateLineName3 = (_v) => updateState('line3', _v);
    const updateStationName3 = (_v) => updateState('station3', _v);
    const updateWalkingTime3 = (_v) => updateState('walk3', _v);
    const updateBus3 = (_v) => updateState('bus3', _v);
    const updatePropertyRemarks = (_v) => updateState('biko', _v);
    const updateApplicationDate = (_v) => updateState('shinseiDate', _v);
    const updateShinseishaId = (_v) => updateState('shinseishaId', _v);
    const updateCityTax = (_v) => updateState('kotozei', _v);
    const updateBukkenType = (_v) => updateState('bukkenType', _v);
    const updateTochiKenri = (_v) => updateState('tochiKenri', _v);
    const updateKouzou = (_v) => updateState('kouzou', _v);

    const tochiKenriValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('ownership') }, { value: 2, text: t('lease') }];
    const bukkenTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('apartment') }, { value: 2, text: t('detachedHouse') }, { value: 3, text: t('singleBuilding') }];
    const kouzouValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: 'S' + t('-structure') }, { value: 2, text: 'RC' + t('-structure') }, { value: 3, text: 'SRC' + t('-structure') }];

    const tochiKenri = setRadioValues('tochiKenri', tochiKenriValues);
    const bukkenType = setRadioValues('bukkenType', bukkenTypeValues);
    const kouzou = setRadioValues('kouzou', kouzouValues);

    const buildingAgeYear = property['buildingAgeYear'] ? property['buildingAgeYear'] : (property['chiku'] ? (property['chiku'].toString().length === 6 ? property['chiku'].toString().substring(0, 4) : null) : null);
    const buildingAgeMonth = property['buildingAgeMonth'] ? property['buildingAgeMonth'] : (property['chiku'] ? (property['chiku'].toString().length === 6 ? property['chiku'].toString().substring(property['chiku'].toString().length - 2) : null) : null);

    return <ReflexContainer orientation="horizontal">
        <ReflexElement flex={0.7}>
            <div className="card">
                <div className="card-block">
                    <div className="title-block">
                        <h3 className="title">{_title}</h3>
                    </div>
                    <div className='maintenance-form-container'>
                        <div className="title-separator"></div>
                        <form onSubmit={handleSubmit}>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('propertyName')} value={property.name} onChangeHandler={updatePropertyName} labelSize={3} extraClasses="propertyName-container" required={false} />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('phoneticReading')} value={property.yomi} onChangeHandler={updatePhoneticReading} labelSize={3} extraClasses="phoneticReading-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('location')} value={property.address} onChangeHandler={updateLocation} labelSize={3} extraClasses="location-container" />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('locationNumber')} value={property.kaokuNum} onChangeHandler={updateLocationNumber} labelSize={3} extraClasses="locationNumber-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('units')} type={'number'} value={property.kosu} addon={t('-houses')} onChangeHandler={updateUnits} labelSize={3} extraClasses="units-container" />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('floors')} type={'number'} value={property.kaidate} addon={t('-floor')} onChangeHandler={updateFloors} labelSize={3} extraClasses="floors-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateNumberWithMaxLength type={'number'} max={9999} text={t('buildingAge')} value={buildingAgeYear} addon={t('year')} onChangeHandler={updateBuildingAgeYear} labelSize={3} extraClasses="buildingAgeYear-container" />
                                </Col>
                                <Col lg={3} md={12} sm={12} xl={3}>
                                    <helpers.GenerateNumberWithMaxLength type={'number'} max={12} value={buildingAgeMonth} addon={t('month')} onChangeHandler={updateBuildingAgeMonth} labelSize={3} extraClasses="buildingAge-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('buildingManagement')} value={property.tatemonoKanri} onChangeHandler={updateBuildingManagement} labelSize={3} extraClasses="buildingManagement-container" />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('sellingCompany')} value={property.bunjoGaisha} onChangeHandler={updateSellingCompany} labelSize={3} extraClasses="sellingCompany-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('lineName') + ' 1'} value={property.line1} onChangeHandler={updateLineName1} labelSize={3} extraClasses="lineName1-container" />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('stationName') + ' 1'} value={property.moyoriEki} addon={t('station')} onChangeHandler={updateStationName1} labelSize={3} extraClasses="stationName1-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('walkingTime') + ' 1'} type={'number'} value={property.ekiToho} addon={t('minutes')} onChangeHandler={updateWalkingTime1} labelSize={3} extraClasses="walkingTime1-container" />
                                </Col>
                                <Col lg={4} md={12} sm={12} xl={4}>
                                    <helpers.GenerateInput text={t('bus') + ' 1'} type={'number'} value={property.bus1} addon={t('minutes')} onChangeHandler={updateBus1} labelSize={3} extraClasses="bus1-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('lineName') + ' 2'} value={property.line2} onChangeHandler={updateLineName2} labelSize={3} extraClasses="lineName2-container" />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('stationName') + ' 2'} value={property.station2} addon={t('station')} onChangeHandler={updateStationName2} labelSize={3} extraClasses="stationName2-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('walkingTime') + ' 2'} type={'number'} value={property.walk2} addon={t('minutes')} onChangeHandler={updateWalkingTime2} labelSize={3} extraClasses="walkingTime2-container" />
                                </Col>
                                <Col lg={4} md={12} sm={12} xl={4}>
                                    <helpers.GenerateInput text={t('bus') + ' 2'} type={'number'} value={property.bus2} addon={t('minutes')} onChangeHandler={updateBus2} labelSize={3} extraClasses="bus2-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('lineName') + ' 3'} value={property.line3} onChangeHandler={updateLineName3} labelSize={3} extraClasses="lineName3-container" />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('stationName') + ' 3'} value={property.station3} addon={t('station')} onChangeHandler={updateStationName3} labelSize={3} extraClasses="stationName3-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('walkingTime') + ' 3'} type={'number'} value={property.walk3} addon={t('minutes')} onChangeHandler={updateWalkingTime3} labelSize={3} extraClasses="walkingTime3-container" />
                                </Col>
                                <Col lg={4} md={12} sm={12} xl={4}>
                                    <helpers.GenerateInput text={t('bus') + ' 3'} type={'number'} value={property.bus3} addon={t('minutes')} onChangeHandler={updateBus3} labelSize={3} extraClasses="bus3-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('propertyRemarks')} value={property.biko} onChangeHandler={updatePropertyRemarks} labelSize={3} extraClasses="propertyRemarks-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateDatePicker value={property.shinseiDate && new Date(property.shinseiDate)} onChangeHandler={updateApplicationDate} text={t('applicationDate')} showIcon={true} labelSize={3} extraClasses="applicationDate-container" />
                                </Col>
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateSelector value={property.shinseishaId} text={t('applicant')} options={[{ value: null, text: null }].concat((_staffList || []).map(_v => ({ value: _v.id, text: _v.name })))} onChangeHandler={updateShinseishaId} labelSize={3} inputSize={9} extraClasses="shinseishaId-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateRadioGroup text={t('landRights')} id="tochiKenri" values={tochiKenri} onChangeHandler={updateTochiKenri} extraClasses="landRights-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateRadioGroup text={t('propertyType')} id="bukkenType" values={bukkenType} onChangeHandler={updateBukkenType} extraClasses="propertyType-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateRadioGroup text={t('construction')} id="kouzou" values={kouzou} onChangeHandler={updateKouzou} extraClasses="construction-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={12} sm={12} xl={6}>
                                    <helpers.GenerateInput text={t('cityTax')} type={'number'} value={property.kotozei} addon={t('en')} onChangeHandler={updateCityTax} labelSize={3} extraClasses="cityTax-container" />
                                </Col>
                            </Row>
                            <div className="separator"></div>
                            <Row>
                                <Col md={12} className="form-submit-container">
                                    <button type="submit" className="btn btn-primary">{t('okEng')}</button>
                                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('cancel')}</button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
        </ReflexElement>
    </ReflexContainer>;
}

PropertyAddForm.defaultProps = {
    property: {
    }
}