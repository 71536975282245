import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Construction({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const constructionTypesRaw = ["unspecified", "S", "RC", "SRC"];
    const constructionTypesObj = constructionTypesRaw.reduce(_checkboxGroupHOC.checkboxGroupReducer('constructionTypes'), {});
    constructionTypesRaw.slice(1).forEach(_ct => constructionTypesObj[_ct].label += t('-structure'));
    return <>
        <wf.CheckboxGroupField label={t('construction')} values={constructionTypesObj} onChange={(_v) => setData('constructionTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="constructionTypes" />
        <wf.InputField label={t('floors')} value={data.floors.value || data.floors.defaultValue}
            validator={_validatorsHOC.optionalDayHours}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('floors')}
            addon={t('-floor')} />
        <wf.InputField label={t('units')} value={data.units.value || data.units.defaultValue}
            validator={_validatorsHOC.optionalDayHours}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('units')}
            addon={t('-houses')} />
    </>;
}

const ConstructionPopup = ExportDefaultBehaviour(Construction, {
    editableFields: ['constructionTypes', 'floors', 'units']
});
ConstructionPopup.whyDidYouRender = true;
export default ConstructionPopup;