import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function IncomeExpenditureStatus({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    const statusType = ['unspecified', 'plus', 'minus'].reduce(_checkboxGroupHOC.checkboxGroupReducer('statusType'), {});

    const inputDisabled = (() => {
        const valueContainer = data.statusType.value || data.statusType.defaultValue;
        return !valueContainer || valueContainer.unspecified;
    })();

    return <>
        <wf.CheckboxGroupField
            hideLabel={true}
            values={statusType}
            onChange={(_v) => setData('statusType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))}
            radioName="statusType" />
        <wf.InputField hideLabel={true}
            value={!inputDisabled ? data.amount.value || data.amount.defaultValue : ''}
            className="form-group"
            onChange={setData('amount')}
            validator={_validatorsHOC.optionalDayHours}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('en')}
            disabled={inputDisabled} />
    </>
}
const IncomeExpenditureStatusPopup = ExportDefaultBehaviour(IncomeExpenditureStatus, {
    editableFields: ['statusType', 'amount'],
    size: 'sm'
});
IncomeExpenditureStatusPopup.whyDidYouRender = true;
export default IncomeExpenditureStatusPopup;