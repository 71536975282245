var UserProfile = (function () {

    const userDetails = JSON.parse(window.localStorage.getItem('user_details'));

    var name = "";
    var id = "";
    var post = "";
    var editFlg = "";
    var groupId = "";

    var getName = function () {
        name = userDetails ? userDetails.name : "";
        return name;
    };

    var getId = function () {
        id = userDetails ? userDetails.sub.substring(6) : "";
        return id;
    };

    var getPost = function () {
        post = userDetails ? userDetails.nickname.split('|')[0] : "";
        return post;
    };

    var getEditterFlg = function () {
        editFlg = userDetails ? userDetails.nickname.split('|')[1] : "";
        return editFlg;
    };

    var getGroupId = function () {
        groupId = userDetails ? userDetails.nickname.split('|')[2] : "";
        return groupId;
    };

    return {
        getName: getName,
        getId: getId,
        getPost: getPost,
        getEditterFlg: getEditterFlg,
        getGroupId: getGroupId
    }

})();

export default UserProfile;