import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function LoanAmount({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC}) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField
            value={data.loanAmount.value || data.loanAmount.defaultValue}
            className="form-group"
            onChange={setData('loanAmount')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('10kYen')} />
        <wf.InputField label={t('deposit')}
            value={data.deposit.value || data.deposit.defaultValue}
            className="form-group"
            onChange={setData('deposit')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('10kYen')} />
    </>
}
const LoanAmountPopup = ExportDefaultBehaviour(LoanAmount, {
    editableFields: ['loanAmount', 'deposit'],
    size: 'sm'
});
LoanAmountPopup.whyDidYouRender = true;
export default LoanAmountPopup;