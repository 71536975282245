import React, { useState, useEffect } from 'react';

export function AutohideInput({ value: _value, extraClasses: _extraClasses, isEditable: _isEditable, handleChangedCallback: _handleChangedCallback}) {

    const [isEditinig, setIsEditinig] = useState(false);
    const [value, setValue] = useState(_value);

    const valueDisplay = (_isEditinig) => {
        return _isEditinig ?
            <input autoFocus className="value-input form-control boxed" value={value} type="text" onChange={handleChanged} onKeyUp={handleKeyUp} onFocus={handleOnFocus} onBlur={handleOnBlur} /> :
            <><span className="value-field">{value}</span><div className="autohide-input-icon absolute"><i className="fa fa-pencil" aria-hidden="true"></i></div></>;
    };

    const handleEditableCallback = (_args, _reset, _shouldSubmit) => {
        if (_reset) {
            if (_shouldSubmit) {
                _handleChangedCallback({ value: value });
            } else {
                setValue(_value);
            }
            setIsEditinig(false);
        } else if (!isEditinig) {
            setIsEditinig(true);
        }
    }

    const handleChanged = (_e) => setValue(_e.target.value);

    const handleKeyUp = (_e) => {
        if (_e.key === 'Enter') handleEditableCallback(null, true, _handleChangedCallback !== undefined);
        else if (_e.key === 'Escape') handleEditableCallback(null, true, false);
    }

    const handleOnFocus = (_e) => _e.target.select();

    const handleOnBlur = (_e) => handleEditableCallback(null, true, false);

    useEffect(
        () => {
            if (value !== _value && ((_isEditable && !isEditinig) || !_isEditable)) {
                setValue(_value);
            }
        },
        [_value]
    );

    return <div
        className={'autohide-input relative flex justify-center flex-column fill-h ' + _extraClasses}
        onClick={_isEditable ? handleEditableCallback : undefined}>
        {valueDisplay(isEditinig)}
    </div>
}

AutohideInput.defaultProps = {
    extraClasses: '',
    value: '',
    isEditable: true
};