import { SortedArray } from 'helpers/listMaintenanceHelper';

const types = {
    addReinsData: 'IMA_ADD_REINS_DATA',
    clearActiveRequest: 'IMA_CLEAR_ACTIVE_REQUEST'
};



/** Actions **/
export const addReinsData = (data, addWhole) => ({
    type: types.addReinsData,
    data,
    addWhole
});

export const clearActiveRequest = () => ({
    type: types.clearActiveRequest
});


/** Async Actions **/
//export const requestOwners = (data, successCallback, failureCallback) => ({
//    type: types.requestOwners,
//    data,
//    successCallback,
//    failureCallback
//});

//export const requestStaffs = () => ({
//    type: types.requestStaffs
//});

//export const deleteOwner = (id, successCallback, failureCallback) => ({
//    type: types.deleteOwner,
//    id,
//    successCallback,
//    failureCallback
//});

const reinsData = new SortedArray();
reinsData.comparator = (_a, _b) => _a.id - _b.id;

/** Reducer **/
const reducer = (_state = { reinsData: [] }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addReinsData:
            if (_action.addWhole === true) {
                if (Array.isArray(_action.data)) {
                    reinsData.addWhole(_action.data);
                    newState.reinsData = reinsData.toArray();
                }
            }
            else {
                _action.data.forEach(_v => reinsData.addById(_v));
                newState.reinsData = reinsData.toArray();
            }
            break;

        case types.clearActiveRequest:
            newState.reinsData = [];
            break;
        default:
            break;
    }

    return newState;
}
export default reducer;