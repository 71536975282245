import React from 'react';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function BuildingManagement({ useStateHOC: _useStateHOC }) {
    const [data, setData] = _useStateHOC;

    return <wf.InputField hideLabel={true}
        value={data.notes.value || data.notes.defaultValue}
        onChange={setData('notes')}/>;
}
const BuildingManagementPopup = ExportDefaultBehaviour(BuildingManagement, {
    editableFields: ['notes']
});
BuildingManagementPopup.whyDidYouRender = true;
export default BuildingManagementPopup;