import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Fax({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC, checkboxGroupHOC: _checkboxGroupHOC}) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const faxTypes = ["unspecified", "home", "workplace", "other"].reduce(_checkboxGroupHOC.checkboxGroupReducer('faxTypes'), {});
    return <>
        <wf.CheckboxGroupField hideLabel={true} values={faxTypes} onChange={(_v) => setData('faxTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="faxTypes" />
        <wf.InputField
            value={data.fax.value || data.fax.defaultValue}
            validator={_validatorsHOC.phoneValidator}
            hideLabel={true}
            errorMessage={t('incorrectPhoneFormatError')} className="form-group"
            onChange={setData('fax')}
            onError={setData('fax')} />
    </>
}
export const FaxPopup = ExportDefaultBehaviour(Fax, {
    editableFields: ['faxTypes', 'fax']
});
FaxPopup.whyDidYouRender = true;