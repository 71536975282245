import React, { Component } from 'react';
import { DescriptionField } from './descriptionField';
import { themeHelper } from 'helpers/themeHelpers/teams';

export class DescriptionGroup extends Component {

    render() {

        const fields = Object.keys(this.props.fields);

        return (
            <ul className={"team-stats-container " + this.props.extraClasses}>
                {
                    fields.map((_unused, _i) => {//We only need the amount of fields. The order is arbitrary and specified in the theme helper
                        const displayedField = themeHelper.canonicalOrder[_i];
                        return <li key={_i} className="stats-field">
                            <DescriptionField
                                text={displayedField}
                                displayValue={this.props.fields[displayedField].value}
                                extraClasses={this.props.fields[displayedField].theme}
                                isEditable={this.props.fields[displayedField].isEditable}
                                handleChangedCallback={this.props.fields[displayedField].isEditable ? (_val) => {
                                    this.props.handleChangedCallback({
                                        ..._val,
                                        valueIndex: displayedField,
                                        valueId: this.props.fields[displayedField].id
                                    });
                                } : undefined}
                            />
                        </li>
                    })
                }
            </ul>
        );
    }
}

DescriptionGroup.defaultProps = {
    extraClasses: '',
    fields: []
};