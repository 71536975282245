export const formatNumberPadding = (_count, _val) => {
    if (_count && (_val || _val === 0) && _count > 0) {
        const prefix = (Math.pow(10, _count) + '').slice(-_count);
        let val = _val + '';
        return val.length < _count ? (prefix + val).slice(-(_count)) : val;
    } else {
        return _val;
    }
}

export function formatDateShort(_ticks, _noDay = false) {
    if (_ticks == null) return;
    const date = new Date(_ticks);

    return date.getFullYear() + '/' + formatNumberPadding(2, date.getMonth() + 1) + (!_noDay ? '/' + formatNumberPadding(2, date.getDate()) : '');
}

export function formatDateTimeShort(_ticks, _showSeconds = true) {
    const date = new Date(_ticks);

    return formatDateShort(_ticks) + ' ' + formatNumberPadding(2, date.getHours()) + ':' + formatNumberPadding(2, date.getMinutes()) + (_showSeconds ? ':' + formatNumberPadding(2, date.getSeconds()) : '');
}

export function formatCurrency(_number) {
    if (_number === null || _number === "") return "";
    if (isNaN(typeof _number === 'string' ? _number.replace(',', '.') : _number)) return "";
    const [front, ...back] = _number.toString().split(/[.,]+/);
    return [front.split('').reduceRight((_a, _b, _i) => (front.length - (_i + 1)) % 3 === 0 ? `${_b},${_a}` : `${_b}${_a}`), ...back].join('.');
}