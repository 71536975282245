import React, { useState } from "react";

export default function propertyListModuleTable({ tableprops: _tableprops, propsData: _propsData, onClickCell: _onClickCell, idNumber: _idNumber }) {

    function onClickCellTable(e) {
        _onClickCell(e.target.id);
    }
    return (
        <div className="property-list-module-t">
            <div className="property-list-module-table-container modal-body modal-tab-container flex justify-center">
                <div className="property-list-module-table table-responsive">
                    <table className="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>{_propsData.t("propertyName")}</th>
                                <th>{_propsData.t("roomNumber")}</th>
                                <th>{_propsData.t("nominee")}</th>
                                <th>{_propsData.t("sold")}</th>
                                <th>{_propsData.t("purpose")}</th>
                                <th>{_propsData.t("buildingAge")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _tableprops.map(_item =>
                                    <tr onClick={(e) => onClickCellTable(e)} id={_item.roomId} key={"cell" + _item.roomId}  >
                                        <td className={"property-list-module-cell" + (String(_idNumber) === String(_item.roomId) ? ' selected-property-list-module' : '')} id={_item.roomId} key={"propertyName" + _item.id}>{_item.propertyName}</td>
                                        <td className={"property-list-module-cell" + (String(_idNumber) === String(_item.roomId) ? ' selected-property-list-module' : '')} id={_item.roomId} key={"roomNumber" + _item.id}>{_item.roomNumber}</td>
                                        <td className={"property-list-module-cell" + (String(_idNumber) === String(_item.roomId) ? ' selected-property-list-module' : '')} id={_item.roomId} key={"nominee" + _item.id}>{_item.nominee}</td>
                                        <td className={"property-list-module-cell" + (String(_idNumber) === String(_item.roomId) ? ' selected-property-list-module' : '')} id={_item.roomId} key={"Sold" + _item.id}>{_item.Sold ? "○" : "×"}</td>
                                        <td className={"property-list-module-cell" + (String(_idNumber) === String(_item.roomId) ? ' selected-property-list-module' : '')} id={_item.roomId} key={"purpose" + _item.id}>{_item.purpose}</td>
                                        <td className={"property-list-module-cell" + (String(_idNumber) === String(_item.roomId) ? ' selected-property-list-module' : '')} id={_item.roomId} key={"buildingAge" + _item.id}>{_item.buildingAge}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}