import { ApiEntryPoint } from 'apiConnector';
import 'babel-polyfill';
import { addOwners, requestOwners, requestSearchOwners, requestStaffs, addStaffs } from 'reducers/dmReducers';
import { call, put, takeEvery } from 'redux-saga/effects';

function* requestOwnersSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.DMRequestOwners, _action.data);
        if (result && Array.isArray(result.items)) {
            yield put(addOwners(result, true));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from requestOwnersSaga');
        console.log(_e);
    }
}

function* requestStaffsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.CommonDataGetStaffs);
        if (result && Array.isArray(result)) {
            yield put(addStaffs(result, true));
        }
    } catch (_e) {
        console.log('Error from requestStaffsSaga');
        console.log(_e);
    }
}

export function* watchRequestOwnersSaga() {
    yield takeEvery(requestOwners().type, requestOwnersSaga);
}

export function* watchRequestStaffsSaga() {
    yield takeEvery(requestStaffs().type, requestStaffsSaga);
}