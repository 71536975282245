import React from 'react';
import Maintenance from '../../../maintenance/maintenance';

export function AddpropertyRoomPopUp({onCancelCallback: _onCancelCallback}) {

    return <div className=" modal fade show">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">ss</h4>
                    <button type="button" className="btn btn-secondary" onClick={_onCancelCallback}>閉じる</button>
                </div>
                <Maintenance />
            </div>
        </div>
    </div>
}