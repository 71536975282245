import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import { SimpleToggle } from 'components/inputs/toggles';
import * as wf from 'components/callmode/floatingControls/windowFields';

function RentManagement({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField label={t('company') + t('name-short')}
            value={data.companyName.value || data.companyName.defaultValue}
            onChange={setData('companyName')} />
        <wf.InputField label={t('agencyFee')}
            value={data.agencyFee.value || data.agencyFee.defaultValue}
            onChange={setData('agencyFee')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('en')} />
        <wf.GroupField>
            <SimpleToggle label={t('cancellationPenalty')}
                value={data.cancellationPenalty.value || data.cancellationPenalty.defaultValue || false}
                onChange={setData('cancellationPenalty')} />
        </wf.GroupField>
    </>;
}

const RentManagementPopup = ExportDefaultBehaviour(RentManagement, {
    editableFields: ['companyName', 'agencyFee', 'cancellationPenalty']
});
RentManagementPopup.whyDidYouRender = true;
export default RentManagementPopup;