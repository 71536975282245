import PageTable from 'components/tables/pageTable';
import * as helpers from 'helpers/formHelpers';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { requestOwners, requestStaffs } from 'reducers/dmReducers';
import { requestCSV, requestDMCSV, requestBukkenCSV, requestPdf } from 'reducers/exportReducers';
import { displayMessageAction, importFilesAction } from 'reducers/priorityActionsReducers';
import { DMForm } from './dmForm';
import LoadingOverlay from 'react-loading-overlay';
import UserProfile from 'helpers/userProfile';

function DM({ owners: _owners, ownerPageDetails: _ownerPageDetails, requestOwners: _requestOwners, requestStaffs: _requestStaffs, staffs: _staffs, displayMessageAction: _displayMessageAction, importFilesAction: _importFilesAction, requestPdf: _requestPdf, requestCSV: _requestCSV, requestDMCSV: _requestDMCSV, requestBukkenCSV: _requestBukkenCSV }) {

    const { t } = useTranslation();
    const [dmCsvExportRequest, setDmCsvExportRequest] = useState({});
    const [exportRequest, setExportRequest] = useState({});
    const [ownersData, setOwnersData] = useState(!_owners ? [] : _owners);
    const [ownerPageDetails, setOwnerPageDetails] = useState(!_ownerPageDetails ? {} : _ownerPageDetails);
    const [staffsData, setStaffsData] = useState(!_staffs ? [] : _staffs);
    const [menu, setMenu] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(false);
    const [exportState, setExportState] = useState(0);
    const [formData, setFormData] = useState({});
    const [checkAllState, setCheckAllState] = useState(false);
    const [bukkenSearch, setBukkenSearch] = useState(false);

    const switchMenu = (_presetRequest, _o) => {
        setMenu(!menu);
    }

    const exportModes = {
        pdfOutput: 'pdfOutput',
        csvOutput: 'csvOutput',
        smsMailOutput: 'smsMailOutput',
        dmCsvOutput: 'dmCsvOutput',
        bukkenOutput: 'bukkenOutput'
    };

    const handlerDMCSV = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setDmCsvExportRequest({ ...dmCsvExportRequest, ...update });
    }

    const handlerCSV = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setExportRequest({ ...exportRequest, ...update });
    }

    const postFailure = () => {
        _displayMessageAction(t('FAIL'), t('FAIL'));
        setExportState(false);
        setIsActive(false);
    }

    const postSuccess = () => {
        setExportState(false);
        setIsActive(false);
    }

    useEffect(() => {
        setExportState(true);

        _requestOwners({
            loggedInStaffId: UserProfile.getId(),
            pageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));

        _requestStaffs();
    }, []);

    useEffect(() => {
        if (_owners) {
            setOwnersData(_owners);
        }
    }, [_owners]);

    useEffect(() => {
        if (_ownerPageDetails) {
            setOwnerPageDetails(_ownerPageDetails);
        }
    }, [_ownerPageDetails]);

    useEffect(() => {
        if (_staffs) {
            setStaffsData(_staffs);
        }
    }, [_staffs]);

    useEffect(() => {
        if (exportState != 0)
            setIsActive(exportState);
    }, [exportState]);

    const tableAccessor = (_field) => (_d) => typeof _d === 'object' ? '' : _d;
    const tableAccessorDate = (_field) => (_d) => typeof _d === 'object' ? '' : new Date(_d).toLocaleDateString();

    const tableOptions = {
        columnsOptions: bukkenSearch ? {
            check: { header: t('check'), sortable: false, type: 'checkbox', width: 25 },
            name: { header: t('propertyName'), accessor: tableAccessor('name'), sortable: false },
            address: { header: t('propertyAddress'), accessor: tableAccessor('address'), sortable: false },
            roomNum: { header: t('roomNumber1'), accessor: tableAccessor('roomNum'), sortable: false },
            roomNum2: { header: t('roomNumber2'), accessor: tableAccessor('roomNum2'), sortable: false },
            heibei: { header: t('roomHeibei'), accessor: tableAccessor('heibei'), sortable: false }
        } : {
                check: { header: t('check'), sortable: false, type: 'checkbox', width: 25 },
                name: { header: t('fullName'), accessor: tableAccessor('name'), sortable: false },
                address: { header: t('streetAddress'), accessor: tableAccessor('address'), sortable: false },
                buildingName: { header: t('buildingName'), accessor: tableAccessor('buildingName'), sortable: false },
                dmLastSentDate: { header: t('dmLastSentDate'), accessor: tableAccessorDate('dmLastSentDate'), sortable: false },
                propertyName: { header: t('propertyName'), accessor: tableAccessor('propertyName'), sortable: false }
            },
        style: { maxHeight: 440 },
        pageSize: 100
    };

    const checkAll = () => {
        setCheckAllState(true);
        const updated = [];
        const list = [...ownersData];
        list.forEach(function (_value) {
            _value['check'] = true;
            updated.push(_value);
        });
        setOwnersData(updated);
    };

    const releaseAll = () => {
        setCheckAllState(false);
        const updated = [];
        const list = [...ownersData];
        list.forEach(function (_value) {
            _value['check'] = false;
            updated.push(_value);
        });
        setOwnersData(updated);
    };

    const checkPage = () => {
        const updated = [];
        const list = [...ownersData];
        list.forEach(function (_value) {
            _value['check'] = true;
            updated.push(_value);
        });
        setOwnersData(updated);

        setOwnersData(updated);
    };

    const releasePage = () => {
        const updated = [];
        const list = [...ownersData];
        list.forEach(function (_value) {
            _value['check'] = false;
            updated.push(_value);
        });
        setOwnersData(updated);
    };

    const checkedItemLength = () => {
        const updated = [];
        const list = [...ownersData];
        list.forEach(function (_value) {
            if (_value['check'] === true)
                updated.push(_value);
        });
        return checkAllState ? ownerPageDetails.totalCount : updated.length;
    };

    const pageCallback = (_c) => {
        if (currentPage != _c) {
            let records = [...ownersData];
            setExportState(true);
            _requestOwners({
                ...formData,
                pageDetails: {
                    isPrev: (currentPage > _c) ? true : false,
                    latestValue: records[records.length - 1].id,
                    currentPage: _c,
                    pageSize: 100
                }
            }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
            setCurrentPage(_c);
        }
    };

    const callback = (_updated) => setSelected(_updated);

    const setSelected = (_value) => {
        const updated = [...ownersData];
        const index = updated.findIndex(_v => _v.id === _value.id);

        if (index >= 0) {
            updated[index] = _value;
            setOwnersData(updated);
        }
    };


    const generateNoItemSelectedPopup = () => {
        _displayMessageAction((''), t('noOwnerSelectedErrorMessage'),
            {
                text: t('okEng'),
                callback: () => {
                    console.log(t('noOwnerSelectedErrorMessage'));
                }
            }, false);
    }


    //This is just for testing
    const generateUploadPopup = (_presetRequest, _o) => {
        switch (_presetRequest) {
            case 'FailedImport':
                _importFilesAction('FailedImport', t('undeliveredImport'));
                break;
            case 'ECImport':
                _importFilesAction('ECImport', t('ecImport'));
                break;
            case 'DmExportError':
                _importFilesAction('DmExportError', t('dmExportError'));
                break;
            case 'DmOfferPriceImport':
                _importFilesAction('DmOfferPriceImport', t('dmOfferPriceImport'));
                break;
            case 'BukkenImport':
                _importFilesAction('BukkenImport', t('bukkenCsvImport'));
                break;
            default:
                _importFilesAction();
                break;

        }
    }

    const exportPdfCsv = (_presetRequest, _o) => {
        const outList = [];
        const idList = [];
        const list = [...ownersData];
        list.forEach(function (_value) {
            if (_value['check'] === true) {
                outList.push(_value);
                idList.push(_value['id']);
            }
        });

        //Set from session
        dmCsvExportRequest.staffId = UserProfile.getId();
        exportRequest.staffId = UserProfile.getId();

        if (outList.length == 0) {
            switch (_presetRequest) {
                case exportModes.pdfOutput:
                    generateNoItemSelectedPopup();
                    break;
                case exportModes.csvOutput:
                    generateNoItemSelectedPopup();
                    break;
                case exportModes.smsMailOutput:
                    generateNoItemSelectedPopup();
                    break;
                case exportModes.bukkenOutput:
                    generateNoItemSelectedPopup();
                    break;
                case exportModes.dmCsvOutput:
                    setExportState(true);
                    _requestDMCSV(dmCsvExportRequest, postSuccess.bind(null, ''), postFailure.bind(null, ''));
                    break;
            }
        }
        else {
            switch (_presetRequest) {
                case exportModes.pdfOutput:
                    setExportState(true);
                    exportRequest.ids = checkAllState ? ownerPageDetails.totalIds : idList;
                    _requestPdf(exportRequest, postSuccess.bind(null, ''), postFailure.bind(null, ''));
                    break;
                case exportModes.csvOutput:
                    setExportState(true);
                    exportRequest.ids = checkAllState ? ownerPageDetails.totalIds : idList;;
                    exportRequest.senderName = '';
                    _requestCSV(exportRequest, postSuccess.bind(null, ''), postFailure.bind(null, ''));
                    break;
                case exportModes.smsMailOutput:
                    setExportState(true);
                    exportRequest.ids = checkAllState ? ownerPageDetails.totalIds : idList;;
                    exportRequest.senderName = 'smsAndMailButton';
                    _requestCSV(exportRequest, postSuccess.bind(null, ''), postFailure.bind(null, ''));
                    break;
                case exportModes.bukkenOutput:
                    setExportState(true);
                    exportRequest.ids = checkAllState ? ownerPageDetails.totalIds : idList;;
                    _requestBukkenCSV(exportRequest, postSuccess.bind(null, ''), postFailure.bind(null, ''));
                    break;
                case exportModes.dmCsvOutput:
                    setExportState(true);
                    _requestDMCSV(dmCsvExportRequest, postSuccess.bind(null, ''), postFailure.bind(null, ''));
                    break;
            }
        }
    }

    const onSumbitCallback = (_response, _o) => {

        let data = _response.formData;

        data.loggedInStaffId = UserProfile.getId();

        if (Object.entries(data).length === 0 && data.constructor === Object) {
            data.isBukkenSearch = _response.isBukkenSearch;
            setBukkenSearch(_response.isBukkenSearch);
            releaseAll();
        }
        else {
            if (data.isBukkenSearch) {
                data.isBukkenSearch = false;
                setBukkenSearch(false);
                releaseAll();
            }
        }

        setFormData(data);
        setExportState(true);
        _requestOwners({
            ...data,
            pageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        setCurrentPage(1);
    };

    const generateButtonBanner = () => {
        return <>
            <Row>
                <Col md={12}>
                    <div className="card">
                        <div className="card-block">
                            <section className="section title-section">
                                <Row md={12}>
                                    <div className="button-container">
                                        <div className="btn-group">
                                            <button disabled={!bukkenSearch} type="button" onClick={exportPdfCsv.bind(null, exportModes.bukkenOutput)} className={"btn btn-secondary"}>{t('bukkenExport')}</button>
                                            <button disabled={bukkenSearch} type="button" onClick={exportPdfCsv.bind(null, exportModes.pdfOutput)} className={"btn btn-secondary"}>{t('pdfExport')}</button>
                                            <button disabled={bukkenSearch} type="button" onClick={exportPdfCsv.bind(null, exportModes.csvOutput)} className={"btn btn-secondary"}>{t('csvExport')}</button>
                                            <button disabled={bukkenSearch} type="button" onClick={exportPdfCsv.bind(null, exportModes.smsMailOutput)} className={"btn btn-secondary"}>{t('smsMail')}</button>
                                        </div>
                                    </div>
                                    <div className="button-container button-filler flex-grow-1 pd-left">
                                        <div className="btn-group">
                                            <helpers.GenerateCheckbox text={t('includeHistory')} value={exportRequest.rireki} onChangeHandler={(_v) => handlerCSV('rireki', _v)} />
                                            <div disabled={exportRequest.houmonYotei && true}><helpers.GenerateCheckbox text={t('doNotRecordOutputTimeStamp')} value={exportRequest.recPrintDate} onChangeHandler={(_v) => handlerCSV('recPrintDate', _v)} /></div>
                                            <div disabled={exportRequest.recPrintDate && true}><helpers.GenerateCheckbox text={t('scheduledVisit')} value={exportRequest.houmonYotei} onChangeHandler={(_v) => handlerCSV('houmonYotei', _v)} /></div>
                                            <helpers.GenerateCheckbox text={t('includeBuildingAge')} value={exportRequest.chikunen} onChangeHandler={(_v) => handlerCSV('chikunen', _v)} />
                                        </div>
                                    </div>
                                    <div className="button-container">
                                        <div className="btn-group">
                                            <button type="button" onClick={generateUploadPopup.bind(null, 'FailedImport')} className={"btn btn-secondary"}>{t('undeliveredImport')}</button>
                                            <button type="button" onClick={generateUploadPopup.bind(null, 'ECImport')} className={"btn btn-secondary"}>{t('ecImport')}</button>
                                            <button type="button" onClick={generateUploadPopup.bind(null, 'DmExportError')} className={"btn btn-secondary"}>{t('dmExportError')}</button>
                                            <button type="button" onClick={generateUploadPopup.bind(null, 'DmOfferPriceImport')} className={"btn btn-secondary"}>{t('dmOfferPriceImport')}</button>
                                            <button type="button" onClick={generateUploadPopup.bind(null, 'BukkenImport')} className={"btn btn-secondary"}>{t('bukkenCsvImport')}</button>
                                        </div>
                                    </div>
                                </Row>
                            </section>
                            <section className="section title-section margin-align">
                                <Row md={12}>
                                    <div className="button-container">
                                        <div className="btn-group">
                                            <button type="button" onClick={exportPdfCsv.bind(null, exportModes.dmCsvOutput)} className={"dm-button btn btn-secondary"}>{t('DMCSVExport')}</button>
                                            <Col md={3}>
                                                <helpers.GenerateDatePicker value={dmCsvExportRequest.lastPrintDateCalendar} onChangeHandler={(_v) => handlerDMCSV('lastPrintDateCalendar', _v)} text={t('dmLastSentDate')} showIcon={true} labelSize={4} />
                                            </Col>
                                            <Col md={1}>
                                                <helpers.GenerateInput text={t('count')} value={dmCsvExportRequest.dmCounter} onChangeHandler={(_v) => handlerDMCSV('dmCounter', _v)} labelSize={7} />
                                            </Col>
                                            <helpers.GenerateCheckbox text={t('internal')} value={dmCsvExportRequest.tonaiOnly} onChangeHandler={(_v) => handlerDMCSV('tonaiOnly', _v)} />
                                            <Col md={1}>
                                                <helpers.GenerateInput value={dmCsvExportRequest.area} onChangeHandler={(_v) => handlerDMCSV('area', _v)} />
                                            </Col>
                                            <Col md={2}>
                                                <helpers.GenerateInputRange text={t('buildingAge')} addon={t('year')} range={dmCsvExportRequest.chikuYear} onChangeHandler={(_v) => handlerDMCSV('chikuYear', _v)} labelSize={2} />
                                            </Col>
                                            <helpers.GenerateCheckbox text={t('noFixed')} value={dmCsvExportRequest.koteinashi} onChangeHandler={(_v) => handlerDMCSV('koteinashi', _v)} />
                                            <helpers.GenerateCheckbox text={t('noMobile')} value={dmCsvExportRequest.keitainashi} onChangeHandler={(_v) => handlerDMCSV('keitainashi', _v)} />
                                            <helpers.GenerateCheckbox text={t('buildingAgeExport')} value={dmCsvExportRequest.chiku} onChangeHandler={(_v) => handlerDMCSV('chiku', _v)} />
                                        </div>
                                    </div>
                                </Row>
                            </section>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="banner-separator"></div>
        </>;
    }

    const generateTable = () => {
        return <>
            <div className="card">
                <div className="card-block">
                    <section className="section title-section">
                        <Row md={12} className="button-container-align">
                            <div className="button-container">
                                <div className="btn-group">
                                    <button type="button" onClick={checkAll.bind(null, '')} className={"btn btn-secondary"}>{t('fullCheck')}</button>
                                    <button type="button" onClick={releaseAll.bind(null, '')} className={"btn btn-secondary"}>{t('allRelease')}</button>
                                </div>
                            </div>
                            <div className="button-container button-filler flex-grow-1">
                                <div className="btn-group">
                                    <button type="button" onClick={checkPage.bind(null, '')} className={"btn btn-secondary"}>{t('pageCheck')}</button>
                                    <button type="button" onClick={releasePage.bind(null, '')} className={"btn btn-secondary"}>{t('pageRelease')}</button>
                                </div>
                            </div>
                            <div className="button-container">
                                <div className="btn-group">
                                    <label className="title-label flex flex-wrap">{t('noOfCheck') + ': ' + checkedItemLength() + t('-itemCounter')}</label>
                                </div>
                            </div>
                        </Row>
                    </section>
                    <section className="section table-section">
                        {_owners && < PageTable {...tableOptions} rows={[...ownersData]} updateCallback={callback} pageChangeCallback={pageCallback} pageDetails={ownerPageDetails} loading={isActive} />}
                    </section>
                </div>
            </div>
        </>;
    };

    const dmFormProps = {
        onSubmitCallback: onSumbitCallback,
        employeeList: staffsData
    }

    return <LoadingOverlay
        active={false}
        spinner
        text={t('inProcessLoading')}
    >
        <article className="content dm-page">
            <section className="section">
                <Row className="sameheight-container ">
                    <Col md={12}>
                        <div className="card card-block sameheight-item">
                            <div className="tab-content">
                                <div className="dm-data-container">
                                    <div className="data-top-wrapper">
                                        {generateButtonBanner()}
                                    </div>
                                    <div className="data-bottom-wrapper relative">
                                        <ReflexContainer orientation="vertical">
                                            <ReflexElement flex={0.61}>
                                                <ReflexContainer orientation="horizontal">
                                                    <ReflexElement>
                                                        <DMForm {...dmFormProps} />
                                                    </ReflexElement>
                                                </ReflexContainer>
                                            </ReflexElement>
                                            <ReflexSplitter />
                                            <ReflexElement flex={0.39}>
                                                <ReflexContainer orientation="horizontal">
                                                    <ReflexElement>
                                                        {generateTable()}
                                                    </ReflexElement>
                                                </ReflexContainer>
                                            </ReflexElement>
                                        </ReflexContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div onClick={switchMenu.bind(null, '')} className="scrollBarMenu">MENU</div>
                {menu &&
                    <div className="scrollBarDiv">
                        <div><a href="/dm#ownerInformation" className="scroll">{t('ownerInformation')}</a></div>
                        <div><a href="/dm#propertyInformation" className="scroll">{t('propertyInformation')}</a></div>
                        <div><a href="/dm#loanInformation" className="scroll">{t('loanInformation')}</a></div>
                        <div><a href="/dm#saveOrClear" className="scroll">{t('saveOrClear')}</a></div>
                    </div>}
            </section>
        </article>
    </LoadingOverlay>
}

const mapStateToProps = (_state) => {
    return {
        owners: _state.dm.owners.records,
        ownerPageDetails: _state.dm.owners.pageDetails,
        staffs: _state.dm.staffs
    }
}

const mapDispatchToProps = {
    requestOwners: requestOwners,
    requestStaffs: requestStaffs,
    displayMessageAction: displayMessageAction,
    importFilesAction: importFilesAction,
    requestPdf: requestPdf,
    requestCSV: requestCSV,
    requestDMCSV: requestDMCSV,
    requestBukkenCSV: requestBukkenCSV
}

export default connect(mapStateToProps, mapDispatchToProps)(DM);