import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleToggle } from 'components/inputs/toggles';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Sold({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const soldByTypes = ["unspecified", "anotherCompany", "ourCompany"].reduce(_checkboxGroupHOC.checkboxGroupReducer('soldByTypes'), {});
    const sold = data.sold.value || data.sold.defaultValue || false;
    return <>
        <wf.GroupField hideLabel={true}>
            <SimpleToggle label={t('sold')} value={sold}
                onChange={setData('sold')} />
        </wf.GroupField>
        <wf.CheckboxGroupField disabled={!sold} hideLabel={true} values={soldByTypes} onChange={(_v) => setData('soldByTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="soldByTypes" />
        <wf.InputField disabled={!sold} hideLabel={true} value={sold ? data.soldAmount.value || data.soldAmount.defaultValue : ''}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('soldAmount')}
            addon={t('10kYen')} />
    </>;
}

const SoldPopup = ExportDefaultBehaviour(Sold, {
    editableFields: ['sold', 'soldByTypes', 'soldAmount']
});
SoldPopup.whyDidYouRender = true;
export default SoldPopup;