import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function MonthlyRepayment({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <wf.InputField hideLabel={true}
        value={data.monthlyRepayment.value || data.monthlyRepayment.defaultValue}
        className="form-group"
        onChange={setData('monthlyRepayment')}
        validator={_validatorsHOC.optionalDayHours}
        errorMessage={t('incorrectNumberFormatError')}
        addon={t('en')} />
}
const MonthlyRepaymentPopup = ExportDefaultBehaviour(MonthlyRepayment, {
    editableFields: ['monthlyRepayment'],
    size: 'sm'
});
MonthlyRepaymentPopup.whyDidYouRender = true;
export default MonthlyRepaymentPopup;