import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { StatsDescription } from './statsDescription';

export class TeamDescription extends Component {

    render() {
        return (

            <section className={"section" + this.props.extraClasses}>
                <div className="card sameheight-item items">
                <div className="sameheight-container">
                    <Col xl={12}>
                        
                            <StatsDescription
                                name={this.props.name}
                                ranking={this.props.ranking}
                                values={this.props.values}
                                extraClasses="card-header bordered"
                                handleChangedCallback={this.props.handleChangedCallback ? this.props.handleChangedCallback : undefined} />
                        
                    </Col>
                </div>
                    <div className="sameheight-container">
                    {
                        this.props.members.map((_m, _k) => {
                                return <StatsDescription
                                    name={_m.name}
                                    ranking={_m.ranking}
                                    values={_m.stats}
                                    key={_k}
                                    extraClasses="team-member"
                                    handleChangedCallback={this.props.handleChangedCallback ? (_val) => (this.props.handleChangedCallback({ ..._val, memberId: _m.id })) : undefined}
                                />
                            })
                    }
                    </div>
                </div>
            </section>
        );
    }
}

TeamDescription.defaultProps = {
    extraClasses: '',
    ranking: 0,
    name: '',
    values: [],
    members: []
};