import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { formatDateTimeShort } from 'helpers/formatHelpers';
import { validatorPhone, validatorEmail, validatorNumbersOnly, validatorDouble } from 'helpers/validatorHelpers';

export function FloatingWindow({ metadata: _metadata, hasError: _hasError, size: _size, extraClasses: _extraClasses, onChange: _onChange, onIdle: _onIdle, children: _children}) {
    const { t } = useTranslation();
    const buttonSize = _size === 'sm' ? '6' : '3';

    return <div className="floating-container absolute fill-w relative" onClick={_onIdle ? _onIdle : () => { }}>
        <div className={`floating-window${_size ? '-' + _size : ''} card absolute ${_extraClasses}`} onClick={(_e) => _e.stopPropagation()}>
            <div className="window-title title-block flex">
                <h3 className="title single-line">{t('lastUpdate')}</h3>
                <span className="subtitle ellipsize">{`${_metadata.lastUpdate ? formatDateTimeShort(_metadata.lastUpdate, false) : ''} ${_metadata.author}`}</span>
            </div>
            {_children}
            <div className="actions-container form-group row justify-content-end no-gutters">
                <div className={`col-xs-${buttonSize}`}>
                    <button type="button" className="btn btn-primary fill-w" disabled={_hasError} onClick={_onChange ? _onChange : () => { }}>{t('saveChanges')}</button>
                </div>
                <div className={`col-xs-${buttonSize}`}>
                    <button type="button" className="btn btn-secondary fill-w" onClick={_onIdle ? _onIdle : () => { }}>{t('cancel')}</button>
                </div>
            </div>
        </div>
    </div>;
}
FloatingWindow.defaultProps = {
    extraClasses: '',
    metadata: { author: '' }
}

const ExportDefaultBehaviour = (_wrapped, _options) => {
    const WithHOCComponent = (_props) => {
        const { t } = useTranslation();
        const wrapperName = _wrapped.name[0].toLowerCase() + _wrapped.name.slice(1);
        const defaultData = _props.data[wrapperName] || {};
        const [data, setData] = useState(_options && _options.editableFields ? _options.editableFields.reduce((_a, _b) => ({ ..._a, [_b]: { defaultValue: defaultData[_b] } }), {}) : undefined);
        const updateState = (_field) => (_value, _options = {}) => {
            
                setData((_state) => {
                    if (_value !== _state[_field].value || _options.isError !== _state[_field].hasError || _options.isSubmitted !== _state.isSubmitted) {
                        const error = {};
                        const autoSubmit = { isSubmitted: false };
                        if (_options.isError != null) {
                            error.hasError = _options.isError;
                        }
                        if (_options.isSubmitted != null) {
                            autoSubmit.isSubmitted = _options.isSubmitted;
                        }
                        return ({ ..._state, [_field]: { value: _value, ...error }, ...autoSubmit });
                    } else {
                        return _state;
                    }
                });
            
        };
        
        const hocProps = {
            useStateHOC: [data, updateState],
            checkboxGroupHOC: {
                checkboxGroupReducer: (_field) => (_a, _b) => ({ ..._a, [_b]: { checked: (data[_field].value || data[_field].defaultValue || {})[_b] || false, label: t(_b) } }),
                checkboxGroupHandlerDecorator: (_v) => Object.keys(_v).reduce((_a, _b) => ({ ..._a, [_b]: _v[_b].checked }), {})
            },
            errorHandlingHOC: {
                errorDecorator: (_m, _extraClasses = '') => <div className={`error-container absolute fill-w ${_extraClasses}`}><div className="btn btn-danger modal-error-message">{_m}</div></div>
            },
            validatorsHOC: {
                optionalDayHours: (_v) => !_v || validatorNumbersOnly(_v, 0),
                yearValidator: (_v) => !_v || (validatorNumbersOnly(_v, 4, 4) && Number(_v) > 0 && Number(_v) <= new Date().getFullYear()),
                monthValidator: (_v) => !_v || (validatorNumbersOnly(_v, 0, 2) && Number(_v) > 0 && Number(_v) <= 12),
                phoneValidator: (_v) => !_v || validatorPhone(_v),
                emailValidator: (_v) => !_v || (typeof _v === 'string' && _v.length < 1) || validatorEmail(_v),
                doubleValidator: (_v) => !_v || (typeof _v === 'string' && _v.length < 1) || validatorDouble(_v, 0)
            }
        };

        const submit = (_data = data) => {
            const toSubmit = { ..._data };
            delete toSubmit.isSubmitted;

            if (!Object.keys(toSubmit).some(_k => toSubmit[_k].hasError) && _props.onChange) {
                const output = Object.keys(toSubmit).reduce((_a, _b) => {
                    const value = toSubmit[_b].value || toSubmit[_b].defaultValue;
                    return value != null ? { ..._a, [_b]: value } : _a;
                }, {});
                output.metadata = { ...defaultData.metadata, lastUpdate: Date.now() }
                _props.onChange({ [wrapperName]: output });
            }
        };

        const onChangeHandler = () => submit();

        useEffect(() => { if (data.isSubmitted) submit(data); }, [data]);

        return <FloatingWindow hasError={Object.keys(data).some(_k => data[_k].hasError)} onChange={onChangeHandler} onIdle={_props.onIdle} metadata={defaultData.metadata} {..._options}>
            <_wrapped {..._props} {...hocProps} data={_props.data[wrapperName]}/>
        </FloatingWindow>
    }
    WithHOCComponent.whyDidYouRender = true;
    return WithHOCComponent;
}

export default ExportDefaultBehaviour;