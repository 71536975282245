import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function CityTax({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField hideLabel={true} value={data.cityTax.value || data.cityTax.defaultValue}
            validator={_validatorsHOC.optionalDayHours}
            errorMessage={t('incorrectNumberFormatError')}
            onChange={setData('cityTax')}
            addon={t('en')}/>
    </>;
}

const CityTaxPopup = ExportDefaultBehaviour(CityTax, {
    editableFields: ['cityTax'],
    size: 'sm'
});
CityTaxPopup.whyDidYouRender = true;
export default CityTaxPopup;