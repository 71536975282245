import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function SimpleMessageWithTextBox({ title: _title, titleIcon: _titleIcon, message: _message, okText: _okText, cancelText: _cancelText, onOkCallback: _onOkCallback, onCancelCallback: _onCancelCallback }) {

    const { t } = useTranslation();
    const [shown, setShown] = useState(true);
    const [data, setData] = useState({});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const uploadHandler = _onOkCallback ? () => _onOkCallback(data) : () => { };

    const updateName = (_v) => updateState('name', _v);

    const generateTextField = () => {
        return <>
            <div className="single-line">
                <helpers.GenerateInput text={t('propertyName')} onChangeHandler={updateName} value={data.name} extraClasses="name-container" />
            </div>
        </>;
    }

    return <div className="simple-message-modal modal fade show">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{_titleIcon && <i class={"fa " + _titleIcon} />}{_title}</h4>
                    <button type="button" className="close" onClick={_onCancelCallback}>
                        <span>&times;</span>
                        <span className="sr-only">{_title}</span>
                    </button>
                </div>
                <div className="simple-message-body modal-body modal-tab-container flex justify-center">
                    {shown ? _message : generateTextField()}
                </div>
                {shown ? <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => setShown(false)}>Yes</button>
                    <button type="button" className="btn btn-secondary" onClick={_onCancelCallback} >No</button>
                </div> : <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={uploadHandler}>{_okText}</button>
                        <button type="button" className="btn btn-secondary" onClick={_onCancelCallback} >{_cancelText}}</button>
                    </div>}
            </div>
        </div>
    </div>;
}