import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function BuildingAge({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC, errorHandlingHOC: _errorHandlingHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const crossHandler = (_yearValue, _monthValue) => {
        setData('yearMonth')(undefined, { isError: (_monthValue !== null && _monthValue !== '') && (_yearValue === null || _yearValue === '')})
    }
    const numberErrorMessage = _errorHandlingHOC.errorDecorator('invalidBuildAgeError', 'cross-input-error');
    return <>
        <wf.GroupField hideLabel={true}>
            <div className="row no-gutters fill-w">
                <div className="col-xs-5">
                    <wf.InputField hideLabel={true}
                        value={data.year.value || data.year.defaultValue}
                        validator={_validatorsHOC.yearValidator}
                        onChange={(_v, _opts) => {
                            setData('year')(_v, _opts);
                            crossHandler(_v, data.month.value === undefined ? data.month.defaultValue : data.month.value);
                        }}
                        addon={t('year')}
                        errorMessage={t('incorrectNumberFormatError')} />
                </div>
                <div className="offset-2 col-xs-5">
                    <wf.InputField hideLabel={true}
                        value={data.month.value || data.month.defaultValue}
                        validator={_validatorsHOC.monthValidator}
                        onChange={(_v, _opts) => { setData('month')(_v, _opts); crossHandler(data.year.value, _v);}}
                        addon={t('month') + t('-built')}
                        errorMessage={t('incorrectNumberFormatError')} />
                </div>
            </div>
            {data.yearMonth.hasError && !data.month.hasError && numberErrorMessage}
        </wf.GroupField>
    </>
}
const BuildingAgePopup = ExportDefaultBehaviour(BuildingAge, {
    editableFields: ['year', 'month', 'yearMonth']
});
BuildingAgePopup.whyDidYouRender = true;
export default BuildingAgePopup;