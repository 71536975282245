import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function BankDebt({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    const debtType = ['unspecified', 'done', 'improper'].reduce(_checkboxGroupHOC.checkboxGroupReducer('debtType'), {});

    return <>
        <wf.CheckboxGroupField
            values={debtType}
            onChange={(_v) => setData('debtType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))}
            radioName="debtType" />
        <wf.InputField label={t('savings')}
            value={(data.debtType.value || data.debtType.defaultValue || {}).done ? data.savings.value || data.savings.defaultValue : ''}
            className="form-group"
            onChange={setData('savings')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('10kYen')}
            disabled={!(data.debtType.value || data.debtType.defaultValue || {}).done} />
        <wf.InputField label={t('debt')}
            value={(data.debtType.value || data.debtType.defaultValue || {}).done ? data.debt.value || data.debt.defaultValue : ''}
            className="form-group"
            onChange={setData('debt')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('10kYen')}
            disabled={!(data.debtType.value || data.debtType.defaultValue || {}).done} />
        <wf.InputField label={t('bankDebtMemo')}
            value={data.remarks.value || data.remarks.defaultValue}
            className="form-group"
            onChange={setData('remarks')} />
    </>
}
const BankDebtPopup = ExportDefaultBehaviour(BankDebt, {
    editableFields: ['debtType', 'savings', 'debt', 'remarks']
});
BankDebtPopup.whyDidYouRender = true;
export default BankDebtPopup;