
import PageTable from 'components/tables/pageTable';
import ResponsiveDatatable from 'components/tables/responsiveDatatable';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requestPropertyRoomsCSV } from 'reducers/exportReducers';
import { checkUnknownProperty, deleteBuildingAsset, deleteOwner, deletePropertyRoom, insertBuildingAsset, insertOwner, insertRoom, insertUnknownProperty, requestBuildingAssets, requestOwnerRooms, requestOwners, requestPropertyRooms, requestStaffs, requestZaitakuDetails, updateBuildingAsset, updateOwner, updateOwnerRoom, updateRoom } from 'reducers/maintenanceReducers';
import { displayMessageAction, displayMessageWithTextBoxAction } from 'reducers/priorityActionsReducers';
import { OwnerAddForm } from './ownerAddForm';
import { OwnerForm } from './ownerForm';
import { PropertyAddForm } from './propertyAddForm';
import { PropertyForm } from './propertyForm';
import { RoomAddForm } from './roomAddForm';

function Maintenance({ ownersPageDetails: _ownersPageDetails, buildingAssetsPageDetails: _buildingAssetsPageDetails, requestBuildingAssets: _requestBuildingAssets, requestOwnerRooms: _requestOwnerRooms, requestOwners: _requestOwners, requestPropertyRoomsCSV: _requestPropertyRoomsCSV, requestZaitakuDetails: _requestZaitakuDetails, checkUnknownProperty: _checkUnknownProperty, requestStaffs: _requestStaffs, buildingAssets: _buildingAssets, owners: _owners, staffs: _staffs, zaitakuDetails: _zaitakuDetails, propertyRooms: _propertyRooms, deleteBuildingAsset: _deleteBuildingAsset, deletePropertyRoom: _deletePropertyRoom, requestPropertyRooms: _requestPropertyRooms, updateBuildingAsset: _updateBuildingAsset, updateRoom: _updateRoom, updateOwnerRoom: _updateOwnerRoom, insertBuildingAsset: _insertBuildingAsset, insertRoom: _insertRoom, insertUnknownProperty: _insertUnknownProperty, updateOwner: _updateOwner, insertOwner: _insertOwner, deleteOwner: _deleteOwner, displayMessageAction: _displayMessageAction, displayMessageWithTextBoxAction: _displayMessageWithTextBoxAction }) {
    const { t } = useTranslation();
    const searchPages = ['propertyInformation', 'ownerInformation'];
    const [currentMode, setCurrentMode] = useState(0);
    const [currentSearchMode, setCurrentSearchMode] = useState(searchPages[0]);
    const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
    const [currentOwnerIndex, setCurrentOwnerIndex] = useState(0);
    const [currentRoomIndex, setCurrentRoomIndex] = useState(0);
    const [isAddOwnedRoomPage, setIsAddOwnedRoomPage] = useState(false);
    const [buildingAssetssData, setBuildingAssetssData] = useState(!_buildingAssets ? [] : _buildingAssets);
    const [buildingAssetsPageData, setBuildingAssetsPageData] = useState(!_buildingAssetsPageDetails ? {} : _buildingAssetsPageDetails);
    const [ownersData, setOwnersData] = useState(!_owners ? [] : _owners);
    const [ownersPageData, setOwnersPageData] = useState(!_ownersPageDetails ? {} : _ownersPageDetails);
    const [staffsData, setStaffsData] = useState(!_staffs ? [] : _staffs);
    const [zaitakuDetailsData, setZaitakuDetailsData] = useState(!_zaitakuDetails ? [] : _zaitakuDetails);
    const [propertyRoomsData, setPropertyRoomsData] = useState(!_propertyRooms ? [] : _propertyRooms);
    const [formData, setFormData] = useState({});
    const [isActive, setIsActive] = useState(false);
    const [exportState, setExportState] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const actions = {
        registerProperty: 'registerProperty',
        deleteProperty: 'deleteProperty',
        editProperty: 'editProperty',
        registerRoom: 'registerRoom',
        editRoom: 'editRoom',
        deletePropertyRoom: 'deletePropertyRoom',
        deleteOwnerRoom: 'deleteOwnerRoom',
        registerOwner: 'registerOwner',
        deleteOwner: 'deleteOwner',
        editOwner: 'editOwner',
        addOwnedRoom: 'addOwnedRoom'
    };

    var actionsMap = new Map();

    for (const action in actions) {
        actionsMap.set(action, action);
    }

    var searchPageMap = new Map();

    searchPages.forEach((v, i, a) => { searchPageMap.set(v, v) });

    useEffect(() => {
        _requestBuildingAssets({
            pageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        _requestOwners({
            pageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        //_requestPropertyRooms({
        //    id: -1
        //});
        _requestStaffs();

        setExportState(true);
        setPropertyRoomsData([]);
        setFormData({});
        setCurrentPage(1);
    }, [currentSearchMode]);

    useEffect(() => {
        if (exportState != 0)
            setIsActive(exportState);
    }, [exportState]);


    useEffect(() => {
        if (_buildingAssets) {
            setBuildingAssetssData(_buildingAssets);
            setBuildingAssetsPageData(_buildingAssetsPageDetails);
        }
    }, [_buildingAssets]);

    useEffect(() => {
        if (_owners) {
            setOwnersData(_owners);
            setOwnersPageData(_ownersPageDetails);
        }
    }, [_owners]);

    useEffect(() => {
        if (_propertyRooms) {
            setPropertyRoomsData(_propertyRooms);
        }
    }, [_propertyRooms]);

    useEffect(() => {
        if (_staffs) {
            setStaffsData(_staffs);
        }
    }, [_staffs]);

    useEffect(() => {
        if (_zaitakuDetails) {
            setZaitakuDetailsData(_zaitakuDetails);
        }
    }, [_zaitakuDetails]);

    const callback = (_value) => {
        const updated = [...propertyRoomsData];
        const index = updated.findIndex(_v => _v.id === _value.id);
        if (index >= 0) {
            updated[index] = _value;
            setPropertyRoomsData(updated);
        }
    };

    const generateMenuItem = (_id, _text) => {
        const handler = () => {
            setCurrentSearchMode(_id);
            setCurrentMode(0);
            setCurrentPropertyIndex(0);
            setCurrentOwnerIndex(-1);
            setCurrentRoomIndex(0);

            _requestPropertyRooms({
                id: 0
            });

        }
        return <li className="nav-item" onClick={handler}>
            <div className={'nav-link' + (_id === currentSearchMode ? ' active show' : '')}>{_text}</div>
        </li>
    }

    const propertyFormCallback = (_action, _o) => {
        setExportState(true);
        setCurrentMode(0);
        setCurrentPropertyIndex(0);
        setCurrentRoomIndex(0);
        setFormData(_o);
        setCurrentPage(1);
        setPropertyRoomsData([]);

        let urizumi = 0;
        if (_o.sold) urizumi = 1; else urizumi = 0;
        _requestBuildingAssets({
            bukkenName: _o.propertyName,
            bukkenAddress: _o.streetAddress,
            bukkenMoyoriEki: _o.nearestStation,
            roomUrizumi: urizumi,
            pageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));

        //_requestPropertyRooms({
        //    id: 0
        //});

    };

    const ownerFormCallback = (_action, _o) => {
        setExportState(true);
        setCurrentMode(0);
        setCurrentOwnerIndex(-1);
        setCurrentRoomIndex(-1);
        setFormData(_o);
        setCurrentPage(1);
        setPropertyRoomsData([]);

        _requestOwners({
            ownerName: _o.fullName,
            ownerAddress: _o.streetAddress,
            pageDetails: {
                currentPage: 1,
                pageSize: 100
            }
        }, postSuccess.bind(null, ''), postFailure.bind(null, ''));

        //_requestOwnerRooms({
        //    id: -1
        //});

    };

    const addOwnedRoomPageCallBack = (_presetRequest, _o) => {
        if (currentOwnerIndex == -1) {
            generateNoItemSelectedPopup();
        }
        else {
            setIsAddOwnedRoomPage(true);
            setCurrentMode(0);
            setCurrentSearchMode(undefined);
            setCurrentRoomIndex(0);
        }
    };

    const generateNoItemSelectedPopup = () => {
        _displayMessageAction((''), t('No Items Selected'),
            {
                text: t('okEng'),
                callback: () => {
                    console.log(t('No Items Selected'));
                }
            }, false);
    }

    const deleteBuildingAsset = () => {
        if (currentPropertyIndex) {
            _displayMessageAction(t('confirmDelete'), t('emConfirmDeleteMessage'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _deleteBuildingAsset(currentPropertyIndex, postSuccessDeleteBuildingAsset.bind(null, ''), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
        else {
            generateNoItemSelectedPopup();
        }
    }

    const postSuccessDeleteBuildingAsset = () => {
        _displayMessageAction(t('processingCompletedShort'), t('processingCompleted'));
        setCurrentSearchMode(searchPages[0]);
        setCurrentMode(0);
        setCurrentPropertyIndex(0);
        setCurrentRoomIndex(0);

        _requestPropertyRooms({
            id: 0
        });
    }

    const deletePropertyRoom = () => {
        const idList = [];
        const list = [...propertyRoomsData];
        list.forEach(function (_value) {
            if (_value['check'] === true)
                idList.push(_value['id']);
        });
        if (idList.length == -0) {
            generateNoItemSelectedPopup();
        }
        else {
            _displayMessageAction(t('confirmDelete'), t('emConfirmDeleteMessage'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _deletePropertyRoom({ ids: idList }, postSuccessDeletePropertyRoom.bind(null, ''), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
    }

    const csvOutput = () => {
        const outList = [];
        const list = [...propertyRoomsData];
        const propertyData = getSelectedProperty();
        list.forEach(function (_value) {
            if (_value['check'] === true)
                outList.push({ name: propertyData['name'], address: propertyData['address'], roomNum: _value['roomNum'] });
        });
        if (outList.length == -0) {
            generateNoItemSelectedPopup();
        }
        else {
            _displayMessageAction(t('confirmDelete'), t('emConfirmDeleteMessage'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _requestPropertyRoomsCSV(outList);
                    }
                }, true);
        }
    }

    const postSuccessDeletePropertyRoom = () => {
        _displayMessageAction(t('processingCompletedShort'), t('processingCompleted'));
        setCurrentSearchMode(searchPages[0]);
        setCurrentMode(0);
        setCurrentPropertyIndex(0);
        setCurrentRoomIndex(0);
    }

    const deleteOwner = () => {
        if (currentOwnerIndex) {
            _displayMessageAction(t('confirmDelete'), t('emConfirmDeleteMessage'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _deleteOwner(currentOwnerIndex, postSuccessDeleteOwner.bind(null, ''), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
        else {
            generateNoItemSelectedPopup();
        }
    }

    const postSuccessDeleteOwner = () => {
        _displayMessageAction(t('processingCompletedShort'), t('processingCompleted'));
        setCurrentSearchMode(searchPages[1]);
        setCurrentMode(0);
        setCurrentOwnerIndex(-1);
        setCurrentRoomIndex(0);

        _requestOwnerRooms({
            id: -1
        });
    }

    const addRoom = () => {
        if (currentRoomIndex) {
            _displayMessageAction(t('additionConfirmation'), t('additionConfirmation'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _updateOwnerRoom({ roomId: currentRoomIndex, ownerId: currentOwnerIndex }, postAddOwnerRoom.bind(null, ''), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
        else {
            generateNoItemSelectedPopup();
        }
    }

    const postAddOwnerRoom = () => {
        _displayMessageAction(t('processingCompletedShort'), t('processingCompleted'));
        setCurrentSearchMode(searchPages[1]);
        setIsAddOwnedRoomPage(false);
        setCurrentPropertyIndex(0);
        setCurrentOwnerIndex(-1);
        setCurrentRoomIndex(0);
    }

    const addDetailsUnknownProperty = () => {
        _displayMessageWithTextBoxAction(t('additionConfirmation'), t('registerDetailsUnknownPropertyMessage'),
            {
                text: t('addto')
            }, true, (_data) => {
                //Checking if the bukken name is present already
                _checkUnknownProperty({ propertyName: _data.name }, preAddDetailsUnknownProperty.bind(null, currentOwnerIndex, _data.name), _displayMessageAction.bind(null, t('duplicateBukkenName'), _data.name + t('duplicateBukkenNameErrorMsg')));
            });
    }

    const preAddDetailsUnknownProperty = (_ownerId, _propertyName) => {
        _insertUnknownProperty({ ownerId: _ownerId, propertyName: _propertyName }, postAddDetailsUnknownProperty.bind(null, ''), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
    }

    const postAddDetailsUnknownProperty = () => {
        _displayMessageAction(t('processingCompletedShort'), t('processingCompleted'));
        setCurrentPropertyIndex(0);
        setCurrentRoomIndex(0);
    }

    const sidePopupStateChange = (_action, _o) => {
        if (_o === actions.registerProperty)
            setCurrentMode(actions.registerProperty);
        else if (_o === actions.registerRoom)
            if (currentPropertyIndex == 0) generateNoItemSelectedPopup(); else setCurrentMode(actions.registerRoom);
        else if (_o === actions.registerOwner)
            setCurrentMode(actions.registerOwner);
        else if (_o === actions.editProperty)
            if (currentPropertyIndex == 0) generateNoItemSelectedPopup(); else setCurrentMode(actions.editProperty);
        else if (_o === actions.editRoom)
            if (currentRoomIndex == 0) generateNoItemSelectedPopup(); else setCurrentMode(actions.editRoom);
        else if (_o === actions.editOwner)
            if (currentOwnerIndex == -1) generateNoItemSelectedPopup(); else setCurrentMode(actions.editOwner);
    };

    const onBack = (_presetRequest, _o) => {
        setCurrentMode(0);
    };

    const prepareForUpdateRoom = (_data) => {
        var data = _data;
        var currentProperty = getSelectedProperty();

        //Setting holdCheck
        data.holdCheck = false;

        if (currentProperty.shinseishaId !== data.shinseishaId && currentProperty.shinseishaId !== 0) {
            _displayMessageAction(t('holdCheck'), t('holdCheckMsg'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _updateRoom(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    },
                    cancelCallback: () => {
                        data.holdCheck = true;
                        _updateRoom(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
        else {
            _updateRoom(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
        }
    };

    const prepareForUpdateProperty = (_data) => {
        var data = _data;
        const shinseishas = _propertyRooms;

        //Setting holdCheck
        data.holdCheck = true;

        if (shinseishas.length > 0) {
            shinseishas.forEach(_v => {
                if (data.shinseishaId !== _v.shinseishaId && _v.shinseishaId !== 0)
                    data.holdCheck = false;
            });
        }

        if (!data.holdCheck) {
            _displayMessageAction(t('holdCheck'), t('holdCheckPropertyMsg'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _updateBuildingAsset(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    },
                    cancelCallback: () => {
                        data.holdCheck = true;
                        _updateBuildingAsset(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
        else {
            _updateBuildingAsset(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
        }
    };

    const onSidePanelSumbitCallback = (_data, _o) => {
        setCurrentMode(0);
        switch (currentMode) {
            case actions.registerProperty:
                _insertBuildingAsset(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                break;
            case actions.editProperty:
                prepareForUpdateProperty(_data);
                break;
            case actions.registerRoom:
                _insertRoom(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                break;
            case actions.editRoom:
                prepareForUpdateRoom(_data);
                break;
            case actions.registerOwner:
                _insertOwner(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                break;
            case actions.editOwner:
                _updateOwner(_data, _displayMessageAction.bind(null, t('processingCompletedShort'), t('processingCompleted')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                break;
        }
    };

    const getSelectedProperty = () => {
        const index = _buildingAssets.map(function (e) { return e.id; }).indexOf(currentPropertyIndex);
        if (index !== -1)
            return _buildingAssets[index];
    }

    const getSelectedRoom = () => {
        const index = _propertyRooms.map(function (e) { return e.id; }).indexOf(currentRoomIndex);
        if (index !== -1)
            return _propertyRooms[index];
    }

    const getSelectedOwner = () => {
        const index = _owners.map(function (e) { return e.id; }).indexOf(currentOwnerIndex);
        if (index !== -1)
            return _owners[index];
    }

    const tableAccessor = (_field) => (_d) => typeof _d === 'object' ? _d ? _d[_field] : "" : _d;

    const propertyRoomTableOptions = {
        columnsOptions: {
            check: { header: t('check'), sortable: false, type: 'checkbox' },
            roomNum: { header: t('roomNumber'), accessor: tableAccessor('roomNum') },
            ownerName: { header: t('owner'), accessor: tableAccessor('ownerName') },
            ownerAddress1: { header: t('ownerAddress'), accessor: tableAccessor('ownerAddress1') },
            urizumi: { header: t('sold'), accessor: tableAccessor('urizumi') }
        },
        style: { maxHeight: 450 },
        pageSize: 100
    };

    const addOwnedRoomTableOptions = {
        columnsOptions: {
            roomNum: { header: t('roomNumber'), accessor: tableAccessor('roomNum') },
            ownerName: { header: t('owner'), accessor: tableAccessor('ownerName') },
            ownerAddress1: { header: t('ownerAddress'), accessor: tableAccessor('ownerAddress1') },
            urizumi: { header: t('sold'), accessor: tableAccessor('urizumi') }
        },
        style: { maxHeight: 450 },
        pageSize: 100
    };

    const ownerTableOptions = {
        columnsOptions: {
            name: { header: t('fullName'), accessor: tableAccessor('name') },
            address1: { header: t('streetAddress'), accessor: tableAccessor('address1') }
        },
        style: { maxHeight: 405 },
        pageSize: 100
    };

    const propertyTableOptions = {
        columnsOptions: {
            name: { header: t('propertyName'), accessor: tableAccessor('name') },
            address: { header: t('propertyAddress'), accessor: tableAccessor('address') },
            kaokuNum: { header: t('lotNumber'), accessor: tableAccessor('kaokuNum') },
            moyoriEki: { header: t('nearestStation'), accessor: tableAccessor('moyoriEki') }
        },
        style: { maxHeight: 405 },
        pageSize: 100
    };

    const ownerRoomsTableOptions = {
        columnsOptions: {
            roomNum: { header: t('roomNumber'), accessor: tableAccessor('roomNum') },
            bukkenName: { header: t('propertyName'), accessor: tableAccessor('bukkenName') },
            bukkenAddress: { header: t('propertyAddress'), accessor: tableAccessor('bukkenAddress') },
            urizumi: { header: t('sold'), accessor: tableAccessor('urizumi') },
        },
        style: { maxHeight: 450 },
        pageSize: 100
    };

    const onPropertyPageChange = (_c, _i) => {
        if (currentPage != _c) {

            setExportState(true);
            setPropertyRoomsData([]);

            if (formData == {}) {
                _requestBuildingAssets({
                    pageDetails: {
                        isPrev: (currentPage > _c) ? true : false,
                        latestValue: _buildingAssets[_buildingAssets.length - 1].id,
                        currentPage: _c,
                        pageSize: 100
                    }
                }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
            }
            else {
                let urizumi = 0;
                if (formData.sold) urizumi = 1; else urizumi = 0;
                _requestBuildingAssets({
                    bukkenName: formData.propertyName,
                    bukkenAddress: formData.streetAddress,
                    bukkenMoyoriEki: formData.nearestStation,
                    roomUrizumi: urizumi,
                    pageDetails: {
                        isPrev: (currentPage > _c) ? true : false,
                        latestValue: _buildingAssets[_buildingAssets.length - 1].id,
                        currentPage: _c,
                        pageSize: 100
                    }
                }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
            }

            setCurrentPage(_c);
        }
    };

    const onPropertySortChange = (_c, _i) => {
        setExportState(true);
        setCurrentPage(1);
        setPropertyRoomsData([]);

        if (formData == {}) {
            _requestBuildingAssets({
                pageDetails: {
                    currentPage: 1,
                    pageSize: 100,
                    sortType: _c.id,
                    isDesc: _c.desc
                }
            }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        }
        else {
            let urizumi = 0;
            if (formData.sold) urizumi = 1; else urizumi = 0;
            _requestBuildingAssets({
                bukkenName: formData.propertyName,
                bukkenAddress: formData.streetAddress,
                bukkenMoyoriEki: formData.nearestStation,
                roomUrizumi: urizumi,
                pageDetails: {
                    currentPage: 1,
                    pageSize: 100,
                    sortType: _c.id,
                    isDesc: _c.desc
                }
            }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        }
    };

    const onOwnerPageChange = (_c, _i) => {
        if (currentPage != _c) {

            setExportState(true);
            setPropertyRoomsData([]);

            if (formData == {}) {
                _requestOwners({
                    pageDetails: {
                        isPrev: (currentPage > _c) ? true : false,
                        latestValue: _owners[_owners.length - 1].id,
                        currentPage: _c,
                        pageSize: 100
                    }
                }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
            }
            else {
                _requestOwners({
                    ownerName: formData.fullName,
                    ownerAddress: formData.streetAddress,
                    pageDetails: {
                        isPrev: (currentPage > _c) ? true : false,
                        latestValue: _owners[_owners.length - 1].id,
                        currentPage: _c,
                        pageSize: 100
                    }
                }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
            }

            setCurrentPage(_c);
        }
    };

    const onOwnerSortChange = (_c, _i) => {
        setExportState(true);
        setCurrentPage(1);
        setPropertyRoomsData([]);

        if (formData == {}) {
            _requestOwners({
                pageDetails: {
                    currentPage: 1,
                    pageSize: 100,
                    sortType: _c.id,
                    isDesc: _c.desc
                }
            }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        }
        else {
            _requestOwners({
                ownerName: formData.fullName,
                ownerAddress: formData.streetAddress,
                pageDetails: {
                    currentPage: 1,
                    pageSize: 100,
                    sortType: _c.id,
                    isDesc: _c.desc
                }
            }, postSuccess.bind(null, ''), postFailure.bind(null, ''));
        }
    };

    const focusProperty = (_c, _i) => {
        setCurrentPropertyIndex(_c['id']);
        _requestPropertyRooms({
            id: _c['id']
        });
    };

    const focusOwner = (_c, _i) => {
        setCurrentOwnerIndex(_c['id']);
        _requestOwnerRooms({
            id: _c['id']
        });
        _requestZaitakuDetails({
            id: _c['id']
        });
    };

    const focusRoom = (_c, _i) => {
        setCurrentRoomIndex(_c['id']);
    };

    const generateSearchPage = () => {
        let searchPage;


        switch (currentSearchMode) {
            case searchPages[0]:
                searchPage =
                    <div>
                        <PropertyForm callBack={propertyFormCallback.bind(null, '')} delProperty={deleteBuildingAsset.bind(null, '')} delRoom={deletePropertyRoom.bind(null, '')} csvOut={csvOutput.bind(null, '')} addOwnedRoomMode={false} sidePopup={sidePopupStateChange.bind(null, '')} />
                        <div className="tab-content">
                            <Row className="form-group">
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6" disabled={currentMode === actions.editRoom ? true : false}>
                                    <Row>
                                        <Col md={12} sm={12} className="col-12">
                                            <div className="callmode-data-container">
                                                <div className="data-top-wrapper">
                                                    {_buildingAssets && < PageTable {...propertyTableOptions} rows={[...buildingAssetssData]} selectedCallback={focusProperty} pageChangeCallback={onPropertyPageChange} pageDetails={buildingAssetsPageData} sortCallback={onPropertySortChange} loading={isActive} />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col md={12} sm={12} className="col-12">
                                            <div className="callmode-data-container">
                                                <div className="data-top-wrapper">
                                                    {_propertyRooms && <ResponsiveDatatable {...propertyRoomTableOptions} rows={[...propertyRoomsData]} updateCallback={callback} selectedCallback={focusRoom} />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>;
                break;
            case searchPages[1]:
                searchPage =
                    <div>
                        <OwnerForm callBack={ownerFormCallback.bind(null, '')} addOwnedRoom={addOwnedRoomPageCallBack.bind(null, '')} sidePopup={sidePopupStateChange.bind(null, '')} delOwner={deleteOwner.bind(null, '')} />
                        <div className="tab-content">
                            <Row className="form-group">
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col md={12} sm={12} className="col-12">
                                            <div className="callmode-data-container">
                                                <div className="data-top-wrapper">
                                                    {_owners && < PageTable {...ownerTableOptions} rows={[...ownersData]} selectedCallback={focusOwner} pageChangeCallback={onOwnerPageChange} pageDetails={ownersPageData} sortCallback={onOwnerSortChange} loading={isActive} />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                                    <Row>
                                        <Col md={12} sm={12} className="col-12">
                                            <div className="callmode-data-container">
                                                <div className="data-top-wrapper">
                                                    {_propertyRooms && <ResponsiveDatatable {...ownerRoomsTableOptions} rows={[...propertyRoomsData]} selectedCallback={focusRoom} />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>;
                break;
        }

        return <>
            <ul className="nav nav-pills bordered">
                {searchPages.map(_p => <div key={_p}>{generateMenuItem(_p, t(_p))}</div>)}
            </ul>
            <div className="tab-content">
                {searchPage}
            </div>
        </>;
    }

    const sidePanel = (_action) => {
        switch (_action) {
            case actions.registerProperty:
                return generateRegisterPropertyForm(undefined, onBack, onSidePanelSumbitCallback);
            case actions.registerRoom:
                return generateRegisterRoomForm(undefined, onBack, onSidePanelSumbitCallback);
            case actions.registerOwner:
                return generateRegisterOwnerForm(undefined, onBack, onSidePanelSumbitCallback);
            case actions.editProperty:
                return generateRegisterPropertyForm(getSelectedProperty(), onBack, onSidePanelSumbitCallback);
            case actions.editRoom:
                return generateRegisterRoomForm(getSelectedRoom(), onBack, onSidePanelSumbitCallback);
            case actions.editOwner:
                return generateRegisterOwnerForm(getSelectedOwner, onBack, onSidePanelSumbitCallback);
        }
        return <></>;
    }

    const postFailure = () => {
        _displayMessageAction(t('FAIL'), t('FAIL'));
        setExportState(false);
        setIsActive(false);
    }

    const postSuccess = () => {
        setExportState(false);
        setIsActive(false);
    }

    const generateAddOwnedRoomForm = () => {
        return <>
            <ul className="nav nav-pills relative">
                <button type="button" className="btn btn-primary" onClick={() => { setCurrentSearchMode(searchPages[1]); setIsAddOwnedRoomPage(false); }}>{t('goBack')}</button>
            </ul>
            <div>
                <PropertyForm callBack={propertyFormCallback.bind(null, '')} delProperty={deleteBuildingAsset.bind(null, '')} delRoom={deletePropertyRoom.bind(null, '')} sidePopup={sidePopupStateChange.bind(null, '')} addOwnedRoomMode={true} addRoom={addRoom.bind(null, '')} addDetailsUnknownProperty={addDetailsUnknownProperty.bind(null, '')} />
                <div className="tab-content">
                    <Row className="form-group">
                        <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6" disabled={currentMode === actions.editRoom ? true : false}>
                            <Row>
                                <Col md={12} sm={12} className="col-12">
                                    <div className="callmode-data-container">
                                        <div className="data-top-wrapper">
                                            {_buildingAssets && < PageTable {...propertyTableOptions} rows={[...buildingAssetssData]} selectedCallback={focusProperty} pageChangeCallback={onPropertyPageChange} pageDetails={buildingAssetsPageData} sortCallback={onPropertySortChange} loading={isActive} />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} md={6} sm={12} className="col-xxl-6 col-xl-6">
                            <Row>
                                <Col md={12} sm={12} className="col-12">
                                    <div className="callmode-data-container">
                                        <div className="data-top-wrapper">
                                            <ResponsiveDatatable {...addOwnedRoomTableOptions} rows={[...propertyRoomsData]} selectedCallback={focusRoom} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }

    const generateRegisterPropertyForm = (_property, _cancelCallback, _submitCallback) => {
        const props = {
            title: ((_property === undefined) ? t('registerProperty') : t('editProperty')),
            onCancelCallback: _cancelCallback,
            onSubmitCallback: _submitCallback,
            staffList: staffsData,
            property: _property
        }

        return <PropertyAddForm {...props} />;
    }

    const generateRegisterRoomForm = (_room, _cancelCallback, _submitCallback) => {
        const props = {
            title: ((_room === undefined) ? t('registerRoom') : t('editRoom')),
            onCancelCallback: _cancelCallback,
            onSubmitCallback: _submitCallback,
            staffList: staffsData,
            room: _room
        }

        return <RoomAddForm {...props} />;
    }

    const generateRegisterOwnerForm = (_owner, _cancelCallback, _submitCallback) => {
        const props = {
            title: ((_owner === undefined) ? t('registerOwner') : t('editOwner')),
            onCancelCallback: _cancelCallback,
            onSubmitCallback: _submitCallback,
            zaitakuDetails: ((_owner === undefined) ? undefined : zaitakuDetailsData),
            owner: _owner
        }

        return <OwnerAddForm {...props} />;
    }

    return <article className="content maintenance-page">
        <section className="section">
            <Row className="sameheight-container ">
                <Col md={actionsMap.has(currentMode) ? 7 : 12}>
                    <div className="card card-block sameheight-item">
                        {isAddOwnedRoomPage && generateAddOwnedRoomForm()}
                        {(searchPageMap.has(currentSearchMode) || actionsMap.has(currentMode)) && generateSearchPage()}
                    </div>
                </Col>
                {(actionsMap.has(currentMode)) &&
                    <Col md={5} className="side-panel-container">
                        {sidePanel(currentMode)}
                    </Col>}
            </Row>
        </section>
    </article>
}

const mapStateToProps = (_state) => {
    return {
        buildingAssets: _state.maintenance.buildingAssets.records,
        buildingAssetsPageDetails: _state.maintenance.buildingAssets.pageDetails,
        owners: _state.maintenance.owners.records,
        ownersPageDetails: _state.maintenance.owners.pageDetails,
        propertyRooms: _state.maintenance.propertyRooms,
        staffs: _state.maintenance.staffs,
        zaitakuDetails: _state.maintenance.zaitakuDetails,
    }
}

const mapDispatchToProps = {
    requestBuildingAssets: requestBuildingAssets,
    requestPropertyRooms: requestPropertyRooms,
    requestOwnerRooms: requestOwnerRooms,
    requestOwners: requestOwners,
    requestStaffs: requestStaffs,
    requestZaitakuDetails: requestZaitakuDetails,
    requestPropertyRoomsCSV: requestPropertyRoomsCSV,
    deleteBuildingAsset: deleteBuildingAsset,
    deletePropertyRoom: deletePropertyRoom,
    deleteOwner: deleteOwner,
    updateBuildingAsset: updateBuildingAsset,
    updateRoom: updateRoom,
    updateOwner: updateOwner,
    updateOwnerRoom: updateOwnerRoom,
    insertBuildingAsset: insertBuildingAsset,
    insertRoom: insertRoom,
    insertOwner: insertOwner,
    insertUnknownProperty: insertUnknownProperty,
    checkUnknownProperty: checkUnknownProperty,
    displayMessageAction: displayMessageAction,
    displayMessageWithTextBoxAction: displayMessageWithTextBoxAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);