import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import { SimpleToggle } from 'components/inputs/toggles';
import * as wf from 'components/callmode/floatingControls/windowFields';

function AdministrativeAndReserves({ useStateHOC: _useStateHOC, errorHandlingHOC: _errorHandlingHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField label={t('administrativeExpenses')}
            value={data.administrativeExpenses.value || data.administrativeExpenses.defaultValue}
            onChange={setData('administrativeExpenses')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('en')} />
        <wf.GroupField label={t('repairDeposit')}>
            <div className="flex fill-w">
                <wf.InputField hideLabel={true}
                    extraClasses="no-margin"
                    value={data.repairDeposit.value || data.repairDeposit.defaultValue}
                    onChange={setData('repairDeposit')}
                    validator={_validatorsHOC.doubleValidator}
                    errorMessage={t('incorrectNumberFormatError')}
                    addon={t('en')} />
                <div className="flex-grow-1"/>
                <SimpleToggle label={t('over')} value={data.isOver.value || data.isOver.defaultValue || false} onChange={setData('isOver')} />
            </div>
        </wf.GroupField>
        <wf.InputField label={t('other')}
            value={data.otherFees.value || data.otherFees.defaultValue}
            onChange={setData('otherFees')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('en')} />
    </>;
}
const AdministrativeAndReservesPopup = ExportDefaultBehaviour(AdministrativeAndReserves, {
    editableFields: ['administrativeExpenses', 'repairDeposit', 'isOver', 'otherFees']
});
AdministrativeAndReservesPopup.whyDidYouRender = true;

export default AdministrativeAndReservesPopup;