import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { addReminderProcedure, checkReminders, requestReminders } from 'reducers/remindersReducers';
import { displayRemindersAction } from 'reducers/priorityActionsReducers';
import { ReminderButton } from './reminderButton'

function Reminders({ reminders: _reminders, checkReminders: _checkReminders, displayRemindersAction: _displayRemindersAction, requestReminders: _requestReminders }) {

    useEffect(() => {
        _checkReminders();
        _requestReminders();
    }, []);

    useEffect(() => {
        if (_reminders.expiredWorkers && _reminders.expiredWorkers.length > 0) _displayRemindersAction(_reminders.expiredWorkers);
    }, [_reminders.expiredWorkers]);


    const selectedHandler = (_reminder) => {
        _displayRemindersAction([_reminder]);
    };

    const generateReminders = () => {
        return (_reminders.expiredWorkers || []).map((_r) => <ReminderButton reminder={_r} key={_r.id} selectedCallback={selectedHandler} extraClasses={'expired'} />)
            .concat(_reminders.workers.map((_r) => <ReminderButton reminder={_r} key={_r.id} selectedCallback={selectedHandler} />))
    }

    return <div className="reminders-container">
        {(_reminders.workers || _reminders.expiredWorkers) && generateReminders() }
    </div>
}

const mapStateToProps = (_state) => {
    return {
        reminders: _state.reminders
    }
}

const mapDispatchToProps = {
    addReminder: addReminderProcedure,
    checkReminders: checkReminders,
    displayRemindersAction: displayRemindersAction,
    requestReminders: requestReminders
}

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);