const types = {
    setAssetData: 'II_SET_ASSET_DATA',
    setAssets: 'II_SET_ASSETS',
    setDefaultAsset: 'II_SET_DEFAULT_ASSETS',
    requestAssetsDescription: 'II_REQUEST_ASSETS_DESCRIPTION',
    requestAssets: 'II_REQUEST_ASSETS',
    requestDefaultAsset: 'II_REQUEST_DEFAULT_ASSET',
    requestBatchAssetContent: 'II_REQUEST_BATCH_ASSET_CONTENT',
    requestUpdate: 'II_UPDATE_ASSETS'
};

/** Actions **/
export const setAssetData = data => ({
    type: types.setAssetData,
    data
});

export const setAssets = data => ({
    type: types.setAssets,
    data
});

export const setDefaultAsset = data => ({
    type: types.setDefaultAsset,
    data
});

/** Async Actions **/
export const requestAssetsDescription = () => ({
    type: types.requestAssetsDescription
});

export const requestAssets = (BukkenId,fileName) => ({
    type: types.requestAssets,
    BukkenId,
    fileName

});

export const requestDefaultAsset = () => ({
    type: types.requestDefaultAsset
});

export const requestBatchAssetContent = data => ({
    type: types.requestBatchAssetContent,
    data
});

export const requestUpdate = (data, fileName, bukkenId) => ({
    type: types.requestUpdate,
    data,
    fileName,
    bukkenId
});

/** Reducer **/
const reducer = (_state = { assets: { images: [{}, {}, {}, {}] } }, _action) => {


    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.setAssetData:
            let foundIndex = 0;
            if (_action.data.fileName == "registrationInformation") {
                foundIndex = newState.assets.registrationInformation.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.registrationInformation.images[foundIndex].content = _action.data.content;
                    newState.assets.registrationInformation.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            }
            if (_action.data.fileName == "rentEvi") {
                foundIndex = newState.assets.rentEvi.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.rentEvi.images[foundIndex].content = _action.data.content;
                    newState.assets.rentEvi.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            } if (_action.data.fileName == "administrativeAndReserves") {
                foundIndex = newState.assets.administrativeAndReserves.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.administrativeAndReserves.images[foundIndex].content = _action.data.content;
                    newState.assets.administrativeAndReserves.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            } if (_action.data.fileName == "pamphlet") {
                foundIndex = newState.assets.pamphlet.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.pamphlet.images[foundIndex].content = _action.data.content;
                    newState.assets.pamphlet.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            } if (_action.data.fileName == "cityTaxEvi") {
                foundIndex = newState.assets.cityTaxEvi.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.cityTaxEvi.images[foundIndex].content = _action.data.content;
                    newState.assets.cityTaxEvi.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            } if (_action.data.fileName == "returnIntermediary") {
                foundIndex = newState.assets.returnIntermediary.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.returnIntermediary.images[foundIndex].content = _action.data.content;
                    newState.assets.returnIntermediary.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            } if (_action.data.fileName == "image") {
                foundIndex = newState.assets.image.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.image.images[foundIndex].content = _action.data.content;
                    newState.assets.image.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            } if (_action.data.fileName == "imageImport") {
                foundIndex = newState.assets.imageImport.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.imageImport.images[foundIndex].content = _action.data.content;
                    newState.assets.imageImport.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            } if (_action.data.fileName == "loanEvi") {
                foundIndex = newState.assets.loanEvi.images.findIndex(_v => _v.name === _action.data.name);
                if (foundIndex >= 0) {
                    newState.assets.loanEvi.images[foundIndex].content = _action.data.content;
                    newState.assets.loanEvi.images[foundIndex].isDefaultSelected = _action.data.isDefaultSelected;
                }
            }
            

            break;
        case types.setDefaultAsset:
            newState.assets.defaultAsset = _action.data;
            newState.assets.images = newState.assets.images.map(_img => {
                if (_img.isDefaultSelected && !_img.defaultContent) {
                    return { ..._img, defaultContent: newState.assets.defaultAsset };
                } else {
                    return _img;
                }
            });
            break;
        case types.setAssets:
            const knownImages = newState.assets.images ? [...newState.assets.images] : [];
            const remoteAssets = _action.data.images.map(_image => {
                let defaultData = {};
                if (_image.isDefaultSelected && newState.assets.defaultAsset) {
                    defaultData.defaultContent = newState.assets.defaultAsset;
                }
                if (knownImages.length < 1) return { ..._image, ...defaultData };
                const foundIndex = knownImages.findIndex(_v => _v.name === _image.name);
                if (foundIndex < 0) return { ..._image, ...defaultData };
                const content = knownImages[foundIndex];
                knownImages.splice(foundIndex, 1);
                return { ..._image, ...defaultData, content: content };
            });
            if (_action.data.images[0].fileName === "registrationInformation") {
                newState.assets.registrationInformation = {
                    ..._action.data, images: remoteAssets
                };
            }
            if (_action.data.images[0].fileName === "rentEvi") {
                newState.assets.rentEvi = {
                    ..._action.data, images: remoteAssets
                };
            } if (_action.data.images[0].fileName === "administrativeAndReserves") {
                newState.assets.administrativeAndReserves = {
                    ..._action.data, images: remoteAssets
                };
            } if (_action.data.images[0].fileName === "pamphlet") {
                newState.assets.pamphlet = {
                    ..._action.data, images: remoteAssets
                };
            } if (_action.data.images[0].fileName === "cityTaxEvi") {
                newState.assets.cityTaxEvi = {
                    ..._action.data, images: remoteAssets
                };
            } if (_action.data.images[0].fileName === "returnIntermediary") {
                newState.assets.returnIntermediary = {
                    ..._action.data, images: remoteAssets
                };
            } if (_action.data.images[0].fileName === "image") {
                newState.assets.image = {
                    ..._action.data, images: remoteAssets
                };
            } if (_action.data.images[0].fileName === "imageImport") {
                newState.assets.imageImport = {
                    ..._action.data, images: remoteAssets
                };
            } if (_action.data.images[0].fileName === "loanEvi") {
                newState.assets.loanEvi = {
                    ..._action.data, images: remoteAssets
                };
            }
            break;
        default:
            break;
    }

    return newState;
}

export default reducer;