import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function LoanForm({ callBack: _callBack, extraClasses: _extraClasses }) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const updateFinancialInstitution = (_v) => updateState('financialInstitution', _v);
    const updateLoanTypes = (_v) => updateState('loanTypes', _v);
    const updateLoanAmount = (_v) => updateState('loanAmount', _v);
    const updateWidthDownPayment = (_v) => updateState('widthDownPayment', _v);
    const updateResidualDebtTypes = (_v) => updateState('residualDebtTypes', _v);
    const updateDebtRange = (_v) => updateState('debtRange', _v);
    const updateWithPrepayment = (_v) => updateState('withPrepayment', _v);
    const updateInterestRateRange = (_v) => updateState('interestRateRange', _v);
    const updateMonthlyRepaymentRange = (_v) => updateState('monthlyRepaymentRange', _v);
    const updateRepaymentStatusTypes = (_v) => updateState('repaymentStatusTypes', _v);
    const updateExportStatuses = (_v) => updateState('exportStatuses', _v);
    const updateLoanNotes = (_v) => updateState('loanNotes', _v);

    const clearHandler = () => setData({});

    const groupValuesNormalizer = (_attributeName) => (_v, _i) => ({ ..._v, ...{ selected: (data[_attributeName] && data[_attributeName][_i] && data[_attributeName][_i].selected) || false } });
    const loanTypes = [
        { value: 'jointCollateral', text: t('jointCollateral') },
        { value: 'mortgage', text: t('mortgage') },
    ].map(groupValuesNormalizer('loanTypes'));
    const residualDebtTypes = [
        { value: 'with', text: t('with') },
        { value: 'without', text: t('without-kana') },
    ].map(groupValuesNormalizer('residualDebtTypes'));
    const repaymentStatusTypes = [
        { value: 'plus', text: t('plus') },
        { value: 'minus', text: t('minus') },
    ].map(groupValuesNormalizer('repaymentStatusTypes'));
    const exportStatuses = [
        { value: 'possible', text: t('possible') },
        { value: 'impossible', text: t('impossible') },
    ].map(groupValuesNormalizer('exportStatuses'));

    return <div className={'callmode-form-container ' + _extraClasses}>
        <form>
            <Row className="form-group">
                <Col lg={4} md={4} sm={5} className="col-xl-3 col-6">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('financialInstitution')} value={data.financialInstitution} onChangeHandler={updateFinancialInstitution} labelSize={4} extraClasses="financialInstitution-container" />
                    </div>
                </Col>
                <Col lg={4} sm={5} className="col-xl-3 col-6">
                    <div className="single-line">
                        <helpers.GenerateCheckboxGroup values={loanTypes} onChangeHandler={updateLoanTypes} extraClasses="loanTypes-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={5} md={5} sm={6} className="col-xl-5 col-8">
                    <div className="single-line">
                        <helpers.GenerateInputRange text={t('loanAmount')} range={data.loanAmount} onChangeHandler={updateLoanAmount} labelSize={2} addon={t('10kYen')} extraClasses="loanAmount-container" />
                    </div>
                </Col>
                <Col lg={4} sm={5} className="col-xl-3 col-4">
                    <div className="single-line">
                        <helpers.GenerateCheckbox text={t('widthDownPayment')} value={data.widthDownPayment} onChangeHandler={updateWidthDownPayment} extraClasses="widthDownPayment-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col md={4} sm={5} className="col-xl-auto col-lg-auto col-6">
                    <div className="single-line">
                        <helpers.GenerateCheckboxGroup text={t('residualDebt')} values={residualDebtTypes} onChangeHandler={updateResidualDebtTypes} wrap={false} extraClasses={'normalized-size-group residualDebtTypes-container'} extraCheckboxGroupClasses={'justify-end'} />
                    </div>
                </Col>
                <Col lg={7} md={8} sm={11} className="col-xl-8 col-11 offset-md-0 offset-sm-1 offset-1">
                    <Row>
                        <Col lg={8} md={8} sm={8} className="col-xl-5 col-7">
                            <div className="single-line">
                                <helpers.GenerateInputRange range={data.debtRange} onChangeHandler={updateDebtRange} addon={t('10kYen')} extraClasses="debtRange-container" />
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={4} className="col-xl-3 col-5">
                            <div className="single-line">
                                <helpers.GenerateCheckbox text={t('withPrepayment')} value={data.withPrepayment} onChangeHandler={updateWithPrepayment} extraClasses="withPrepayment-container" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={5} md={6} sm={6} className="col-xl-4 col-12">
                    <div className="single-line">
                        <helpers.GenerateInputRange text={t('interestRate')} range={data.interestRateRange} onChangeHandler={updateInterestRateRange} addon={'%'} labelSize={3} extraClasses="interestRateRange-container" />
                    </div>
                </Col>
                <Col lg={5} md={6} sm={6} className="col-xl-4 col-12">
                    <div className="single-line">
                        <helpers.GenerateInputRange text={t('monthlyRepayment')} range={data.monthlyRepaymentRange} onChangeHandler={updateMonthlyRepaymentRange} addon={t('en')} labelSize={3} extraClasses="monthlyRepaymentRange-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={5} md={5} sm={7} className="col-xl-4 col-8">
                    <div className="single-line">
                        <helpers.GenerateCheckboxGroup text={t('repaymentStatus')} values={repaymentStatusTypes} onChangeHandler={updateRepaymentStatusTypes} labelSize={3} extraClasses="repaymentStatusTypes-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={5} md={5} sm={7} className="col-xl-4 col-8">
                    <div className="single-line">
                        <helpers.GenerateCheckboxGroup text={t('export')} values={exportStatuses} onChangeHandler={updateExportStatuses} labelSize={3} extraClasses="exportStatuses-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="form-group">
                <Col lg={9} md={9} sm={12} className="col-xl-5 col-12">
                    <div className="single-line">
                        <helpers.GenerateInput text={t('loanNotes')} value={data.loanNotes} onChangeHandler={updateLoanNotes} labelSize={3} inputSize={9} extraClasses="loanNotes-container" />
                    </div>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="form-submit-container">
                    <button type="button" className="btn btn-primary" onClick={_callBack && (() => { _callBack(data); })}>{t('search')}</button>
                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('clear')}</button>
                </Col>
            </Row>
        </form>
    </div>
}

LoanForm.defaultProps = {
    extraClasses: ''
}
LoanForm.whyDidYouRender = true;