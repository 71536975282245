import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ResponsiveDatatable from 'components/tables/responsiveDatatable';
import { EmployeeEditForm } from './employeeEditForm';
import { UserTeamsEdit } from './userTeamsEdit';
import { TeamsEdit } from './teamsEdit';
import { GroupsEdit } from './groupEdit';
import { requestEmployees, requestTeams, requestMultiTeams, requestGroups, pushEmployee, pushModifyEmployee, pushModifyEmployeeTeams, pushDeleteEmployee, pushModifyTeam, pushDeleteTeam, pushModifyGroup, pushDeleteGroup, pushAddTeam, pushAddGroup } from '../../reducers/employeeManagerReducer';
import { displayMessageAction, displayGroupEditPopup, displayTeamEditPopup } from 'reducers/priorityActionsReducers';
import UserProfile from 'helpers/userProfile';

class EmployeeManager extends Component {
    constructor(_props) {
        super(_props);
        this.state = {
            showHiddenEntries: false,
            editButton: false,
            multiTeamEditButton: false,
            delButton: false
        }

        this.actions = {
            employeeAdd: 'employeeAdd',
            employeeEdit: 'employeeEdit',
            employeeTeamsEdit: 'employeeTeamsEdit',
            teamsEdit: 'teamsEdit',
            groupsEdit: 'groupsEdit'
        };

        this.roles = [
            { id: 0, name: this.props.t('general') },
            { id: 1, name: this.props.t('role') },
            { id: 2, name: this.props.t('leader') },
            { id: 3, name: this.props.t('listAdministrator') },
            { id: 4, name: this.props.t('manager') },
            { id: 5, name: this.props.t('applicantForTranscript') },
            { id: 6, name: this.props.t('systemAdministrator') }
        ];

        this.tableOptions = {
            columnsOptions: {
                staffId: {
                    header: 'staffId',
                    accessor: 'id',
                    width: 90
                },
                staffName: {
                    header: 'staffName',
                    accessor: 'name'
                },
                role: {
                    header: 'role'
                },
                team: {
                    header: 'teamName',
                    accessor: 'team'
                },
                group: {
                    header: 'groupName'
                },
                display: {
                    header: 'display',
                    width: 70
                }
            },
            style: { maxHeight: 700 },
            selectedCallback: (_e) => {

                let index = this.props.employeesManager.employees.findIndex(_v => _v.id === _e.id);
                let selPost = parseInt(_e.post) === NaN ? 0 : parseInt(_e.post);
                let logPost = parseInt(UserProfile.getPost()) === NaN ? 0 : parseInt(UserProfile.getPost());

                let selId = parseInt(_e.id);
                let logId = parseInt(UserProfile.getId());

                if ((selPost >= logPost) && selId !== logId) {
                    this.setState({ editButton: false });
                    this.setState({ delButton: false });
                    index = undefined;
                } else {
                    this.setState({ editButton: true });
                    this.setState({ delButton: true });
                }

                if (selPost === 2) {
                    this.setState({ multiTeamEditButton: true });
                } else {
                    this.setState({ multiTeamEditButton: false });
                }

                if (_e.delFlag === "1") {
                    this.setState({ editButton: false });
                    this.setState({ delButton: false });
                    index = undefined;
                }

                if (_e.groupId === "") {
                    this.setState({ editButton: true });
                }


                this.setState({ selectedEmployeeIndex: index });
            }
        };

        const changeSidePanel = (_newState) => {
            if (this.state.currentAction === _newState) {
                this.setState({ currentAction: undefined });
            } else {
                this.setState({ currentAction: _newState });
            }

        }

        this.getSelectedEmployee = () => {
            if (this.props.employeesManager.employees &&
                this.state.selectedEmployeeIndex !== undefined &&
                this.state.selectedEmployeeIndex < this.props.employeesManager.employees.length)
                return this.props.employeesManager.employees[this.state.selectedEmployeeIndex];
        }
        this.displayAddEmployee = changeSidePanel.bind(this, this.actions.employeeAdd);
        this.displayEditEmployee = () => {
            if (this.getSelectedEmployee()) {
                changeSidePanel(this.actions.employeeEdit);
            }
        }

        this.deleteEmployee = () => {
            if (this.getSelectedEmployee()) {
                this.props.displayMessageAction(this.props.t('confirmDelete'), this.props.t('emConfirmDeleteMessage'),
                    {
                        text: this.props.t('okEng'),
                        callback: () => {
                            this.props.pushDeleteEmployee(this.getSelectedEmployee(), this.props.displayMessageAction.bind(null, this.props.t('processingCompletedShort'), this.props.t('processingCompleted')));
                        }
                    }, true);
            }
        }
        this.displayEditEmployeeTeams = () => {
            if (this.getSelectedEmployee()) {
                changeSidePanel(this.actions.employeeTeamsEdit);
            }
        }
        this.displayTeamsEdit = changeSidePanel.bind(this, this.actions.teamsEdit);
        this.displayGroupsEdit = changeSidePanel.bind(this, this.actions.groupsEdit);
        this.toggleHiddenEntries = (_e) => this.setState({ showHiddenEntries: _e.target.checked });


        ///////////////////////Group
        this.displayAddGroupPopup = () => {
            this.props.displayGroupEditPopup(null, this.props.t('groupEdit'), this.leaderList(), null, true, (_v) => { this.props.pushAddGroup(_v); });
        }

        this.displayEditGroupPopup = (_data) => {
            this.props.displayGroupEditPopup(this.findGroup(_data), this.props.t('groupEdit'), this.leaderList(), null, true, (_v) => { this.props.pushModifyGroup(_v); });
        }

        ///////////////////////Team
        this.displayAddTeamPopup = () => {
            this.props.displayTeamEditPopup(null, this.props.t('teamInformationEditingTool'), this.props.employeesManager.groups, null, true, (_v) => { this.props.pushAddTeam(_v); });
        }

        this.displayEditTeamPopup = (_data) => {
            this.props.displayTeamEditPopup(this.findTeam(_data), this.props.t('teamInformationEditingTool'), this.props.employeesManager.groups, null, true, (_v) => { this.props.pushModifyTeam(_v); });
        }
    }

    componentDidMount() {
        this.props.requestEmployees(UserProfile.getId());
        this.props.requestTeams();
        this.props.requestMultiTeams();
        this.props.requestGroups();
    }

    findTeam(_teamId) {
        const index = this.props.employeesManager.teams.findIndex(_v => _v.id === _teamId);
        if (index === -1){
            console.log("findTeam if");
            console.log(this.props);
            return '';
        }
        else {
            console.log("findTeam else");
            console.log(this.props);
            return this.props.employeesManager.teams[index];
        }
    }

    findGroup(_groupId) {
        const index = this.props.employeesManager.groups.findIndex(_v => _v.id === _groupId);
        if (index === -1) {
            console.log("findGroup if");
            console.log(this.props);
            return '';
        }
        else {
            console.log("findGroup else");
            console.log(this.props);
        }
            return this.props.employeesManager.groups[index];
    }

    findMultiTeams(_staffId) {
        const result = [];
        this.props.employeesManager.multiTeams.forEach(_v => {
            if (_v.staffId === _staffId)
                result.push(_v);
        });
        return result;
    }

    leaderList() {
        const result = [];
        this.props.employeesManager.employees.forEach(_v => {
            if ((_v.post === 4 || _v.post === 6) && _v.visibleFlg === '1')
                result.push(_v);
        });
        return result;
    }

    setRoles() {
        const result = [];

        let PostNumber = parseInt(UserProfile.getPost()) === NaN ? 0 : parseInt(UserProfile.getPost());

        for (let i = 0; i < PostNumber; i++) {
            if (!(PostNumber == 4 && i == 3))
                result.push(this.roles[i]);
        }

        return result;
    }

    formattedTeamData() {
        const toAdd = [];

        if (UserProfile.getPost() === "4") {
            this.props.employeesManager.teams.forEach(_t => {
                if (_t.groupId === parseInt(UserProfile.getGroupId()))
                    toAdd.push(_t);
            });
        }
        else {
            return this.props.employeesManager.teams;
        }

        return toAdd;
    }


    generateTable(_data, _options) {
        if ((!_data || !Array.isArray(_data)) ||
            (!this.props.employeesManager.teams || this.props.employeesManager.teams.length < 1)) return undefined;

        const formattedData = _data.reduce((_a, _b) => {
            const toAdd = [];
            if (!this.state.showHiddenEntries) {
                if (_b.visibleFlg === '1') {
                    toAdd.push({
                        ...{
                            id: _b.id,
                            name: _b.name,
                            role: this.roles[_b.post] ? this.roles[_b.post].name : '',
                            team: this.findTeam(_b.teamId).name,
                            group: this.findGroup(_b.groupId).groupName,
                            display: _b.visibleFlg === '1' ? '✓' : '✗',
                            post: _b.post,
                            delFlag: _b.delFlag,
                            groupId: _b.groupId,

                        }
                    })
                }
            }
            else {
                toAdd.push({
                    ...{
                        id: _b.id,
                        name: _b.name,
                        role: this.roles[_b.post] ? this.roles[_b.post].name : '',
                        team: this.findTeam(_b.teamId).name,
                        group: this.findGroup(_b.groupId).groupName,
                        display: _b.visibleFlg === '1' ? '✓' : '✗',
                        post: _b.post,
                        delFlag: _b.delFlag,
                        groupId: _b.groupId,
                    }
                })
            }
            return _a.concat(toAdd);
        }, []);

        return <ResponsiveDatatable {...{ rows: formattedData, ..._options }} />;
    }

    generateEmployeeEditForm(_employee, _submitCallback, _cancelCallback) {

        const props = {
            title: this.props.t('staffInformationEditingTool'),
            roleOptions: this.setRoles(),
            teamOptions: this.props.employeesManager.teams,
            groupOptions: this.props.employeesManager.groups,
            onSubmitCallback: _submitCallback,
            onCancelCallback: _cancelCallback,
            employee: _employee,
            type: _employee ? 'edit' : 'add'
        }

        return <EmployeeEditForm {...props} />;
    }

    generateEmployeeTeamsEdit(_employee, _submitCallback, _cancelCallback) {
        if (this.props.employeesManager.teams && this.props.employeesManager.teams.length > 0)
            return <UserTeamsEdit employee={_employee} title={this.props.t('secondaryTeamEditTool')} teams={this.props.employeesManager.teams} multiTeams={this.findMultiTeams(_employee.id)} onSubmitCallback={_submitCallback} onCancelCallback={_cancelCallback} />;
    }

    generateTeamsEdit(_editCallback, _deleteCallback, _cancelCallback, _editPopup) {
        if (this.props.employeesManager.teams && this.props.employeesManager.teams.length > 0)
            return <TeamsEdit teams={this.formattedTeamData()} title={this.props.t('teamInformationEditingTool')} onTeamEditCallback={_editCallback} onTeamDeleteCallback={_deleteCallback} onCancelCallback={_cancelCallback} editPopup={_editPopup} />;
    }

    generateGroupsEdit(_editCallback, _deleteCallback, _cancelCallback, _editPopup) {
        if (this.props.employeesManager.groups && this.props.employeesManager.groups.length > 0)
            return <GroupsEdit groups={this.props.employeesManager.groups} teams={this.props.employeesManager.teams} employees={this.props.employeesManager.employees} title={this.props.t('groupInformationEditingTool')} onGroupEditCallback={_editCallback} onGroupDeleteCallback={_deleteCallback} editPopup={_editPopup} />;
    }

    sidePanel(_action) {

        switch (_action) {
            case this.actions.employeeAdd:

                const handler = (_e) => {
                    this.props.pushEmployee(_e, this.props.displayMessageAction.bind(null, this.props.t('ADDED'), this.props.t('ADDED')), this.props.displayMessageAction.bind(null, this.props.t('FAIL'), this.props.t('FAIL')));
                    this.displayAddEmployee();
                }

                return this.generateEmployeeEditForm(undefined, handler, this.displayAddEmployee);
            case this.actions.employeeEdit:
                if (this.getSelectedEmployee()) {

                    const handler = (_e) => {
                        this.props.pushModifyEmployee(_e, this.props.displayMessageAction.bind(null, this.props.t('UPDATED'), this.props.t('UPDATED')), this.props.displayMessageAction.bind(null, this.props.t('FAIL'), this.props.t('FAIL')));
                        this.displayEditEmployee();
                    }

                    return this.generateEmployeeEditForm(this.getSelectedEmployee(), handler, this.displayEditEmployee);
                }
                break;
            case this.actions.employeeTeamsEdit:
                if (this.getSelectedEmployee()) {
                    const handler = (_employee, _teams) => {
                        const newTeams = [];
                        _teams.forEach(_t => {
                            if (_t.selected)
                                newTeams.push(_t.id);
                        });
                        this.props.pushModifyEmployeeTeams({ ...{ staffId: _employee.id, teamIds: newTeams } });
                        this.displayEditEmployeeTeams();
                    }
                    return this.generateEmployeeTeamsEdit(this.getSelectedEmployee(), handler, this.displayEditEmployeeTeams);
                }
                break;
            case this.actions.teamsEdit:
                const deleteTeamHandler = (_team) => {
                    if (_team) {
                        this.props.displayMessageAction(this.props.t('confirmDelete'), this.props.t('teamDeleteConfirmationMessage'),
                            {
                                text: this.props.t('okEng'),
                                callback: () => {
                                    this.props.pushDeleteTeam(_team, this.props.displayMessageAction.bind(null, this.props.t('processingCompletedShort'), this.props.t('processingCompleted')));
                                }
                            }, true);
                    }
                };
                return this.generateTeamsEdit(this.displayEditTeamPopup, deleteTeamHandler, this.displayTeamsEdit, this.displayAddTeamPopup);
                break;
            case this.actions.groupsEdit:
                const deleteGroupHandler = (_group) => {
                    if (_group) {
                        this.props.displayMessageAction(this.props.t('confirmDelete'), this.props.t('groupDeleteConfirmationMessage'),
                            {
                                text: this.props.t('okEng'),
                                callback: () => {
                                    this.props.pushDeleteGroup(_group, this.props.displayMessageAction.bind(null, this.props.t('processingCompletedShort'), this.props.t('processingCompleted')));
                                }
                            }, true);
                    }
                };

                return this.generateGroupsEdit(this.displayEditGroupPopup, deleteGroupHandler, this.displayGroupsEdit, this.displayAddGroupPopup);
                break;

        }
        return <></>;
    }

    render() {
        return (<article className="content add-employee-page">
            <section className="section">
                <Row>
                    <Col md={this.state.currentAction != null ? 7 : 12}>
                        <div className="card">
                            <div className="card-block">
                                <section className="section title-section">
                                    <div className="button-row flex">
                                        <div className="button-container">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-secondary" onClick={this.displayAddEmployee}>{this.props.t('addto')}</button>
                                                <button type="button" className="btn btn-secondary" onClick={this.displayEditEmployee} disabled={this.state.editButton ? '' : 'disabled'}>{this.props.t('edit')}</button>
                                                <button type="button" className="btn btn-secondary" onClick={this.deleteEmployee} disabled={this.state.delButton ? '' : 'disabled'}>{this.props.t('delete')}</button>
                                                {UserProfile.getPost() !== "2" && <button type="button" className="btn btn-secondary" onClick={this.displayEditEmployeeTeams} disabled={this.state.multiTeamEditButton ? '' : 'disabled'}>{this.props.t('secondaryTeamEdit')}</button>}
                                            </div>
                                            <div className="btn-group">
                                                {UserProfile.getPost() !== "2" && <button type="button" className="btn btn-secondary" onClick={this.displayTeamsEdit}>{this.props.t('teamEdit')}</button>}
                                                {!(UserProfile.getPost() === "2" || UserProfile.getPost() === "4") && <button type="button" className="btn btn-secondary" onClick={this.displayGroupsEdit}>{this.props.t('groupEdit')}</button>}
                                            </div>
                                            <div className="btn-group">
                                                <label className="single-line">
                                                    <input className="checkbox" type="checkbox" checked={this.state.showHiddenEntries} onChange={this.toggleHiddenEntries} /><span>{this.props.t('showAll')}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="section table-section">
                                    {this.props.employeesManager.employees && this.props.employeesManager.employees.length > 0 &&
                                        this.generateTable(this.props.employeesManager.employees, this.tableOptions)}
                                </section>
                            </div>
                        </div>
                    </Col>
                    {this.state.currentAction &&
                        <Col md={5} className="side-panel-container">
                            {this.sidePanel(this.state.currentAction)}
                        </Col>
                    }
                </Row>
            </section>
        </article>);
    }
}

const mapStateToProps = (_state) => {
    return {
        employeesManager: _state.employeesManager
    }
}
const mapDispatchToProps = {
    requestEmployees: requestEmployees,
    requestTeams: requestTeams,
    requestMultiTeams: requestMultiTeams,
    requestGroups: requestGroups,
    pushEmployee: pushEmployee,
    pushModifyEmployee: pushModifyEmployee,
    pushDeleteEmployee: pushDeleteEmployee,
    pushModifyEmployeeTeams: pushModifyEmployeeTeams,
    pushModifyTeam: pushModifyTeam,
    pushAddTeam: pushAddTeam,
    pushDeleteTeam: pushDeleteTeam,
    pushModifyGroup: pushModifyGroup,
    pushAddGroup: pushAddGroup,
    pushDeleteGroup: pushDeleteGroup,
    displayMessageAction: displayMessageAction,
    displayGroupEditPopup: displayGroupEditPopup,
    displayTeamEditPopup: displayTeamEditPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmployeeManager));