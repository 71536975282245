import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function RoomRemarks({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField label={t('propertyRemarks')}
            value={data.remarks.value || data.remarks.defaultValue}
            onChange={setData('remarks')}
            isMultiline={true}/>
    </>;
}

const RoomRemarksPopup = ExportDefaultBehaviour(RoomRemarks, {
    editableFields: ['remarks']
});
RoomRemarksPopup.whyDidYouRender = true;
export default RoomRemarksPopup;