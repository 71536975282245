import React from "react";

export default function propertyListModuleButtons({ ownerId: _ownerId, idNumber: _idNumber, tablePropsCount: _tablePropsCount, propsData: _propsData, onUnlockPropertyRoomPopup: _onUnlockPropertyRoomPopup, onAddPropertyRoomPopUp: _onAddPropertyRoomPopUp }) {

    const addButtonOnClick = () => {
        _onAddPropertyRoomPopUp(_ownerId);
    }

    const unlockRoom = () => {
        _onUnlockPropertyRoomPopup(_idNumber);
    }

    return (
        <div className="row">
            <div className="col-xs-8 ellipsize">
                <input type="button" value={_propsData.t("addRoom")} onClick={addButtonOnClick} />
                <input type="button" value={_propsData.t("unlockRoom")} onClick={unlockRoom} />
            </div>
            <div className="col-xs-4 ellipsize have-room">
                {_tablePropsCount + (_propsData.t("haveRoom"))}
            </div>
        </div>
    )
}
