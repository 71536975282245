/*
    App config file. Set global variables and such
*/
const config =
{
    defaultLocale: 'ja',
    apiServer: {
        address: 'api',
        teamInformation: 'TeamData/GetTeamInformation',
        teamUpdate: 'TeamData/PushTeamUpdate',
        uploadCSV: 'TeamData/PushCSV',
        partnersInformation: 'PartnersData/GetPartnersEntries',
        partnerEntry: 'PartnersData/GetEntryData',
        getReminders: 'RemindersData/GetReminders',
        addReminder: 'RemindersData/AddReminder',
        renewReminder: 'RemindersData/RenewReminder',
        cancelReminder: 'RemindersData/CancelReminder',
        cmRequestRecords: 'CallModeData/GetRecords',
        cmRequestRecordsMetadata: 'CallModeData/GetRecordsMetadata',
        cmRequestCall: 'CallModeData/RequestCall',
        cmRequestHangUp: 'CallModeData/RequestHangup',
        cmRequestEmployees: 'CallModeData/GetEmployees',
        cmUpdateRoomData: 'CallModeData/UpdateRoomData',
        cmRequestHistoryRecords: 'CallModeData/GetHistoryDatas',
        cmRequestPropertyListDatas: 'CallModeData/GetPropertyListDatas',
        cmUnlockPropertyListDatas: 'CallModeData/UnlockPropertyListDatas',
        cmDeleteHistoryRecords: 'CallModeData/DeleteHistoryData',
        cmUpdateHistoryRecords: 'CallModeData/UpdateHistoryData',
        cmTelCall: 'CallModeData/TelCall',
        cmUpdateAllSold: "CallModeData/UpdateAllSold",
        cmCallModeUpdateStaffData: "CallModeData/CallModeUpdateStaffData",
        cmInsertHistoryDatas: 'CallModeData/InsertHistoryDatas',
        cmInsertReCallData: 'CallModeData/InsertReCallData',

        emRequestEmployees: 'EmployeeManagerData/GetEmployees',
        emRequestRoles: 'EmployeeManagerData/GetRoles',
        emRequestTeams: 'EmployeeManagerData/GetTeams',
        emRequestMultiTeams: 'EmployeeManagerData/GetMultiTeams',
        emRequestGroups: 'EmployeeManagerData/GetGroups',
        emPushEmployee: 'EmployeeManagerData/AddEmployee',
        emEditEmployee: 'EmployeeManagerData/EditEmployee',
        emEditEmployeeTeams: 'EmployeeManagerData/EditEmployeeTeams',
        emDeleteEmployee: 'EmployeeManagerData/DeleteEmployee',
        emEditTeam: 'EmployeeManagerData/EditTeam',
        emAddTeam: 'EmployeeManagerData/AddTeam',
        emDeleteTeam: 'EmployeeManagerData/DeleteTeam',
        emEditGroup: 'EmployeeManagerData/EditGroup',
        emAddGroup: 'EmployeeManagerData/AddGroup',
        emDeleteGroup: 'EmployeeManagerData/DeleteGroup',
        iiGetServerAssetsDescription: 'ImageImportData/GetServerAssetsDescription',
        iiGetServerDefaultAsset: 'ImageImportData/GetDefaultAsset',
        iiGetServerAsset: 'ImageImportData/GetServerAsset',
        iiGetServerAssets: 'ImageImportData/GetServerAssets',
        iiUpdateServerAssets: 'ImageImportData/UpdateServerAssets',
        gsGetGlobalStatistics: 'GlobalStatisticsData/GetGlobalStatistics',
        exRequestPromotionalActivityPdf: 'ExportData/RequestPromotionalActivityPdf',
        exRequestPromotionalActivityExcel: 'ExportData/RequestPromotionalActivityExcel',

        dmRequestOwners: 'DMData/GetOwners',
        dmRequestOwnerDetail: 'DMData/GetOwnerDetail',
        dmDeleteOwner: 'DMData/DeleteOwner',

        exRequestExportPdf: 'ExportData/RequestExportPdf',
        exRequestExportCSV: 'ExportData/RequestExportCSV',
        exRequestBukkenExportCSV: 'ExportData/RequestBukkenExportCSV',
        exRequestExportDMCSV: 'ExportData/RequestExportDMCSV',
        exRequestPropertyRoomsCSV: 'ExportData/RequestPropertyRoomsCSV',
        exRequestFailedImport: 'ExportData/RequestFailedImport',
        exRequestECImport: 'ExportData/ECImport',
        exRequestDMMistake: 'ExportData/DMMistake',
        exRequestDMImport: 'ExportData/DMImport',
        exRequestBukkenImport: 'ExportData/BukkenImport',
        exSendMailHansoku: 'ExportData/SendMailHansoku',
        exRequestImportAll: 'ExportData/ImportAll',

        mRequestBuildingAssets: 'MaintenanceData/GetBuildingAssets',
        mRequestRooms: 'MaintenanceData/GetRooms',
        mRequestOwners: 'MaintenanceData/GetOwners',
        mRequestPropertyRooms: 'MaintenanceData/GetPropertyRooms',
        mRequestOwnerRooms: 'MaintenanceData/GetOwnerRooms',
        mDeleteBuildingAsset: 'MaintenanceData/DeleteBuildingAsset',
        mDeleteRoom: 'MaintenanceData/DeleteRoom',
        mDeleteOwner: 'MaintenanceData/DeleteOwner',
        mUpdateBuildingAsset: 'MaintenanceData/UpdateBuildingAssset',
        mUpdateRoom: 'MaintenanceData/UpdateRoom',
        mUpdateOwner: 'MaintenanceData/UpdateOwner',
        mUpdateOwnerRoom: 'MaintenanceData/UpdateOwnerRoom',
        mInsertBuildingAssset: 'MaintenanceData/InsertBuildingAssset',
        mInsertRoom: 'MaintenanceData/InsertRoom',
        mInsertOwner: 'MaintenanceData/InsertOwner',
        mInsertUnknownProperty: 'MaintenanceData/InsertUnknownProperty',
        mCheckUnknownProperty: 'MaintenanceData/CheckUnknownProperty',

        asRequestBuildingAssets: 'AssessmentRequestData/GetBuildingAssets',
        asRequestRooms: 'AssessmentRequestData/GetRooms',
        asRequestOwners: 'AssessmentRequestData/GetOwners',
        asExportCsv: 'AssessmentRequestData/ExportCSV',
        asRequestPropertyRooms: 'AssessmentRequestData/GetPropertyRooms',
        asRequestOwnerRooms: 'AssessmentRequestData/GetOwnerRooms',
        asRequestOwnerListRooms: 'AssessmentRequestData/GetOwnerListRooms',
        asDeleteBuildingAsset: 'AssessmentRequestData/DeleteBuildingAsset',
        asDeleteRoom: 'AssessmentRequestData/DeleteRoom',
        asDeleteOwner: 'AssessmentRequestData/DeleteOwner',
        asUpdateBuildingAsset: 'AssessmentRequestData/UpdateBuildingAssset',
        asUpdateRoom: 'AssessmentRequestData/UpdateRoom',
        asUpdateOwner: 'AssessmentRequestData/UpdateOwner',
        asUpdateMemo: 'AssessmentRequestData/UpdateMemo',
        asUpdateKidoku: 'AssessmentRequestData/UpdateKidoku',
        asUpdateShinsei: 'AssessmentRequestData/UpdateShinsei',
        asUpdateTorisage: 'AssessmentRequestData/UpdateTorisage',
        asUpdateStates: 'AssessmentRequestData/UpdateStates',
        asUpdateOwnerRoom: 'AssessmentRequestData/UpdateOwnerRoom',
        asInsertBuildingAssset: 'AssessmentRequestData/InsertBuildingAssset',
        asInsertRoom: 'AssessmentRequestData/InsertRoom',
        asInsertOwner: 'AssessmentRequestData/InsertOwner',
        asInsertUnknownProperty: 'AssessmentRequestData/InsertUnknownProperty',
        asCheckUnknownProperty: 'AssessmentRequestData/CheckUnknownProperty',

        pRequestHansokuRecords: 'PartnersData/GetHansokuRecords',
        pDeleteHansokuRecords: 'PartnersData/DeleteHansokuRecords',
        pRequestKainushiTantou: 'PartnersData/GetKainushiTantou',
        pUpdateBoxType: 'PartnersData/SetBoxType',
        pRequestTemplateList: 'PartnersData/GetTemplateList',
        pUpdateTemplateList: 'PartnersData/UpdateTemplateList',
        pRequestStaffList: 'PartnersData/GetStaffList',
        pUpdateHansokuRecords: 'PartnersData/UpdateHansokuRecords',
        pRequestHansokuRireki: 'PartnersData/GetHansokuRireki',
        pRequestHansokuGyousha: 'PartnersData/GetHansokuGyousha',
        pRequestStaffAndTemplate: 'PartnersData/GetStaffAndTemplate',
        pRequestRoomData: 'PartnersData/GetRoomData',
        pUpdateRoomData: 'PartnersData/UpdateRoomData',

        cGetStaffs: 'CommonData/GetStaffs',
        cGetZaitakuDetails: 'CommonData/GetZaitakuDetails',
        cGetLastEditer: 'CommonData/GetLastEditer',
        cSetLastEditer: 'CommonData/SetLastEditer',
        cImportImages: 'CommonData/ImportImages'
    },
    authInformation: {
        authServer: {
            domain: 'dev-mg8v5qik.au.auth0.com',
            clientID: 'TdUCL2zY0RBmuaQOYpZyiq3Ay44KAX6Z',
            redirectUri: '/authenticated',
            audience: 'https://crm.ls-home.co.jp/',
            responseType: 'token id_token',
            scope: 'openid profile email'
        },
        logoutUrl: window.location.origin,
        defaultLanguage: 'ja',
        renewDelay: 600000//10 minutes
    }
};
export default config;