import 'babel-polyfill';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiEntryPoint } from 'apiConnector';
import { saveStats, requestStats } from 'reducers/userSalesReducer';


function* requestStatsSaga() {
    try {
        const result = yield call(ApiEntryPoint.FetchGlobalStatistics);
            if (result ) {
            yield put(saveStats(result));
        }
    } catch (_e) {
        console.log('Error from requestStatsSaga');
        console.log(_e);
    }
}



export function* watchResuestStatsSaga() {
    yield takeEvery(requestStats().type, requestStatsSaga);
}