import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Purchase({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const handoffCategory = ["unspecified", "newConstruction", "secondHand"].reduce(_checkboxGroupHOC.checkboxGroupReducer('handoffCategory'), {});
    const hideDatepicker = ((_v) => !_v || Object.keys(_v).every(_k => _k !== 'secondHand' || !_v[_k]))(data.handoffCategory.value || data.handoffCategory.defaultValue);
    return <>
        <wf.CheckboxGroupField values={handoffCategory} onChange={(_v) => setData('handoffCategory')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="handoffCategory" />
        <wf.DatePicker extraClasses={hideDatepicker && 'dissapear'} label={t('phase')} value={data.phase.value || data.phase.defaultValue} onChange={setData('phase')} />
        <wf.InputField label={t('other')}
            value={data.sum.value || data.sum.defaultValue}
            onChange={setData('sum')}
            validator={_validatorsHOC.doubleValidator}
            errorMessage={t('incorrectNumberFormatError')}
            addon={t('10kYen')} />
    </>;
}
const PurchasePopup = ExportDefaultBehaviour(Purchase, {
    editableFields: ['handoffCategory', 'phase', 'sum']
});
PurchasePopup.whyDidYouRender = true;

export default PurchasePopup;