import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TeamDescription } from './teamDescription';
import { updateTeam, requestTeams } from 'reducers/teamReducers';

class Home extends Component {
    
    componentDidMount() {
        if (this.props.teams == null) {
            this.props.requestTeams();
        }
    }

    render() {
        return (
            <article className="content dashboard-page">
                {
                    Array.isArray(this.props.teams) &&
                    this.props.teams.map((_t, _i) => <TeamDescription
                        ranking={_t.ranking}
                        name={_t.name}
                        values={_t.stats}
                        members={_t.members}
                        handleChangedCallback={(_val) => this.props.updateTeam({
                            ..._val,
                            teamIndex: _i,
                            teamId: _t.id
                        })}
                        key={_i}
                    />)
                    }
            </article>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        teams: state.teams
    }
}
const mapDispatchToProps = {
    updateTeam: updateTeam,
    requestTeams: requestTeams
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);