export function isFunction(_test) {
    return _test && {}.toString.call(_test) === '[object Function]';
}

export function binaryIndexOf(_searchElement, _evalFunction, _positiveAnswer) {

    if (!Array.isArray(this)) throw "Object is not an array";

    var minIndex = 0;
    var maxIndex = this.length - 1;
    var currentIndex;
    var currentElement;

    var evalFunction = isFunction(_evalFunction) ? _evalFunction : (_a, _b) => _a - _b;

    while (minIndex <= maxIndex) {
        currentIndex = (minIndex + maxIndex) / 2 | 0;
        currentElement = this[currentIndex];

        var evalResult = evalFunction(currentElement, _searchElement);
        if (evalResult < 0) {
            minIndex = currentIndex + 1;
        }
        else if (evalResult > 0) {
            maxIndex = currentIndex - 1;
        }
        else {
            return currentIndex;
        }
    }

    return _positiveAnswer === true ? minIndex : -1;
}

export function insertBinary(_element, _evalFunction) {
    const targetIndex = binaryIndexOf.call(this, _element, _evalFunction, true);
    this.splice(targetIndex, 0, _element);
    return this;
}

export function SortedArray() {
    const array = [];
    let comparator;

    this.addById = (_v) => {
        if (_v) {
            let foundIndex = array.map(function (e) { return e.id; }).indexOf(_v.id);
            if (foundIndex !== -1) {
                array[foundIndex] = _v;
            }
            else {
                foundIndex = binaryIndexOf.call(array, _v, comparator, true);
                array.splice(foundIndex, 0, _v);
            }
        }
    }

    this.addWhole = (_v) => {
        if (_v) {
            array.splice(0, array.length);
            if (_v.length !== 0)
                array.splice(0, 0, ..._v);
        }
    }

    this.delById = (_v) => {
        if (_v) {
            const foundIndex = array.map(function (e) { return e.id; }).indexOf(_v);
            if (foundIndex !== -1) {
                array.splice(foundIndex, 1);
            }
        }
    }

    this.toArray = () => array.slice();

    this.map = function () {
        return array.map.apply(Array.prototype.slice.call(arguments));
    }

    Object.defineProperty(this, 'comparator', {
        get: () => comparator,
        set: (_c) => comparator = _c
    });

    Object.defineProperty(this, 'length', {
        get: () => array.length
    });
}