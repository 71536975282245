import 'babel-polyfill';
import { call, put, takeEvery } from 'redux-saga/effects'
import { ApiEntryPoint } from 'apiConnector';
import { requestTeams, addTeam, updateTeam, serverUpdateTeam } from 'reducers/teamReducers'

function* teamsFetcherSaga() {
    try {
        const result = yield call(ApiEntryPoint.FetchTeams);
        for (let r in result) {
            yield put(addTeam(result[r]));
        }
    } catch (_e) {
        console.log('Error from teamsFetcher');
        console.log(_e);
    }
}

function* updateTeamSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PushTeamUpdate, _action.data);
        yield put(serverUpdateTeam(result));
    } catch (_e) {
        console.log('Error from watchTeamsSaga');
        console.log(_e);
    }
}

export function* watchTeamsSaga() {
    yield takeEvery(requestTeams().type, teamsFetcherSaga);
}
export function* watchUpdateTeamSaga() {
    yield takeEvery(updateTeam().type, updateTeamSaga);
}