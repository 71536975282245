import ResponsiveDatatable from 'components/tables/responsiveDatatable';
import * as helpers from 'helpers/formHelpers';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requestHansokuRecords, requestKainushiTantou, requestStaffList, deleteHansokuRecords, updateRoomData, requestRoomData, requestHansokuRireki, requestTemplateList, requestHansokuGyousha, updateHansokuRecords, updateBoxType, requestStaffAndTemplate, updateTemplateList } from 'reducers/partnersReducers';
import { sendMailHansoku, requestPromotionalActivityExcel } from 'reducers/exportReducers';
import { displayMessageAction } from 'reducers/priorityActionsReducers';
import PromotionalActivitiesData from './promotionalActivitiesData';
import UserProfile from 'helpers/userProfile';

function PromotionalActivities({ roomData: _roomData, requestRoomData: _requestRoomData, updateRoomData: _updateRoomData, requestPromotionalActivityExcel: _requestPromotionalActivityExcel, requestHansokuRireki: _requestHansokuRireki, deleteHansokuRecords: _deleteHansokuRecords, hansokuRirekis: _hansokuRirekis, pdfTemplate: _pdfTemplate, staffTemplate: _staffTemplate, updateTemplateList: _updateTemplateList, requestStaffAndTemplate: _requestStaffAndTemplate, sendMailHansoku: _sendMailHansoku, hansokuGyousha: _hansokuGyousha, hansokuRecords: _hansokuRecords, kainushiTantous: _kainushiTantous, templates: _templates, staffs: _staffs, updateHansokuRecords: _updateHansokuRecords, updateBoxType: _updateBoxType, requestKainushiTantou: _requestKainushiTantou, requestHansokuGyousha: _requestHansokuGyousha, requestStaffList: _requestStaffList, requestTemplateList: _requestTemplateList, partnerRows: _partnerRows, partnerEntries: _partnerEntries, requestHansokuRecords: _requestHansokuRecords, displayMessageAction: _displayMessageAction }) {
    const processStates = {
        sellBox: 0,
        stopSellingBox: 1,
        handoverPreparation: 2,
        succeeded: 'succeeded',
        contracted: 'contracted',
        settled: 'settled',
        all: 'all'
    }

    const bottomRenderStates = {
        information: 'information',
        diagram: 'diagram'
    }

    const { t } = useTranslation();
    const [processState, setProcessState] = useState(processStates.sellBox);
    const [renderState, setRenderState] = useState(bottomRenderStates.diagram);
    const [data, setdata] = useState(!_partnerRows ? [] : _partnerRows[processState]);
    const [roomData, setRoomData] = useState(!_roomData ? {} : _roomData);
    const [hansokuRecords, setHansokuRecords] = useState(!_hansokuRecords ? [] : _hansokuRecords);
    const [hansokuGyousha, setHansokuGyousha] = useState(!_hansokuGyousha ? [] : _hansokuGyousha);
    const [hansokuRirekis, setHansokuRirekis] = useState(!_hansokuRirekis ? [] : _hansokuRirekis);
    const [kainushiTantous, setKainushiTantous] = useState(!_kainushiTantous ? [] : _kainushiTantous);
    const [templates, setTemplates] = useState(!_templates ? [] : _templates);
    const [staffs, setStaffs] = useState(!_staffs ? [] : _staffs);
    const [staffTemplate, setStaffTemplate] = useState(!_staffTemplate ? [] : _staffTemplate);
    const [pdfTemplate, setPdfTemplate] = useState(!_pdfTemplate ? [] : _pdfTemplate);
    const [currentStaff, setCurrentStaff] = useState(UserProfile.getId());
    const [currentImgIndex, setCurrentImgIndex] = useState(1);
    const [currentComboStaffId, setCurrentComboStaffId] = useState(-2);
    const [selectedEntryData, setSelectedEntryData] = useState(undefined);
    const [hansokuGyoushaButtonState, setHansokuGyoushaButtonState] = useState('gyousha');
    const [hansokuRirekiButtonState, setHansokuRirekiButtonState] = useState('all');

    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setdata({ ...data, ...update });
    }

    const updateTemplateType = (_v) => setCurrentImgIndex(_v);
    const updateComboStaffId = (_v) => setCurrentComboStaffId(_v);

    const areaList = [
        t('unspecified'),
        t('kantoChiasa'),
        t('kanto'),
        t('kantoFamily'),
        t('kansaiChiasa'),
        t('kansaiAncientTimes'),
        t('kyushuChiasa'),
        t('Kyushu'),
        t('chinaAndShikoku'),
        t('yohoku'),
        t('chubu'),
        t('hokkaido')
    ];

    const tableOptions = {
        columnsOptions: {
            check: {
                header: 'check',
                sortable: false,
                type: 'checkbox',
                width: 70
            },
            receivedDate: {
                header: 'receivedDate'
            },
            contactName: {
                header: 'contactName'
            },
            buyerResponsible: {
                header: 'buyerResponsible',
                options: _kainushiTantous,
                sortable: false,
                type: 'dropdown'
            },
            propertyName: {
                header: 'propertyName'
            },
            roomNumber: {
                header: 'roomNumber',
                width: 70
            },
            area: {
                header: 'area',
                options: areaList,
                sortable: false,
                type: 'dropdown'
            },
            desiredAmount: {
                header: 'desiredAmount',
                type: 'editable'
            },
            displayedAmount: {
                header: 'displayedAmount',
                type: 'editable'
            },
            highestValue: {
                header: 'highestValue'
            },
            difference: {
                header: 'difference'
            }
        },
        style: { maxHeight: 400 },
        selectedCallback: (_data) => {
            _requestRoomData(_data.roomId);
            updateRecord(_data);
            setSelectedEntryData(_data);
        }
    };

    const updateRecord = (_value) => {
        const updated = [...hansokuRecords];
        const index = updated.findIndex(_v => _v.id === _value.id);

        if (index >= 0) {

            var preRecord = updated[index];
            if (processState === processStates.sellBox || processState === processStates.stopSellingBox || processState === processStates.handoverPreparation) {
                if (preRecord.desiredAmount !== _value.desiredAmount) {
                    _updateHansokuRecords(_value.id, processState, currentStaff, 'kibougaku', _value.desiredAmount);
                }
                else if (preRecord.displayedAmount !== _value.displayedAmount) {
                    _updateHansokuRecords(_value.id, processState, currentStaff, 'zumengaku', _value.displayedAmount);
                }
                else if (preRecord.area !== _value.area) {
                    _updateHansokuRecords(_value.id, processState, currentStaff, 'area', _value.area);
                }
                else if (preRecord.buyerResponsible !== _value.buyerResponsible) {
                    _updateHansokuRecords(_value.id, processState, currentStaff, 'kainushiTantou', _value.buyerResponsible);
                }
            }

            _requestHansokuRireki(_value.id, hansokuRirekiButtonState);

            updated[index] = _value;

            _requestTemplateList(currentStaff);

            const idList = [];
            updated.forEach(function (_val) {
                if (_val['check'] === true) {
                    idList.push(areaList.indexOf(_val['area']));
                }
            });
            if (idList.length === 0) {
                _requestHansokuGyousha({ type: hansokuGyoushaButtonState });
            }
            else {
                _requestHansokuGyousha({ type: hansokuGyoushaButtonState, areaIds: idList });
            }

            setHansokuRecords(updated);
        }
    };

    const onHansokuGyoushaButtonChange = (_value) => {
        setHansokuGyoushaButtonState(_value);
        if (_value !== hansokuGyoushaButtonState) {
            const updated = [...hansokuRecords];
            const idList = [];
            updated.forEach(function (_val) {
                if (_val['check'] === true) {
                    idList.push(areaList.indexOf(_val['area']));
                }
            });
            if (idList.length === 0) {
                _requestHansokuGyousha({ type: _value });
            }
            else {
                _requestHansokuGyousha({ type: _value, areaIds: idList });
            }
        }
    }

    const onHansokuRirekiButtonChange = (_value) => {
        setHansokuRirekiButtonState(_value);
        if (_value !== hansokuRirekiButtonState) {
            _requestHansokuRireki(selectedEntryData.id, _value);
        }
    }

    const formattedData = hansokuGyousha.reduce((_a, _b) => {
        const toAdd = [];
        toAdd.push({
            ...{
                id: _b.id,
                affectedBuyer: _b.gyoushaName,
                contactName: _b.tantousha1,
                buyerResponsible: UserProfile.getName(),
                selected: false,
                sortable: 1,
                mail: _b.tantousha1Mail,
            }
        })
        return _a.concat(toAdd);
    }, []);

    const generateNoItemSelectedPopup = () => {
        _displayMessageAction((''), t('noItemsSelected'),
            {
                text: t('okEng'),
                callback: () => {
                    console.log(t('noItemsSelected'));
                }
            }, false);
    }

    const handleProcessStateChange = (_newState) => {
        setProcessState(_newState);
        if (_newState === processStates.sellBox || _newState === processStates.stopSellingBox || _newState === processStates.handoverPreparation) {
            _requestHansokuRecords(_newState, currentStaff);
        }
    }

    const handleBoxTypeChange = (_newBoxType) => {
        const idList = [];
        const list = [...hansokuRecords];
        list.forEach(function (_value) {
            if (_value['check'] === true) {
                idList.push(_value['id']);
            }
        });
        if (idList.length === 0) {
            generateNoItemSelectedPopup();
        }
        else {
            let request = { BoxType: processState, StaffId: currentStaff, SendBox: _newBoxType, Ids: idList };
            _updateBoxType(request);
        }
    }

    const handleRenderProcessStateChange = (_newState) => {
        setRenderState(_newState);
    }

    const deleteRecord = () => {

        const idList = [];
        const list = [...hansokuRecords];
        list.forEach(function (_value) {
            if (_value['check'] === true) {
                idList.push(_value['id']);
            }
        });
        if (idList.length === 0) {
            generateNoItemSelectedPopup();
        }
        else {
            _displayMessageAction(t('confirmDelete'), t('emConfirmDeleteMessage'),
                {
                    text: t('okEng'),
                    callback: () => {
                        _deleteHansokuRecords({ ids: idList }, _displayMessageAction.bind(null, t('SUCCESS'), t('SUCCESS')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
                    }
                }, true);
        }
    }

    const updateSelectedStaff = (_v) => {
        setCurrentStaff(_v)
        if (processState === processStates.sellBox || processState === processStates.stopSellingBox || processState === processStates.handoverPreparation) {
            _requestHansokuRecords(processState, _v);
        }
        else {
            _requestHansokuRecords(0, _v);
        }
    };

    const processStateButton = (_currentState, _processState) => <button type="button" className={"btn" + (_currentState === processStates[_processState] ? " btn-primary" : " btn-secondary")} onClick={handleProcessStateChange.bind(null, processStates[_processState])} > {t(_processState)}</button >

    const processRenderStateButton = (_currentState, _renderState) => <button type="button" className={"btn" + (_currentState === _renderState ? " btn-primary" : " btn-secondary")} onClick={handleRenderProcessStateChange.bind(null, _renderState)}>{t(_renderState)}</button>

    const callback = (_updated) => updateRecord(_updated);

    const sendMailAndPdfCallback = (gyoushaData) => {

        const hansokuDatas = [];
        const list = [...hansokuRecords];
        list.forEach(function (_value) {
            if (_value['check'] === true) {
                hansokuDatas.push(_value);
            }
        });

        if (hansokuDatas.length === 0) {
            generateNoItemSelectedPopup();
        }

        let formattedData = null;

        if (gyoushaData !== "noMail") {
            formattedData = gyoushaData.reduce((_a, _b) => {
                const toAdd = [];
                toAdd.push({
                    ...{
                        id: _b.id,
                        templateIndex: _b.sortable,
                        gyoushaName: _b.affectedBuyer,
                        tantousha1: _b.contactName,
                        kaiushiTantou: _b.buyerResponsible,
                        tantousha1Mail: _b.mail
                    }
                })
                return _a.concat(toAdd);
            }, []);
        }

        let request = {};
        request.templateType = currentImgIndex - 1;
        request.comboStaffId = currentComboStaffId;
        request.staffId = UserProfile.getId();
        request.gyoushaData = formattedData;
        request.hansokuData = hansokuDatas;
        request.mailNeeded = gyoushaData === "noMail" ? false : true;

        _sendMailHansoku(request, _displayMessageAction.bind(null, t('SUCCESS'), t('SUCCESS')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
    }

    const exportExcel = () => {
        let count = 0;
        const list = [...hansokuRecords];
        list.forEach(function (_value) {
            if (_value['check'] === true) {
                count++;
                let request = {};
                request.templateType = currentImgIndex - 1;
                request.comboStaffId = currentComboStaffId;
                request.roomId = _value.roomId;

                _requestPromotionalActivityExcel(request, _displayMessageAction.bind(null, t('SUCCESS'), t('SUCCESS')), _displayMessageAction.bind(null, t('FAIL'), t('FAIL')));
            }
        });
        if (count == 0) {
            generateNoItemSelectedPopup();
        }
    }

    useEffect(() => {
        if (_hansokuRecords) {
            setHansokuRecords(_hansokuRecords);
        }
    }, [_hansokuRecords]);

    useEffect(() => {
        if (_kainushiTantous) {
            setKainushiTantous(_kainushiTantous);
        }
    }, [_kainushiTantous]);

    useEffect(() => {
        if (_templates) {
            setTemplates(_templates);
        }
    }, [_templates]);

    useEffect(() => {
        if (_staffs) {
            setStaffs(_staffs);
        }
    }, [_staffs]);

    useEffect(() => {
        if (_hansokuGyousha) {
            setHansokuGyousha(_hansokuGyousha);
        }
    }, [_hansokuGyousha]);

    useEffect(() => {
        if (_hansokuRirekis) {
            setHansokuRirekis(_hansokuRirekis);
        }
    }, [_hansokuRirekis]);

    useEffect(() => {
        if (_roomData) {
            setRoomData(_roomData);
        }
    }, [_roomData]);

    useEffect(() => {
        _requestHansokuRecords(0, currentStaff);
        _requestKainushiTantou();
        _requestStaffList(currentStaff);
        _requestStaffAndTemplate();
    }, []);

    const generateMoveBoxTypeButton = () => {
        if (processState === processStates.sellBox) {
            return <div className="send-to">
                <button type="button" className="btn btn-secondary" onClick={handleBoxTypeChange.bind(null, 1)}>{t('sendToStopSelling')}</button>
                <button type="button" className="btn btn-secondary" onClick={handleBoxTypeChange.bind(null, 2)}>{t('sendToHandover')}</button>
            </div>
        }
        else if (processState === processStates.stopSellingBox) {
            return <div className="send-to">
                <button type="button" className="btn btn-secondary" onClick={handleBoxTypeChange.bind(null, 0)}>{t('sendToSelling')}</button>
                <button type="button" className="btn btn-secondary" onClick={handleBoxTypeChange.bind(null, 2)}>{t('sendToHandover')}</button>
            </div>
        }
        else if (processState === processStates.handoverPreparation) {
            return <div className="send-to">
                <button type="button" className="btn btn-secondary" onClick={handleBoxTypeChange.bind(null, 1)}>{t('sendToStopSelling')}</button>
                <button type="button" className="btn btn-secondary" onClick={handleBoxTypeChange.bind(null, 0)}>{t('sendToSelling')}</button>
            </div>
        }
    };

    const updateTemplate = (data) => {
        _updateTemplateList(data);
    }

    return <article className="content thirdParties-page">
        <section className="section">
            <Row>
                <Col md={12}>
                    <div className="card">
                        <div className="card-block">
                            <section className="title-section">
                                <div className="button-row flex">
                                    <div className="button-container">
                                        <div className="btn-group">
                                            {processStateButton(processState, 'sellBox')}
                                            {processStateButton(processState, 'stopSellingBox')}
                                        </div>
                                    </div>
                                    <div className="button-container">
                                        <div className="btn-group">
                                            {processStateButton(processState, 'handoverPreparation')}
                                            {processStateButton(processState, 'succeeded')}
                                            {processStateButton(processState, 'contracted')}
                                            {processStateButton(processState, 'settled')}
                                            {processStateButton(processState, 'all')}
                                        </div>
                                    </div>
                                    {UserProfile.getPost() !== '0' && UserProfile.getPost() !== '1' && < div className="button-container">
                                        <div className="btn-group">
                                            <helpers.GenerateSelector value={currentStaff} options={(_staffs || []).map(_v => ({ value: _v.id, text: _v.name }))} onChangeHandler={updateSelectedStaff} labelSize={1} inputSize={3} extraClasses="staffs-container" />
                                        </div>
                                    </div>}
                                </div>
                            </section>
                            <section className="section table-section">
                                {hansokuRecords && < ResponsiveDatatable {...{ rows: hansokuRecords, ...tableOptions }} updateCallback={callback} />}
                            </section>
                            <section className="section table-commands-section flex">
                                <div className="commands">
                                    <button type="button" onClick={deleteRecord.bind(null, '')} className="btn btn-secondary">{t('delete')}</button>
                                </div>
                                {generateMoveBoxTypeButton()}
                            </section>
                            {selectedEntryData && <>
                                <div className="button-row flex">
                                    <div className="button-container">
                                        <div className="btn-group">
                                            {processRenderStateButton(renderState, bottomRenderStates.information)}
                                            {processRenderStateButton(renderState, bottomRenderStates.diagram)}
                                        </div>
                                    </div>
                                    <div className="button-container">
                                        <div className="btn-group pl-5">
                                            <helpers.GenerateSelector text={t('excelTemplate')} value={currentImgIndex} options={(_pdfTemplate || []).map(_v => ({ value: _v.id, text: _v.name }))} onChangeHandler={updateTemplateType} labelSize={7} inputSize={2} extraClasses="excelTemplate-container" />
                                        </div>
                                    </div>
                                    <div className="button-container">
                                        <div className="btn-group pl-5">
                                            <helpers.GenerateSelector text={t('phoneNumber')} value={currentComboStaffId} options={(_staffTemplate || []).map(_v => ({ value: _v.id, text: _v.name }))} onChangeHandler={updateComboStaffId} labelSize={3} inputSize={10} extraClasses="phoneNumber-container" />
                                        </div>
                                    </div>
                                </div>
                                <section className="section entry-description-section">
                                    <article className="callmode-page">
                                        <Row className="sameheight-container ">
                                            <Col md={12}>
                                                <PromotionalActivitiesData
                                                    imgIndex={currentImgIndex}
                                                    templateData={templates}
                                                    templateUpdateCallback={updateTemplate}
                                                    hansokuGyoushaData={formattedData}
                                                    hansokuRirekiDataData={hansokuRirekis}
                                                    hansokuGyoushaButtonChangeCallback={onHansokuGyoushaButtonChange}
                                                    hansokuRirekiButtonChangeCallback={onHansokuRirekiButtonChange}
                                                    data={roomData} propertyData={selectedEntryData.flyerData}
                                                    renderState={renderState} processState={processState}
                                                    sendMailCallback={sendMailAndPdfCallback}
                                                    exportExcel={exportExcel}
                                                    onRecordUpdate={_updateRoomData} />
                                            </Col>
                                        </Row>
                                    </article>
                                </section>
                            </>}
                            <div className="bottom-spacer"></div>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    </article>;
}
PromotionalActivities.whyDidYouRender = true;

const mapStateToProps = (state) => {
    return {
        partnerRows: state.partners.partnerRows,
        partnerEntries: state.partners.partnerEntries,
        hansokuRecords: state.partners.hansokuRecords,
        kainushiTantous: state.partners.kainushiTantous,
        templates: state.partners.templates,
        staffs: state.partners.staffs,
        hansokuGyousha: state.partners.hansokuGyoushas,
        hansokuRirekis: state.partners.hansokuRirekis,
        staffTemplate: state.partners.staffTemplate,
        pdfTemplate: state.partners.pdfTemplate,
        roomData: state.partners.roomData
    }
}
const mapDispatchToProps = {
    requestHansokuRecords: requestHansokuRecords,
    requestKainushiTantou: requestKainushiTantou,
    requestTemplateList: requestTemplateList,
    requestStaffList: requestStaffList,
    requestHansokuGyousha: requestHansokuGyousha,
    requestHansokuRireki: requestHansokuRireki,
    requestStaffAndTemplate: requestStaffAndTemplate,
    updateHansokuRecords: updateHansokuRecords,
    updateBoxType: updateBoxType,
    updateRoomData: updateRoomData,
    displayMessageAction: displayMessageAction,
    sendMailHansoku: sendMailHansoku,
    updateTemplateList: updateTemplateList,
    deleteHansokuRecords: deleteHansokuRecords,
    requestPromotionalActivityExcel: requestPromotionalActivityExcel,
    requestRoomData: requestRoomData
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalActivities);