import React from 'react';
import { isNull } from 'util';

export default function SetCellComponent({ content: _content, className: _className, widthValue: _widthValue, UserId: _UserId  }) {
    
    return <div className={`col-xs-${_widthValue} ${_className || ''} `}  >
        {_content}
    </div>
    
}
