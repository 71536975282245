import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import * as helpers from 'helpers/formHelpers';
import UserProfile from 'helpers/userProfile';

export function EmployeeEditForm({ title: _title, employee: _employee, roleOptions: _roleOptions, teamOptions: _teamOptions, groupOptions: _groupOptions, onSubmitCallback: _onSubmitCallback, onCancelCallback: _onCancelCallback, type: _type }) {

    const { t } = useTranslation();
    const [employee, setEmployee] = useState(_employee);
    useEffect(() => setEmployee(_employee), [_employee]);

    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setEmployee({ ...employee, ...update });
    }

    const setRoles = () => {
        const result = [];

        let logPost = parseInt(UserProfile.getPost()) === NaN ? 0 : parseInt(UserProfile.getPost());
        let logId = parseInt(UserProfile.getId());

        const roles = [
            { id: 0, name: t('general') },
            { id: 1, name: t('role') },
            { id: 2, name: t('leader') },
            { id: 3, name: t('listAdministrator') },
            { id: 4, name: t('manager') },
            { id: 5, name: t('applicantForTranscript') },
            { id: 6, name: t('systemAdministrator') }
        ];

        if (_type === 'add') {
            if (UserProfile.getPost() == "2") {
                _roleOptions.forEach(_v => {
                    if (_v.id === 0 || _v.id === 1)
                        result.push(_v);
                });
            }
            else if (UserProfile.getPost() == "4") {
                _roleOptions.forEach(_v => {
                    if (_v.id === 0 || _v.id === 1 || _v.id === 2)
                        result.push(_v);
                });
            }
            else {
                return _roleOptions;
            }
        }
        else {
            if (employee['id'] === logId) {
                for (let i = 0; i <= logPost; i++) {
                    if (!(employee['post'] === 4 && i === 3))
                        result.push(roles[i]);
                }
                if (employee['post'] === 4)
                    updateState('post', (parseInt(employee['post'], 10) - 1));
            }
            else if (employee['post'] === 3) {
                for (let i = 0; i <= 4; i++) {
                    result.push(roles[i]);
                }
            }
            else {
                return _roleOptions;
            }
        }

        return result;
    }

    const enableEditterFlg = employee['post'] ? (employee['post'] === 0 || employee['post'] === 1 || employee['post'] === 3 || employee['post'] === 5 || employee['post'] === 7) ? true : false : true;

    const remaindermailLimitValues = [{ id: 1, name: 1 + t('beforeDays') }, { id: 2, name: 2 + t('beforeDays') }, { id: 3, name: 3 + t('beforeDays') }, { id: 4, name: 4 + t('beforeDays') }, { id: 5, name: 5 + t('beforeDays') }];

    const onSubmitHandler = _onSubmitCallback ? () => _onSubmitCallback(employee) : undefined;

    return <div className="card">
        <div className="card-block">
            <div className="title-block">
                <h3 className="title">{_title}</h3>
            </div>
            <section className="section title-section">
                <div className="employee-edit-form">
                    <form>
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateInput text={t('name')} value={employee['name']} onChangeHandler={(_v) => updateState('name', _v)} labelSize={2} extraClasses="name-container" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateInput text={t('idEng')} value={employee['loginId']} onChangeHandler={(_v) => updateState('loginId', _v)} labelSize={2} extraClasses="loginId-container" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateInput text={t('passEng')} type={'password'} value={employee['loginPass']} onChangeHandler={(_v) => updateState('loginPass', _v)} labelSize={2} extraClasses="loginPass-container" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateSelector value={employee['post']} text={t('role')} options={(setRoles() || []).map(_v => ({ value: _v.id, text: _v.name }))} onChangeHandler={(_v) => updateState('post', parseInt(_v, 10))} labelSize={2} inputSize={10} extraClasses="role-container" />
                            </Col>
                        </Row>


                        {(UserProfile.getPost() == "6" || UserProfile.getPost() == "4") &&
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateSelector value={employee['teamId']} text={t('team')} options={[{ value: null, text: null }].concat((_teamOptions || []).map(_v => ({ value: _v.id, text: _v.name })))} onChangeHandler={(_v) => updateState('teamId', parseInt(_v, 10))} labelSize={2} inputSize={10} extraClasses="teamId-container" />
                                </Col>
                            </Row>}

                        {UserProfile.getPost() == "6" &&
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateSelector value={employee['groupId']} text={t('group')} options={[{ value: null, text: null }].concat((_groupOptions || []).map(_v => ({ value: _v.id, text: _v.groupName })))} onChangeHandler={(_v) => updateState('groupId', parseInt(_v, 10))} labelSize={2} inputSize={10} extraClasses="groupId-container" />
                                </Col>
                            </Row>}


                        <Row>
                            <Col lg={3} md={3} sm={3} xl={3}>
                            </Col>
                            <Col lg={3} md={3} sm={3} xl={3}>
                                <helpers.GenerateCheckbox text={t('display')} value={employee['visibleFlg'] === "1" ? true : false} onChangeHandler={(_v) => updateState('visibleFlg', _v === true ? "1" : "0")} />
                            </Col>
                            <Col lg={3} md={3} sm={3} xl={3}>
                                <helpers.GenerateCheckbox text={t('transferOwnerInCharge')} value={employee['ownerJoutoKanrisha'] === 1 ? true : false} onChangeHandler={(_v) => updateState('ownerJoutoKanrisha', _v === true ? 1 : 0)} />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateInput text={t('reminderMail')} value={employee['mail']} onChangeHandler={(_v) => updateState('mail', _v)} labelSize={2} extraClasses="mail-container" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateSelector value={employee['mailLimit']} text={t('sendRemainder')} options={[{ value: null, text: null }].concat((remaindermailLimitValues || []).map(_v => ({ value: _v.id, text: _v.name })))} onChangeHandler={(_v) => updateState('mailLimit', parseInt(_v, 10))} labelSize={2} inputSize={10} extraClasses="mailLimit-container" />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateCheckbox text={t('salesDrawingChargeFlag')} value={employee['zumenFlag'] === 1 ? true : false} onChangeHandler={(_v) => updateState('zumenFlag', _v === true ? 1 : 0)} />
                            </Col>
                        </Row>
                        {employee['zumenFlag'] === 1 && <>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateInput text={t('salesDrawingMail')} value={employee['zumenMailAddress']} onChangeHandler={(_v) => updateState('zumenMailAddress', _v)} labelSize={2} extraClasses="zumenMailAddress-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateInput text={t('salesDrawingTel')} value={employee['zumenPhoneNumber']} onChangeHandler={(_v) => updateState('zumenPhoneNumber', _v)} labelSize={2} extraClasses="zumenPhoneNumber-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateInput text={t('salesDrawingLine')} value={employee['zumenLineAccount']} onChangeHandler={(_v) => updateState('zumenLineAccount', _v)} labelSize={2} extraClasses="zumenLineAccount-container" />
                                </Col>
                            </Row>
                        </>}
                        <Row className="form-group">
                            <Col lg={12} md={12} sm={12} xl={12}>
                                <helpers.GenerateCheckbox text={t('businessMobileUseFlag')} value={employee['callFlg'] === "1" ? true : false} onChangeHandler={(_v) => updateState('callFlg', _v === true ? "1" : "0")} />
                            </Col>
                        </Row>
                        {employee['callFlg'] === "1" && <>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateInput text={t('callUserName')} value={employee['callUser']} onChangeHandler={(_v) => updateState('callUser', _v)} labelSize={2} extraClasses="callUser-container" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateInput text={t('callId')} type={'number'} value={employee['callId']} onChangeHandler={(_v) => updateState('callId', _v)} labelSize={2} extraClasses="callId-container" />
                                </Col>
                            </Row>
                        </>}

                        {UserProfile.getPost() !== "2" &&
                            <Row className={"form-group"} disabled={enableEditterFlg}>
                                <Col lg={12} md={12} sm={12} xl={12}>
                                    <helpers.GenerateCheckbox text={t('grantLoginAuthority')} value={employee['editterFlg'] === 1 ? true : false} onChangeHandler={(_v) => updateState('editterFlg', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>}

                        {UserProfile.getPost() !== "2" && <>
                            <Row className="form-group">
                                <Col lg={6} md={6} sm={6} xl={6}>
                                    <helpers.GenerateCheckbox text={t('hankyouFlag')} value={employee['hankyoFlg'] === 1 ? true : false} onChangeHandler={(_v) => updateState('hankyoFlg', _v === true ? 1 : 0)} />
                                </Col>
                                <Col lg={6} md={6} sm={6} xl={6}>
                                    <helpers.GenerateCheckbox text={t('ecFlag')} value={employee['ecFlg'] === 1 ? true : false} onChangeHandler={(_v) => updateState('ecFlg', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col lg={6} md={6} sm={6} xl={6}>
                                    <helpers.GenerateCheckbox text={t('visitFlag')} value={employee['houmonFlg'] === 1 ? true : false} onChangeHandler={(_v) => updateState('houmonFlg', _v === true ? 1 : 0)} />
                                </Col>
                                <Col lg={6} md={6} sm={6} xl={6}>
                                    <helpers.GenerateCheckbox text={t('lsFlag')} value={employee['lsHoldFlg'] === "1" ? true : false} onChangeHandler={(_v) => updateState('lsHoldFlg', _v === true ? "1" : "0")} />
                                </Col>
                            </Row>
                        </>}
                        <div className="form-group row">
                            <div className="col-sm-12 form-controls">
                                <button type="button" className="btn btn-secondary" onClick={_onCancelCallback}>{t('cancel')}</button>
                                <button type="button" className="btn btn-primary" onClick={onSubmitHandler}>{t('okEng')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>;
}

EmployeeEditForm.defaultProps = {
    employee: {
    }
}