import React, { Component } from "react";

export function SimpleToggle({ label: _label, value: _value, defaultValue: _defaultValue, disabled: _disabled, radioName: _radioName, extraClasses: _extraClasses, onChange: _onChange}){
    const inputProps = {
        type: 'checkbox'
    }

    if (_radioName) {
        inputProps.type = 'radio';
        inputProps.className = `radio`;
    } else {
        inputProps.className = `checkbox`;
    }
    return <label className={`single-line  ${_extraClasses || ''}`}>
        <input {...inputProps} defaultValue={_defaultValue} disabled={_disabled} checked={_value} onChange={(_e) => _onChange(_e.target.checked)} /><span>{_label}</span>
    </label>

}
SimpleToggle.whyDidYouRender = true;