import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function DescriptionField({ text: _text, displayValue: _displayValue, extraClasses: _extraClasses, isEditable: _isEditable, handleChangedCallback: _handleChangedCallback, extraValue: _extraValue }) {
    const { t } = useTranslation();
    const [isEditinig, setIsEditinig] = useState(false);
    const [value, setValue] = useState(_displayValue);

    const valueDisplay = (_isEditinig) => {
        return _isEditinig ?
            <input autoFocus className="value-input form-control boxed" value={value} type="text" onChange={handleChanged} onKeyUp={handleKeyUp} onFocus={(_e) => _e.target.select()} /> :
            <span className="value-field">{value}</span>;
    };

    const handleEditableCallback = (_args, _reset, _shouldSubmit) => {
        if (_reset) {
            if (_shouldSubmit) {
                _handleChangedCallback({ value: value });
            } else {
                setValue(_displayValue);    
            }
            setIsEditinig(false);
        } else if (!isEditinig) {
            setIsEditinig(true);
        }
    }

    const handleChanged = (_e) => {
        setValue(_e.target.value);
    }

    const handleKeyUp = (_e) => {
        if (_e.key === 'Enter') handleEditableCallback(null, true, true);
        else if (_e.key === 'Escape') handleEditableCallback(null, true, false);
    }
    
    useEffect(
        () => {
            if (value !== _displayValue && ((_isEditable && !isEditinig) || !_isEditable)) {
                setValue(_displayValue);
            }
        },
        [_displayValue]
    );

    return <div
        className={"description-container " + _extraClasses}
        onClick={_isEditable ? handleEditableCallback : undefined}>
        <div className="name-field">{t(_text)}</div>
        <div className={"value-container" + (isEditinig ? " active" : " idle")}>
            {valueDisplay(isEditinig)}
            <div className="indicator" />
        </div>
        {_extraValue && <span className="extra-value-field">{_extraValue}</span>}
    </div>
}

DescriptionField.defaultProps = {
    extraClasses: '',
    text: '',
    value: ''
};