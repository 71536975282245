import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTimeShort } from 'helpers/formatHelpers'

export function DisplayReminders({ reminders: _reminders, onCancelCallback: _onCancelCallback, onRenewReminderCallback: _onRenewReminderCallback, onRemoveReminderCallback: _onRemoveReminderCallback}) {

    const { t } = useTranslation();

    const [selected, setSelected] = useState(_reminders && _reminders.length > 0 ? { id: _reminders[0].id, index: 0 } : { id: -1, index: -1 });
    const [selectedDelay, setSelectedDelay] = useState(0);

    const reupOptions = () => {
        const output = [];
        for (let i = 0; i < 12; i++)
            output.push(<option key={i} value={i}>{(i + 1) * 5}</option>);
        return output;
    };

    const renewReminder = _onRenewReminderCallback ? () => {
        if (selected.index >= 0 && _reminders.length > 0) {
            _onRenewReminderCallback(_reminders[selected.index].id, Date.now() + ((selectedDelay + 1) * 5 * 60000));
        }
    } : undefined;

    const cancelReminder = _onRemoveReminderCallback ? () => {
        if (selected.index >= 0 && _reminders.length > 0) {
            _onRemoveReminderCallback(_reminders[selected.index].id);
        }
    } : undefined;

    return <div className="reminders-modal modal fade show">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{t("callAgain")}</h4>
                    <button type="button" className="close" onClick={_onCancelCallback}>
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">{t("callAgain")}</span>
                    </button>
                </div>
                <div className="reminders-modal-table-container modal-body modal-tab-container flex justify-center">
                    <div className="reminders-modal-table table-responsive">
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>{t("ownerName")}</th>
                                    <th>{t("time")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_reminders && _reminders.map((_r, _i) => <tr className={'reminder-cell ' + (_r.id === selected.id ? 'selected-reminder' : '')} onClick={() => setSelected({ id: _r.id, index: _i })} key={_r.id}>
                                    <td className="reminders-modal-name-cell">{_r.name || _r.id}</td>
                                    <td className="single-line">{formatDateTimeShort(_r.expiryDate, false)}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="modal-footer">

                    <section className="actions-section section ">
                        <button type="button" className="btn btn-secondary">{t("goToCallPage")}</button>
                        <div className="btn-group">
                            <div className="flex">
                                <select className="reup-selector form-control" value={selectedDelay} onChange={_v => { setSelectedDelay(Number(_v.target.value)) }}>
                                    {reupOptions()}
                                </select>
                                <button type="button" className="btn btn-secondary" onClick={renewReminder} >{t("callAfterMinutes")}</button>
                            </div>
                        </div>
                        <button type="button" className="btn btn-secondary" onClick={cancelReminder} >{t("deleteSelectedItem")}</button>
                    </section>

                </div>
            </div>
        </div>
    </div>;
}