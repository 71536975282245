import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';
import { validatorNumbersOnly } from 'helpers/validatorHelpers';

function PropertyAddress({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    return <>
        <wf.InputField
            label={t('location')}
            value={data.location.value || data.location.defaultValue}
            className="form-group"
            onChange={setData('location')} />
        <wf.InputField
            label={t('locationNumber')}
            value={data.locationNumber.value || data.locationNumber.defaultValue}
            className="form-group"
            onChange={setData('locationNumber')}
            onError={setData('locationNumber')}
            validator={(_v) => validatorNumbersOnly(_v, 0)}
            errorMessage={t('incorrectNumberFormatError')}/>
    </>
}
const PropertyAddressPopup = ExportDefaultBehaviour(PropertyAddress, {
    editableFields: ['location', 'locationNumber']
});
PropertyAddressPopup.whyDidYouRender = true;
export default PropertyAddressPopup;