import 'babel-polyfill';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiEntryPoint } from 'apiConnector';
import { requestEmployees, requestRoles, requestTeams, requestMultiTeams, requestGroups, pushEmployee, pushModifyEmployee, pushModifyEmployeeTeams, pushDeleteEmployee, pushModifyTeam, pushDeleteTeam, pushModifyGroup, pushDeleteGroup, pushAddTeam, pushAddGroup, addEmployees, addRoles, addTeams, addMultiTeams, replaceMultiTeams, deleteTeam, deleteEmployee, deleteEmployees, addGroups, deleteGroup } from 'reducers/employeeManagerReducer';

function* requestEmployeesSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerRequestEmployees, _action.id);
        if (result && Array.isArray(result)) {
            yield put(addEmployees(result));
        }
    } catch (_e) {
        console.log('Error from requestEmployeesSaga');
        console.log(_e);
    }
}

function* requestRolesSaga() {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerRequestRoles);
        if (result && Array.isArray(result)) {
            yield put(addRoles(result));
        }
    } catch (_e) {
        console.log('Error from requestRolesSaga');
        console.log(_e);
    }
}

function* requestTeamsSaga() {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerRequestTeams);
        if (result && Array.isArray(result)) {
            yield put(addTeams(result));
        }
    } catch (_e) {
        console.log('Error from requestTeamsSaga');
        console.log(_e);
    }
}

function* requestMultiTeamsSaga() {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerRequestMultiTeams);
        if (result && Array.isArray(result)) {
            yield put(addMultiTeams(result));
        }
    } catch (_e) {
        console.log('Error from requestMultiTeamsSaga');
        console.log(_e);
    }
}

function* requestGroupsSaga() {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerRequestGroups);
        if (result && Array.isArray(result)) {
            yield put(addGroups(result));
        }
    } catch (_e) {
        console.log('Error from requestGroupsSaga');
        console.log(_e);
    }
}

function* pushEmployeeSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerPushEmployee, _action.employee);
        if (result && Array.isArray(result)) {
            yield put(addEmployees(result));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from pushEmployeeSaga');
        console.log(_e);
    }
}

function* pushModifyEmployeeSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerModifyEmployee, _action.employee);
        if (result && Array.isArray(result)) {
            yield put(addEmployees(result));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from pushModifyEmployeeSaga');
        console.log(_e);
    }
}

function* pushModifyEmployeeTeamsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerModifyEmployeeTeams, _action.employee);
        if (result && Array.isArray(result)) {
            yield put(replaceMultiTeams(result));
        }
    } catch (_e) {
        console.log('Error from pushModifyEmployeeTeamsSaga');
        console.log(_e);
    }
}

function* pushDeleteEmployeeSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerDeleteEmployee, _action.employee);
        if (!result.error) {
            yield put(deleteEmployee(_action.employee.id));
            if (_action.successCallback) _action.successCallback();
        }
    } catch (_e) {
        console.log('Error from pushDeleteEmployeeSaga');
        console.log(_e);
    }
}

function* pushModifyTeamSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerModifyTeam, _action.team);
        if (result && Array.isArray(result.staffs) && Array.isArray(result.teams)) {
            yield put(addTeams(result.teams));
            yield put(addEmployees(result.staffs));
        }
    } catch (_e) {
        console.log('Error from pushModifyTeamSaga');
        console.log(_e);
    }
}

function* pushAddTeamSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerAddTeam, _action.team);
        if (result && Array.isArray(result) && Array.isArray(result)) {
            yield put(addTeams(result));
        }
    } catch (_e) {
        console.log('Error from pushAddTeamSaga');
        console.log(_e);
    }
}

function* pushDeleteTeamSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerDeleteTeam, _action.team);
        if (!result.error) {
            yield put(deleteTeam(_action.team));
            yield put(addEmployees(result));
            if (_action.successCallback) _action.successCallback();
        }
    } catch (_e) {
        console.log('Error from pushDeleteTeamSaga');
        console.log(_e);
    }
}

function* pushModifyGroupSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerModifyGroup, _action.group);
        if (result && Array.isArray(result)) {
            yield put(addGroups(result));
        }
    } catch (_e) {
        console.log('Error from pushModifyGroupSaga');
        console.log(_e);
    }
}

function* pushAddGroupSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerAddGroup, _action.group);
        if (result && Array.isArray(result)) {
            yield put(addGroups(result));
        }
    } catch (_e) {
        console.log('Error from pushAddGroupSaga');
        console.log(_e);
    }
}

function* pushDeleteGroupSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.EmployeesManagerDeleteGroup, _action.group);
        if (!result.error) {
            yield put(deleteGroup(_action.group));
            yield put(addEmployees(result));
            if (_action.successCallback) _action.successCallback();
        }
    } catch (_e) {
        console.log('Error from pushDeleteGroupSaga');
        console.log(_e);
    }
}

export function* watchRequestEmployeesSaga() {
    yield takeEvery(requestEmployees().type, requestEmployeesSaga);
}

export function* watchRequestRolesSaga() {
    yield takeEvery(requestRoles().type, requestRolesSaga);
}

export function* watchRequestTeamsSaga() {
    yield takeEvery(requestTeams().type, requestTeamsSaga);
}

export function* watchRequestMultiTeamsSaga() {
    yield takeEvery(requestMultiTeams().type, requestMultiTeamsSaga);
}

export function* watchRequestGroupsSaga() {
    yield takeEvery(requestGroups().type, requestGroupsSaga);
}

export function* watchPushEmployeeSaga() {
    yield takeEvery(pushEmployee().type, pushEmployeeSaga);
}

export function* watchPushModifyEmployeeSaga() {
    yield takeEvery(pushModifyEmployee().type, pushModifyEmployeeSaga);
}

export function* watchPushModifyEmployeeTeamsSaga() {
    yield takeEvery(pushModifyEmployeeTeams().type, pushModifyEmployeeTeamsSaga);
}

export function* watchPushDeleteEmployeeSaga() {
    yield takeEvery(pushDeleteEmployee().type, pushDeleteEmployeeSaga);
}

export function* watchPushModifyTeamSaga() {
    yield takeEvery(pushModifyTeam().type, pushModifyTeamSaga);
}

export function* watchPushAddTeamSaga() {
    yield takeEvery(pushAddTeam().type, pushAddTeamSaga);
}

export function* watchPushDeleteTeamSaga() {
    yield takeEvery(pushDeleteTeam().type, pushDeleteTeamSaga);
}

export function* watchPushModifyGroupSaga() {
    yield takeEvery(pushModifyGroup().type, pushModifyGroupSaga);
}

export function* watchPushAddGroupSaga() {
    yield takeEvery(pushAddGroup().type, pushAddGroupSaga);
}

export function* watchPushDeleteGroupSaga() {
    yield takeEvery(pushDeleteGroup().type, pushDeleteGroupSaga);
}