import 'babel-polyfill';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiEntryPoint } from 'apiConnector';
import * as ii from 'reducers/imageImportReducer';

function* requestAssetsDescriptionSaga() {
    try {
        const result = yield call(ApiEntryPoint.ImageImportRequestAssetsDescription);
        if (result) {
            yield put(ii.setAssets(result));
        }
    } catch (_e) {
        console.log('Error from requestAssetsDescriptionSaga');
        console.log(_e);
    }
}

function* requestAssetsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.ImageImportRequestAssets, _action.BukkenId, _action.fileName);
        if (result) {
            result.images.map(_t => {
                _t.fileName = _action.fileName;
            })
            yield put(ii.setAssets(result));
        }
    } catch (_e) {
        console.log('Error from requestAssetsSaga');
        console.log(_e);
    }
}

function* requestDefaultAssetSaga() {
    try {
        const result = yield call(ApiEntryPoint.ImageImportRequestDefaultAsset);
        if (result) {
            yield put(ii.setDefaultAsset(result.data));
        }
    } catch (_e) {
        console.log('Error from requestDefaultAssetSaga');
        console.log(_e);
    }
}

function* requestBatchAssetContentSaga(_action) {
    try {
        for (let requested of _action.data) {
            const result = yield call(ApiEntryPoint.ImageImportRequestAsset, requested);
            if (result && !result.error) {
                yield put(ii.setAssetData({
                    name: requested,
                    content: result.data
                }));
            }
        }
    } catch (_e) {
        console.log('Error from requestBatchAssetContentSaga');
        console.log(_e);
    }
}

function* requestUpdateSaga(_action) {
    try {
        _action.data.map(_t => {
            console.log(_t);
            _t.fileName = _action.fileName;
            _t.bukkenId = _action.bukkenId;
        })

        const result = yield call(ApiEntryPoint.ImageImportUpdateAssets, _action.data);
        if (result) {
            result.images.map(_t => {
                _t.fileName = _action.fileName;
            })
            yield put(ii.setAssets(result));
        }
    } catch (_e) {
        console.log('Error from requestUpdateSaga');
        console.log(_e);
    }
}

export function* watchRequestAssetsDescriptionSaga() {
    yield takeEvery(ii.requestAssetsDescription().type, requestAssetsDescriptionSaga);
}

export function* watchRequestAssetsSaga() {
    yield takeEvery(ii.requestAssets().type, requestAssetsSaga);
}

export function* watchRequestDefaultAssetSaga() {
    yield takeEvery(ii.requestDefaultAsset().type, requestDefaultAssetSaga);
}

export function* watchRequestBatchAssetContentSaga() {
    yield takeEvery(ii.requestBatchAssetContent().type, requestBatchAssetContentSaga);
}

export function* watchRequestUpdateSaga() {
    yield takeEvery(ii.requestUpdate().type, requestUpdateSaga);
}