const types = {
    submitedFiles: 'FM_SUBMITED_FILES',
    receivedFiles: 'FM_RECEIVED_FILES',
    cancelSubmission: 'FM_CANCEL_SUBMISSION',
    cancelAllSubmissions: 'FM_CANCEL_ALL_SUBMISSIONS'
};

/** Async Actions **/
export const submitedFiles = fileRequest => ({
    type: types.submitedFiles,
    fileRequest
});

export const receivedFiles = files => ({
    type: types.receivedFiles,
    files
});

export const cancelSubmission = files => ({
    type: types.cancelSubmission,
    files
});

export const cancelAllSubmissions = () => ({
    type: types.cancelAllSubmissions
});

/** Reducer **/
const reducer = (_state = {}, _action) => {
    const newState = { ..._state };
    switch (_action.type) {
        case types.submitedFiles:
            newState.uploading = { ...newState.uploading, ..._action.fileRequest};
            return newState
        case types.receivedFiles:
            return newState
        default:
            return _state;
    }
}

export default reducer;