import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';
import { DateSelector } from 'components/inputs/textInput'

function SaleDate({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const saleReasonTypes = ["unspecified", "retirement", "move", "other"].reduce(_checkboxGroupHOC.checkboxGroupReducer('saleReasonTypes'), {});
    return <>
        <wf.CheckboxGroupField values={saleReasonTypes} onChange={(_v) => setData('saleReasonTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="saleReasonTypes" />
        <wf.InputField
            value={data.saleReasonNote.value || data.saleReasonNote.defaultValue}
            className="form-group"
            onChange={setData('saleReasonNote')} />
        <wf.GroupField label={t('saleDate')}>
            <DateSelector showIcon={true} value={data.saleDate.value || data.saleDate.defaultValue} onChange={setData('saleDate')} extraClasses="fill-w"/>
        </wf.GroupField>
    </>
}
export const SaleDatePopup = ExportDefaultBehaviour(SaleDate, {
    editableFields: ['saleReasonTypes', 'saleReasonNote', 'saleDate']
});
SaleDatePopup.whyDidYouRender = true;