import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ImageContainer from './imageContainer';

export function ImageImport({ data: _data, onUploadCallback: _onUploadCallback, onDeleteCallback: _onDeleteCallback, onCancelCallback: _onCancelCallback, disabled: _disabled }) {

    const { t } = useTranslation();
    const [data, setData] = useState(_data);
    const [largeDisplay, setLargeDisplay] = useState();
    const dataRef = useRef(data);
    const uploadHandler = _onUploadCallback ? () => _onUploadCallback(_data) : () => { };
    const expandHandler = (_image) => {
        if (_image) setLargeDisplay(_image);
    }

    const generateImageControls = () => {
        const output = [];
        for (let i = 0; i < dataRef.current.length; i++) {
            const defaultData = {};
            if (dataRef.current[i].defaultContent) {
                defaultData.onDefaultSelected = (_v) => {
                    const images = [...dataRef.current];
                    images[i].isDefaultSelected = _v;
                    dataRef.current = images;
                    setData(images);
                }
                defaultData.defaultImage = dataRef.current[i].defaultContent;
                defaultData.isDefaultSelected = dataRef.current[i].isDefaultSelected;
            }

            output.push(<ImageContainer
                title={`${t('image')} ${i + 1}` + (dataRef.current[i].title ? `: ${dataRef.current[i].title}` : '')}
                image={dataRef.current[i].content}
                isLoading={dataRef.current[i].name != null && dataRef.current[i].content === undefined}
                onExpandCallback={expandHandler}
                onImageCallback={(_image) => {
                    const images = [...dataRef.current];
                    images[i].content = _image;
                    images[i].Name = "Name";
                    dataRef.current = images;
                    setData(images);
                }}
                onDeleteCallback={(_image) => {
                    if (!_onDeleteCallback || _onDeleteCallback(_image)) {
                        const images = [...dataRef.current];
                        images[i].content = null;
                        dataRef.current = images;
                        setData(images);
                    }
                }}
                {...defaultData}
                key={i} />);
        }
        return output;
    }

    const generateButtons = () => {
        if (largeDisplay) {
            return <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setLargeDisplay(null)}>{t("close")}</button>
            </div>
        } else {
            return <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={_onCancelCallback}>{t("cancel")}</button>
                {_disabled &&
                    <button type="button" className="btn btn-primary disabled">{t("loading")}&nbsp;<i className="fas fa-circle-notch fa-spin" /></button>}
                {!_disabled &&
                    <button type="button" className="btn btn-primary" onClick={uploadHandler}>{t("save")}</button>
                }

            </div>
        }
    };

    const handleEsc = (_e) => {
        if (_e.keyCode === 27) {
            _e.preventDefault();
            setLargeDisplay(null)
        }
    }

    useEffect(() => {
        dataRef.current = _data;
        setData(_data);
    }, [_data])

    useEffect(() => {
        if (largeDisplay) {
            document.addEventListener("keydown", handleEsc);
            return () => document.removeEventListener("keydown", handleEsc);
        }
    }, [largeDisplay])

    const containerProps = {};

    if (largeDisplay) {
        containerProps.className = 'modal-dialog modal-xxl';
    } else {
        containerProps.className = 'modal-dialog modal-lg';
    }

    return <div className="file-upload-modal modal fade show">
        <div {...containerProps}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{t("imageRegistration")}</h4>
                    <button type="button" className="close" onClick={_onCancelCallback}>
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">{t("fileUpload")}</span>
                    </button>
                </div>
                <div className="modal-body modal-tab-container upload-container row">
                    {!largeDisplay && generateImageControls()}
                    {largeDisplay &&
                        <div className="large-display image-container flex fill-h fill-w justify-center">
                            <img src={largeDisplay} alt={'no-data'} />
                        </div>
                    }
                </div>
                {generateButtons()}
            </div>
        </div>
    </div>
}

ImageImport.defaultProps = {
    data: []
}