import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';
import { validatorNumbersOnly } from 'helpers/validatorHelpers';

function Age({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    const ageOptions = { 'unspecified': { checked: (data.ageOptions.value || data.ageOptions.defaultValue || {}).unspecified || false, label: t('unspecified') } };
    ['twenties', 'thirties', 'forties', 'fifties', 'sixties', 'seventies', 'eighties', 'nineties'].forEach(_k => {
        ageOptions[_k] = { checked: (data.ageOptions.value || data.ageOptions.defaultValue || {})[_k] || false, label: t(_k) + t('-decade') }
    });

    return <>
        <wf.CheckboxGroupField hideLabel={true} values={ageOptions} onChange={(_v) => setData('ageOptions')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="ageOptions" contentExtraClasses="flex-wrap" />
        <wf.GroupField hideLabel={true}>
            <Row>
                <Col className="col-xs-3">
                    <wf.InputField
                        value={data.age.value || data.age.defaultValue}
                        hideLabel={true}
                        className="form-group"
                        validator={(_v) => validatorNumbersOnly(_v, 0)}
                        errorMessage={t('incorrectNumberFormatError')}
                        onChange={setData('age')}
                        onError={setData('age')}
                        addon={t('yearsOld')} />
                </Col>
                <Col className="col-xs-4 offset-sm-1">
                    <wf.InputField
                        value={data.yob.value || data.yob.defaultValue}
                        hideLabel={true}
                        className="form-group"
                        validator={(_v) => _v.length < 1 || (validatorNumbersOnly(_v, 4, 4) && _v > new Date().getFullYear() - 1000 && _v < new Date().getFullYear())}
                        errorMessage={t('incorrectYearFormatError')}
                        onChange={setData('yob')}
                        onError={setData('yob')}
                        addon={t('year')} />
                </Col>
                <Col className="col-xs-4">
                    <wf.InputField
                        value={data.mob.value || data.mob.defaultValue}
                        hideLabel={true}
                        className="form-group"
                        validator={(_v) => _v.length < 1 || (validatorNumbersOnly(_v, 0, 2) && _v > 0 && _v < 13)}
                        errorMessage={t('incorrectMonthFormatError')}
                        onChange={setData('mob')}
                        onError={setData('mob')}
                        addon={t('month') + t('pointInTime')} />
                </Col>
            </Row>
        </wf.GroupField>
    </>
}
export const AgePopup = ExportDefaultBehaviour(Age, {
    editableFields: ['ageOptions', 'age', 'yob', 'mob']
});
AgePopup.whyDidYouRender = true;