import { all } from 'redux-saga/effects';
import * as tSagas from './teamSagas';
import * as pSagas from './partnersSagas';
import * as rSagas from './remindersSagas';
import * as cmSagas from './callmodeSearchSagas';
import * as emSagas from './employeeManagerSagas';
import * as iiSagas from './imageImportSagas';
import * as smSagas from './sessionManagerSagas';
import * as usSagas from './userSalesSagas';
import * as dmSagas from './dmSagas';
import * as exportSagas from './exportSagas';
import * as mSagas from './maintenanceSagas';
import * as imaSagas from './importAllSagas';
import * as asSagas from './assessmentRequestSagas';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    const importAll = (_module) => {
        const output = [];
        for (const fn in _module) {
            output.push(_module[fn]());
        }
        return output;
    }

    yield all([
        tSagas,
        pSagas,
        rSagas,
        cmSagas,
        emSagas,
        iiSagas,
        smSagas,
        usSagas,
        dmSagas,
        exportSagas,
        mSagas,
        imaSagas,
        asSagas
    ].reduce((_a, _b) => _a.concat(importAll(_b)), []));
}