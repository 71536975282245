import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { SidebarButton } from './sidebarButton';

class Sidebar extends Component {
    displayName = Sidebar.name

    constructor(_props) {
        super(_props);
    }

    actionWrapper(_target, _action, _e) {
        _e.nativeEvent.stopImmediatePropagation();
        _action();
    }

    render() {
        return (
            <aside className="sidebar">
                <div className="sidebar-container">
                    <div className="sidebar-header">
                        <div className="brand">
                            <div className="logo">
                            </div></div>
                    </div>
                    <nav className="menu">
                        <ul className="sidebar-menu metismenu" id="sidebar-menu">
                            {
                                this.props.pages && this.props.pages.map(_p => {
                                    return <SidebarButton key={_p.name} text={_p.name} clickHandler={this.actionWrapper.bind(this, _p, _p.handler)} isActive={this.props.location === _p.name} />
                                }, this)
                            }
                        </ul>
                    </nav>
                </div>
                <footer className="sidebar-footer">
                    <ul id="customize-menu" className="sidebar-menu metismenu">
                        <SidebarButton text={this.props.t('logout')} clickHandler={this.actionWrapper.bind(null, null, this.props.logout)} />
                    </ul>
                </footer>
            </aside>
        );
    }
}

const mapDispatchToProps = {
}

export default connect(null, mapDispatchToProps)(withTranslation()(Sidebar));