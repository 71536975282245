import { SortedArray } from 'helpers/listMaintenanceHelper';

const types = {
    requestBuildingAssets: 'M_REQUEST_BUILDING_ASSETS',
    requestOwners: 'M_REQUEST_OWNERS',
    requestPropertyRooms: 'M_REQUEST_PROPERTY_ROOMS',
    requestOwnerRooms: 'M_REQUEST_OWNER_ROOMS',
    requestStaffs: 'M_REQUEST_STAFFS',
    requestZaitakuDetails: 'M_REQUEST_ZAITAKU_DETAILS',

    addBuildingAssets: 'M_ADD_BUILDING_ASSETS',
    addOwners: 'M_ADD_OWNERS',
    addPropertyRooms: 'M_ADD_PROPERTY_ROOMS',
    addStaffs: 'M_ADD_STAFFS',
    addZaitakuDetails: 'M_ADD_ZAITAKU_DETAILS',

    deleteBuildingAsset: 'M_DELETE_BUILDING_ASSET',
    deleteOwner: 'M_DELETE_OWNER',
    deletePropertyRoom: 'M_DELETE_PROPERTY_ROOM',

    postDeleteBuildingAsset: 'M_POST_DELETE_BUILDING_ASSET',
    postDeleteOwner: 'M_POST_DELETE_OWNER',
    postDeletePropertyRoom: 'M_POST_DELETE_PROPERTY_ROOM',

    updateBuildingAsset: 'M_UPDATE_BUILDING_ASSET',
    updateRoom: 'M_UPDATE_ROOM',
    updateOwner: 'M_UPDATE_OWNER',
    updateOwnerRoom: 'M_UPDATE_OWNER_ROOM',

    insertBuildingAsset: 'M_INSERT_BUILDING_ASSET',
    insertRoom: 'M_INSERT_ROOM',
    insertOwner: 'M_INSERT_OWNER',
    insertUnknownProperty: 'M_INSERT_UNKNOWN_PROPERTY',

    checkUnknownProperty: 'M_CHECK_UNKNOWN_PROPERTY'
};

/** Actions **/
export const addBuildingAssets = (data, addWhole) => ({
    type: types.addBuildingAssets,
    data,
    addWhole
});

export const addOwners = (data, addWhole) => ({
    type: types.addOwners,
    data,
    addWhole
});

export const addPropertyRooms = (data, addWhole) => ({
    type: types.addPropertyRooms,
    data,
    addWhole
});

export const addStaffs = (data, addWhole) => ({
    type: types.addStaffs,
    data,
    addWhole
});

export const addZaitakuDetails = (data, addWhole) => ({
    type: types.addZaitakuDetails,
    data,
    addWhole
});

export const postDeleteBuildingAsset = id => ({
    type: types.postDeleteBuildingAsset,
    id
});

export const postDeleteOwner = id => ({
    type: types.postDeleteOwner,
    id
});

export const postDeletePropertyRoom = ids => ({
    type: types.postDeletePropertyRoom,
    ids
});

/** Async Actions **/
export const requestBuildingAssets = (data, successCallback, failureCallback) => ({
    type: types.requestBuildingAssets,
    data,
    successCallback,
    failureCallback
});

export const requestOwners = (data, successCallback, failureCallback) => ({
    type: types.requestOwners,
    data,
    successCallback,
    failureCallback
});

export const requestPropertyRooms = data => ({
    type: types.requestPropertyRooms,
    data
});

export const requestOwnerRooms = data => ({
    type: types.requestOwnerRooms,
    data
});

export const requestStaffs = () => ({
    type: types.requestStaffs
});

export const requestZaitakuDetails = data => ({
    type: types.requestZaitakuDetails,
    data
});

export const deleteBuildingAsset = (id, successCallback, failureCallback) => ({
    type: types.deleteBuildingAsset,
    id,
    successCallback,
    failureCallback
});

export const deleteOwner = (id, successCallback, failureCallback) => ({
    type: types.deleteOwner,
    id,
    successCallback,
    failureCallback
});

export const deletePropertyRoom = (data, successCallback, failureCallback) => ({
    type: types.deletePropertyRoom,
    data,
    successCallback,
    failureCallback
});

export const updateBuildingAsset = (data, successCallback, failureCallback) => ({
    type: types.updateBuildingAsset,
    data,
    successCallback,
    failureCallback
});

export const updateRoom = (data, successCallback, failureCallback) => ({
    type: types.updateRoom,
    data,
    successCallback,
    failureCallback
});

export const updateOwner = (data, successCallback, failureCallback) => ({
    type: types.updateOwner,
    data,
    successCallback,
    failureCallback
});

export const updateOwnerRoom = (data, successCallback, failureCallback) => ({
    type: types.updateOwnerRoom,
    data,
    successCallback,
    failureCallback
});

export const insertBuildingAsset = (data, successCallback, failureCallback) => ({
    type: types.insertBuildingAsset,
    data,
    successCallback,
    failureCallback
});

export const insertRoom = (data, successCallback, failureCallback) => ({
    type: types.insertRoom,
    data,
    successCallback,
    failureCallback
});

export const insertOwner = (data, successCallback, failureCallback) => ({
    type: types.insertOwner,
    data,
    successCallback,
    failureCallback
});

export const insertUnknownProperty = (data, successCallback, failureCallback) => ({
    type: types.insertUnknownProperty,
    data,
    successCallback,
    failureCallback
});

export const checkUnknownProperty = (data, successCallback, failureCallback) => ({
    type: types.checkUnknownProperty,
    data,
    successCallback,
    failureCallback
});

const buildingAssets = new SortedArray();
buildingAssets.comparator = (_a, _b) => _a.id - _b.id;

const owners = new SortedArray();
owners.comparator = (_a, _b) => _a.id - _b.id;

const propertyRooms = new SortedArray();
propertyRooms.comparator = (_a, _b) => _a.id - _b.id;

const staffs = new SortedArray();
staffs.comparator = (_a, _b) => _a.id - _b.id;

const zaitakuDetails = new SortedArray();
zaitakuDetails.comparator = (_a, _b) => _a.id - _b.id;

/** Reducer **/
const reducer = (_state = { buildingAssets: { records: [], pageDetails: { currentPage: 0, pageSize: 0, totalCount: 0, currentCount: 0, totalPages: 0 } }, owners: { records: [], pageDetails: { currentPage: 0, pageSize: 0, totalCount: 0, totalPages: 0 } }, propertyRooms: [], zaitakuDetails: [], staffs: [] }, _action) => {
    if (!_action || !_action.type) return _state;
    const newState = { ..._state };

    switch (_action.type) {
        case types.addBuildingAssets:
            if (_action.addWhole === true) {

                if (Array.isArray(_action.data.items)) {
                    buildingAssets.addWhole(_action.data.items);
                    newState.buildingAssets.records = buildingAssets.toArray();

                    newState.buildingAssets.pageDetails.currentPage = _action.data.currentPage;
                    newState.buildingAssets.pageDetails.pageSize = _action.data.pageSize;
                    newState.buildingAssets.pageDetails.totalCount = _action.data.totalCount;
                    newState.buildingAssets.pageDetails.currentCount = _action.data.currentCount;
                    newState.buildingAssets.pageDetails.totalPages = _action.data.totalPages;
                }
            }
            else {
                _action.data.forEach(_v => buildingAssets.addById(_v));
                newState.buildingAssets.records = buildingAssets.toArray();
            }
            break;

        case types.addOwners:
            if (_action.addWhole === true) {
                owners.addWhole(_action.data.items);
                newState.owners.records = owners.toArray();

                newState.owners.pageDetails.currentPage = _action.data.currentPage;
                newState.owners.pageDetails.pageSize = _action.data.pageSize;
                newState.owners.pageDetails.totalCount = _action.data.totalCount;
                newState.owners.pageDetails.currentCount = _action.data.currentCount;
                newState.owners.pageDetails.totalPages = _action.data.totalPages;
            }
            else {
                _action.data.forEach(_v => owners.addById(_v));
                newState.owners = owners.toArray();
            }
            break;

        case types.addStaffs:
            if (_action.addWhole === true) {
                staffs.addWhole(_action.data);
                newState.staffs = staffs.toArray();
            }
            else {
                _action.data.forEach(_v => staffs.addById(_v));
                newState.staffs = staffs.toArray();
            }
            break;

        case types.addZaitakuDetails:
            if (_action.addWhole === true) {
                zaitakuDetails.addWhole(_action.data);
                newState.zaitakuDetails = zaitakuDetails.toArray();
            }
            else {
                _action.data.forEach(_v => zaitakuDetails.addById(_v));
                newState.zaitakuDetails = zaitakuDetails.toArray();
            }
            break;

        case types.addPropertyRooms:
            if (_action.addWhole === true) {
                propertyRooms.addWhole(_action.data);
                newState.propertyRooms = propertyRooms.toArray();
            }
            else {
                _action.data.forEach(_v => propertyRooms.addById(_v));
                newState.propertyRooms = propertyRooms.toArray();
            }
            break;

        case types.postDeleteBuildingAsset:
            buildingAssets.delById(_action.id);
            newState.buildingAssets.records = buildingAssets.toArray();
            break;

        case types.postDeleteOwner:
            owners.delById(_action.id);
            newState.owners.records = owners.toArray();
            break;

        case types.postDeletePropertyRoom:
            _action.ids.forEach(_v => {
                propertyRooms.delById(_v);
                newState.propertyRooms = propertyRooms.toArray();
            });
            break;

        default:
            break;
    }

    return newState;
}
export default reducer;