import * as helpers from 'helpers/formHelpers';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReflexContainer, ReflexElement } from 'react-reflex';

export function OwnerAddForm({ title: _title, owner: _owner, onSubmitCallback: _onSubmitCallback, onCancelCallback: _onCancelCallback, zaitakuDetails: _zaitakuDetails }) {
    const { t } = useTranslation();

    const [owner, setOwner] = useState(!_owner ? {} : _owner);

    const Daytype = {
        'everyDay': 0,
        'weekday': 1,
        'nonWorkingDay': 2,
        'monday': 3,
        'tuesday': 4,
        'wednesday': 5,
        'thursday': 6,
        'friday': 7,
        'saturday': 8,
        'sunday': 9
    };

    const clearHandler = () => {
        setOwner({});
        _onCancelCallback();
    };

    useEffect(() => {
        setOwner(_owner);
    }, [_owner]);

    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setOwner({ ...owner, ...update });
    }

    const replaceState = (_attr, _val) => {
        const update = owner;
        update[_attr] = _val;
        setOwner(update);
    }

    const setSelectedValue = (_attr) => {
        var selectedValue = 0;
        (owner[_attr] && Array.isArray(owner[_attr])) && owner[_attr].forEach(_v => {
            if (_v.selected === true)
                selectedValue = _v.value;
        });
        replaceState(_attr, selectedValue);
    };

    const setSelectedComboValue = (_attr, _attrValues) => {
        if (owner[_attr] === null || owner[_attr] === undefined) {
            _attrValues.forEach(_v => {
                replaceState(_v.value, 0);
            });
        }
        else {
            owner[_attr].forEach(_v => {
                if (_v.selected === true)
                    replaceState(_v.value, 1);
                else
                    replaceState(_v.value, 0);
            });
        }
    };

    const setSelectedInputRangeValue = (_attr) => {
        if (owner[_attr] === null || owner[_attr] === undefined) {
            if (!((owner[_attr + '1'] === null || owner[_attr + '1'] === undefined) && (owner[_attr + '2'] === null || owner[_attr + '2'] === undefined))) {
                replaceState(_attr + '1', owner[_attr + '1']);
                replaceState(_attr + '2', owner[_attr + '2']);
            }
        }
        else {
            replaceState(_attr + '1', owner[_attr].start);
            replaceState(_attr + '2', owner[_attr].end);
        }
    };

    const setZaitakuData = () => {
        var zaitakuData = [];
        zaitakuData.push({ zaitakuType: Daytype.everyDay, comboBox: owner['everyDay'], timeFrom: owner['everyDayTime'] ? owner['everyDayTime'].start : null, timeTo: owner['everyDayTime'] ? owner['everyDayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.weekday, comboBox: owner['weekday'], timeFrom: owner['weekdayTime'] ? owner['weekdayTime'].start : null, timeTo: owner['weekdayTime'] ? owner['weekdayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.nonWorkingDay, comboBox: owner['nonWorkingDay'], timeFrom: owner['nonWorkingDayTime'] ? owner['nonWorkingDayTime'].start : null, timeTo: owner['nonWorkingDayTime'] ? owner['nonWorkingDayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.monday, comboBox: owner['monday'], timeFrom: owner['mondayTime'] ? owner['mondayTime'].start : null, timeTo: owner['mondayTime'] ? owner['mondayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.tuesday, comboBox: owner['tuesday'], timeFrom: owner['tuesdayTime'] ? owner['tuesdayTime'].start : null, timeTo: owner['tuesdayTime'] ? owner['tuesdayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.wednesday, comboBox: owner['wednesday'], timeFrom: owner['wednesdayTime'] ? owner['wednesdayTime'].start : null, timeTo: owner['wednesdayTime'] ? owner['wednesdayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.thursday, comboBox: owner['thursday'], timeFrom: owner['thursdayTime'] ? owner['thursdayTime'].start : null, timeTo: owner['thursdayTime'] ? owner['thursdayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.friday, comboBox: owner['friday'], timeFrom: owner['fridayTime'] ? owner['fridayTime'].start : null, timeTo: owner['fridayTime'] ? owner['fridayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.saturday, comboBox: owner['saturday'], timeFrom: owner['saturdayTime'] ? owner['saturdayTime'].start : null, timeTo: owner['saturdayTime'] ? owner['saturdayTime'].end : null });
        zaitakuData.push({ zaitakuType: Daytype.sunday, comboBox: owner['sunday'], timeFrom: owner['sundayTime'] ? owner['sundayTime'].start : null, timeTo: owner['sundayTime'] ? owner['sundayTime'].end : null });
        replaceState('zaitakuData', zaitakuData);
    };

    const setRadioValues = (_attr, _attrValues) => {
        return _attrValues.map(function (_v, _i) {
            return ({ value: _v.value, text: _v.text, selected: owner[_attr] ? (Array.isArray(owner[_attr]) ? owner[_attr][_i].selected === true ? owner[_attr][_i].selected === true : false : _v.value === owner[_attr]) : _v.selected === true });
        });
    }

    const setGroupCheckBoxValues = (_attr, _attrValues) => {
        return _attrValues.map(function (_v, _i) {
            return ({
                value: _v.value, text: _v.text, selected: owner[_v.value] ? ((owner[_v.value] === 1 ? true : false)) : (owner[_attr] ? owner[_attr][_i].selected === true : false)
            });
        });
    }

    const setZaitakuTimeValue = (_attr) => {
        if (owner[_attr] === null || owner[_attr] === undefined) {
            if ((owner[_attr + '1'] === null || owner[_attr + '1'] === undefined) && (owner[_attr + '2'] === null || owner[_attr + '2'] === undefined)) {
                return undefined;
            }
            else {
                return { start: owner[_attr + '1'], end: owner[_attr + '2'] };
            }
        }
        else {
            return owner[_attr];
        }
    }

    const setInputRangeValues = (_attr) => {
        if (owner[_attr + 'Time'] === null || owner[_attr + 'Time'] === undefined) {
            if ((_zaitakuDetails === null || _zaitakuDetails === undefined)) {
                return undefined;
            }
            else {
                for (let i = 0; i < _zaitakuDetails.length; i++) {
                    if (_zaitakuDetails[i].dayType === Daytype[_attr]) {
                        replaceState(_attr + 'Time', { start: _zaitakuDetails[i].timeFrom, end: _zaitakuDetails[i].timeTo });
                        return { start: _zaitakuDetails[i].timeFrom, end: _zaitakuDetails[i].timeTo };
                    }
                }
            }
        }
        else {
            return owner[_attr + 'Time'];
        }
    }

    const setCheckBoxValues = (_attr) => {
        if (owner[_attr] === null || owner[_attr] === undefined) {
            if ((_zaitakuDetails === null || _zaitakuDetails === undefined)) {
                return 0;
            }
            else {
                for (let i = 0; i < _zaitakuDetails.length; i++) {
                    if (_zaitakuDetails[i].dayType === Daytype[_attr]) {
                        replaceState(_attr, 1);
                        return 1;
                    }
                }
            }
        }
        else {
            return owner[_attr];
        }
    }

    const setPincode1 = (_attr) => {
        var value = pincode1;
        if ((owner[_attr] === null || owner[_attr] === undefined)) {
            if ((pincode1 === null || pincode1 === undefined)) {
                value = 0;
            }
            else {
                value = pincode1;
            }
        }
        else {
            value = owner[_attr];
        }
        replaceState(_attr, value);
    };

    const setPincode2 = (_attr) => {
        var value = pincode2;
        if ((owner[_attr] === null || owner[_attr] === undefined)) {
            if ((pincode2 === null || pincode2 === undefined)) {
                value = 0;
            }
            else {
                value = pincode2;
            }
        }
        else {
            value = owner[_attr];
        }
        replaceState(_attr, value);
    };

    const handleSubmit = (event) => {
        //setting values for radio buttons
        setSelectedValue('gender');
        setSelectedValue('jobType');
        setSelectedValue('ageRange');
        setSelectedValue('dairininType');
        setSelectedValue('addressType');
        setSelectedValue('telType');
        setSelectedValue('faxNumberType');
        setSelectedValue('baikyakuType');
        setSelectedValue('souzokuType');
        setSelectedValue('states');
        setSelectedValue('dm');

        //setting values for check box group buttons
        setSelectedComboValue('checkboxObject1', checkboxObject1Values);
        setSelectedComboValue('checkboxObject2', checkboxObject2Values);
        setSelectedComboValue('checkboxObject3', checkboxObject3Values);

        //setting values for input range
        setSelectedInputRangeValue('zaitakuTime');

        //setting values for postcode
        setPincode1('pincode1');
        setPincode2('pincode2');

        //setting zaitaku data
        setZaitakuData();

        _onSubmitCallback(owner);
    };


    //Group Check Boxes
    const checkboxObject1Values = [
        { value: 'kyohi', text: t('03rejected') },
        { value: 'meiwakuMode', text: t('inconvenienceMode') },
        { value: 'keitaiTenso', text: t('cellphoneForwarding') },
        { value: 'noDisplay', text: t('noDisplay') }
    ];

    const checkboxObject2Values = [
        { value: 'kyohi2', text: t('03rejected') },
        { value: 'meiwakuMode2', text: t('inconvenienceMode') },
        { value: 'keitaiTenso2', text: t('cellphoneForwarding') },
        { value: 'noDisplay2', text: t('noDisplay') }
    ];

    const checkboxObject3Values = [
        { value: 'niniBaikyaku', text: t('offmarketSale-short') },
        { value: 'jikoHasan', text: t('voluntaryBankruptcy') },
        { value: 'minjiSaisei', text: t('civilRehabilitationLaw') }
    ];

    //Group Check Box Values
    const checkboxObject1 = setGroupCheckBoxValues('checkboxObject1', checkboxObject1Values);
    const checkboxObject2 = setGroupCheckBoxValues('checkboxObject2', checkboxObject2Values);
    const checkboxObject3 = setGroupCheckBoxValues('checkboxObject3', checkboxObject3Values);

    //Check Box & Input Range Values
    const everyDay = setCheckBoxValues('everyDay');
    const everyDayTime = setInputRangeValues('everyDay');
    const weekday = setCheckBoxValues('weekday');
    const weekdayTime = setInputRangeValues('weekday');
    const nonWorkingDay = setCheckBoxValues('nonWorkingDay');
    const nonWorkingDayTime = setInputRangeValues('nonWorkingDay');
    const monday = setCheckBoxValues('monday');
    const mondayTime = setInputRangeValues('monday');
    const tuesday = setCheckBoxValues('tuesday');
    const tuesdayTime = setInputRangeValues('tuesday');
    const wednesday = setCheckBoxValues('wednesday');
    const wednesdayTime = setInputRangeValues('wednesday');
    const thursday = setCheckBoxValues('thursday');
    const thursdayTime = setInputRangeValues('thursday');
    const friday = setCheckBoxValues('friday');
    const fridayTime = setInputRangeValues('friday');
    const saturday = setCheckBoxValues('saturday');
    const saturdayTime = setInputRangeValues('saturday');
    const sunday = setCheckBoxValues('sunday');
    const sundayTime = setInputRangeValues('sunday');

    //Zaitaku Time Value
    const zaitakuTime = setZaitakuTimeValue('zaitakuTime');

    //Group Radio Buttons Values
    const genderValues = [{ value: 0, text: t('unknown'), selected: true }, { value: 1, text: t('man') }, { value: 2, text: t('woman') }];

    const jobTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('working') }, { value: 2, text: t('employee') },
    { value: 3, text: t('retired') }, { value: 4, text: t('selfEmployed') }, { value: 5, text: t('civilServant') }, { value: 6, text: t('doctor') },
    { value: 7, text: t('teacher') }, { value: 8, text: t('other') }, { value: 9, text: t('jobChecked') }];

    const ageRangeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: '20' + t('-decade') }, { value: 2, text: '30' + t('-decade') },
    { value: 3, text: '40' + t('-decade') }, { value: 4, text: '50' + t('-decade') }, { value: 5, text: '60' + t('-decade') }, { value: 6, text: '70' + t('-decade') },
    { value: 7, text: '80' + t('-decade') }, { value: 8, text: '90' + t('-decade') }];

    const dairininTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('spouse') }, { value: 2, text: t('progeny') },
    { value: 3, text: t('siblings') }, { value: 4, text: t('relatives') }];

    const addressTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('newAddress') }, { value: 2, text: t('company') },
    { value: 3, text: t('familyHome') }, { value: 4, text: t('anotherHouse') }];

    const telTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('direct') }, { value: 2, text: t('anotherHouse') },
    { value: 3, text: t('workplace') }, { value: 4, text: t('other') }];

    const faxNumberTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('home') }, { value: 2, text: t('workplace') },
    { value: 3, text: t('other') }];

    const baikyakuTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('retirement') }, { value: 2, text: t('move') },
    { value: 3, text: t('other') }];

    const souzokuTypeValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('ownerDeath') }, { value: 2, text: t('children') },
    { value: 3, text: t('spouse') }, { value: 4, text: t('grandchild') }, { value: 5, text: t('JON') }, { value: 6, text: t('inheritanceNotRegistered') }, { value: 7, text: t('inherited') }];

    const statesValues = [{ value: 0, text: t('normal'), selected: true },
    { value: 1, text: t('declined') },
    { value: 2, text: t('soldOut') },
    { value: 3, text: t('currentlyUnavailable') },
    { value: 4, text: t('expected') },
    { value: 5, text: t('intermediary') },
    { value: 6, text: t('ongoingContracts') },
    { value: 7, text: t('settlement') },
    { value: 8, text: t('remediation') },
    { value: 9, text: t('visitCommon') },
    { value: 10, text: t('visitDeclined') },
    { value: 11, text: t('visitIpRefused') },
    { value: 12, text: t('visitAllSoldOut') },
    { value: 13, text: t('visitCurrentlyUnavailable') },
    { value: 14, text: t('visitExpected') },
    { value: 15, text: t('visitMediation') },
    { value: 16, text: t('visitMediationReceived') },
    { value: 17, text: t('visitInheritance') },
    { value: 18, text: t('visitSettlement') },
    { value: 19, text: t('ecNormal') },
    { value: 20, text: t('ecInterrupted') },
    { value: 21, text: t('ecWithheldRefusal') },
    { value: 22, text: t('ecLineBusy') },
    { value: 23, text: t('ecCellPhoneNormal') },
    { value: 24, text: t('ecCellPhoneInterrupted') },
    { value: 25, text: t('ecCellPhoneWithheldRefusal') },
    { value: 26, text: t('ecCellPhoneBusy') },
    { value: 27, text: t('ecSampledDispatchedSchedule') },
    { value: 28, text: t('visitScheduled') }];

    const dmValues = [{ value: 0, text: t('unspecified'), selected: true }, { value: 1, text: t('done') }, { value: 2, text: t('disallowedReturned') }, { value: 3, text: t('disallowedBeforeReturCheck') }];


    const gender = setRadioValues('gender', genderValues);
    const jobType = setRadioValues('jobType', jobTypeValues);
    const ageRange = setRadioValues('ageRange', ageRangeValues);
    const dairininType = setRadioValues('dairininType', dairininTypeValues);
    const addressType = setRadioValues('addressType', addressTypeValues);
    const telType = setRadioValues('telType', telTypeValues);
    const faxNumberType = setRadioValues('faxNumberType', faxNumberTypeValues);
    const baikyakuType = setRadioValues('baikyakuType', baikyakuTypeValues);
    const souzokuType = setRadioValues('souzokuType', souzokuTypeValues);
    const states = setRadioValues('states', statesValues);
    const dm = setRadioValues('dm', dmValues);

    //Input value
    const pincode1 = owner['pincode1'] ? owner['pincode1'] : (owner['postcode'] ? (owner['postcode'].toString().length === 7 ? owner['postcode'].substring(0, 3) : null) : null);
    const pincode2 = owner['pincode2'] ? owner['pincode2'] : (owner['postcode'] ? (owner['postcode'].toString().length === 7 ? owner['postcode'].substring(owner['postcode'].length - 4) : null) : null);


    //Generate various input methods

    const generateInput = (_attribute, _label, _lg, _addon, _offset, _type) => {
        const onChangeHandler = (_v) => updateState(_attribute, _v);
        return <Row>
            <Col lg={_offset} md={12} sm={12} xl={_offset}>
            </Col>
            <Col lg={_lg} md={12} sm={12} xl={_lg}>
                <helpers.GenerateInput text={t(_label)} value={owner[_attribute]} type={(_type === null || _type === undefined) ? 'text' : _type} addon={_addon === undefined ? '' : t(_addon)} onChangeHandler={onChangeHandler} labelSize={3} />
            </Col>
        </Row>;
    }

    const generateDatePicker = (_attribute, _label, _lg, _offset) => {
        const onChangeHandler = (_v) => updateState(_attribute, _v);
        return <Row>
            <Col lg={_offset} md={12} sm={12} xl={_offset}>
            </Col>
            <Col lg={_lg} md={12} sm={12} xl={_lg}>
                <helpers.GenerateDatePicker value={owner[_attribute] && new Date(owner[_attribute])} onChangeHandler={onChangeHandler} text={t(_label)} showIcon={true} labelSize={3} />
            </Col>
        </Row>;
    }

    const generateSelect = (_attribute, _label, _options, _lg, _offset) => {
        const onChangeHandler = (_v) => updateState(_attribute, _v);
        return <Row>
            <Col lg={_offset} md={12} sm={12} xl={_offset}>
            </Col>
            <Col lg={_lg} md={12} sm={12} xl={_lg}>
                <helpers.GenerateRadioGroup text={t(_label)} id={t(_attribute)} values={_options} onChangeHandler={onChangeHandler} labelSize={3} />
            </Col>
        </Row>;
    }

    const generateCheckbox = (_attribute, _label, _options, _lg, _offset) => {
        const onChangeHandler = (_v) => updateState(_attribute, _v);
        return <Row>
            <Col lg={_offset} md={12} sm={12} xl={_offset}>
            </Col>
            <Col lg={_lg} md={12} sm={12} xl={_lg}>
                <helpers.GenerateCheckboxGroup text={t(_label)} id={t(_attribute)} values={_options} onChangeHandler={onChangeHandler} labelSize={3} />
            </Col>
        </Row>;
    }

    //render
    return <ReflexContainer orientation="horizontal">
        <ReflexElement flex={0.235}>
            <div className="card">
                <div className="card-block">
                    <div className="title-block">
                        <h3 className="title">{_title}</h3>
                    </div>
                    <div className='maintenance-form-container'>
                        <div className="title-separator"></div>
                        <form onSubmit={handleSubmit}>
                            {generateInput('name', 'name', 8, undefined, undefined)}
                            {generateInput('kana', 'phoneticReading', 8, undefined, undefined)}
                            <div className="separator"></div>
                            {generateSelect('gender', 'gender', gender, 8, undefined)}
                            <div className="separator"></div>
                            {generateSelect('jobType', 'profession', jobType, 8, undefined)}
                            {generateInput('job', '', 8, undefined, 2)}
                            <div className="separator"></div>
                            {generateSelect('ageRange', 'age', ageRange, 8, undefined)}
                            <Row className='buildAge'>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <helpers.GenerateInput value={owner['age']} type={'number'} addon={t('yearsOld')} onChangeHandler={(_v) => updateState('age', _v)} />
                                </Col>
                            </Row>
                            <Row className='buildAge'>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={5} md={6} sm={6} xl={5}>
                                    <helpers.GenerateNumberWithMaxLength max={9999} value={owner['ageYear']} type={'number'} addon={t('year')} onChangeHandler={(_v) => updateState('ageYear', _v)} />
                                </Col>
                                <Col lg={5} md={6} sm={6} xl={5}>
                                    <helpers.GenerateNumberWithMaxLength max={12} value={owner['ageMonth']} type={'number'} addon={t('asOfMonth')} onChangeHandler={(_v) => updateState('ageMonth', _v)} />
                                </Col>
                            </Row>
                            <div className="separator"></div>
                            {generateSelect('dairininType', 'proxy', dairininType, 8, undefined)}
                            {generateInput('dairinin', '', 8, undefined, 2)}
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={5} md={6} sm={6} xl={5}>
                                    <helpers.GenerateCheckbox text={t('identityConfirmed')} value={owner['honninKakuninZumi'] === 1 ? true : false} onChangeHandler={(_v) => updateState('honninKakuninZumi', _v === true ? 1 : 0)} />
                                </Col>
                                <Col lg={5} md={6} sm={6} xl={5}>
                                    <helpers.GenerateCheckbox text={t('reverberation')} value={owner['hankyoFlg'] === 1 ? true : false} onChangeHandler={(_v) => updateState('hankyoFlg', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            {owner['hankyoFlg'] === 1 ? generateDatePicker('hankyoDate', '', 8, 2) : null}
                            <div className="separator"></div>
                            <Row>
                                <Col lg={5} md={5} sm={5} xl={5}>
                                    <helpers.GenerateNumberWithMaxLength max={999} text={t('pincode')} type={'number'} value={pincode1} onChangeHandler={(_v) => updateState('pincode1', _v)} />
                                </Col>
                                ー
                                <Col lg={2} md={2} sm={2} xl={2}>
                                    <helpers.GenerateNumberWithMaxLength max={9999} type={'number'} value={pincode2} onChangeHandler={(_v) => updateState('pincode2', _v)} />
                                </Col>
                            </Row>
                            {generateInput('address1', 'basicAddress', 8, undefined, undefined)}
                            {generateSelect('addressType', '', addressType, 8, 2)}
                            {generateInput('address2', '', 8, undefined, 2)}
                            <div className="separator"></div>
                            <Row>
                                <Col lg={8} md={8} sm={8} xl={8}>
                                    <helpers.GenerateInput text={t('mainPhoneNumber') + '1'} value={owner['tel1']} onChangeHandler={(_v) => updateState('tel1', _v)} />
                                </Col>
                                <Col lg={4} md={4} sm={4} xl={4}>
                                    <helpers.GenerateCheckbox text={t('phoneProhibited')} value={owner['jitakuTelNG'] === 1 ? true : false} onChangeHandler={(_v) => updateState('jitakuTelNG', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            {generateCheckbox('checkboxObject1', '', checkboxObject1, 8, 2)}
                            {generateSelect('telType', '', telType, 8, 2)}
                            <Row>
                                <Col lg={8} md={8} sm={8} xl={8}>
                                    <helpers.GenerateInput text={t('mainPhoneNumber') + '2'} value={owner['tel2']} onChangeHandler={(_v) => updateState('tel2', _v)} />
                                </Col>
                                <Col lg={4} md={4} sm={4} xl={4}>
                                    <helpers.GenerateCheckbox text={t('phoneProhibited')} value={owner['jitakuTelNG2'] === 1 ? true : false} onChangeHandler={(_v) => updateState('jitakuTelNG2', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            {generateCheckbox('checkboxObject2', '', checkboxObject2, 8, 2)}
                            {generateInput('mainPhoneNumber3', '', 8, undefined, 2)}
                            {generateInput('cellphone1', 'cellphone', 8, undefined, undefined)}
                            {generateInput('cellphone2', '', 8, undefined, 2)}
                            <div className="separator"></div>
                            {generateSelect('faxNumberType', 'faxNumberType', faxNumberType, 8, undefined)}
                            {generateInput('fax', 'FAX', 8, undefined, undefined)}
                            {generateInput('mail', 'Mail', 8, undefined, undefined)}
                            <div className="separator"></div>
                            <Row className="telephoneTimeRange">
                                <Col lg={2} md={12} sm={12} xl={2}>
                                    <label class="title-label flex flex-wrap">{'TEL' + t('timeRange')}</label>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3}>
                                    <helpers.GenerateCheckbox text={t('everyDay')} value={everyDay === 1 ? true : false} onChangeHandler={(_v) => updateState('everyDay', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['everyDay'] === 1 || everyDay === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={everyDayTime} onChangeHandler={(_v) => updateState('everyDayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('weekday')} value={weekday === 1 ? true : false} onChangeHandler={(_v) => updateState('weekday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['weekday'] === 1 || weekday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={weekdayTime} onChangeHandler={(_v) => updateState('weekdayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('nonWorkingDay')} value={nonWorkingDay === 1 ? true : false} onChangeHandler={(_v) => updateState('nonWorkingDay', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['nonWorkingDay'] === 1 || nonWorkingDay === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={nonWorkingDayTime} onChangeHandler={(_v) => updateState('nonWorkingDayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('monday')} value={monday === 1 ? true : false} onChangeHandler={(_v) => updateState('monday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['monday'] === 1 || monday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={mondayTime} onChangeHandler={(_v) => updateState('mondayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('tuesday')} value={tuesday === 1 ? true : false} onChangeHandler={(_v) => updateState('tuesday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['tuesday'] === 1 || tuesday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={tuesdayTime} onChangeHandler={(_v) => updateState('tuesdayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('wednesday')} value={wednesday === 1 ? true : false} onChangeHandler={(_v) => updateState('wednesday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['wednesday'] === 1 || wednesday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={wednesdayTime} onChangeHandler={(_v) => updateState('wednesdayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('thursday')} value={thursday === 1 ? true : false} onChangeHandler={(_v) => updateState('thursday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['thursday'] === 1 || thursday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={thursdayTime} onChangeHandler={(_v) => updateState('thursdayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('friday')} value={friday === 1 ? true : false} onChangeHandler={(_v) => updateState('friday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['friday'] === 1 || friday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={fridayTime} onChangeHandler={(_v) => updateState('fridayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('saturday')} value={saturday === 1 ? true : false} onChangeHandler={(_v) => updateState('saturday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['saturday'] === 1 || saturday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={saturdayTime} onChangeHandler={(_v) => updateState('saturdayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={3} md={6} sm={6} xl={3} >
                                    <helpers.GenerateCheckbox text={t('sunday')} value={sunday === 1 ? true : false} onChangeHandler={(_v) => updateState('sunday', _v === true ? 1 : 0)} />
                                </Col>
                                {(owner['sunday'] === 1 || sunday === 1) ?
                                    <Col lg={7} md={6} sm={6} xl={7}>
                                        <helpers.GenerateInputRange type={'number'} addon={t('hour')} range={sundayTime} onChangeHandler={(_v) => updateState('sundayTime', _v)} labelSize={3} />
                                    </Col> : null}
                            </Row>
                            <div className="separator"></div>
                            <Row>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <helpers.GenerateInputRange type={'number'} text={t('timeAtHome')} addon={t('hour')} range={zaitakuTime} onChangeHandler={(_v) => updateState('zaitakuTime', _v)} labelSize={3} />
                                </Col>
                            </Row>
                            {generateInput('zaitakuMemo', 'timeAtHomeMemo', 8, undefined, undefined)}
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <helpers.GenerateCheckbox text={t('buyer')} value={owner['kaikyaku'] === 1 ? true : false} onChangeHandler={(_v) => updateState('kaikyaku', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            {generateInput('kibouArea', 'desiredArea', 8, undefined, undefined)}
                            {generateInput('kibouRimawari', 'desiredYield', 8, '%', undefined, 'number')}
                            {generateInput('jikoShikin', 'selfFunding', 8, '10kYen', undefined, 'number')}

                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <helpers.GenerateCheckbox text={t('usingLoan')} value={owner['yushiRiyou'] === 1 ? true : false} onChangeHandler={(_v) => updateState('yushiRiyou', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            {generateSelect('baikyakuType', '', baikyakuType, 8, 2)}
                            {generateInput('baikyakuEtc', '', 8, undefined, 2)}
                            {generateDatePicker('baikyakuJiki', 'saleDate', 8, undefined)}
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <helpers.GenerateCheckbox text={t('familyPrivacy')} value={owner['kazokuNaisho'] === 1 ? true : false} onChangeHandler={(_v) => updateState('kazokuNaisho', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            {generateSelect('souzokuType', 'inheritance', souzokuType, 8, undefined)}
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <div class="form-group">
                                        <textarea class="form-control rounded-1" value={owner['souzoku']} onChangeHandler={(_v) => updateState('souzoku', _v)} rows="3"></textarea>
                                    </div>
                                </Col>
                            </Row>
                            <div className="separator"></div>
                            {generateCheckbox('checkboxObject3', 'debtConsolidation', checkboxObject3, 8, undefined)}
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <helpers.GenerateInput value={owner['saimuSeiri']} onChangeHandler={(_v) => updateState('saimuSeiri', _v)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                    <label class="title-label flex flex-wrap">{t('neck')}</label>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <div class="form-group">
                                        <textarea class="form-control rounded-1" value={owner['neck']} onChangeHandler={(_v) => updateState('neck', _v)} rows="3"></textarea>
                                    </div>
                                </Col>
                            </Row>
                            <div className="separator"></div>
                            {generateSelect('states', 'status', states, 8, undefined)}
                            <div className="separator"></div>
                            {generateInput('biko', 'ownerRemarks', 8, undefined, undefined)}
                            {generateSelect('dm', 'dm', dm, 8, undefined)}
                            <Row>
                                <Col lg={2} md={12} sm={12} xl={2}>
                                </Col>
                                <Col lg={8} md={12} sm={12} xl={8}>
                                    <helpers.GenerateCheckbox text={t('dmProhibited')} value={owner['dmNg'] === 1 ? true : false} onChangeHandler={(_v) => updateState('dmNg', _v === true ? 1 : 0)} />
                                </Col>
                            </Row>
                            <div className="separator"></div>
                            <Row>
                                <Col md={12} className="form-submit-container">
                                    <button type="submit" className="btn btn-primary">{t('okEng')}</button>
                                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('cancel')}</button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
        </ReflexElement>
    </ReflexContainer>
}

OwnerAddForm.defaultProps = {
    owner: {
    }
}