import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

export function Inheritance({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC }) {

    const [data, setData] = _useStateHOC;

    const inheritanceType = ['unspecified', 'ownerDeath', 'children', 'spouse', 'grandchild', 'inheritanceNotRegistered', 'inherited', 'JON'].reduce(_checkboxGroupHOC.checkboxGroupReducer('inheritanceType'), {});
    return <>
        <wf.CheckboxGroupField hideLabel={true} values={inheritanceType} onChange={(_v) => setData('inheritanceType')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="inheritanceType" contentExtraClasses="flex-wrap" />
        <wf.InputField
            value={data.inheritanceNote.value || data.inheritanceNote.defaultValue}
            hideLabel={true}
            className="form-group"
            onChange={setData('inheritanceNote')}
            isMultiline={true}/>
    </>
}
export const InheritancePopup = ExportDefaultBehaviour(Inheritance, {
    editableFields: ['inheritanceType', 'inheritanceNote'],
    size: 'sm'
});
InheritancePopup.whyDidYouRender = true;