import React, { Component, useState } from 'react';
import { SimpleTextInput, InputRange as BaseInputRange, DateSelector } from 'components/inputs/textInput';

export class GroupField extends Component {
    render() {
        const labelSizeOverride = this.props.labelSizeOverride || 3;
        return (<div className={`floating-row-container row ${this.props.extraClasses}`}>
            {!this.props.hideLabel && <label className={`col-xs-${labelSizeOverride}`}>{this.props.label}</label>}
            <div className={`content-container flex col-xs-${this.props.hideLabel ? '12' : (12 - labelSizeOverride)} ${this.props.contentExtraClasses || ''}`}>
                {this.props.children}
            </div>
        </div>);
    }
}
GroupField.whyDidYouRender = true;
GroupField.defaultProps = {
    extraClasses: '',
    contentExtraClasses: ''
}

export class TextField extends Component {
    shouldComponentUpdate(_nextProps, _nextState) {
        return this.props.extraClasses !== _nextProps.extraClasses || this.props.text !== _nextProps.text || this.props.label !== _nextProps.label;
    }
    render() {
        return (<GroupField {...this.props}>
            <span className={`fill-w relative ellipsize ${this.props.extraClasses}`}>{this.props.text}</span>
        </GroupField>);
    }
}
TextField.whyDidYouRender = true;
GroupField.defaultProps = {
    extraClasses: ''
}

export class InputField extends Component {
    constructor(_props) {
        super(_props);
        this.state = {
            isError: false
        };

        const triggerChange = (_state, _shouldTriggerSubmit) => {
            const options = {};
            const hasOptions = this.props.validator || _shouldTriggerSubmit;
            if (this.props.validator) options.isError = _state.isError;
            if (_shouldTriggerSubmit) options.isSubmitted = true;
            this.setState(_state);
            if (this.props.onChange) this.props.onChange.apply(null, [_state.value].concat(hasOptions ? options : []));
        }

        this.onChangeHandler = (_v, _shouldTriggerSubmit) => {
            const newState = { isError: this.props.validator ? !this.props.validator(_v) : false, value: _v }
            triggerChange(newState, _shouldTriggerSubmit);
        };
        this.onErrorHandler = (_v) => {
            const newState = { isError: true, value: _v }
            triggerChange(newState);
        };
        this.onEnterHandler = () => this.onChangeHandler(this.state.value, true);
        this.errorDisplay = (_message) => <div className="error-container absolute fill-w"><div className="btn btn-danger modal-error-message">{_message}</div></div>;
    }
    shouldComponentUpdate(_nextProps, _nextState) {
        return this.props.disabled !== _nextProps.disabled || this.props.extraClasses !== _nextProps.extraClasses || this.props.value !== _nextProps.value || this.props.label !== _nextProps.label || this.state.isError !== _nextState.isError;
    }

    render() {
        return (<GroupField {...this.props}>
            <SimpleTextInput
                extraClasses={'fill-w relative' + (this.state.isError ? ' input-error' : '')}
                value={this.props.value}
                validator={this.props.validator}
                onChange={this.onChangeHandler}
                onError={this.onErrorHandler}
                onEnterPressed={this.onEnterHandler}
                addon={this.props.addon}
                isMultiline={this.props.isMultiline}
                disabled={this.props.disabled}>
                {this.state.isError && this.props.errorMessage && this.errorDisplay(this.props.errorMessage)}
            </SimpleTextInput>
        </GroupField>);
    }
}
InputField.whyDidYouRender = true;
InputField.defaultProps = {
    extraClasses: '',
    value: ''
}

export function DatePicker(_props) {
    return <GroupField {..._props}>
        <DateSelector
            extraClasses={'fill-w relative flex flex-nowrap' + (_props.isError ? ' input-error' : '')}
            showIcon={_props.showIcon || true}
            value={_props.value}
            onChange={_props.onChange}>
            {_props.isError && 
                <div className="error-container absolute fill-w"><div className="btn btn-danger modal-error-message">{_props.errorMessage}</div></div>
            }
        </DateSelector>
    </GroupField>
}
        DatePicker.whyDidYouRender = true;
DatePicker.defaultProps = {
    extraClasses: ''
}

export function InputRange(_props) {
    const [errorCode, setErrorCode] = useState(0);
    const onChangeHandler = (_v) => {
        if ((_v.start || '').length > 0 && (_v.end || '').length > 0 && _props.crossValidator && !_props.crossValidator(_v.start, _v.end)) {
            //const output = { errorCode: 3, value: _v };
            setErrorCode(3);
            if (_props.onChange) _props.onError(_v, true);
        } else {
            //const output = { errorCode: 0, value: _v };
            setErrorCode(0);
            if (_props.onChange) _props.onChange(_v, false);
        }
    };
    const onErrorHandler = (_v, _errorCode) => {
        //const output = { errorCode: _errorCode, value: _v };
        setErrorCode(_errorCode);
        if (_props.onError) _props.onError(_v, true);
    };
    const errorDisplay = (_message) => <div className="error-container absolute fill-w"><div className="btn btn-danger modal-error-message">{_message}</div></div>;
    const errorComponents = {};
    if (errorCode & 1) {
        errorComponents.start = errorDisplay(_props.errorMessage);
    }
    if (errorCode & 2) {
        errorComponents.end = errorDisplay(_props.errorMessage);
    }
    const props = { ..._props };
    props.extraClasses = (props.extraClasses || '') + ' no-margin';
    return (<GroupField {...props}>
        <BaseInputRange
            extraClasses={'fill-w relative' + (errorCode > 0 ? ' input-error' : '')}
            value={_props.value}
            validator={_props.validator}
            onChange={onChangeHandler}
            onError={onErrorHandler}
            addons={_props.addon}
            subComponents={errorComponents}/>
    </GroupField>);
}
InputRange.whyDidYouRender = true;

export class CheckboxGroupField extends Component {
    constructor(_props) {
        super(_props);
        this.inputProps = {
            type: 'checkbox',
            className: 'checkbox'
        }
        const checkboxReducer = (_a, _b) => ({ ..._a, [_b]: { ...this.props.values[_b] } });
        const radioReducer = (_a, _b) => ({ ..._a, [_b]: { ...this.props.values[_b], checked: false } });
        this.handler = (_k, _e) => {
            if (this.props.onChange) {
                const output = Object.keys(this.props.values).reduce(this.props.radioName ? radioReducer : checkboxReducer, {});
                output[_k].checked = _e.target.checked;
                this.props.onChange(output);
            }
        };
    }

    shouldComponentUpdate(_nextProps, _nextState) {
        return this.props.disabled !== _nextProps.disabled || this.props.extraClasses !== _nextProps.extraClasses || this.props.radioName !== _nextProps.radioName || this.props.label !== _nextProps.label || JSON.stringify(this.props.values) !== JSON.stringify(_nextProps.values);
    }

    render() {

        if (this.props.radioName) {
            this.inputProps.type = 'radio';
            this.inputProps.name = this.props.radioName;
            this.inputProps.className = 'radio';
        }
        return (<GroupField {...this.props}>
            {Object.keys(this.props.values).map(_k => {
                return <label className="single-line" key={_k}>
                    <input {...this.inputProps} disabled={this.props.disabled} checked={!this.props.disabled && this.props.values[_k].checked} onChange={(_e) => this.handler(_k, _e)} /><span>{this.props.values[_k].label}</span>
                </label>
            })}
        </GroupField>);
    }
}
CheckboxGroupField.whyDidYouRender = true;
CheckboxGroupField.defaultProps = {
    extraClasses: ''
}