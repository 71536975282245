export function validatorNotEmptyString(_test) {
    return _test && (typeof _test === 'string' || _test instanceof String) && _test.replace(/\s/g, '').length > 0;
}

export function validatorNumbersOnly(_test, _minLength, _maxLength) {
    return RegExp(`^\\d{${_minLength || _minLength === 0 ? _minLength : '1'},${_maxLength ? _maxLength + '}' : '}'}$`).test(_test);
}

export function validatorDouble(_test, _minLength, _maxLength, _maxDecimal) {
    return RegExp(`^\\d{${_minLength || _minLength === 0 ? _minLength : '1'},${_maxLength ? _maxLength + '}' : '}'}([.,]\\d{1,${_maxDecimal ? _maxDecimal : ''}})?$`).test(_test);
}

export function validatorPhone(_test) {
    return RegExp(/^(?:\d{10}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/).test(_test);
}

export function validatorEmail(_test, _minLength, _maxLength) {
    return RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(_test);
}