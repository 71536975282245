import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone'


export function FileUpload({ onUploadCallback: _onUploadCallback, onCancelCallback: _onCancelCallback, disabled: _disabled}) {


    const [files, setFiles] = useState({});
    const onDrop = useCallback(acceptedFiles => {
        const newFiles = { ...files };
        acceptedFiles.forEach(_f => {
            if (!files.hasOwnProperty(_f.path)) {
                newFiles[_f.path + ((Math.random() * 100) | 0)] = Object.assign(_f, {
                        preview: URL.createObjectURL(_f)
                    });
            }
        });

        setFiles(newFiles);
    }, [files]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv' });
    const { t } = useTranslation();

    const cancelFile = (_id) => {
        const remainingFiles = { ...files };
        delete remainingFiles[_id];
        setFiles(remainingFiles);
    };

    const uploadHandler = () => _onUploadCallback(files);

    const thumbs = Object.keys(files).map((_k, _i) => (
        <div className="thumb" key={_k}>
            <div className="thumb-inner">
                {files[_k].path.endsWith(".csv") &&
                    <i className="fa fa-file-text-o file-icon" />}
                {!files[_k].path.endsWith(".csv") &&
                    <img src={files[_k].preview} />}
                <div className="name-container" onClick={cancelFile.bind(null, _k)}>
                    <span>{files[_k].name}</span>
                    <i className="fa fa-times-circle" />
                </div>
            </div>
        </div>
    ));


    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        const keys = Object.keys(files);
        keys.forEach(_k => {
            URL.revokeObjectURL(files[_k].preview)
        });
    }, [files]);

    return <div className="file-upload-modal modal fade show">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{t("fileUpload")}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={_onCancelCallback}>&times;</span>
                        <span className="sr-only">{t("fileUpload")}</span>
                    </button>
                </div>
                <div className="modal-body modal-tab-container upload-container">
                    <div className="dropzone"  {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-message-block">
                            <div className="dz-message needsclick"> {t("dragAndUploadMessage")} </div>
                        </div>
                    </div>
                </div>
                <aside className="thumbs-container">
                    {thumbs}
                </aside>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={_onCancelCallback} data-dismiss="modal">{t("cancel")}</button>
                    {_disabled &&
                        <button type="button" className="btn btn-primary disabled" onClick={uploadHandler}>{t("loading")}&nbsp;<i className="fas fa-circle-notch fa-spin"/></button>}
                    {!_disabled &&
                        <button type="button" className="btn btn-primary" onClick={uploadHandler}>{t("upload")}</button>}
                </div>
            </div>
        </div>
    </div>;
}

FileUpload.defaultProps = {
    onUploadCallback: () => { },
    onCancelCallback: () => { }
}