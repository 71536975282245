import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

class ImageContainer extends Component {
    constructor(_props) {
        super(_props);
        this.state = {
            defaultImage: this.props.isDefaultSelected ? this.props.defaultImage : undefined
        }
    }

    shouldComponentUpdate(_nextProps) {
        return this.props.title !== _nextProps.title || this.props.isDefaultSelected !== _nextProps.isDefaultSelected || this.props.defaultImage !== _nextProps.defaultImage || this.props.image !== _nextProps.image
    }

    dropZoneOnDrop = acceptedFile => {
        if (acceptedFile.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (this.props.onImageCallback) this.props.onImageCallback(e.target.result);
            };
            reader.readAsDataURL(acceptedFile[0]);
        }
    };

    dropzone = (_showMessage) => {
        return <Dropzone onDrop={this.dropZoneOnDrop} accept="image/*">
            {({ getRootProps, getInputProps }) => (
                <div className="dropzone-container dz-message-block fill-h fill-w">
                    <div {...getRootProps()} className="dropzone-message fill-w fill-h dz-message flex justify-center flex-column">
                        <input {...getInputProps()} />
                        {_showMessage && this.props.t("dragAndUploadMessage")}
                    </div>
                </div>
            )}
        </Dropzone>
    }

    expandCallback = this.props.onExpandCallback ?
        (_e) => {
            if (this.props.image) {
                _e.stopPropagation();
                this.props.onExpandCallback(this.props.image);
            }
        } : () => { };

    deleteCallback = this.props.onDeleteCallback ?
        (_e) => {
            if (this.props.image) {
                _e.stopPropagation();
                this.props.onDeleteCallback(this.props.image);
            }
        } : () => { };

    dowloadImage = (_e) => {
        if (this.props.image) {
            _e.stopPropagation();
            const [header, data] = this.props.image.split(',');
            var pom = document.createElement('a');
            pom.setAttribute('href', `${header},${encodeURIComponent(data)}`);
            pom.setAttribute('download', 'picture.png');

            if (document.createEvent) {
                var event = document.createEvent('MouseEvents');
                event.initEvent('click', true, true);
                pom.dispatchEvent(event);
            }
            else {
                pom.click();
            }
        }
    };

    defaultCheckHandler = this.props.onDefaultSelected ? (_e) => {
        this.props.onDefaultSelected(_e.target.checked);
        this.setState({ defaultImage: _e.target.checked ? this.props.defaultImage : undefined });
    } : () => { };

    generateImageSection = () => {
        if (!this.state.defaultImage) {
            if (this.props.image || this.props.isLoading) {
                return <>
                    {this.props.isLoading && <div className="image-container flex flex-column justify-center"><i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i><span className="loading-message sr-only">Loading...</span></div>}
                    {!this.props.isLoading && this.props.image && <>
                        {this.dropzone(false)}
                        <div className="image-container flex justify-center"><img src={this.props.image} /></div>
                        <div className="absolute left bottom flex">
                            <div className="expand-action image-sample-action" onClick={this.expandCallback}>
                                <i className="fa fa-arrows-alt fa-2x" aria-hidden="true"></i>
                            </div>
                            <div className="download-action image-sample-action" onClick={this.dowloadImage}>
                                <i className="fa fa-cloud-download fa-2x" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="delete-action image-sample-action absolute right" onClick={this.deleteCallback}>
                            <i className="fa fa-trash fa-2x" aria-hidden="true"></i>
                        </div>
                    </>
                    }
                </>
            } else {
                return this.dropzone(true);
            }
        } else {
            return <div className="image-container flex justify-center">
                <img src={this.state.defaultImage} />
            </div>
        }
    };

    render() {
        return (
            <div className="image-import col-sm-6">
                <div className="title-section flex">
                    <div className="title-container flex-grow-1 bold">{this.props.title}</div>
                    {this.props.defaultImage && <div className="action-container">
                        <label className="single-line">
                            <input className="checkbox" type="checkbox" checked={this.props.isDefaultSelected === undefined ? false : this.props.isDefaultSelected} onChange={this.defaultCheckHandler} /><span>{this.props.t('checking')}</span>
                        </label>
                    </div>}
                </div>
                <div className={`dropzone${this.state.defaultImage ? ' disabled' : ''} relative flex justify-center`}>
                    {this.generateImageSection()}
                </div>
            </div>);
    }
}

ImageContainer.whyDidYouRender = true;

export default withTranslation()(ImageContainer);