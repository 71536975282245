import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'

export function BottomRightHikitsugiZumiMainForm({ processState: _processState }) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }
    const selectorList = [1, 2, 3, 4, 5, 6];

    const updateSupervisor = (_v) => updateState('supervisor', _v);
    const updatePurchaser = (_v) => updateState('purchaser', _v);
    const updateIntermediationDivision = (_v) => updateState('intermediationDivision', _v);
    const updateTradingValue = (_v) => updateState('tradingValue', _v);
    const updateNoContact = (_v) => updateState('noContact', _v);
    const updateDepositMoney = (_v) => updateState('depositMoney', _v);
    const updateAdvanceContract = (_v) => updateState('advanceContract', _v);
    const updateSite = (_v) => updateState('site', _v);
    const updateSpecialInstruction = (_v) => updateState('specialInstruction', _v);
    const updateSales = (_v) => updateState('sales', _v);
    const updateSpecialAgreement = (_v) => updateState('specialAgreement', _v);
    const updateLumpSumRepayment = (_v) => updateState('lumpSumRepayment', _v);
    const updateRegistrationInformationAcquisition = (_v) => updateState('registrationInformationAcquisition', _v);
    const updateRentalContractAcquisitionRequest = (_v) => updateState('rentalContractAcquisitionRequest', _v);
    const updateRentalContractAcquisition = (_v) => updateState('rentalContractAcquisition', _v);
    const updateTubeVolumeInformationRequest = (_v) => updateState('tubeVolumeInformationRequest', _v);
    const updateTubeVolumeInformationConfirmation = (_v) => updateState('tubeVolumeInformationConfirmation', _v);
    const updateTubeVolumeInformationConfirmationMethod = (_v) => updateState('tubeVolumeInformationConfirmationMethod', _v);
    const updateYamastoRequest = (_v) => updateState('yamastoRequest', _v);
    const updateOneSidedMethod = (_v) => updateState('oneSidedMethod', _v);
    const updateOneSidedDay = (_v) => updateState('oneSidedDay', _v);
    const updateReExplanationAuthorMethod = (_v) => updateState('reExplanationAuthorMethod', _v);
    const updateReExplanationAuthor = (_v) => updateState('reExplanationAuthor', _v);
    const updateDraftEMail = (_v) => updateState('draftEMail', _v);
    const updateContractMethod = (_v) => updateState('contractMethod', _v);
    const updateContractDate = (_v) => updateState('contractDate', _v);
    const updateBuyerReplySchedule = (_v) => updateState('buyerReplySchedule', _v);
    const updateManualReleasePeriod = (_v) => updateState('manualReleasePeriod', _v);
    const updateContractReturnMethod = (_v) => updateState('contractReturnMethod', _v);
    const updateContractArrivalEstimate = (_v) => updateState('contractArrivalEstimate', _v);
    const updateConfirmContractArrivalWithSeller = (_v) => updateState('confirmContractArrivalWithSeller', _v);
    const updateContractTerminationReport = (_v) => updateState('contractTerminationReport', _v);
    const updateSettlementOfBuyerConfirmation = (_v) => updateState('settlementOfBuyerConfirmation', _v);
    const updateSettlementDateOfBuyer = (_v) => updateState('settlementDateOfBuyer', _v);
    const updateContactWithSellerRegSettlementDate = (_v) => updateState('contactWithSellerRegSettlementDate', _v);
    const updateSettlementAttendance = (_v) => updateState('settlementAttendance', _v);
    const updateFinalSettlementDate = (_v) => updateState('finalSettlementDate', _v);

    const updateAnnounceToSeller = (_v) => updateState('announceToSeller', _v);
    const updateWaitForResultFromSeller = (_v) => updateState('waitForResultFromSeller', _v);
    const updateFinancialInstitutionInCharge = (_v) => updateState('financialInstitutionInCharge', _v);
    const updateRepaymentAmountConfirmation = (_v) => updateState('repaymentAmountConfirmation', _v);
    const updateSellerVisit = (_v) => updateState('sellerVisit', _v);
    const updateFinancialInstitutionArrivalSchedule = (_v) => updateState('financialInstitutionArrivalSchedule', _v);
    const updateSellerDocumentArrivalSchedule = (_v) => updateState('sellerDocumentArrivalSchedule', _v);
    const updateFinancialInstitutionConfirmation = (_v) => updateState('financialInstitutionConfirmation', _v);

    return (
        <>
            {_processState === 2 &&
                <ReflexElement flex={0.4}>
                    <ReflexContainer orientation="horizontal">
                        <ReflexElement>
                            <form className="hikitsugiZumiForm">
                                <helpers.GenerateInput value={data.supervisor} text={t('supervisor')} onChangeHandler={updateSupervisor} labelSize={4} inputSize={8} extraClasses="supervisor-container" />
                                <helpers.GenerateInput value={data.purchaser} text={t('purchaser')} onChangeHandler={updatePurchaser} labelSize={4} inputSize={8} extraClasses="purchaser-container" />
                                <helpers.GenerateInput value={data.intermediationDivision} text={t('intermediationDivision')} onChangeHandler={updateIntermediationDivision} labelSize={4} inputSize={8} extraClasses="intermediationDivision-container" />
                                <helpers.GenerateInput value={data.tradingValue} text={t('tradingValue')} onChangeHandler={updateTradingValue} labelSize={4} inputSize={8} extraClasses="tradingValue-container" />
                                <helpers.GenerateInput value={data.noContact} text={t('noContact')} onChangeHandler={updateNoContact} labelSize={4} inputSize={8} extraClasses="noContact-container" />
                                <helpers.GenerateInput value={data.depositMoney} text={t('depositMoney')} onChangeHandler={updateDepositMoney} labelSize={4} inputSize={8} extraClasses="depositMoney-container" />
                                <helpers.GenerateInput value={data.advanceContract} text={t('advanceContract')} onChangeHandler={updateAdvanceContract} labelSize={4} inputSize={8} extraClasses="advanceContract-container" />
                                <helpers.GenerateInput value={data.site} text={t('site')} onChangeHandler={updateSite} labelSize={4} inputSize={8} extraClasses="site-container" />
                                <helpers.GenerateInput value={data.specialInstruction} text={t('specialInstruction')} onChangeHandler={updateSpecialInstruction} labelSize={4} inputSize={8} extraClasses="specialInstruction-container" />
                                <helpers.GenerateInput value={data.sales} text={t('sales')} onChangeHandler={updateSales} labelSize={4} inputSize={8} extraClasses="sales-container" />
                                <helpers.GenerateInput value={data.specialAgreement} text={t('specialAgreement')} onChangeHandler={updateSpecialAgreement} labelSize={4} inputSize={8} extraClasses="specialAgreement-container" />
                                <helpers.GenerateInput value={data.lumpSumRepayment} text={t('lumpSumRepayment')} onChangeHandler={updateLumpSumRepayment} labelSize={4} inputSize={8} extraClasses="lumpSumRepayment-container" />
                                <div align="right">
                                    <button type="button" className="btn btn-primary">{t('purchasing')}</button>
                                </div>
                            </form>
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            }
            {_processState !== 2 &&
                <>
                    <ReflexElement flex={0.3}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement>
                                <form className="hikitsugiZumiForm">
                                    <helpers.GenerateInput value={data.supervisor} text={t('supervisor')} onChangeHandler={updateSupervisor} labelSize={4} inputSize={8} extraClasses="supervisor-container" />
                                    <helpers.GenerateInput value={data.purchaser} text={t('purchaser')} onChangeHandler={updatePurchaser} labelSize={4} inputSize={8} extraClasses="purchaser-container" />
                                    <helpers.GenerateInput value={data.intermediationDivision} text={t('intermediationDivision')} onChangeHandler={updateIntermediationDivision} labelSize={4} inputSize={8} extraClasses="intermediationDivision-container" />
                                    <helpers.GenerateInput value={data.tradingValue} text={t('tradingValue')} onChangeHandler={updateTradingValue} labelSize={4} inputSize={8} extraClasses="tradingValue-container" />
                                    <helpers.GenerateInput value={data.noContact} text={t('noContact')} onChangeHandler={updateNoContact} labelSize={4} inputSize={8} extraClasses="noContact-container" />
                                    <helpers.GenerateInput value={data.depositMoney} text={t('depositMoney')} onChangeHandler={updateDepositMoney} labelSize={4} inputSize={8} extraClasses="depositMoney-container" />
                                    <helpers.GenerateInput value={data.advanceContract} text={t('advanceContract')} onChangeHandler={updateAdvanceContract} labelSize={4} inputSize={8} extraClasses="advanceContract-container" />
                                    <helpers.GenerateInput value={data.site} text={t('site')} onChangeHandler={updateSite} labelSize={4} inputSize={8} extraClasses="site-container" />
                                    <helpers.GenerateInput value={data.specialInstruction} text={t('specialInstruction')} onChangeHandler={updateSpecialInstruction} labelSize={4} inputSize={8} extraClasses="specialInstruction-container" />
                                    <helpers.GenerateInput value={data.sales} text={t('sales')} onChangeHandler={updateSales} labelSize={4} inputSize={8} extraClasses="sales-container" />
                                    <helpers.GenerateInput value={data.specialAgreement} text={t('specialAgreement')} onChangeHandler={updateSpecialAgreement} labelSize={4} inputSize={8} extraClasses="specialAgreement-container" />
                                <helpers.GenerateInput value={data.lumpSumRepayment} text={t('lumpSumRepayment')} onChangeHandler={updateLumpSumRepayment} labelSize={4} inputSize={8} extraClasses="lumpSumRepayment-container" />
                                <div align="right">
                                    <button type="button" className="btn btn-primary">{t('purchasing')}</button>
                                </div>
                                </form>
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>
                    <ReflexSplitter />
                    <ReflexElement flex={0.3}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement>
                                <form className="hikitsugiZumiForm-bottom">
                                    <h5>{t('afterTakeover')}</h5>
                                    <helpers.GenerateDatePicker value={data.registrationInformationAcquisition} onChangeHandler={updateRegistrationInformationAcquisition} text={t('registrationInformationAcquisition')} showIcon={true} labelSize={4} extraClasses="registrationInformationAcquisition-container" />
                                    <helpers.GenerateDatePicker value={data.rentalContractAcquisitionRequest} onChangeHandler={updateRentalContractAcquisitionRequest} text={t('rentalContractAcquisitionRequest')} showIcon={true} labelSize={4} extraClasses="rentalContractAcquisitionRequest-container" />
                                    <helpers.GenerateDatePicker value={data.rentalContractAcquisition} onChangeHandler={updateRentalContractAcquisition} text={t('rentalContractAcquisition')} showIcon={true} labelSize={4} extraClasses="rentalContractAcquisition-container" />
                                    <helpers.GenerateDatePicker value={data.tubeVolumeInformationRequest} onChangeHandler={updateTubeVolumeInformationRequest} text={t('tubeVolumeInformationRequest')} showIcon={true} labelSize={4} extraClasses="tubeVolumeInformationRequest-container" />
                                    <helpers.GenerateDatePicker value={data.tubeVolumeInformationConfirmation} onChangeHandler={updateTubeVolumeInformationConfirmation} text={t('tubeVolumeInformationConfirmation')} showIcon={true} labelSize={4} extraClasses="tubeVolumeInformationConfirmation-container" />
                                    <helpers.GenerateSelector value={data.tubeVolumeInformationConfirmationMethod} text={t('tubeVolumeInformationConfirmationMethod')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateTubeVolumeInformationConfirmationMethod} labelSize={4} inputSize={8} extraClasses="tubeVolumeInformationConfirmationMethod-container" />
                                    <helpers.GenerateDatePicker value={data.yamastoRequest} onChangeHandler={updateYamastoRequest} text={t('yamastoRequest')} showIcon={true} labelSize={4} extraClasses="yamastoRequest-container" />
                                    <helpers.GenerateSelector value={data.oneSidedMethod} text={t('oneSidedMethod')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateOneSidedMethod} labelSize={4} inputSize={8} extraClasses="oneSidedMethod-container" />
                                    <helpers.GenerateDatePicker value={data.oneSidedDay} onChangeHandler={updateOneSidedDay} text={t('oneSidedDay')} showIcon={true} labelSize={4} extraClasses="oneSidedDay-container" />
                                    <h5>{t('afterOneSidedDay')}</h5>
                                    <helpers.GenerateSelector value={data.reExplanationAuthorMethod} text={t('reExplanationAuthor')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateReExplanationAuthorMethod} labelSize={4} inputSize={8} extraClasses="reExplanationAuthorMethod-container" />
                                    <helpers.GenerateDatePicker value={data.reExplanationAuthor} onChangeHandler={updateReExplanationAuthor} text={t('reExplanationAuthor')} showIcon={true} labelSize={4} extraClasses="reExplanationAuthor-container" />
                                    <helpers.GenerateDatePicker value={data.draftEMail} onChangeHandler={updateDraftEMail} text={t('draftEMail')} showIcon={true} labelSize={4} extraClasses="draftEMail-container" />
                                    <helpers.GenerateDatePicker value={data.contractMethod} onChangeHandler={updateContractMethod} text={t('contractMethod')} showIcon={true} labelSize={4} extraClasses="contractMethod-container" />
                                    <helpers.GenerateDatePicker value={data.contractDate} onChangeHandler={updateContractDate} text={t('contractDate')} showIcon={true} labelSize={4} extraClasses="contractDate-container" />
                                    <helpers.GenerateDatePicker value={data.buyerReplySchedule} onChangeHandler={updateBuyerReplySchedule} text={t('buyerReplySchedule')} showIcon={true} labelSize={4} extraClasses="buyerReplySchedule-container" />
                                    <h5>{t('afterContract')}</h5>
                                    <helpers.GenerateSelector value={data.manualReleasePeriod} text={t('manualReleasePeriod')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateManualReleasePeriod} labelSize={4} inputSize={8} extraClasses="manualReleasePeriod-container" />
                                    <helpers.GenerateDatePicker value={data.contractReturnMethod} onChangeHandler={updateContractReturnMethod} text={t('contractReturnMethod')} showIcon={true} labelSize={4} extraClasses="contractReturnMethod-container" />
                                    <helpers.GenerateSelector value={data.contractArrivalEstimate} text={t('contractArrivalEstimate')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateContractArrivalEstimate} labelSize={4} inputSize={8} extraClasses="contractArrivalEstimate-container" />
                                    <helpers.GenerateSelector value={data.confirmContractArrivalWithSeller} text={t('confirmContractArrivalWithSeller')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateConfirmContractArrivalWithSeller} labelSize={4} inputSize={8} extraClasses="confirmContractArrivalWithSeller-container" />
                                    <helpers.GenerateSelector value={data.contractTerminationReport} text={t('contractTerminationReport')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateContractTerminationReport} labelSize={4} inputSize={8} extraClasses="contractTerminationReport-container" />
                                    <div />
                                    <helpers.GenerateDatePicker value={data.settlementOfBuyerConfirmation} onChangeHandler={updateSettlementOfBuyerConfirmation} text={t('settlementOfBuyerConfirmation')} showIcon={true} labelSize={4} extraClasses="settlementOfBuyerConfirmation-container" />
                                    <helpers.GenerateDatePicker value={data.settlementDateOfBuyer} onChangeHandler={updateSettlementDateOfBuyer} text={t('settlementDateOfBuyer')} showIcon={true} labelSize={4} extraClasses="settlementDateOfBuyer-container" />
                                    <helpers.GenerateDatePicker value={data.contactWithSellerRegSettlementDate} onChangeHandler={updateContactWithSellerRegSettlementDate} text={t('contactWithSellerRegSettlementDate')} showIcon={true} labelSize={4} extraClasses="contactWithSellerRegSettlementDate-container" />
                                    <helpers.GenerateSelector value={data.settlementAttendance} text={t('settlementAttendance')} options={[{ value: null, text: null }].concat(selectorList.map(_v => ({ value: _v, text: _v })))} onChangeHandler={updateSettlementAttendance} labelSize={4} inputSize={8} extraClasses="settlementAttendance-container" />
                                    <helpers.GenerateDatePicker value={data.finalSettlementDate} onChangeHandler={updateFinalSettlementDate} text={t('finalSettlementDate')} showIcon={true} labelSize={4} extraClasses="finalSettlementDate-container" />
                                    <h5>{t('mortgageErasure')}</h5>
                                    <helpers.GenerateDatePicker value={data.announceToSeller} onChangeHandler={updateAnnounceToSeller} text={t('announceToSeller')} showIcon={true} labelSize={4} extraClasses="announceToSeller-container" />
                                    <helpers.GenerateDatePicker value={data.waitForResultFromSeller} onChangeHandler={updateWaitForResultFromSeller} text={t('waitForResultFromSeller')} showIcon={true} labelSize={4} extraClasses="waitForResultFromSeller-container" />
                                    <helpers.GenerateInput value={data.financialInstitutionInCharge} text={t('financialInstitutionInCharge')} onChangeHandler={updateFinancialInstitutionInCharge} labelSize={4} inputSize={5} extraClasses="financialInstitutionInCharge-container" />
                                    <helpers.GenerateDatePicker value={data.repaymentAmountConfirmation} onChangeHandler={updateRepaymentAmountConfirmation} text={t('repaymentAmountConfirmation')} showIcon={true} labelSize={4} extraClasses="repaymentAmountConfirmation-container" />
                                    <helpers.GenerateDatePicker value={data.sellerVisit} onChangeHandler={updateSellerVisit} text={t('sellerVisit')} showIcon={true} labelSize={4} extraClasses="sellerVisit-container" />
                                    <helpers.GenerateDatePicker value={data.financialInstitutionArrivalSchedule} onChangeHandler={updateFinancialInstitutionArrivalSchedule} text={t('financialInstitutionArrivalSchedule')} showIcon={true} labelSize={4} extraClasses="financialInstitutionArrivalSchedule-container" />
                                    <helpers.GenerateDatePicker value={data.sellerDocumentArrivalSchedule} onChangeHandler={updateSellerDocumentArrivalSchedule} text={t('sellerDocumentArrivalSchedule')} showIcon={true} labelSize={4} extraClasses="sellerDocumentArrivalSchedule-container" />
                                    <helpers.GenerateDatePicker value={data.financialInstitutionConfirmation} onChangeHandler={updateFinancialInstitutionConfirmation} text={t('financialInstitutionConfirmation')} showIcon={true} labelSize={4} extraClasses="financialInstitutionConfirmation-container" />
                                </form>
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>
                </>
            }
        </>);
}

BottomRightHikitsugiZumiMainForm.defaultProps = {
    extraClasses: ''
}