import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function UserTeamsEdit({ title: _title, employee: _employee, teams: _teams, multiTeams: _multiTeams, onSubmitCallback: _onSubmitCallback, onCancelCallback: _onCancelCallback }) {

    const { t } = useTranslation();
    const [employee, setEmployee] = useState(_employee);
    const [teams, setTeams] = useState(_teams);
    const [multiTeams, setMultiTeams] = useState(_multiTeams);
    useEffect(() => {
        const newTeams = JSON.parse(JSON.stringify(_teams));
        if (_multiTeams) {
            _multiTeams.forEach(_t => {
                const index = newTeams.findIndex(_st => _st.id === _t.teamId);
                if (index >= 0) newTeams[index].selected = true;
            });
        }
        setEmployee(_employee);
        setTeams(newTeams);
        setMultiTeams(_multiTeams);
    }, [_employee, _teams, _multiTeams]);

    const generateCheckbox = (_team, _index) => {
        const handler = (_e) => {
            if (employee.teamId !== _team.id) {
                const newTeams = teams.slice();
                newTeams[_index].selected = _e.target.checked;
                setTeams(newTeams);
            }
        }

        return <div key={_team.id}>
            <label>
                <input className="checkbox" type="checkbox" checked={_team.selected || employee.teamId === _team.id ? 'checked' : ''} disabled={employee.teamId === _team.id ? 'disabled' : ''} onChange={handler} />
                <span>{_team.name}</span>
            </label>
        </div>
    }

    const onSubmitHandler = _onSubmitCallback ? () => _onSubmitCallback(employee, teams, multiTeams) : undefined;

    return <div className="card">
        <div className="card-block">
            <div className="title-block">
                <h3 className="title">{_title}</h3>
            </div>
            <section className="section title-section">
                <div className="employee-edit-form">
                    <form>
                        <div className="form-group">
                            <label className="control-label secondary-title">{_employee.name + t('teamsOfUserSuffix')}</label>
                            {teams.map((_g, _i) => generateCheckbox(_g, _i))}
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 form-controls">
                                <button type="button" className="btn btn-secondary" onClick={_onCancelCallback}>{t('cancel')}</button>
                                <button type="button" className="btn btn-primary" onClick={onSubmitHandler}>{t('okEng')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>;
}