import React, { Component } from 'react';

export default class Layout extends Component {
    displayName = Layout.name;

    render() {
        return (
            <div className="main-wrapper">
                <div className="app">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
