import React, { useState, useEffect } from "react";
import { SimpleTextInput, InputRange as BaseInputRange, DateSelector } from 'components/inputs/textInput';

export default function CallPageInput({ Flag: _Flag, Memo: _Memo, isVisible: _isVisible,
    onChangeHistoryInputTextValues: _onChangeHistoryInputTextValues, rowName: _rowName,
    widthValue: _widthValue, isMultiline: _isMultiline, FlagNumber: _FlagNumber, SelectUser: _SelectUser, newInputflag: _newInputflag }) {

    const [memo, setMemo] = useState(_Memo);
    const [selectUser, setSelectUser] = useState(_SelectUser);

    if (selectUser !== _SelectUser) {
        setMemo(_Memo);
        setSelectUser(_SelectUser)
    }

    const onSetTextValue = (e, _Flag) => {
        const onSetTextValues = () => setMemo(e);
        onSetTextValues();
    }
    //const [memo, setMemo] = useState(_Memo)
   

    //function memoSet() {
    //    if (typeof memo === "undefined") {
    //        return _Memo;
    //    }
        
    //}

    if (_newInputflag && memo != _Memo) {
        setMemo(_Memo);
    }

    useEffect(() => {
        //if (_FlagNumber) {
            setMemo(_Memo);
        //}
        
    }, [_Memo])

    useEffect(() => {
        if (_onChangeHistoryInputTextValues && _FlagNumber) {
            _onChangeHistoryInputTextValues(memo);
        }
    }, [memo])

    return (

        <div hidden={_isVisible} className={_rowName}>
            {
                <div className={"col-xs-" + _widthValue}>
                    {
                        _Flag ?
                            <SimpleTextInput
                                isMultiline={_isMultiline}
                                value={memo}
                                onChange={(e) => onSetTextValue(e, Number(_Flag))}
                            /> :
                            <div>
                                {
                                    memo
                                }
                            </div>
                    }

                </div>
            }
        </div>)

}