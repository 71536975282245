import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleToggle } from 'components/inputs/toggles';
import { DateSelector } from 'components/inputs/textInput';
import { validatorPhone } from 'helpers/validatorHelpers';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function Phone({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, errorHandlingHOC: _errorHandlingHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const mainPhoneModes = ["03rejected", "inconvenienceMode", "cellphoneForwarding", "noDisplay"].reduce(_checkboxGroupHOC.checkboxGroupReducer('mainPhoneModes'), {});
    const phoneTypes = ["unspecified", "direct", "anotherHouse", "workplace", "other"].reduce(_checkboxGroupHOC.checkboxGroupReducer('phoneTypes'), {});
    const secondaryPhoneModes = ["03rejected", "inconvenienceMode", "cellphoneForwarding", "noDisplay"].reduce(_checkboxGroupHOC.checkboxGroupReducer('secondaryPhoneModes'), {});

    return <>
        <wf.GroupField className="form-group row" label={t('mainPhoneNumber')} contentExtraClasses="no-gutters">
            <span className="col-xs-8 ellipsize">{data.phoneNumber.defaultValue}</span>
            <SimpleToggle extraClasses="input-side-control col-xs-4" label={t('phoneProhibited')} value={data.phoneProhibited.value || data.phoneProhibited.defaultValue || false}
                onChange={setData('phoneProhibited')} />
        </wf.GroupField>
        <wf.CheckboxGroupField values={mainPhoneModes} onChange={(_v) => setData('mainPhoneModes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} />
        <wf.CheckboxGroupField values={phoneTypes} radioName="phoneTypes" onChange={(_v) => setData('phoneTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} />
        <wf.GroupField contentExtraClasses="no-gutters">
            <wf.InputField extraClasses="col-xs-8 no-margin" hideLabel={true}
                value={data.secondaryPhone.value || data.secondaryPhone.defaultValue}
                validator={(_v) => !_v || validatorPhone(_v)}
                errorMessage={t('incorrectPhoneFormatError')}
                onChange={setData('secondaryPhone')} />
            <SimpleToggle extraClasses="input-side-control col-xs-4" label={t('phoneProhibited')} value={data.secondaryPhoneProhibited.value || data.secondaryPhoneProhibited.defaultValue || false} onChange={setData('secondaryPhoneProhibited')} />
        </wf.GroupField>
        <wf.CheckboxGroupField values={secondaryPhoneModes} onChange={(_v) => setData('secondaryPhoneModes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} />
        <wf.InputField value={data.tertiaryPhone.value || data.tertiaryPhone.defaultValue}
            validator={(_v) => !_v || validatorPhone(_v)}
            errorMessage={t('incorrectPhoneFormatError')}
            onChange={setData('tertiaryPhone')}/>
        <wf.GroupField label={t('workplaceSearchPhoneNumber')}>
            <wf.InputField extraClasses="flex-grow-2 no-margin" hideLabel={true}
                value={data.workplaceSearchPhoneNumberMain.value || data.workplaceSearchPhoneNumberMain.defaultValue}
                validator={(_v) => !_v || validatorPhone(_v)}
                errorMessage={t('incorrectPhoneFormatError')}
                onChange={setData('workplaceSearchPhoneNumberMain')} />
            <div className="flex-grow-1" />
            <DateSelector extraClasses="col-xs-4" showIcon={true} value={data.workplaceSearchPhoneNumberMainDate.value || data.workplaceSearchPhoneNumberMainDate.defaultValue} onChange={setData('workplaceSearchPhoneNumberMainDate')} extraClasses="flex flex-nowrap" />
        </wf.GroupField>
        <wf.GroupField>
            <wf.InputField extraClasses="flex-grow-2 no-margin" hideLabel={true}
                value={data.workplaceSearchPhoneNumberSecondary.value || data.workplaceSearchPhoneNumberSecondary.defaultValue}
                validator={(_v) => !_v || validatorPhone(_v)}
                errorMessage={t('incorrectPhoneFormatError')}
                onChange={setData('workplaceSearchPhoneNumberSecondary')} />
            <div className="flex-grow-1" />
            <DateSelector extraClasses="col-xs-4" showIcon={true} value={data.workplaceSearchPhoneNumberSecondaryDate.value || data.workplaceSearchPhoneNumberSecondaryDate.defaultValue} onChange={setData('workplaceSearchPhoneNumberSecondaryDate')} extraClasses="flex flex-nowrap" />
        </wf.GroupField>
        <wf.GroupField label={t('externalImport')}>
            <wf.InputField extraClasses="flex-grow-2 no-margin" hideLabel={true}
                value={data.externalImportNumberMain.value || data.externalImportNumberMain.defaultValue}
                validator={(_v) => !_v || validatorPhone(_v)}
                errorMessage={t('incorrectPhoneFormatError')}
                onChange={setData('externalImportNumberMain')} />
            <div className="flex-grow-1" />
            <DateSelector extraClasses="col-xs-4" showIcon={true} value={data.externalImportNumberMainDate.value || data.externalImportNumberMainDate.defaultValue} onChange={setData('externalImportNumberMainDate')} extraClasses="flex flex-nowrap" />
        </wf.GroupField>
        <wf.GroupField>
            <wf.InputField extraClasses="flex-grow-2 no-margin" hideLabel={true}
                value={data.externalImportNumberSecondary.value || data.externalImportNumberSecondary.defaultValue}
                validator={(_v) => !_v || validatorPhone(_v)}
                errorMessage={t('incorrectPhoneFormatError')}
                onChange={setData('externalImportNumberSecondary')} />
            <div className="flex-grow-1" />
            <DateSelector extraClasses="col-xs-4" showIcon={true} value={data.externalImportNumberSecondaryDate.value || data.externalImportNumberSecondaryDate.defaultValue} onChange={setData('externalImportNumberSecondaryDate')} extraClasses="flex flex-nowrap" />
        </wf.GroupField>
    </>;
}

export const PhonePopup = ExportDefaultBehaviour(Phone, {
    editableFields: ['phoneNumber', 'phoneProhibited', 'mainPhoneModes', 'phoneTypes', 'secondaryPhone', 'secondaryPhoneProhibited', 'secondaryPhoneModes', 'tertiaryPhone', 'workplaceSearchPhoneNumberMain', 'workplaceSearchPhoneNumberMainDate', 'workplaceSearchPhoneNumberSecondary', 'workplaceSearchPhoneNumberSecondaryDate', 'externalImportNumberMain', 'externalImportNumberMainDate', 'externalImportNumberSecondary', 'externalImportNumberSecondaryDate'],
    extraClasses: 'push-up',
    size:'md'
});
PhonePopup.whyDidYouRender = true;