const types = {
    requestStats: 'US_REQUEST_STATS',//async
    saveStats: 'US_SAVE_STATS'//sync
};

/** Actions **/
export const saveStats = data => ({
    type: types.saveStats,
    data
});

/** Async Actions **/
export const requestStats = () => {
    return ({
        type: types.requestStats
    })
};

/** Reducer **/
const reducer = (_state = {}, _action) => {
    let newState = { ..._state };
    switch (_action.type) {
        case types.saveStats:
            newState = { ..._action.data };
            break;
    }
    return newState;
}

export default reducer;