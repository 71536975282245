import { ApiEntryPoint } from 'apiConnector';
import 'babel-polyfill';
import { addHansokuGyousha, addHansokuRecords, addHansokuRireki, addKainushiTantous, requestUpdateImage, setAssets, addRoomData, requestRoomData, addStaffs, addTemplates, requestHansokuGyousha, postDeleteHansokuRecords, requestHansokuRecords, requestHansokuRireki, requestKainushiTantou, requestStaffList, requestTemplateList, updateBoxType, updateHansokuRecords, addStaffAndTemplate, updateRoomData, requestStaffAndTemplate, updateTemplateList, deleteHansokuRecords } from 'reducers/partnersReducers';
import { call, put, takeEvery } from 'redux-saga/effects';

function* requestUpdateImageSaga(_action) {
    try {
        _action.data.map(_t => {
            _t.fileName = _action.fileName;
            _t.bukkenId = _action.bukkenId;
        })

        const result = yield call(ApiEntryPoint.ImageImportUpdateAssets, _action.data);
        if (result) {
            yield put(setAssets(result));
        }
    } catch (_e) {
        console.log('Error from requestUpdateImageSaga');
        console.log(_e);
    }
}

function* requestHansokuRecordsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestHansokuRecords, _action.boxType, _action.staffId);
        if (result && Array.isArray(result)) {
            yield put(addHansokuRecords(result));
        }
    } catch (_e) {
        console.log('Error from requestHansokuRecords');
        console.log(_e);
    }
}

function* requestKainushiTantouSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestKainushiTantou);
        if (result && Array.isArray(result)) {
            yield put(addKainushiTantous(result));
        }
    } catch (_e) {
        console.log('Error from requestKainushiTantouSaga');
        console.log(_e);
    }
}

function* requestTemplatesSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestTemplateList, _action.id);
        if (result && Array.isArray(result)) {
            yield put(addTemplates(result));
        }
    } catch (_e) {
        console.log('Error from requestTemplatesSaga');
        console.log(_e);
    }
}

function* requestStaffsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestStaffList, _action.id);
        if (result && Array.isArray(result)) {
            yield put(addStaffs(result));
        }
    } catch (_e) {
        console.log('Error from requestStaffsSaga');
        console.log(_e);
    }
}

function* requestRoomDataSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestRoomData, _action.id);
        if (result) {
            yield put(addRoomData(result));
        }
    } catch (_e) {
        console.log('Error from requestRoomDataSaga');
        console.log(_e);
    }
}

function* updateHansokuRecordsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersUpdateHansokuRecords, _action.id, _action.boxType, _action.staffId, _action.updateType, _action.updateValue);
        if (result && Array.isArray(result)) {
            yield put(addHansokuRecords(result));
        }
    } catch (_e) {
        console.log('Error from updateHansokuRecordsSaga');
        console.log(_e);
    }
}


function* updateBoxTypeSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersUpdateBoxType, _action.data);
        if (result && Array.isArray(result)) {
            yield put(addHansokuRecords(result));
        }
    } catch (_e) {
        console.log('Error from updateBoxTypeSaga');
        console.log(_e);
    }
}

function* updateTemplateListSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersUpdateTemplateList, _action.data);
        if (result && Array.isArray(result)) {
            yield put(addTemplates(result));
        }
    } catch (_e) {
        console.log('Error from updateTemplateListSaga');
        console.log(_e);
    }
}

function* updateRoomDataSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersUpdateRoomData, _action.data);
        if (result) {
            yield put(addRoomData(result));
        }
    } catch (_e) {
        console.log('Error from updateRoomDataSaga');
        console.log(_e);
    }
}

function* requestHansokuGyoushaSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestHansokuGyousha, _action.data);
        if (result && Array.isArray(result)) {
            yield put(addHansokuGyousha(result));
        }
    } catch (_e) {
        console.log('Error from requestHansokuGyoushaSaga');
        console.log(_e);
    }
}

function* requestHansokuRirekiSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestHansokuRireki, _action.id, _action.reqType);
        if (result && Array.isArray(result)) {
            yield put(addHansokuRireki(result));
        }
    } catch (_e) {
        console.log('Error from requestHansokuRirekiSaga');
        console.log(_e);
    }
}

function* requestStaffAndTemplateSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersRequestStaffAndTemplate);
        if (result) {
            yield put(addStaffAndTemplate(result));
        }
    } catch (_e) {
        console.log('Error from requestStaffAndTemplateSaga');
        console.log(_e);
    }
}

function* deleteHansokuRecordsSaga(_action) {
    try {
        const result = yield call(ApiEntryPoint.PartnersDeleteHansokuRecords, _action.data);
        if (!result.error) {
            yield put(postDeleteHansokuRecords(_action.data.ids));
            if (_action.successCallback) _action.successCallback();
        }
        else {
            if (_action.failureCallback) _action.failureCallback();
        }
    } catch (_e) {
        console.log('Error from deleteHansokuRecordsSaga');
        console.log(_e);
    }
}

export function* watchRequestHansokuRecordsSaga() {
    yield takeEvery(requestHansokuRecords().type, requestHansokuRecordsSaga);
}

export function* watchRequestKainushiTantouSaga() {
    yield takeEvery(requestKainushiTantou().type, requestKainushiTantouSaga);
}

export function* watchRequestTemplatesSaga() {
    yield takeEvery(requestTemplateList().type, requestTemplatesSaga);
}

export function* watchRequestStaffsSaga() {
    yield takeEvery(requestStaffList().type, requestStaffsSaga);
}

export function* watchUpdateHansokuRecordsSaga() {
    yield takeEvery(updateHansokuRecords().type, updateHansokuRecordsSaga);
}

export function* watchUpdateBoxTypeSaga() {
    yield takeEvery(updateBoxType().type, updateBoxTypeSaga);
}

export function* watchUpdateTemplateListSaga() {
    yield takeEvery(updateTemplateList().type, updateTemplateListSaga);
}

export function* watchRequestHansokuGyoushaSaga() {
    yield takeEvery(requestHansokuGyousha().type, requestHansokuGyoushaSaga);
}

export function* watchRequestHansokuRirekiSaga() {
    yield takeEvery(requestHansokuRireki().type, requestHansokuRirekiSaga);
}

export function* watchRequestStaffAndTemplateSaga() {
    yield takeEvery(requestStaffAndTemplate().type, requestStaffAndTemplateSaga);
}

export function* watchDeleteHansokuRecordsSaga() {
    yield takeEvery(deleteHansokuRecords().type, deleteHansokuRecordsSaga);
}

export function* watchRequestRoomDataSaga() {
    yield takeEvery(requestRoomData().type, requestRoomDataSaga);
}

export function* watchUpdateRoomDataSaga() {
    yield takeEvery(updateRoomData().type, updateRoomDataSaga);
}

export function* watchRequestUpdateImageSaga() {
    yield takeEvery(requestUpdateImage().type, requestUpdateImageSaga);
}