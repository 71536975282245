import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as helpers from 'helpers/formHelpers';

export function OwnerForm({ employeeList: _employeeList, callBack: _callBack, extraClasses: _extraClasses }) {
    const [data, setData] = useState({});
    const [styles, setStyles] = useState({
        phoneColProps: {},
        cellphoneColProps: {},
        faxColProps: {}
    });
    const { t } = useTranslation();
    const updateState = (_attr, _val) => {
        const update = {};
        update[_attr] = _val;
        setData({ ...data, ...update });
    }

    const updateName = (_v) => updateState('name', _v);
    const updateFurigana = (_v) => updateState('furigana', _v);
    const updatePropertiesOwnedAmount = (_v) => updateState('propertiesOwnedAmount', _v);
    const updatePropertiesOwnerAmountLess = (_v) => updateState('propertiesOwnedAmountLess', _v);
    const updateExcludeCompany = (_v) => updateState('excludeCompany', _v);
    const updateReverberation = (_v) => updateState('reverberation', _v);
    const updatePhoneNumber = (_v) => updateState('phoneNumber', _v);
    const updateWithoutPhoneNumber = (_v) => updateState('withoutPhoneNumber', _v);
    const updateCellphone = (_v) => updateState('cellphone', _v);
    const updateWithoutCellphone = (_v) => updateState('withoutCellphone', _v);
    const updateDateRange = (_v) => updateState('dateRange', _v);
    const updateFax = (_v) => updateState('fax', _v);
    const updateTelephoneTypes = (_v) => updateState('phoneNumberTypes', _v);
    const updateWorkplaceSearch = (_v) => updateState('workplaceSearch', _v);
    const updateWorkplaceSearchRange = (_v) => updateState('workplaceSearchRange', _v);
    const updateExternalData = (_v) => updateState('externalData', _v);
    const updateExternalDataRange = (_v) => updateState('externalDataRange', _v);
    const updateExternalDataMobile = (_v) => updateState('externalDataMobile', _v);
    const updateExternalDataMobileRange = (_v) => updateState('externalDataMobileRange', _v);
    const updateStreetAddress = (_v) => updateState('streetAddress', _v);
    const updateIncorrectStreetAddress = (_v) => updateState('incorrectStreetAddress', _v);
    const updateEmail = (_v) => updateState('email', _v);
    const updateSex = (_v) => updateState('sex', _v);
    const updateAgeRange = (_v) => updateState('ageRange', _v);
    const updateBuyer = (_v) => updateState('buyer', _v);
    const updateProfession = (_v) => updateState('profession', _v);
    const updateCheck = (_v) => updateState('check', _v);
    const updateCheckEcOpening = (_v) => updateState('checkEcOpening', _v);
    const updateCheckExtra = (_v) => updateState('checkExtra', _v);
    const updateCheckTimeRange = (_v) => updateState('checkTimeRange', _v);
    const updateCheckExternalImportFile = (_v) => updateState('checkExternalImportFile', _v);
    const updateCheck2 = (_v) => updateState('check2', _v);
    const updateCheck2EcOpening = (_v) => updateState('check2EcOpening', _v);
    const updateCheck2Extra = (_v) => updateState('check2Extra', _v);
    const updateStatus = (_v) => updateState('status', _v);
    const updateStatusEc = (_v) => updateState('statusEc', _v);
    const updateStatusExtra = (_v) => updateState('statusExtra', _v);
    const updateStatusEcDispatchedSchedule = (_v) => updateState('statusEcDispatchedSchedule', _v);
    const updateStatusVisitScheduled = (_v) => updateState('statusVisitScheduled', _v);
    const updateStatus2 = (_v) => updateState('status2', _v);
    const updateStatus2Ec = (_v) => updateState('status2Ec', _v);
    const updateStatus2Extra = (_v) => updateState('status2Extra', _v);
    const updateStatus2EcDispatchedSchedule = (_v) => updateState('status2EcDispatchedSchedule', _v);
    const updateStatus2VisitScheduled = (_v) => updateState('status2VisitScheduled', _v);
    const updateStatus2WorkplaceSearchRange = (_v) => updateState('status2workplaceSearchRange', _v);
    const updateStatus2SelectedEmployee = (_v) => updateState('status2SelectedEmployee', _v);
    const updateStatus2EcLastCommunicationDate = (_v) => updateState('status2EcLastCommunicationDate', _v);
    const updateTelAvailabilityRange = (_v) => updateState('telAvailabilityRange', _v);
    const updateSalesPeriod = (_v) => updateState('salesPeriod', _v);
    const updateSalesPeriodRetirement = (_v) => updateState('salesPeriodRetirement', _v);
    const updateSalesPeriodMoving = (_v) => updateState('salesPeriodMoving', _v);
    const updateSalesPeriodOther = (_v) => updateState('salesPeriodOther', _v);
    const updateSalesPeriodPrivate = (_v) => updateState('salesPeriodPrivate', _v);
    const updateInheritanceTelAvailabilityRange = (_v) => updateState('inheritance', _v);
    const updateDebtConsolidation = (_v) => updateState('debtConsolidation', _v);
    const updateAvailabilityTimeRange = (_v) => updateState('availabilityTimeRange', _v);
    const updateRegistration = (_v) => updateState('registration', _v);
    const updateOwnerRemarks = (_v) => updateState('ownerRemarks', _v);
    const updateCallCount = (_v) => updateState('callCount', _v);
    const updateLastCallDate = (_v) => updateState('lastCallDate', _v);
    const updateExternalDataAcquisitionDate = (_v) => updateState('externalDataAcquisitionDate', _v);
    const updateDm = (_v) => updateState('dm', _v);
    const updateDmProhibited = (_v) => updateState('dmProhibited', _v);
    const updateDmNot = (_v) => updateState('dmNot', _v);
    const updateDmLastSentDate = (_v) => updateState('dmLastSentDate', _v);
    const updateDmLastSentDateNotSent = (_v) => updateState('dmLastSentDateNotSent', _v);

    const clearHandler = () => setData({});

    const phoneColProps = {
        long: { lg: 5, md: 4, sm: 6, className: 'col-xl-4' },
        short: { lg: 5, md: 5, sm: 6, className: 'col-xxl-3 col-xl-5' }
    };
    const cellphoneColProps = {
        long: { lg: 4, md: 4, sm: 6, className: 'col-xl-4' },
        short: { lg: 5, md: 5, sm: 6, className: 'col-xxl-3 col-xl-5' }
    };
    const faxColProps = {
        long: { lg: 3, md: 4, sm: 6, className: 'col-xl-4' },
        short: { lg: 5, md: 5, sm: 6, className: 'col-xxl-3 col-xl-5' }
    };

    const groupValuesNormalizer = (_attributeName) => (_v, _i) => ({ ..._v, ...{ selected: _v.selected || (data[_attributeName] && data[_attributeName][_i] && data[_attributeName][_i].selected) || false } });

    const phoneNumberTypes = [
        { value: 'unspecified', text: t('unspecified') },
        { value: 'direct', text: t('direct') },
        { value: 'anotherHouse', text: t('anotherHouse') },
        { value: 'workplace', text: t('workplace') },
        { value: 'other', text: t('other') },
        { value: 'phoneNotProhibited', text: t('phoneNotProhibited') },
        { value: 'noWorkCheckRegistration', text: t('noWorkCheckRegistration') }
    ].map(groupValuesNormalizer('phoneNumberTypes'));
    const sex = [
        { value: 'male', text: t('male') },
        { value: 'female', text: t('female') },
        { value: 'unknown', text: t('unknown') }
    ].map(groupValuesNormalizer('sex'));
    const profession = [
        { value: 'unspecified', text: t('unspecified') },
        { value: 'working', text: t('working') },
        { value: 'employee', text: t('employee') },
        { value: 'retired', text: t('retired') },
        { value: 'selfEmployed', text: t('selfEmployed') },
        { value: 'civilServant', text: t('civilServant') },
        { value: 'doctor', text: t('doctor') },
        { value: 'teacher', text: t('teacher') },
        { value: 'other', text: t('other') },
        { value: 'occupationUnidentified', text: t('occupationUnidentified') }
    ].map(groupValuesNormalizer('profession'));
    const checkValues = [
        { value: 'absence', text: t('absence') },
        { value: 'ownerAbsent', text: t('ownerAbsent') },
        { value: 'sovereignty', text: t('sovereignty') },
        { value: 'interrupted', text: t('interrupted') },
        { value: 'receptionist', text: t('receptionist') },
        { value: 'gkg', text: t('gkg') },
        { value: 'sovereigntyG', text: t('sovereigntyG') },
        { value: 'anotherPerson', text: t('anotherPerson') },
        { value: 'separateResidence', text: t('separateResidence') },
        { value: 'lineBusy', text: t('lineBusy') },
        { value: 'dm', text: t('dm') },
        { value: 'visitAbsent', text: t('visitAbsent') },
        { value: 'visitOwnerAbsent', text: t('visitOwnerAbsent') },
        { value: 'visitSovereignty', text: t('visitSovereignty') },
        { value: 'visitReception', text: t('visitReception') },
        { value: 'visitGkg', text: t('visitGkg') },
        { value: 'visitSovereigntyG', text: t('visitSovereigntyG') },
        { value: 'visitBespoke', text: t('visitBespoke') },
        { value: 'moving', text: t('moving') },
        { value: 'unknown', text: t('unknown') },
        { value: 'unknownRoom', text: t('unknownRoom') }
    ];
    const check = checkValues.map((_v, _i) => groupValuesNormalizer('check')({ ..._v }, _i));
    const checkExtraValues = [{ value: 'things', text: t('things'), selected: true }, { value: 'thingsIncludingHistory', text: t('thingsIncludingHistory') }];
    const checkExtra = checkExtraValues.map((_v, _i) => groupValuesNormalizer('checkExtra')({ ..._v, ...{ selected: data.checkExtra ? data.checkExtra[_i].selected : _v.selected === true } }, _i));
    const check2 = checkValues.map((_v, _i) => groupValuesNormalizer('check2')({ ..._v }, _i));
    const check2ExtraValues = [{ value: 'withoutThings', text: t('withoutThings'), selected: true }, { value: 'withoutThingsIncludingHistory', text: t('withoutThingsIncludingHistory') }];
    const check2Extra = check2ExtraValues.map((_v, _i) => groupValuesNormalizer('check2Extra')({ ..._v, ...{ selected: data.check2Extra ? data.check2Extra[_i].selected : _v.selected === true } }, _i));
    const statusValues = [
        { value: 'normal', text: t('normal') },
        { value: 'declined', text: t('declined') },
        { value: 'soldOut', text: t('soldOut') },
        { value: 'currentlyUnavailable', text: t('currentlyUnavailable') },
        { value: 'expected', text: t('expected') },
        { value: 'intermediary', text: t('intermediary') },
        { value: 'remediation', text: t('remediation') },
        { value: 'ongoingContracts', text: t('ongoingContracts') },
        { value: 'settlement', text: t('settlement') },
        { value: 'visitCommon', text: t('visitCommon') },
        { value: 'visitDeclined', text: t('visitDeclined') },
        { value: 'visitIpRefused', text: t('visitIpRefused') },
        { value: 'visitAllSoldOut', text: t('visitAllSoldOut') },
        { value: 'visitCurrentlyUnavailable', text: t('visitCurrentlyUnavailable') },
        { value: 'visitExpected', text: t('visitExpected') },
        { value: 'visitMediation', text: t('visitMediation') },
        { value: 'visitMediationReceived', text: t('visitMediationReceived') },
        { value: 'visitInheritance', text: t('visitInheritance') },
        { value: 'visitSettlement', text: t('visitSettlement') }
    ];
    const status = statusValues.map((_v, _i) => groupValuesNormalizer('status')({ ..._v }, _i));
    const statusEcValues = [
        { value: 'ecNormal', text: t('ecNormal') },
        { value: 'ecInterrupted', text: t('ecInterrupted') },
        { value: 'ecWithheldRefusal', text: t('ecWithheldRefusal') },
        { value: 'ecLineBusy', text: t('ecLineBusy') },
        { value: 'ecCellPhoneNormal', text: t('ecCellPhoneNormal') },
        { value: 'ecCellPhoneInterrupted', text: t('ecCellPhoneInterrupted') },
        { value: 'ecCellPhoneWithheldRefusal', text: t('ecCellPhoneWithheldRefusal') },
        { value: 'ecCellPhoneBusy', text: t('ecCellPhoneBusy') }
    ];
    const statusEc = statusEcValues.map((_v, _i) => groupValuesNormalizer('statusEc')({ ..._v }, _i));
    const statusExtra = checkExtraValues.map((_v, _i) => groupValuesNormalizer('statusExtra')({ ..._v, ...{ selected: data.statusExtra ? data.statusExtra[_i].selected : _v.selected === true } }, _i));
    const status2 = statusValues.map((_v, _i) => groupValuesNormalizer('status2')({ ..._v }, _i));
    const status2Ec = statusEcValues.map((_v, _i) => groupValuesNormalizer('status2Ec')({ ..._v }, _i));
    const status2Extra = check2ExtraValues.map((_v, _i) => groupValuesNormalizer('status2Extra')({ ..._v, ...{ selected: data.status2Extra ? data.status2Extra[_i].selected : _v.selected === true } }, _i));
    const telAvailabilityRange = [
        { value: 'everyDay', text: t('everyDay') },
        { value: 'weekday', text: t('weekday') },
        { value: 'nonWorkingDay', text: t('nonWorkingDay') },
        { value: 'monday', text: t('monday') },
        { value: 'tuesday', text: t('tuesday') },
        { value: 'wednesday', text: t('wednesday') },
        { value: 'thursday', text: t('thursday') },
        { value: 'friday', text: t('friday') },
        { value: 'saturday', text: t('saturday') },
        { value: 'sunday', text: t('sunday') }
    ].map(groupValuesNormalizer('telAvailabilityRange'));
    const inheritance = [
        { value: 'ownerDeath', text: t('ownerDeath') },
        { value: 'children', text: t('children') },
        { value: 'spouse', text: t('spouse') },
        { value: 'grandchild', text: t('grandchild') },
        { value: 'other', text: t('other') },
        { value: 'jonEng', text: t('jonEng') }
    ].map(groupValuesNormalizer('inheritance'));
    const debtConsolidation = [
        { value: 'offmarketSale', text: t('offmarketSale') },
        { value: 'voluntaryBankruptcy', text: t('voluntaryBankruptcy') },
        { value: 'civilRehabilitationLaw', text: t('civilRehabilitationLaw') }
    ].map(groupValuesNormalizer('debtConsolidation'));
    const registration = [
        { value: 'registered', text: t('registered') },
        { value: 'unregistered', text: t('unregistered') }
    ].map(groupValuesNormalizer('registration'));
    const dm = [
        { value: 'notDone', text: t('notDone') },
        { value: 'done', text: t('done') },
        { value: 'disallowedReturned', text: t('disallowedReturned') },
        { value: 'disallowedBeforeReturCheck', text: t('disallowedBeforeReturCheck') }
    ].map(groupValuesNormalizer('dm'));

    useEffect(() => {
        if (data.reverberation) {
            setStyles({
                phoneColProps: phoneColProps.short,
                cellphoneColProps: cellphoneColProps.short,
                faxColProps: faxColProps.short
            });
        } else {
            setStyles({
                phoneColProps: phoneColProps.long,
                cellphoneColProps: cellphoneColProps.long,
                faxColProps: faxColProps.long
            });
        }
    }, [data.reverberation]);

    return <div className={'callmode-form-container ' + _extraClasses}>
        <form>
            <Row className="form-group">
                <Col lg={5} md={5} sm={6} className="col-xxl-2 col-xl-5">
                    <div className="single-line">
                        <helpers.GenerateInput value={data.name} text={t('fullName')} onChangeHandler={updateName} extraClasses="name-container" />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={6} className="col-xxl-3 col-xl-5">
                    <helpers.GenerateInput value={data.furigana} text={t('furigana')} onChangeHandler={updateFurigana} extraClasses="furigana-container" />
                </Col>
                <Col lg={12} md={12} sm={12} className="col-xxl-7 col-xl-12">
                    <Row>
                        <Col lg={8} md={8} sm={12} className="col-xxl-8 col-xl-8">
                            <Row>
                                <Col lg={4} md={4} sm={3} className="col-xxl-6 col-xl-8 col-6">
                                    <helpers.GenerateInput value={data.propertiesOwnedAmount} type={'number'} text={t('propertiesOwnedAmount')} onChangeHandler={updatePropertiesOwnedAmount} labelSize={5} inputSize={6} extraClasses="propertiesOwnedAmount-container" />
                                </Col>
                                <Col lg={2} md={2} sm={3} className="flex flex-column flex-center col-xxl-6 col-xl-4 col-6">
                                    <label className="form-control-label form-group single-line">{t('moreThan')}</label>
                                </Col>
                                <Col lg={4} md={4} sm={3} className="col-xxl-6 col-xl-8 col-6">
                                    <helpers.GenerateInput value={data.propertiesOwnedAmountLess} type={'number'} onChangeHandler={updatePropertiesOwnerAmountLess} labelSize={5} inputSize={6} extraClasses="propertiesOwnedAmount-container" />
                                </Col>
                                <Col lg={2} md={4} sm={3} className="flex flex-column flex-center col-xxl-6 col-xl-4 col-6">
                                    <label className="form-control-label form-group single-line">{t('lessThan')}({t('excludeSold')})</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} className="flex flex-nowrap form-group col-xxl-4 col-xl-4 offset-md-0 offset-2 ">
                            <helpers.GenerateCheckbox value={data.excludeCompany} text={t('excludeCompany')} onChangeHandler={updateExcludeCompany} extraClasses="excludeCompany-container" />
                            <helpers.GenerateCheckbox value={data.reverberation} text={t('reverberation')} onChangeHandler={updateReverberation} extraClasses="reverberation-container" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="form-group">
                <Col {...styles.phoneColProps}>
                    <helpers.GenerateDisableInput
                        value={data.phoneNumber}
                        text={t('phoneNumber')} labelSize={3} inputSize={8}
                        disabled={data.withoutPhoneNumber}
                        disableText={t('without')}
                        onChangeHandler={updatePhoneNumber}
                        onDisableHandler={updateWithoutPhoneNumber}
                        extraClasses="phoneNumber-container" />
                </Col>
                <Col {...styles.cellphoneColProps}>
                    <helpers.GenerateDisableInput
                        value={data.cellphone}
                        text={t('cellphone')} labelSize={3} inputSize={8}
                        disabled={data.withoutCellphone}
                        disableText={t('without')}
                        onChangeHandler={updateCellphone}
                        onDisableHandler={updateWithoutCellphone}
                        extraClasses="cellphone-container" />
                </Col>
                {data.reverberation && <div className="w-100 d-block d-xxl-none"></div>}
                <Col {...styles.faxColProps}>
                    <helpers.GenerateInput value={data.fax} text={t('faxEng')} onChangeHandler={updateFax} labelSize={3} inputSize={8} extraClasses="fax-container" />
                </Col>
                {data.reverberation &&
                    <Col lg={5} md={5} sm={6} className="col-xxl-3 col-xl-5">
                        <helpers.GenerateDateRange dateRange={data.dateRange} onChangeHandler={updateDateRange} showIcon={true} extraClasses="dateRange-container" />
                    </Col>
                }
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="super-wide-input col-xxl-11 col-xl-12">
                    <helpers.GenerateCheckboxGroup text={[t('phoneNumber'), t('category')]} values={phoneNumberTypes} onChangeHandler={updateTelephoneTypes} labelSize={2} inputSize={10} extraClasses="phoneNumberTypes-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={7} sm={12} className="col-xxl-5 col-xl-7">
                    <helpers.GenerateInput value={data.workplaceSearch} text={t('workplaceSearch')} onChangeHandler={updateWorkplaceSearch} labelSize={3} extraClasses="workplaceSearch-container" />
                </Col>
                <Col md={5} sm={8} className="col-xl-4 col-8 offset-md-0 offset-4">
                    <helpers.GenerateDateRange dateRange={data.workplaceSearchRange} onChangeHandler={updateWorkplaceSearchRange} showIcon={true} extraClasses="workplaceSearchRange-container" />
                </Col>
                <Col md={7} sm={12} className="col-xxl-5 col-xl-7">
                    <helpers.GenerateInput value={data.externalData} text={t('externalData')} onChangeHandler={updateExternalData} labelSize={3} extraClasses="externalData-container" />
                </Col>
                <Col md={5} sm={8} className="col-xl-4 col-8 offset-md-0 offset-4">
                    <helpers.GenerateDateRange dateRange={data.externalDataRange} onChangeHandler={updateExternalDataRange} showIcon={true} extraClasses="externalDataRange-container" />
                </Col>
                <Col md={7} sm={12} className="col-xxl-5 col-xl-7">
                    <helpers.GenerateInput value={data.externalDataMobile} text={[t('externalData'), t('cellphone')]} onChangeHandler={updateExternalDataMobile} labelSize={3} extraClasses="externalDataMobile-container" />
                </Col>
                <Col md={5} sm={8} className="col-xl-4 col-8 offset-md-0 offset-4">
                    <helpers.GenerateDateRange dateRange={data.externalDataMobileRange} onChangeHandler={updateExternalDataMobileRange} showIcon={true} extraClasses="externalDataMobileRange-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={6} sm={12} className="col-xxl-6 col-xl-6">
                    <Row>
                        <Col md={6} sm={6} className="col-xxl-6 col-xl-6 col-6">
                            <helpers.GenerateInput value={data.streetAddress} text={t('streetAddress')} onChangeHandler={updateStreetAddress} labelSize={2} inputSize={6} extraClasses="streetAddress-container" />
                        </Col>
                        <Col md={6} sm={6} className="col-xxl-6 col-xl-6 col-6">
                            <helpers.GenerateCheckbox value={data.incorrectStreetAddress} text={t('incorrectStreetAddress')} onChangeHandler={updateIncorrectStreetAddress} extraClasses="incorrectStreetAddress-container" />
                        </Col>
                    </Row>
                </Col>
                <Col md={6} sm={12} className="col-xxl-6 col-xl-6">
                    <helpers.GenerateInput value={data.email} text={t('emailEng')} onChangeHandler={updateEmail} labelSize={2} inputSize={10} extraClasses="email-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col lg={5} md={5} sm={9} className="col-xxl-3 col-xl-4 col-9">
                    <helpers.GenerateCheckboxGroup text={t('gender')} values={sex} onChangeHandler={updateSex} extraClasses="sex-container" />
                </Col>
                <Col md={4} sm={9} className="col-xxl-3 col-xl-4 col-9">
                    <helpers.GenerateInputRange text={t('age')} range={data.ageRange} onChangeHandler={updateAgeRange} labelSize={2} extraClasses="ageRange-container" />
                </Col>
                <Col lg={2} md={2} sm={2} className="col-xxl-4 col-xl-4 col-3">
                    <helpers.GenerateCheckbox value={data.buyer} text={t('buyer')} onChangeHandler={updateBuyer} containerSize='12' extraClasses="buyer-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="super-wide-input col-xxl-11 col-xl-12">
                    <helpers.GenerateCheckboxGroup text={t('profession')} values={profession} onChangeHandler={updateProfession} labelSize={2} inputSize={10} extraClasses="profession-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="super-wide-input col-xxl-11 col-xl-12">
                    <helpers.GenerateCheckboxGroup text={t('check')} values={check} onChangeHandler={updateCheck} labelSize={2} inputSize={10} extraClasses="check-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <helpers.GenerateCheckbox value={data.checkEcOpening} text={t('ecOpening')} onChangeHandler={updateCheckEcOpening} extraClasses="checkEcOpening-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <Row>
                        <Col lg={12} md={12} className="col-xxl-3 col-xl-4 col-12">
                            <helpers.GenerateRadioGroup id="checkExtra" values={checkExtra} onChangeHandler={updateCheckExtra} extraClasses="checkExtra-container" />
                        </Col>
                        <Col lg={6} md={7} sm={6} className="col-xl-4 col-11 offset-sm-0 offset-1">
                            <helpers.GenerateInputRange text={t('timeRange')} addon={t('hour')} type={'number'} range={data.checkTimeRange} onChangeHandler={updateCheckTimeRange} labelSize={3} extraClasses="checkTimeRange-container" />
                        </Col>
                        <Col lg={6} md={5} sm={6} className="col-xl-4 col-11 offset-sm-0 offset-1">
                            <helpers.GenerateCheckbox value={data.checkExternalImportFile} text={t('externalImportFile')} onChangeHandler={updateCheckExternalImportFile} extraClasses="checkExternalImportFile-container" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="super-wide-input col-xxl-11 col-xl-12">
                    <helpers.GenerateCheckboxGroup text={t('check') + 2} values={check2} onChangeHandler={updateCheck2} extraClasses="check2-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <helpers.GenerateCheckbox value={data.check2EcOpening} text={t('ecOpening')} onChangeHandler={updateCheck2EcOpening} extraClasses="check2EcOpening-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <Row>
                        <Col lg={12} md={12} className="col-xxl-3 col-xl-12 col-12">
                            <helpers.GenerateRadioGroup id="check2Extra" values={check2Extra} onChangeHandler={updateCheck2Extra} extraClasses="check2Extra-container" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="super-wide-input col-xxl-11 col-xl-12">
                    <helpers.GenerateCheckboxGroup text={t('status')} values={status} onChangeHandler={updateStatus} extraClasses="status-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <helpers.GenerateCheckboxGroup values={statusEc} onChangeHandler={updateStatusEc} extraClasses="statusEc-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <Row>
                        <Col lg={6} md={12} className="col-xxl-3 col-xl-4 col-12">
                            <helpers.GenerateRadioGroup id="statusExtra" values={statusExtra} onChangeHandler={updateStatusExtra} extraClasses="statusExtra-container" />
                        </Col>
                        <Col lg={7} md={7} sm={6} className="flex flex-nowrap col-xl-4 col-11">
                            <helpers.GenerateCheckbox value={data.statusEcDispatchedSchedule} text={t('ecSampledDispatchedSchedule')} onChangeHandler={updateStatusEcDispatchedSchedule} extraClasses="statusEcDispatchedSchedule-container" />
                            <helpers.GenerateCheckbox value={data.statusVisitScheduled} text={t('visitScheduled')} onChangeHandler={updateStatusVisitScheduled} extraClasses="statusVisitScheduled-container" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="super-wide-input col-xxl-11 col-xl-12">
                    <helpers.GenerateCheckboxGroup text={t('status') + 2} values={status2} onChangeHandler={updateStatus2} extraClasses="status2-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <helpers.GenerateCheckboxGroup values={status2Ec} onChangeHandler={updateStatus2Ec} extraClasses="status2Ec-container" />
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={11} className="col-xxl-11 col-xl-10 col-10 offset-xxl-1 offset-md-2 offset-1">
                    <Row>
                        <Col lg={9} md={12} className="col-xxl-3 col-xl-4 col-12">
                            <helpers.GenerateRadioGroup id="status2Extra" values={status2Extra} onChangeHandler={updateStatus2Extra} extraClasses="status2Extra-container" />
                        </Col>
                        <Col lg={7} md={7} sm={6} className="flex flex-nowrap col-xl-4 col-11">
                            <helpers.GenerateCheckbox value={data.status2EcDispatchedSchedule} text={t('ecSampledDispatchedSchedule')} onChangeHandler={updateStatus2EcDispatchedSchedule} extraClasses="status2EcDispatchedSchedule-container" />
                            <helpers.GenerateCheckbox value={data.status2VisitScheduled} text={t('visitScheduled')} onChangeHandler={updateStatus2VisitScheduled} extraClasses="status2VisitScheduled-container" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={10} md={10} sm={11} className="col-10 offset-xxl-1 offset-md-2 offset-1">
                    <Row>
                        <Col lg={8} md={7} sm={12} className="col-xxl-5 col-xl-6 col-12">
                            <helpers.GenerateDateRange dateRange={data.status2workplaceSearchRange} onChangeHandler={updateStatus2WorkplaceSearchRange} showTimeSelect={true} text={t('recordDateTime')} showIcon={true} labelSize={3} extraClasses="status2workplaceSearchRange-container" />
                        </Col>
                        <Col lg={4} md={5} sm={12} className="col-xxl-5 col-12">
                            <helpers.GenerateSelector value={data.status2SelectedEmployee} text={t('contactName')} options={[{ value: null, text: null }].concat((_employeeList || []).map(_v => ({ value: _v.id, text: _v.name })))} onChangeHandler={updateStatus2SelectedEmployee} labelSize={4} inputSize={7} extraClasses="status2SelectedEmployee-container" />
                        </Col>
                    </Row>
                </Col>
                <Col lg={10} md={10} sm={11} className="col-10 offset-xxl-1 offset-md-2 offset-1">
                    <Row>
                        <Col lg={8} md={7} sm={12} className="col-xxl-5 col-xl-6 col-12">
                            <helpers.GenerateDateRange dateRange={data.status2EcLastCommunicationDate} onChangeHandler={updateStatus2EcLastCommunicationDate} showTimeSelect={true} text={t('ecLast') + t('dispatchDate')} labelSize={3} showIcon={true} extraClasses="status2EcLastCommunicationDate-container" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={8} sm={7} className="super-wide-input col-xxl-8 col-xl-9">
                    <helpers.GenerateCheckboxGroup text={[t('telEng'), t('timeRange')]} values={telAvailabilityRange} type={'number'} onChangeHandler={updateTelAvailabilityRange} labelSize={2} inputSize={10} extraClasses="telAvailabilityRange-container" />
                </Col>
                <Col md={4} sm={5} className="col-xxl-3 col-xl-3 col-10 offset-sm-0 offset-2">
                    <helpers.GenerateInputRange range={data.availabilityTimeRange} onChangeHandler={updateAvailabilityTimeRange} addon={t('hour')} addonRhs={t('approxTime')} extraClasses="availabilityTimeRange-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col sm={5} className="col-xxl-3 col-xl-4 col-7">
                    <Row>
                        <Col md={4} sm={5} className="flex flex-column flex-center form-group col-xxl-5 col-4">
                            <label className="bold">{t('saleDate')}</label>
                        </Col>
                        <Col md={8} sm={7} className="col-xxl-7 col-8">
                            <helpers.GenerateInput type={'number'} value={data.salesPeriod} text={t('after')} onChangeHandler={updateSalesPeriod} addon={t('months')} labelSize={3} extraClasses="salesPeriod-container" />
                        </Col>
                    </Row>
                </Col>
                <Col sm={7} className="flex flex-nowrap form-group col-xl-4 col-8 offset-sm-0 offset-2">
                    <helpers.GenerateCheckbox value={data.salesPeriodRetirement} text={t('retirement')} onChangeHandler={updateSalesPeriodRetirement} extraClasses="salesPeriodRetirement-container" />
                    <helpers.GenerateCheckbox value={data.salesPeriodMoving} text={t('moving')} onChangeHandler={updateSalesPeriodMoving} extraClasses="salesPeriodMoving-container" />
                    <helpers.GenerateCheckbox value={data.salesPeriodOther} text={t('other')} onChangeHandler={updateSalesPeriodOther} extraClasses="salesPeriodOther-container" />
                    <helpers.GenerateCheckbox value={data.salesPeriodPrivate} text={<strong>{t('private')}</strong>} onChangeHandler={updateSalesPeriodPrivate} extraClasses="salesPeriodPrivate-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="super-wide-input col-xxl-6 col-xl-7">
                    <helpers.GenerateCheckboxGroup text={t('inheritance')} values={inheritance} onChangeHandler={updateInheritanceTelAvailabilityRange} labelSize={2} inputSize={9} extraClasses="inheritance-container" />
                </Col>
                <Col md={12} className="col-xxl-5 col-xl-5">
                    <helpers.GenerateCheckboxGroup text={t('debtConsolidation')} values={debtConsolidation} onChangeHandler={updateDebtConsolidation} labelSize={2} inputSize={9} extraClasses="debtConsolidation-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={4} className="col-xxl-3 col-sm-11">
                    <helpers.GenerateCheckboxGroup text={t('registration')} values={registration} onChangeHandler={updateRegistration} labelSize={3} inputSize={7} extraClasses="registration-container" />
                </Col>
                <Col md={8} className="col-xxl-4 col-sm-12">
                    <helpers.GenerateInput value={data.ownerRemarks} text={t('ownerRemarks')} onChangeHandler={updateOwnerRemarks} labelSize={3} inputSize={7} extraClasses="ownerRemarks-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col sm={12} lg={7} md={8} className="col-xxl-3 col-xl-6">
                    <helpers.GenerateInputRange text={t('callCount')} type={'number'} range={data.callCount} onChangeHandler={updateCallCount} labelSize={4} labelSize={4} inputSize={7} extraClasses="callCount-container" />
                </Col>
                <Col sm={12} lg={7} md={8} className="col-xxl-4 col-xl-6">
                    <helpers.GenerateDateRange dateRange={data.lastCallDate} onChangeHandler={updateLastCallDate} text={t('lastCallDate')} labelSize={4} inputSize={7} showIcon={true} extraClasses="lastCallDate-container" />
                </Col>
                <Col sm={12} lg={7} md={8} className="col-xxl-5 col-xl-6">
                    <helpers.GenerateDateRange dateRange={data.externalDataAcquisitionDate} onChangeHandler={updateExternalDataAcquisitionDate} text={t('externalDataAcquisitionDate')} labelSize={4} inputSize={7} showIcon={true} extraClasses="externalDataAcquisitionDate-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row className="no-gutters">
                <Col lg={8} md={8} className="super-wide-input col-xxl-5 col-xl-7">
                    <helpers.GenerateCheckboxGroup text={t('dm')} values={dm} onChangeHandler={updateDm} labelSize={1} inputSize={10} extraClasses="dm-container" />
                </Col>
                <Col sm={4} className="flex flex-nowrap col-xl-4 offset-lg-0 offset-md-0 offset-1 col-4">
                    <helpers.GenerateCheckbox value={data.dmProhibited} text={<strong>{t('dmProhibited')}</strong>} onChangeHandler={updateDmProhibited} extraClasses="dmProhibited-container" />
                    <helpers.GenerateCheckbox value={data.dmNot} text={t('dmNot')} onChangeHandler={updateDmNot} extraClasses="dmNot-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={6} className="col-xxl-5">
                    <helpers.GenerateDateRange dateRange={data.dmLastSentDate} onChangeHandler={updateDmLastSentDate} text={t('dmLastSentDate')} showIcon={true} labelSize={3} extraClasses="dmLastSentDate-container" />
                </Col>
                <Col md={6} sm={8} className="col-xxl-4 col-xl-5 col-9">
                    <helpers.GenerateDatePicker value={data.dmLastSentDateNotSent} onChangeHandler={updateDmLastSentDateNotSent} text={t('dmLastSentDate') + '(' + t('notSent') + ')'} showIcon={true} labelSize={6} extraClasses="dmLastSentDateNotSent-container" />
                </Col>
            </Row>
            <div className="separator"></div>
            <Row>
                <Col md={12} className="form-submit-container">
                    <button type="button" className="btn btn-primary" onClick={_callBack && (() => { _callBack(data); })}>{t('search')}</button>
                    <button type="button" className="btn btn-secondary" onClick={clearHandler}>{t('clear')}</button>
                </Col>
            </Row>
        </form>
    </div>
}

OwnerForm.defaultProps = {
    extraClasses: ''
}
OwnerForm.whyDidYouRender = true;