import React from 'react';
import { formatDateTimeShort } from 'helpers/formatHelpers'

export function ReminderButton({ reminder: _reminder, selectedCallback: _selectedCallback, extraClasses: _extraClasses }) {

    const selectedHandler = _selectedCallback && _selectedCallback.bind(null, _reminder);

    const displayRender = (_r) => {
        if (!_r) return '';

        return (_r.name || _r.id) + ': ' + (_r.expiryDate && formatDateTimeShort(_r.expiryDate, false));
    };

    return <div className={"reminder-button " + _extraClasses}>
        <button className="reminder-label btn btn-secondary" onClick={selectedHandler}>{displayRender(_reminder)}</button>
    </div>;
}