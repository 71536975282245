import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';
import { SimpleToggle } from 'components/inputs/toggles';

export function FamilyPrivacy({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;
    return <>
        <wf.GroupField hideLabel={true}>
            <SimpleToggle label={t('familyPrivacy')} value={data.familyPrivacy.value || data.familyPrivacy.defaultValue || false} onChange={setData('familyPrivacy')} />
        </wf.GroupField>
    </>
}
export const FamilyPrivacyPopup = ExportDefaultBehaviour(FamilyPrivacy, {
    editableFields: ['familyPrivacy'],
    size: 'sm'
});
FamilyPrivacyPopup.whyDidYouRender = true;