import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function PropertyRemarks({ useStateHOC: _useStateHOC }) {
    const { t } = useTranslation();
    const [data, setData] = _useStateHOC;

    return <>
        <wf.InputField label={t('propertyRemarks')}
            value={data.remarks.value || data.remarks.defaultValue}
            onChange={setData('remarks')} />
    </>;
}

const PropertyRemarksPopup = ExportDefaultBehaviour(PropertyRemarks, {
    editableFields: ['remarks']
});
PropertyRemarksPopup.whyDidYouRender = true;
export default PropertyRemarksPopup;