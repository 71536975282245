const types = {
    displayMessage: 'SB_DISPLAY_MESSAGE',
    dismissStatusBar: 'SB_DISMISS_STATUSBAR'
};

/** Actions **/
export const displayMessage = ({ ...data }) => {
    return ({
        type: types.displayMessage,
        data
    })
};

export const dismissStatusBar = () => ({
    type: types.dismissStatusBar
});

/** Reducer **/
const reducer = (_state = {}, _action) => {
    let newState = { ..._state };
    switch (_action.type) {
        case types.displayMessage:
            newState = { ..._action.data };
            break;
        case types.dismissStatusBar:
            newState = {};
            break;
    }
    return newState;
}

export default reducer;