import { call, select, cancel, fork, take, takeLeading } from 'redux-saga/effects'
import * as sm from 'reducers/sessionManagerReducer';

const delay = _ms => new Promise(_resolve => setTimeout(_resolve, _ms))

function* checker(_function, _validationCallback, _errorCallback, _delay) {
    while (true) {
        const result = yield call(_function);
        const currentState = (yield select((_state) => _state.sessionManager)).state;
        if (currentState !== sm.States.Started) {
            break;
        } else {
            if (!_validationCallback(result)) {
                if (_errorCallback) _errorCallback();
            }
            else yield call(delay, 1000);
        }
    }
}

function* sessionWatcherSaga(_action) {
    try {
        while (yield take(sm.startSessionCheck().type)) {
            const bgTask = yield fork(checker, _action.checkCallback, (_args) => {
                console.log('Checker callback');
                console.log(_args);
                return true;
            }, _action.errorCallback);
            yield take(sm.stopSessionCheck().type);
            yield cancel(bgTask);
        }
    } catch (_e) {
        console.log('Error from requestRecordsSaga');
        console.log(_e);
    }
}

export function* watchSessionWatcherSaga() {
    yield takeLeading(sm.triggerSessionWatcher().type, sessionWatcherSaga);
}