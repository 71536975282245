import { combineReducers } from 'redux'
import teams from './teamReducers'
import fileManagement from './fileManagementReducers'
import priorityActions from './priorityActionsReducers'
import partners from './partnersReducers'
import reminders from './remindersReducers'
import callmodeSearch from './callmodeSearchReducers'
import employeesManager from './employeeManagerReducer'
import imageImport from './imageImportReducer'
import sessionManager from './sessionManagerReducer'
import statusBar from './statusBarReducer'
import userSales from './userSalesReducer'
import ex from './exportReducers'
import dm from './dmReducers'
import maintenance from './maintenanceReducers'
import importAll from './importAllReducers'
import assessmentRequest from './assessmentRequestReducers'

export default combineReducers({
    teams,
    fileManagement,
    priorityActions,
    partners,
    reminders,
    callmodeSearch,
    employeesManager,
    imageImport,
    sessionManager,
    statusBar,
    userSales,
    ex,
    dm,
    maintenance,
    importAll,
    assessmentRequest
});