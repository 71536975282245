import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResponsiveDatatable from 'components/tables/responsiveDatatable';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'

export function HistoryPannel({ mailTemplate: _mailTemplate, gyoushaData: _gyoushaData, rirekiData: _rirekiData, extraClasses: _extraClasses, onTemplateChange: _onTemplateChange, onGyoushaButtonChange: _onGyoushaButtonChange, onRirekiButtonChange: _onRirekiButtonChange, onMailButtonClick: _onMailButtonClick }) {
    const { t } = useTranslation();
    const [hansokuGyoushaData, setHansokuGyoushaData] = useState(_gyoushaData);
    const [hansokuRirekiData, setHansokuRirekiData] = useState(_rirekiData);
    const [mailTemplate, setMailTemplate] = useState(_mailTemplate);
    const [selectedEntryData, setSelectedEntryData] = useState(undefined);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    const handleHansokuRirekiTypeChange = (_newType) => {
        _onRirekiButtonChange(_newType);
    }

    const handleHansokuGyoushaTypeChange = (_newType) => {
        _onGyoushaButtonChange(_newType);
    }

    const callback = (_updated) => updateRecord(_updated);

    const updateRecord = (_value) => {
        const updated = [...hansokuGyoushaData];
        const index = updated.findIndex(_v => _v.id === _value.id);

        if (index >= 0) {
            updated[index] = _value;
            setHansokuGyoushaData(updated);
        }
    };

    const rereikiTableOptions = {
        columnsOptions: {
            registrationDate: {
                sortable: false
            },
            staffName: {
                sortable: false
            },
            gyoushaName: {
                sortable: false
            },
            zumengaku: {
                sortable: false
            },
            kibougaku: {
                sortable: false
                //type: 'editable'
            },
            hyoukagaku: {
                sortable: false
                //type: 'editable'
            }
        },
        style: { maxHeight: 140 },
        selectedCallback: (_rirekiData) => {
        }
    };

    const tableOptions = {
        columnsOptions: {
            affectedBuyer: {
                sortable: false
            },
            contactName: {
                sortable: false
            },
            buyerResponsible: {
                sortable: false
            },
            selected: {
                sortable: false,
                type: 'checkbox',
                width: 45
            },
            message: {
                sortable: false,
                options: [1, 2, 3, 4, 5, 6],
                type: 'dropdown',
                width: 80
            }
        },
        style: { maxHeight: 115 },
        selectedCallback: (_gyoushaData) => {
            updateRecord(_gyoushaData);
            setSelectedEntryData(_gyoushaData);
        }
    };

    useEffect(() => {
        if (_gyoushaData) {
            setHansokuGyoushaData(_gyoushaData);
        }
    }, [_gyoushaData]);

    useEffect(() => {
        if (_rirekiData) {
            setHansokuRirekiData(_rirekiData);
        }
    }, [_rirekiData]);

    useEffect(() => {
        if (_mailTemplate) {
            setMailTemplate(_mailTemplate);
            var temp = getMailTemplate();
            setTitle(temp ? temp.mailTitle : "");
            setBody(temp ? temp.mailBody : "");
        }
    }, [_mailTemplate]);

    const updateIndex = (_val) => {
        const index = _val.target.value - 1;
        if (index >= 0) {
            setCurrentIndex(index);
        }

        var temp = {};
        temp = getMailTemplate();
        temp.mailTitle = title;
        temp.mailBody = body;
        _onTemplateChange(temp);
    };

    const getMailTemplate = () => {
        const updated = [..._mailTemplate];
        let val = {};
        if (currentIndex >= 0) {
            val = updated[currentIndex];
        }
        return val;
    };

    const updateDataState = (_attr, _val) => {
        var temp = getMailTemplate();
        temp[_attr] = _val;
        mailTemplate[currentIndex] = temp;
        setMailTemplate(mailTemplate);
    }

    const onMailSendCallback = (v) => {
        var temp = [...hansokuGyoushaData];
        const gyoushaData = [];
        temp.forEach(function (_val) {
            if (_val['selected'] === true) {
                gyoushaData.push(_val);
            }
        });
        _onMailButtonClick(gyoushaData);
    }

    const updateMailTitle = (_v) => setTitle(_v.target.value);
    const updateMailBody = (_v) => setBody(_v.target.value);

    return <ReflexContainer orientation="horizontal">
        <ReflexElement flex={0.33}>
            <div className={"history-pannel " + _extraClasses} style={{ padding: 5 + 'px' }}>
                <div className="employee-history section flex flex-column">
                    <div className="title flex">
                        <div className="flex-grow-1 btn-group">
                            <button type="button" onClick={handleHansokuRirekiTypeChange.bind(null, 'all')} className="btn btn-secondary">{t('allHistory')}</button>
                            <button type="button" onClick={handleHansokuRirekiTypeChange.bind(null, 'hyouka')} className="btn btn-secondary">{t('evaluationHistory')}</button>
                        </div>
                    </div>
                    <div className="history-content">
                        <ResponsiveDatatable {...{ rows: hansokuRirekiData, ...rereikiTableOptions }} />
                    </div>
                </div>
            </div>
        </ReflexElement>
        <ReflexSplitter />
        <ReflexElement flex={0.29}>
            <div className={"history-pannel " + _extraClasses} style={{ padding: 5 + 'px' }}>
                <div className="third-party-history section">
                    <div className="title flex flex-column">
                        <div className="flex-grow-1 btn-group">
                            <button type="button" onClick={handleHansokuGyoushaTypeChange.bind(null, 'gyousha')} className="btn btn-secondary">{t('vendor')}</button>
                            <button type="button" onClick={handleHansokuGyoushaTypeChange.bind(null, 'end')} className="btn btn-secondary">{t('end')}</button>
                        </div>
                    </div>
                    <div className="third-party-content">
                        <ResponsiveDatatable {...{ rows: hansokuGyoushaData, ...tableOptions }} updateCallback={callback} />
                    </div>
                </div>
            </div>
        </ReflexElement>
        <ReflexElement flex={0.38}>
            <div className={"history-pannel " + _extraClasses} style={{ padding: 0 + 'px' + ' ' + 15 + 'px' }}>
                <div className="message-form section">
                    <form role="form" className="inner-message-form">
                        <div className="form-group row">
                            <label htmlFor="sampleSelector" className="col-sm-3 form-control-label form-text">{t('modelSample')}</label>
                            <div className="col-sm-3">
                                <select className="form-control form-control-sm" onChange={updateIndex} id="sampleSelector">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-text">{t('tagHowTo')}</div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="nameInput" className="col-sm-2 form-control-label form-text">{t('subject')}</label>
                            <div className="col-sm-10">
                                <input type="text" value={title} className="form-control" onChange={updateMailTitle} id="nameInput" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="contentInput" className="col-sm-2 form-control-label form-text">{t('bodyText')}</label>
                            <div className="col-sm-10">
                                <textarea rows="3" value={body} className="form-control" onChange={updateMailBody} id="contentInput"></textarea>
                            </div>
                        </div>
                        <div className="form-group" align="right">
                            <button type="submit" onClick={onMailSendCallback} className="btn btn-primary">{t('send')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </ReflexElement>
    </ReflexContainer>
}

HistoryPannel.defaultProps = {
    extraClasses: ''
};