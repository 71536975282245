import React, {useRef, Component} from 'react';
import { useTranslation } from 'react-i18next';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

class CompoundRow extends Component {

    shouldComponentUpdate(_nextProps) {
        return this.props.index !== _nextProps.index ||
            this.props.walkingTime !== _nextProps.walkingTime ||
            this.props.busTime !== _nextProps.busTime ||
            this.props.walkingTimeErrorFlag !== _nextProps.walkingTimeErrorFlag ||
            this.props.busTimeErrorFlag !== _nextProps.busTimeErrorFlag;
    }

    render() {
        return <wf.GroupField
            label={`${this.props.translator('walkingTime')} ${this.props.index}`}
            className="form-group">
            <div className="row no-gutters fill-w">
                <div className="col-xs-4">
                    <wf.InputField hideLabel={true}
                        extraClasses="no-margin"
                        value={this.props.walkingTime}
                        validator={this.props.validator}
                        onChange={this.props.handlers.walkingTimeHandler}
                        onError={this.props.handlers.walkingTimeHandler}
                        addon={this.props.translator('minutes')}
                        errorMessage={this.props.errorMessage} />
                </div>
                <div className="col-xs-8">
                    <div className="row no-gutters">
                        <div className="col-xs-6 flex flex-column justify-center">
                            <label className="inline-label">{`${this.props.translator('bus')} ${this.props.index}`}</label>
                        </div>
                        <div className="col-xs-6">
                            <wf.InputField hideLabel={true}
                                extraClasses="no-margin"
                                value={this.props.busTime}
                                validator={this.props.validator}
                                onChange={this.props.handlers.busTimeHandler}
                                onError={this.props.handlers.busTimeHandler}
                                addon={this.props.translator('minutes')}
                                errorMessage={this.props.errorMessage} />
                        </div>
                    </div>
                </div>
            </div>
        </wf.GroupField>;
    }
}

function InputGroup({ index: _index, lineName: _lineName, stationName: _stationName, walkingTime: _walkingTime, busTime: _busTime, walkingTimeErrorFlag: _walkingTimeErrorFlag, busTimeErrorFlag: _busTimeErrorFlag, handlers: _handlers, validator: _validator, errorMessage: _errorMessage, translator: _t}) {

    return <>
        <wf.InputField
            label={`${_t('lineName')} ${_index}`}
            value={_lineName}
            className="form-group"
            onChange={_handlers.lineNameHandler}/>
        <wf.InputField
            label={`${_t('stationName')} ${_index}`}
            value={_stationName}
            className="form-group"
            onChange={_handlers.stationNameHandler}
            addon={_t('station')}/>
        <CompoundRow index={_index}
            walkingTime={_walkingTime}
            busTime={_busTime}
            walkingTimeErrorFlag={_walkingTimeErrorFlag}
            busTimeErrorFlag={_busTimeErrorFlag}
            handlers={_handlers}
            validator={_validator}
            errorMessage={_errorMessage}
            translator={_t}/>
    </>;
}

const groupIndexes = ['1', '2', '3'];
function Station({ useStateHOC: _useStateHOC, validatorsHOC: _validatorsHOC, errorHandlingHOC: _errorHandlingHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;
    const handlers = useRef(groupIndexes.reduce((_a, _b) => _a.concat({
            lineNameHandler: setData(`lineName${_b}`),
            stationNameHandler: setData(`stationName${_b}`),
            walkingTimeHandler: setData(`walkingTime${_b}`),
            busTimeHandler: setData(`busTime${_b}`)
    }), []));
    const errorMessage = useRef(t('incorrectNumberFormatError'));
    const validator = useRef(_validatorsHOC.optionalDayHours);

    return <>{groupIndexes.map((_v, _i) => <InputGroup key={_v} index={_v}
        lineName={data[`lineName${_v}`].value || data[`lineName${_v}`].defaultValue}
        stationName={data[`stationName${_v}`].value || data[`stationName${_v}`].defaultValue}
        walkingTime={data[`walkingTime${_v}`].value || data[`walkingTime${_v}`].defaultValue}
        busTime={data[`busTime${_v}`].value || data[`busTime${_v}`].defaultValue}
        walkingTimeErrorFlag={data[`walkingTime${_v}`] && data[`walkingTime${_v}`].hasError}
        busTimeErrorFlag={data[`busTime${_v}`] && data[`busTime${_v}`].hasError}
        handlers={handlers.current[_i]}
        validator={validator.current}
        errorMessage={errorMessage.current}
        translator={t} />)}</>;
}
const StationPopup = ExportDefaultBehaviour(Station, {
    editableFields: groupIndexes.reduce((_a, _b) => _a.concat([`lineName${_b}`, `stationName${_b}`, `walkingTime${_b}`, `busTime${_b}`]), []),
    extraClasses: 'push-up'
});
StationPopup.whyDidYouRender = true;
export default StationPopup;