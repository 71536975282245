import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { DescriptionGroup } from './descriptionGroup';

export class StatsDescription extends Component {

    render() {
        const content = <DescriptionGroup
            fields={this.props.values}
            handleChangedCallback={this.props.handleChangedCallback ? this.props.handleChangedCallback : undefined} />;
        const attributeLength = Object.keys(this.props.values).length;
        return (
            <div className={this.props.extraClasses}>

                <div className="card-header-column">
                    <div className="header-block">
                        <div className="rating"><h3 className="title">{this.props.ranking}</h3></div>
                        <h3 className="title">{this.props.name}</h3>
                    </div>
                    <div className="fill-w">
                        {attributeLength <= 6 &&

                            <Col sm={attributeLength * 2}>
                            {content}
                            </Col>
                        }
                        {attributeLength > 6 && content}
                        
                    </div>
                </div>
            </div>
        );
    }
}

StatsDescription.defaultProps = {
    extraClasses: '',
    ranking: 0,
    name: '',
    values: [],
    members: []
};