import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleToggle } from 'components/inputs/toggles';
import ExportDefaultBehaviour from 'components/callmode/floatingControls/floatingWindow';
import * as wf from 'components/callmode/floatingControls/windowFields';

function LandRights({ useStateHOC: _useStateHOC, checkboxGroupHOC: _checkboxGroupHOC, validatorsHOC: _validatorsHOC }) {
    const { t } = useTranslation();

    const [data, setData] = _useStateHOC;

    const landRightsTypes = ["unspecified", "ownership", "lease"].reduce(_checkboxGroupHOC.checkboxGroupReducer('landRightsTypes'), {});
    return <>
        <wf.CheckboxGroupField hideLabel={true} values={landRightsTypes} onChange={(_v) => setData('landRightsTypes')(_checkboxGroupHOC.checkboxGroupHandlerDecorator(_v))} radioName="landRightsTypes" />
    </>;
}

const LandRightsPopup = ExportDefaultBehaviour(LandRights, {
    editableFields: ['landRightsTypes'],
    size:"sm"
});
LandRightsPopup.whyDidYouRender = true;
export default LandRightsPopup;