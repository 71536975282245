import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { imagesImportAction } from 'reducers/priorityActionsReducers';
import { connect } from 'react-redux';
import ModuleRow, * as mr from './moduleRow';
import FinancialInstitutionPopup from './loanPopups/financialInstitutionPopup';
import LoanAmountPopup from './loanPopups/loanAmountPopup';
import ResidualDebtPopup from './loanPopups/residualDebtPopup';
import LiquidationPopup from './loanPopups/liquidationPopup';
import InterestRatePopup from './loanPopups/interestRatePopup';
import MonthlyRepaymentPopup from './loanPopups/monthlyRepaymentPopup';
import IncomeExpenditureStatusPopup from './loanPopups/incomeExpenditureStatusPopup';
import BankDebtPopup from './loanPopups/bankDebtPopup';
import LoanNotesPopup from './loanPopups/loanNotesPopup';
import { requestUpdate } from 'reducers/callmodeSearchReducers';
import { requestUpdateImage } from 'reducers/partnersReducers';

export class LoanModule extends Component {

    constructor(_props) {
        super(_props);

        const reduceBooleanObject = (_v) => mr.reduceBooleanObject(_v, this.props.t);

        const generateOnActiveHandler = (_window) => this.props.onActive ? () => {
            this.props.onActive(_window);
        } : () => { }

        const popupGenerator = (Component) => mr.popupGenerator(Component, this.props.onChange, this.props.onIdle);
        const mapper = _v => ({ ..._v, label: this.props.t(_v.label || _v.key), onActive: this.popups[_v.key + 'Popup'] ? generateOnActiveHandler(this.popups[_v.key + 'Popup']) : undefined });

        this.moduleRowPropGenerator = (_rowData, _props) => ({
            ..._rowData,
            content: _rowData.content ? _rowData.content(_props[_rowData.key] || {}, _props) : [(_props[_rowData.key] || {})[_rowData.key]]
        });

        this.popups = {
            financialInstitutionPopup: popupGenerator(FinancialInstitutionPopup),
            loanAmountPopup: popupGenerator(LoanAmountPopup),
            residualDebtPopup: popupGenerator(ResidualDebtPopup),
            liquidationPopup: popupGenerator(LiquidationPopup),
            interestRatePopup: popupGenerator(InterestRatePopup),
            monthlyRepaymentPopup: popupGenerator(MonthlyRepaymentPopup),
            incomeExpenditureStatusPopup: popupGenerator(IncomeExpenditureStatusPopup),
            bankDebtPopup: popupGenerator(BankDebtPopup),
            loanNotesPopup: popupGenerator(LoanNotesPopup)
        };

        this.loanEviHandler = () => {
            this.props.imagesImportAction(
                this.props.imageRecords.loanEvi ? this.props.imageRecords.loanEvi.images : [{}, {}, {}, {}],
                (_received) => {
                    if (this.props.imageImportType === 'callmodePage')
                        this.props.requestUpdate(_received, "loanEvi", this.props.data.bukkenId);
                    else if (this.props.imageImportType === 'partnersPage')
                        this.props.requestUpdateImage(_received, "loanEvi", this.props.data.bukkenId);
                }
            );
        };

        this.section1Rows = [
            {
                key: 'financialInstitution', content: (_d) => {
                    let financialInstitutionType = "";
                    let jointCollateral = "";
                    let mortgage = "";

                    if (_d.financialInstitutionType) {
                        if (_d.financialInstitutionType.unspecified) {

                        } else if (_d.financialInstitutionType.other) {
                            financialInstitutionType = _d.financialInstitutionOther
                        } else {
                            if (_d.financialInstitutionType.jaccs) {
                                financialInstitutionType = this.props.t('jaccs');
                            } else if (_d.financialInstitutionType.orix) {
                                financialInstitutionType = this.props.t('orix');
                            } else if (_d.financialInstitutionType.suruga) {
                                financialInstitutionType = this.props.t('suruga');
                            } else if (_d.financialInstitutionType.mizuho) {
                                financialInstitutionType = this.props.t('mizuho');
                            } else if (_d.financialInstitutionType.orico) {
                                financialInstitutionType = this.props.t('orico');
                            } else if (_d.financialInstitutionType.kansaiUrbanBank) {
                                financialInstitutionType = this.props.t('kansaiUrbanBank');
                            }
                        }
                    }

                    if (_d.loanType) {
                        if (_d.loanType.jointCollateral) {
                            jointCollateral = this.props.t('jointCollateral');
                        }
                        if (_d.loanType.mortgage) {
                            mortgage = this.props.t('mortgage');
                        }
                    }


                    return [financialInstitutionType, jointCollateral + "  " + mortgage];
                }
            },
            {
                key: 'loanAmount', content: (_d) => [
                    _d.loanAmount ? _d.loanAmount + this.props.t('10kYen') : undefined,
                    _d.deposit ? this.props.t('deposit') + _d.deposit + this.props.t('10kYen') : undefined]
            },
            {
                key: 'residualDebt', content: (_d) => {
                    let existenceType = "";
                    let amount = ""
                    let month = "";
                    let year = "";
                    let withPrepayment = "";
                    let unVisibleFlag = false;
                    let withoutFlag = false;

                    if (_d.existenceType) {
                        if (_d.existenceType.unspecified) {
                            unVisibleFlag = true;
                        } else if (_d.existenceType.without) {
                            existenceType = this.props.t('without')
                            withoutFlag = true;
                        } else if (_d.existenceType.with) {
                            existenceType = this.props.t('with');
                        }
                    }
                    if (!unVisibleFlag) {
                        if (_d.amount && !withoutFlag) {
                            amount = _d.amount ? _d.amount + this.props.t('10kYen') : "";
                        }
                        if (_d.month && !withoutFlag) {
                            month = _d.month ? _d.month + this.props.t('month') + this.props.t('pointInTime') : "";
                        }
                        if (_d.year && !withoutFlag) {
                            year = _d.year ? _d.year + this.props.t('year') : "";
                        }

                        if (_d.withPrepayment) {
                            if (_d.prepaymentAmount) {
                                withPrepayment = _d.prepaymentAmount + this.props.t('10kYen')
                            } else {
                                withPrepayment = this.props.t('withPrepayment');
                            }

                        }
                    }
                    return [existenceType, amount + "  " + year + month, withPrepayment]
                }
            }

        ].map(mapper);

        this.section2Rows = [
            {
                key: 'liquidation', content: (_d) => [
                    (_d.year != null ? _d.year + "/" : '') + (_d.month != null ? _d.month : '')
                ]
            },
            { key: 'interestRate', content: (_d) => [_d.interestRate ? _d.interestRate + "%" : undefined] },
            { key: 'monthlyRepayment', content: (_d) => [_d.monthlyRepayment ? _d.monthlyRepayment + this.props.t('en') : undefined] },
            {
                key: 'incomeExpenditureStatus', content: (_d) => {

                    let status = "";
                    let amount = "";

                    if (_d.statusType) {
                        if (_d.statusType.plus) {
                            //プラスの時は値段はにゅうりょくちそのまま、マイナスの時は入力値にマイナスの記号を付けて表示を行う
                            status = "プラス";
                        } else if (_d.statusType.minus) {
                            status = "マイナス";
                        } else if (_d.statusType.unspecified) {
                            //ここでは金額の入力値がリセットされて表示が行われていなかった。だからなにも表示しない。消去する機能はPopUpに書いたほうがいいと思う
                            status = "";
                        }
                    }

                    if (_d.amount) {
                        if (status === "マイナス") {
                            amount = "-" + _d.amount + this.props.t('en');
                        } else if (status === "プラス") {
                            amount = _d.amount + this.props.t('en');
                        }
                    }



                    return [status, amount]
                }

            },
            {
                key: 'bankDebt', content: (_d) => {
                    let debtType = "";
                    let savings = "";
                    let debt = "";
                    let remarks = "";
                    let doneFlag = false;

                    if (_d.debtType) {
                        if (_d.debtType.done) {
                            debtType = "可";
                            doneFlag = true;
                        } else if (_d.debtType.improper) {
                            debtType = "不可";
                        } else if (_d.debtType.unspecified) {

                        }
                    }

                    if (_d.savings && doneFlag) {
                        savings = _d.savings ? this.props.t('savings') + ":" + _d.savings + this.props.t('10kYen') + " " : ""
                    }
                    if (_d.debt && doneFlag) {
                        debt = _d.debt ? this.props.t('debt') + _d.debt + this.props.t('10kYen') : ""
                    }
                    if (_d.remarks) {
                        remarks = _d.remarks ? _d.remarks : ""
                    }
                    return [debtType, savings + debt, remarks]

                }
            },
            { key: 'loanNotes', content: (_d) => [_d.remarks ? _d.remarks : undefined] }
        ].map(mapper);

    }

    //表示が行われる条件
    //①sectionRowsにkeyとコンテントが設定されている
    //②コンテントの中に、_dに続く形で、ポップアップの中で使用している名前で設定が行われている


    render() {
        return (
            <>
                <div className={`loan-module module-container ${this.props.extraClasses}`}>
                    {this.section1Rows.map(_r => {
                        return <ModuleRow {...this.moduleRowPropGenerator(_r, this.props.data)} />;
                    })
                    }
                    <div className="row no-gutters justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={this.loanEviHandler}>{this.props.t('loanEvi')}</button>
                    </div>
                    {this.section2Rows.map(_r => {
                        return <ModuleRow {...this.moduleRowPropGenerator(_r, this.props.data)} />;
                    })
                    }
                </div>
            </>);
    }
}
LoanModule.defaultProps = {
    extraClasses: '',
    data: {}
}

const mapDispatchToProps = {
    imagesImportAction: imagesImportAction,
    requestUpdate: requestUpdate,
    requestUpdateImage: requestUpdateImage

}

export default connect(null, mapDispatchToProps)(withTranslation()(LoanModule));